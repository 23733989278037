import React, { useState } from 'react';
import { useQuery, useLazyQuery } from 'react-apollo';
import securePage from '../hocs/securePage';
import useNotifier from '../hooks/useNotifier';
import {
  IPlaces,
  IPlacesDetailed,
  IPlaceCategories
} from '../interfaces/Place/Place';
import {
  PLACES,
  PLACE_CATEGORIES,
  PLACE_EXPORT
} from '../graphql/queries/PlaceQueries';
import Layout from '../components/Layout';
import Section from '../components/Section';
import PlaceTable from '../components/PlaceTable';
import { TABLE_ROWS_DEFAULT } from '../constants';
import { IExportPlaceStatus, exportPlaces } from '../utils/export';
import { getLastSearch } from '../store/localStorage/lastSearch';

export interface IFilters {
  text: string;
  active?: boolean;
  zipCodes?: string[];
  regions?: string[];
  categoryIds?: string[];
  exactCategories?: boolean;
  fenceId?: string;
}

export type ISort = { [field: string]: 'ASC' | 'DESC' };

const initialFilterObject: IFilters = {
  text: "",
  exactCategories: false
}

const Places: React.FC = () => {
  const notifier = useNotifier();
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROWS_DEFAULT);
  const [filters, setFilters] = useState<IFilters>(getLastSearch("place") !== null ? getLastSearch("place") : initialFilterObject);
  const [sort, setSort] = useState<ISort>({ name: 'ASC' });
  const {
    text,
    active,
    zipCodes,
    regions,
    exactCategories,
    categoryIds,
    fenceId
  } = filters;

  const [exportStatus, setExportStatus] = useState<IExportPlaceStatus>({
    isExporting: false,
    itemsToFetch: 0,
    places: [],
    filter: {
      text,
      zipCodes,
      regions,
      exactCategories,
      categoryIds,
      fenceId
    },
    sort: { ...sort },
    active
  });

  const { data: categories, loading: catLoading } = useQuery<IPlaceCategories>(
    PLACE_CATEGORIES
  );
  const { data, loading, error } = useQuery<IPlaces>(PLACES, {
    variables: {
      active,
      skip: currentPage * rowsPerPage,
      take: rowsPerPage,
      sort,
      filter: {
        text,
        zipCodes,
        regionIds: regions,
        exactCategories,
        categoryIds,
        fenceId
      }
    }
  });

  const [getPlaceDetails] = useLazyQuery<IPlacesDetailed>(PLACE_EXPORT, {
    variables: {
      skip: 0,
      take: 20000,
      filter: exportStatus.filter,
      sort: exportStatus.sort,
      active: exportStatus.active
    },
    onCompleted: exportData,
    onError: () => {
      setExportStatus(prevState => ({ ...prevState, isExporting: false }));
      notifier.notify('Villa kom upp', { variant: 'error' });
    }
  });

  const places = data ? data.Places.Places : [];
  const count = data ? data.Places.count : 0;

  return (
    <Layout useDefaultSpacing>
      <Section
        title="Staðir"
        link="/place/new"
        linkText="Skrá nýjan stað"
        loading={loading || catLoading}
        error={error ? error.message : ''}
      >
        <PlaceTable
          places={places}
          placeCategories={categories}
          count={count}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          initialFilters={filters}
          sort={sort}
          setCurrentPage={setCurrentPage}
          setRowsPerPage={setRowsPerPage}
          searchForPlaces={setFilters}
          updateSort={setSort}
          exportData={getExportData}
          exportStatus={exportStatus}
        />
      </Section>
    </Layout>
  );

  function getExportData(filter: IFilters) {
    const {
      text,
      regions,
      exactCategories,
      categoryIds,
      fenceId,
      active
    } = filter;
    setExportStatus({
      isExporting: true,
      itemsToFetch: data ? data.Places.count : 0,
      places: [],
      filter: {
        text,
        zipCodes,
        regionIds: regions,
        exactCategories,
        categoryIds,
        fenceId
      },
      sort: { ...sort },
      active
    });
    getPlaceDetails();
  }

  function exportData(detailsData: IPlacesDetailed) {
    if (exportStatus.isExporting) {
      const newPlaces = detailsData ? detailsData.Places.Places : [];
      exportPlaces([...exportStatus.places, ...newPlaces]);
      setExportStatus(prevState => ({
        ...prevState,
        isExporting: false
      }));
    }
  }
};

export default securePage(Places);

import { useMutation } from '@apollo/react-hooks';
import MomentUtils from '@date-io/moment';
import Joi from '@hapi/joi';
import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Tab,
  Tabs,
  TextField
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import { Autocomplete } from '@material-ui/lab';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import arrayMove from 'array-move';
import { Form, Formik, FormikProps } from 'formik';
import includes from 'lodash/includes';
import remove from 'lodash/remove';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import Dropzone from 'react-dropzone';
import EditCollection from '../components/EditCollection';
import EditPhotoCollection from '../components/EditPhotoCollection';
import EditPhotos from '../components/EditPhotos';
import EditSigns from '../components/EditSigns';
import Section from '../components/Section';
import { DATE_AND_TIME_FORMAT } from '../constants';
import {
  serviceProviderEdit,
  serviceProviderRemove,
  serviceProviderUpload
} from '../graphql/mutations/images';
import {
  ADD_COLLECTION,
  ADD_PERMIT_IMAGE,
  DELETE_COLLECTION,
  DELETE_PERMIT_IMAGE,
  EDIT_COLLECTION
} from '../graphql/mutations/serviceProvider';
import { GET_DATA } from '../graphql/queries/Misc';
import {
  ALL_SERVICE_PROVIDERS,
  CLEAN_AND_SAFE_EMAIL
} from '../graphql/queries/ServiceProviderQueries';
import useAdminChecker from '../hooks/useAdminChecker';
import useEditorChecker from '../hooks/useEditorChecker';
import useEditorRegionSelector from '../hooks/useEditorRegionSelector';
import useNotifier from '../hooks/useNotifier';
import {
  IChargingStation,
  IChargingStationCategories,
  IChargingStationCategory,
  IChargingStationProvider,
  IChargingStationProviders,
  IChargingStations
} from '../interfaces/ChargingStation/ChargingStation';
import { ICodes, IDataNew } from '../interfaces/Misc/zipCodes';
import { ITranslation } from '../interfaces/Place/Place';
import {
  ICollectionContent,
  IPhoto,
  IProvider,
  IServiceProvidersExtraLite,
  ISign,
  ImageFile
} from '../interfaces/ServiceProvider/ServiceProvider';
import { assistanceTexts } from '../utils/serviceProviderFieldTooltips';
import ConfirmDialog from './ConfirmDialog';
import EditTranslations from './EditTranslations';
import LocationInput from './LocationInput';
import { IPhotoForm } from './PhotoItem';
import SavePrompt from './SavePrompt';
import SelectChargingStation from './SelectChargingStation';
import SelectPlace from './SelectPlace';
import SelectProvider from './SelectProvider';

import {
  GET_CATEGORIES,
  GET_CHARGINGSTATIONS,
  GET_PROVIDERS
} from '../graphql/queries/ChargingStations';
import ChargingStationTable from './ChargingStationsTable';

interface IProps {
  serviceProviderId: string;
  serviceProvider: IProvider;
  handleFormSubmit: (values: any, signs: ISign[]) => void;
  latest: boolean;
  hasLatestVersion: boolean;
  handleVersionChange: (latest: boolean) => void;
  editServiceProvider: boolean;
  confirmServiceProvider?: () => void;
  rejectServiceProvider?: () => void;
  deleteServiceProvider?: () => void /*   movePhoto?: ({
    newIndex,
    oldIndex
  }: {
    newIndex: number;
    oldIndex: number;
  }) => void;

  cancelMovePhoto?: () => void; */;
  /*   updateTranslations?: (translations: ITranslation[]) => void;
   */ pending?: boolean;
  validateServiceProvider?: () => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

type IFormik = FormikProps<any>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      padding: theme.spacing(2)
    },
    headline: {
      padding: theme.spacing(2)
    },
    imgRoot: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden'
    },
    gridList: {
      flexWrap: 'nowrap',
      transform: 'translateZ(0)',
      width: '100%'
    },
    title: {
      color: theme.palette.primary.light
    },
    titleBar: {
      //background: 'none'
      background:
        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
    },
    formControl: {
      minWidth: 200
    },
    textField: {
      //minWidth: 200,
      width: 200
    },
    typographyTitle: {
      padding: 20,
      paddingBottom: 5,
      paddingTop: 10
    },
    paperSections: {
      marginTop: 20
    },
    typography: {
      display: 'inline'
    },
    typographyStrong: {
      fontWeight: 700,
      display: 'inline'
    },
    seeMoreGrid: {
      display: 'flex',
      justifyContent: 'flex-end',
      margin: 10
    },
    typographyDisabled: {
      color: 'rgba(0, 0, 0, 0.5)'
    },
    popoverTypography: {
      padding: theme.spacing(2)
    },
    aliasList: {
      display: 'inline-block',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      margin: 0
    },
    aliasChip: {
      margin: theme.spacing(0.5)
    }
  })
);

const EditServiceProvider: React.FC<IProps> = ({
  serviceProviderId,
  serviceProvider,
  handleFormSubmit,
  latest,
  hasLatestVersion,
  handleVersionChange,
  editServiceProvider,
  confirmServiceProvider,
  rejectServiceProvider,
  deleteServiceProvider /*   movePhoto,
  cancelMovePhoto, */,
  /*   updateTranslations = () => null,
   */ pending,
  validateServiceProvider
}) => {
  const [tabValue, setTabValue] = React.useState(0);
  const [name, setName] = useState('');
  const [zipCodeOptions, setZipCodeOptions] = useState<ICodes[] | null>(null);
  const [townCodeOptions, setTownCodeOptions] = useState<ICodes[] | null>(null);
  const [regionsOptions, setRegionsOptions] = useState<ICodes[] | null>(null);
  const [allServicesOptions, setAllServicesOptions] = useState<ICodes[] | null>(
    null
  );
  const [isSortingPhotos, setSortingPhotos] = useState<boolean>(false);
  const [photoMovedCounter, changePhotoMoved] = useState<number>(0);
  const [signsOptions, setSignsOptions] = useState<ICodes[] | null>(null);
  const [categoriesOptions, setCategoriesOptions] = useState<any>(null);
  const [latestVersion, setLatestVersion] = useState(latest);
  const regionIds = serviceProvider.regions.map(region => region.id);
  const [signs, setSigns] = useState<ISign[]>(
    serviceProvider.signs ? serviceProvider.signs : []
  );
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [popoverText, setPopoverText] = useState('');
  const [deletePermitImg, setDeletePermitImg] = useState(false);
  var initialAliasKey = 0;
  const [aliasList, setAliasList] = useState(
    serviceProvider.aliases
      ? serviceProvider.aliases.map(a => {
        initialAliasKey = initialAliasKey + 1;
        return {
          key: initialAliasKey,
          label: a
        };
      })
      : []
  );
  const [aliasKey, setAliasKey] = useState(initialAliasKey);
  const isAdmin = useAdminChecker();
  const isEditor = useEditorChecker();
  const userRegions = useEditorRegionSelector();
  const isAdminEditor: boolean = isAdmin || isEditor;
  const notifier = useNotifier();
  const history = useHistory();

  const [imageUpload] = useMutation(serviceProviderUpload, {
    refetchQueries: [`getServiceProvider`]
  });
  const [imageDelete] = useMutation(serviceProviderRemove, {
    refetchQueries: [`getServiceProvider`]
  });
  const [imageEdit] = useMutation(serviceProviderEdit, {
    refetchQueries: [`getServiceProvider`]
  });
  const [AddCollection] = useMutation(ADD_COLLECTION, {
    refetchQueries: [`getServiceProvider`]
  });
  const [EditExistingCollection] = useMutation(EDIT_COLLECTION, {
    refetchQueries: [`getServiceProvider`]
  });
  const [DeleteCollection] = useMutation(DELETE_COLLECTION, {
    refetchQueries: [`getServiceProvider`]
  });

  const [permitImageUpload] = useMutation(ADD_PERMIT_IMAGE, {
    refetchQueries: ['getServiceProvider']
  });

  const [permitImageDelete] = useMutation(DELETE_PERMIT_IMAGE, {
    refetchQueries: ['getServiceProvider']
  });

  const {
    loading: sLoading,
    error: sError,
    data: sData
  } = useQuery<IChargingStations>(GET_CHARGINGSTATIONS, {
    variables: {
      skip: 0,
      take: 10000
    }
  });

  const {
    loading: pLoading,
    error: pError,
    data: pData
  } = useQuery<IChargingStationProviders>(GET_PROVIDERS);
  const {
    loading: cLoading,
    error: cError,
    data: cData
  } = useQuery<IChargingStationCategories>(GET_CATEGORIES);
  //const place = data ? data.Place : null;

  const [stations, setStations] = useState<IChargingStation[]>([]);
  const [categories, setCategories] = useState<IChargingStationCategory[]>([]);
  const [providers, setProviders] = useState<IChargingStationProvider[]>([]);

  const basicInfo = ['legalName', 'SSN', 'translations'];
  const namesBasicInfo = ['Lögheiti', 'Kennitala'];
  const addressInfo = [
    'address',
    'poBox',
    'zipCode',
    'townCode',
    'latitude',
    'longitude'
  ];

  const namesAddressInfo = [
    'Heimilisfang',
    'Pósthólf',
    'Póstnúmer',
    'Sveitafélagsnúmer',
    'Breiddargráða',
    'Lengdargráða'
  ];

  const networkInfo = [
    'email',
    'email2',
    'phoneNr',
    'phoneNr2',
    'website',
    'website2',
    'facebook',
    'instagram',
    'tripadvisor',
    'youtube'
  ];
  const namesNetworkInfo = [
    'Netfang',
    'Netfang 2',
    'Símanúmer',
    'Símanúmer 2',
    'Vefsíða',
    'Vefsíða 2',
    'Facebook',
    'Instagram',
    'Tripadvisor',
    'Youtube'
  ];

  const typeOfServiceInfo = ['eService'];
  const namesTypeOfServiceInfo = ['Eingöngu rafræn þjónusta'];

  const serviceInfo = ['nrOfHouses', 'nrOfApartments', 'nrOfRooms', 'nrOfBeds'];
  const namesServiceInfo = [
    'Fjöldi húsa',
    'Fjöldi íbúða',
    'Fjöldi herbergja',
    'Fjöldi rúma'
  ];

  const vakinn = [
    'isApprovedRestaurant',
    'isApprovedTourismService',
    'ecoLabel',
    'stars',
    'superior',
    'iso',
    'swan',
    'blueFlag',
    'earthcheck',
    'greenKey'
  ];

  const namesVakinn = [
    'Er viðurkenndur veitingastaður',
    'Er viðurkennd ferðaþjónusta',
    'Umhverfismerki',
    'Stjörnur',
    'Superior',
    'Gæðavottað hostel',
    'Gæðavottað orlofshús- íbúð',
    'Gæðavottað tjaldsvæði',
    'Gæðavottað gistiheimili',
    'Gæðavottuð heimagisting'
  ];

  const chargingStations = ['chargingStationIds'];

  const namesChargingStations = ['Hleðlustöðvar'];

  const openingHours = ['seasonal', 'from', 'to'];
  const namesOpeningHours = ['Árstíðarbundið', 'Frá', 'Til'];

  const contactInfo = [
    'contactName',
    'contactEmail',
    'contactPhone',
    'contactComment'
  ];
  const namesContactInfo = ['Nafn', 'Netfang', 'Sími', 'Athugasemd'];

  const relatedServiceProvidersInfo = ['serviceProviderIds'];
  const namesRelatedServiceProvidersInfo = ['Tengdar þjónustur'];

  const placesInfo = ['places'];
  const namesPlacesInfo = ['Tengdir staðir'];

  const giftcertInfo = ['giftcert', 'isat'];
  const namesGiftcertInfo = ['Gjafabréf', 'ÍSAT'];

  const cleanAndSafeInfo = ['cleanAndSafe'];
  const namesCleanAndSafeInfo = ['Clean&Safe'];

  const accessibleInfo = ['accessible'];
  const namesAccessibleInfo = ['Gott aðgengi'];

  const permitInfo = ['permitId', 'permitIssued', 'aliases'];
  const namesPermitInfo = [
    'Leyfisnúmer',
    'Útgáfudagur Leyfis',
    'Bæta við hjáheiti'
  ];

  const permitImgInfo = ['permitImg'];
  const namesPermitImgInfo = ['Leyfi'];

  const multiline = ['contactComment'];

  const required = [
    'SSN',
    'legalName',
    'address',
    'regions',
    'zipCode',
    'phoneNr',
    'email',
    /*     'website',
     */ 'latitude',
    'longitude'
    /* 'purhcasedRegistration' */
  ];

  const disabled = {
    legalName: isEditor,
    giftcert: !isAdmin,
    cleanAndSafe: !isAdmin,
    isat: !isAdmin,
    isApprovedRestaurant: !isAdmin,
    isApprovedTourismService: !isAdmin,
    ecoLabel: !isAdmin,
    stars: !isAdmin,
    superior: !isAdmin,
    places: !isAdminEditor,
    serviceProviderIds: !isAdminEditor,
    accessible: !isAdminEditor
  };

  const selectAllowedList = [
    'zipCode',
    'townCode',
    'regions',
    'eService',
    'isApprovedRestaurant',
    'isApprovedTourismService',
    'superior',
    'ecoLabel',
    'stars',
    'seasonal',
    'from',
    'to',
    'active',
    'purhcasedRegistration',
    'serviceProviderIds',
    'places',
    'giftcert',
    'cleanAndSafe',
    'iso',
    'swan',
    'blueFlag',
    'earthcheck',
    'greenKey',
    'accessible'
  ];
  const optionsList = {
    zipCode: zipCodeOptions,
    townCode: townCodeOptions,
    regions: regionsOptions,
    purhcasedRegistration: regionsOptions,
    serviceProviderIds: allServicesOptions
  };

  const labelWidth = {
    eService: 200,
    isApprovedRestaurant: 235,
    isApprovedTourismService: 215,
    ecoLabel: 120,
    stars: 60,
    superior: 70,
    seasonal: 110,
    active: 30,
    purhcasedRegistration: 120,
    giftcert: 70,
    cleanAndSafe: 70
  };

  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" color="primary" />;

  const {
    loading: allServicesLoading,
    error: allServicesError,
    data: allServices
  } = useQuery<IServiceProvidersExtraLite>(ALL_SERVICE_PROVIDERS);
  const { loading, error, data } = useQuery<IDataNew>(GET_DATA);
  const [sendCleanEmail, { loading: emailLoading, data: emailData }] =
    useLazyQuery<{ success: boolean }>(CLEAN_AND_SAFE_EMAIL, {
      variables: {
        serviceProviderId: serviceProviderId
      },
      onError: () => {
        notifier.notify('Villa kom upp við að senda email', {
          variant: 'error'
        });
      }
    });
  const [popoverAnchor, setPopoverAnchor] = React.useState(null);
  useEffect(() => {
    if (allServices) {
      const allServicesOptions =
        allServices.ServiceProviders.ServiceProviders.map(provider => {
          return { label: provider.name, value: provider.serviceProviderId };
        });
      if (
        data &&
        data.Categories &&
        serviceProvider &&
        serviceProvider.categoryIds
      ) {
        var newCategoriesOptions = {};
        data.Categories.forEach(category => {
          category.subCategories.forEach(sub => {
            newCategoriesOptions[sub.id] = includes(
              serviceProvider.categoryIds,
              sub.id
            );
          });
        });
        setCategoriesOptions(newCategoriesOptions);
        setAllServicesOptions(allServicesOptions);
      }
    }
  }, [allServices, data, serviceProvider]);

  useEffect(() => {
    if (sData) {
      setStations(sData.ChargingStations.ChargingStations);
    }
  }, [sData]);

  useEffect(() => {
    if (cData) {
      setCategories(cData['ChargingStationCategories'].Categories);
    }
  }, [cData]);

  useEffect(() => {
    if (pData) {
      setProviders(pData['ChargingStationProviders'].Providers);
    }
  }, [pData]);

  useEffect(() => {
    if (data) {
      setZipCodeOptions(data.ZipCodes);
      setTownCodeOptions(data.TownCodes);
      const newRegions = data.Regions.map(region => {
        return { label: region.translations[0].name, value: region.id };
      });
      setRegionsOptions(newRegions);
      setName(serviceProvider.legalName);
      var newCategoriesOptions = {};
      data.Categories.forEach(category => {
        category.subCategories.forEach(sub => {
          newCategoriesOptions[sub.id] = includes(
            serviceProvider.categoryIds,
            sub.id
          );
        });
      });
      setCategoriesOptions(newCategoriesOptions);

      const newSigns = data.Signs.map(sign => {
        return { label: sign.translations[0].name, value: sign.id };
      });
      setSignsOptions(newSigns);
    }
    // TODO: fix warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, signs]);

  const classes = useStyles({});
  const schema = Joi.object({
    SSN: Joi.string().min(10).max(10),
    legalName: Joi.string().min(1),
    address: Joi.string().allow(''),
    regions: Joi.array().min(1),
    zipCode: Joi.any(),
    phoneNr: Joi.string().min(1),
    email: Joi.string().min(1),
    website: Joi.string().min(1),
    latitude: Joi.string().allow(''),
    longitude: Joi.string().allow(''),
    purhcasedRegistration: Joi.string().min(1)
  });

  const handleInfoClick = (event, infoText) => {
    setPopoverAnchor({ x: event.clientX, y: event.clientY });
    setPopoverText(infoText);
  };

  const handlePopoverClose = () => {
    setPopoverAnchor(null);
  };

  const popoverOpen = Boolean(popoverAnchor != null);
  const popoverId = popoverOpen ? 'simple-popover' : undefined;

  const requiresPermit = () => {
    const requirePermit = [
      'Bændagisting',
      'Farfuglaheimili og hostel',
      'Gistiheimili',
      'Glamping lúxustjöld',
      'Heimagisting',
      'Hótel',
      'Íbúðir',
      'Sumarhús',
      'Svefnpokagisting',
      'Bílaleigur',
      'Húsbílar og ferðabílar',
      'Barir og skemmtistaðir',
      'Beint frá býli',
      'Kaffihús',
      'Skyndibiti',
      'Veitingahús',
      'Ferðasali dagsferða',
      'Ferðaskipuleggjendur',
      'Ferðaskrifstofur'
    ];

    var res = serviceProvider.categories.find(c =>
      c.subCategories.find(sc =>
        sc.translations.find(
          t => t.locale === 'is-IS' && requirePermit.includes(t.name)
        )
      )
    );
    return res !== undefined;
  };

  const handleDeleteAlias = aliasToDelete => {
    var filteredAlias = aliasList.filter(a => a.label !== aliasToDelete.label);
    setAliasList(filteredAlias);
    return filteredAlias.map(a => a.label);
  };

  var newAlias = '';
  const handleAliasChange = (event: any) => {
    newAlias = event.currentTarget.value;
  };

  const addAlias = (aliasToAdd: string) => {
    var a = aliasList;
    var newKey = aliasKey + 1;
    setAliasKey(newKey);
    aliasList.push({ key: newKey, label: aliasToAdd });
    setAliasList(a);
    return aliasList.map(a => a.label);
  };

  const onPermitImgDrop = ([file]) => {
    if (file.size > 10000000) {
      notifier.notify('Of stór mynd - myndin þarf að vera minni en 10Mb', {
        variant: 'error'
      });
    }

    permitImageUpload({
      variables: {
        image: file,
        serviceProviderId: serviceProviderId
      }
    });
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    /* setSortingPhotos(false); */
    /* cancelMove(formik); */
  };

  async function uploadImage(image: ImageFile, info: IPhotoForm) {
    imageUpload({
      variables: { image, serviceProviderId, title: info.title, alt: info.alt }
    });
  }

  async function deleteImage(imageId: string) {
    imageDelete({ variables: { imageId, serviceProviderId } });
  }

  async function updateImage(imageId: string, info: IPhotoForm) {
    imageEdit({
      variables: {
        imageId,
        serviceProviderId,
        title: info.title,
        alt: info.alt
      }
    });
  }

  async function addCollection(
    categoryId: string,
    collection: ICollectionContent
  ) {
    AddCollection({
      variables: {
        serviceProviderId,
        categoryId,
        value: collection
      }
    })
      .then(() => {
        notifier.notify('Efni bætt við', { variant: 'success' });
      })
      .catch(e => {
        notifier.notify('Villa kom upp', { variant: 'error' });
      });
  }

  async function editCollection(
    categoryId: string,
    collection: ICollectionContent,
    collectionId: string
  ) {
    EditExistingCollection({
      variables: {
        serviceProviderId,
        categoryId,
        collectionId,
        value: collection
      }
    })
      .then(() => {
        notifier.notify('Efni breytt', { variant: 'success' });
      })
      .catch(e => {
        notifier.notify('Villa kom upp', { variant: 'error' });
      });
  }

  async function deleteCollection(collectionId: string) {
    DeleteCollection({
      variables: {
        serviceProviderId,
        collectionId
      }
    });
  }

  const checkFormValidation = (values: any) => {
    var validateValues = {};
    required.forEach(value => {
      validateValues[value] = values[value];
    });
    const joiValidate = schema.validate(validateValues, { abortEarly: false });
    if (joiValidate.error) {
      notifier.notify('Laga þarf villurnar í forminu til að halda áfram', {
        variant: 'error'
      });
    } else {
      handleFormSubmit(values, signs);
      setSortingPhotos(false);
    }
  };

  const canEdit = () => {
    if (isAdmin) {
      return true;
    }
    if (isEditor) {
      var canEdit = regionIds.some(id => userRegions.includes(id));
      return canEdit;
    }
    return true;
  };

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={0}>{children}</Box>}
      </Typography>
    );
  }

  if (loading || allServicesLoading) {
    return (
      <Grid container direction="column" justify="center" alignContent="center">
        <CircularProgress size={100} />
      </Grid>
    );
  }
  if (error) {
    return <Typography variant="h2">{error.message}</Typography>;
  }
  if (allServicesError) {
    return <Typography variant="h2">{allServicesError.message}</Typography>;
  }
  if (
    data &&
    zipCodeOptions &&
    townCodeOptions &&
    regionsOptions &&
    allServices &&
    categoriesOptions &&
    signsOptions
  ) {
    return (
      <Section title={name}>
        <div className={classes.root}>
          <AppBar position="static">
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="fullWidth"
              scrollButtons="auto"
              aria-label="full width tabs example"
            >
              <Tab label="Grunnupplýsingar" />
              <Tab label="Þjónustuupplýsingar" />
              <Tab label="Gildi og Tákn" />
              <Tab label="Textalýsing" />
              {isAdminEditor && <Tab label="Auka efni" />}
              <Tab label="Myndir" />
              {isAdminEditor && <Tab label="Myndasöfn" />}
            </Tabs>
          </AppBar>
          <fieldset disabled={!canEdit()}>
            <Formik
              enableReinitialize
              initialValues={{
                SSN: serviceProvider.SSN,
                legalName: serviceProvider.legalName,
                translations: serviceProvider.translations,
                address: serviceProvider.address,
                regions: regionsOptions.filter(region =>
                  includes(regionIds, region.value)
                ),
                zipCode: zipCodeOptions.filter(
                  code => code.value === serviceProvider.zipCode
                )[0],
                townCode: townCodeOptions.filter(
                  code => code.value === serviceProvider.townCode
                )[0],
                poBox: serviceProvider.poBox,
                phoneNr: serviceProvider.phoneNr,
                phoneNr2:
                  serviceProvider.phoneNr2 === null
                    ? ''
                    : serviceProvider.phoneNr2,
                email: serviceProvider.email,
                email2:
                  serviceProvider.email2 === null ? '' : serviceProvider.email2,
                website:
                  serviceProvider.website === null
                    ? null
                    : serviceProvider.website,
                website2:
                  serviceProvider.website2 === null
                    ? ''
                    : serviceProvider.website2,
                eService:
                  serviceProvider.eService === null
                    ? ''
                    : serviceProvider.eService.toString(),
                facebook:
                  serviceProvider.facebook === null
                    ? ''
                    : serviceProvider.facebook,
                instagram:
                  serviceProvider.instagram === null
                    ? ''
                    : serviceProvider.instagram,
                tripadvisor:
                  serviceProvider.tripadvisor === null
                    ? ''
                    : serviceProvider.tripadvisor,
                youtube:
                  serviceProvider.youtube === null
                    ? ''
                    : serviceProvider.youtube,
                giftcert:
                  serviceProvider.giftcert === null
                    ? ''
                    : serviceProvider.giftcert.toString(),
                isat: serviceProvider.isat === null ? '' : serviceProvider.isat,
                cleanAndSafe:
                  serviceProvider.cleanAndSafe === null
                    ? ''
                    : serviceProvider.cleanAndSafe.toString(),
                accessible:
                  serviceProvider.accessible === null ||
                    ![
                      'Mobility',
                      'MobilityVisibility',
                      'MobilityHearing',
                      'MobilityVisibilityHearing'
                    ].includes(serviceProvider.accessible)
                    ? ''
                    : serviceProvider.accessible.toString(),
                isApprovedRestaurant:
                  serviceProvider.vakinn.isApprovedRestaurant === null
                    ? ''
                    : serviceProvider.vakinn.isApprovedRestaurant.toString(),
                isApprovedTourismService:
                  serviceProvider.vakinn.isApprovedTourismService === null
                    ? ''
                    : serviceProvider.vakinn.isApprovedTourismService.toString(),
                stars:
                  serviceProvider.vakinn.stars === null
                    ? ''
                    : serviceProvider.vakinn.stars.toString(),
                ecoLabel:
                  serviceProvider.vakinn.ecoLabel === null
                    ? ''
                    : serviceProvider.vakinn.ecoLabel,
                superior:
                  serviceProvider.vakinn.superior === null
                    ? ''
                    : serviceProvider.vakinn.superior.toString(),
                iso:
                  serviceProvider.vakinn.iso === null
                    ? ''
                    : serviceProvider.vakinn.iso.toString(),
                swan:
                  serviceProvider.vakinn.swan === null
                    ? ''
                    : serviceProvider.vakinn.swan.toString(),
                blueFlag:
                  serviceProvider.vakinn.blueFlag === null
                    ? ''
                    : serviceProvider.vakinn.blueFlag.toString(),
                earthcheck:
                  serviceProvider.vakinn.earthcheck === null
                    ? ''
                    : serviceProvider.vakinn.earthcheck.toString(),
                greenKey:
                  serviceProvider.vakinn.greenKey === null
                    ? ''
                    : serviceProvider.vakinn.greenKey.toString(),
                nrOfHouses:
                  serviceProvider.nrOfHouses === null
                    ? ''
                    : serviceProvider.nrOfHouses,
                nrOfApartments:
                  serviceProvider.nrOfApartments === null
                    ? ''
                    : serviceProvider.nrOfApartments,
                nrOfRooms:
                  serviceProvider.nrOfRooms === null
                    ? ''
                    : serviceProvider.nrOfRooms,
                nrOfBeds:
                  serviceProvider.nrOfBeds === null
                    ? ''
                    : serviceProvider.nrOfBeds,
                nrOfSleepingBagSpace:
                  serviceProvider.nrOfSleepingBagSpace === null
                    ? ''
                    : serviceProvider.nrOfSleepingBagSpace,
                seasonal:
                  serviceProvider.openingHours.seasonal === null
                    ? ''
                    : serviceProvider.openingHours.seasonal.toString(),
                from:
                  serviceProvider.openingHours.from === null
                    ? null
                    : moment(serviceProvider.openingHours.from),
                to:
                  serviceProvider.openingHours.to === null
                    ? null
                    : moment(serviceProvider.openingHours.to),
                latitude: serviceProvider.location
                  ? serviceProvider.location.coordinates[1].toString()
                  : '',
                longitude: serviceProvider.location
                  ? serviceProvider.location.coordinates[0].toString()
                  : '',
                contactName:
                  serviceProvider.contactInfo.name === null
                    ? ''
                    : serviceProvider.contactInfo.name,
                contactEmail:
                  serviceProvider.contactInfo.email === null
                    ? ''
                    : serviceProvider.contactInfo.email,
                contactPhone:
                  serviceProvider.contactInfo.phone === null
                    ? ''
                    : serviceProvider.contactInfo.phone,
                contactComment:
                  serviceProvider.contactInfo.comment === null
                    ? ''
                    : serviceProvider.contactInfo.comment,
                permitId:
                  serviceProvider.permitId === null
                    ? ''
                    : serviceProvider.permitId,
                permitIssued:
                  serviceProvider.permitIssued === null
                    ? null
                    : serviceProvider.permitIssued,
                purhcasedRegistration: regionsOptions.filter(region =>
                  includes(serviceProvider.purchasedRegionIds, region.value)
                ),
                serviceProviderIds: (
                  serviceProvider.relatedServiceProviders || []
                ).map(sp => ({
                  value: sp.serviceProviderId,
                  label: sp.legalName
                })),
                places: (serviceProvider.relatedPlaces || []).map(p => ({
                  value: p.placeId,
                  label: p.translations[0].name
                })),
                photos: serviceProvider.photos,
                categories: categoriesOptions,
                collection: {
                  title: 'title',
                  description: '',
                  language: '',
                  category: '',
                  metaDescription: '',
                  metaKeywords: ''
                },
                active: serviceProvider.active,
                ownerEmail: serviceProvider.ownerEmail || '',
                chargingStationIds: (
                  serviceProvider.chargingStationIds || []
                ).map(c => ({
                  value: c,
                  label: 'test'
                }))
              }}
              onSubmit={checkFormValidation}
            >
              {formik => {
                const {
                  values,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  handleSubmit,
                  dirty
                } = formik;
                const validateValues = {};
                required.forEach(value => {
                  validateValues[value] = values[value];
                });
                const hasErrors = !!schema.validate(validateValues).error;

                return (
                  <React.Fragment>
                    <Form autoComplete="new-password" onSubmit={handleSubmit}>
                      <TabPanel value={tabValue} index={0}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={8} key={1}>
                            <Paper className={classes.paperSections}>
                              <Typography
                                variant="h6"
                                className={classes.typographyTitle}
                              >
                                Grunnupplýsingar
                              </Typography>
                              {renderInfo(
                                basicInfo,
                                namesBasicInfo,
                                values,
                                handleChange,
                                handleBlur,
                                setFieldValue
                              )}
                            </Paper>
                            <Paper className={classes.paperSections}>
                              <Typography
                                variant="h6"
                                className={classes.typographyTitle}
                              >
                                Heimilisfang
                              </Typography>
                              {renderInfo(
                                addressInfo,
                                namesAddressInfo,
                                values,
                                handleChange,
                                handleBlur,
                                setFieldValue
                              )}
                            </Paper>
                            <Paper className={classes.paperSections}>
                              <Typography
                                variant="h6"
                                className={classes.typographyTitle}
                              >
                                Netupplýsingar
                              </Typography>
                              {renderInfo(
                                networkInfo,
                                namesNetworkInfo,
                                values,
                                handleChange,
                                handleBlur,
                                setFieldValue
                              )}
                            </Paper>
                            <Paper className={classes.paperSections}>
                              <Typography
                                variant="h6"
                                className={classes.typographyTitle}
                              >
                                Upplýsingar um tengilið
                              </Typography>
                              {renderInfo(
                                contactInfo,
                                namesContactInfo,
                                values,
                                handleChange,
                                handleBlur,
                                setFieldValue
                              )}
                            </Paper>
                          </Grid>
                          {renderRightSide(
                            true,
                            false,
                            values,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            dirty
                          )}
                        </Grid>
                      </TabPanel>

                      <TabPanel value={tabValue} index={1}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={8} key={1}>
                            <Paper className={classes.paperSections}>
                              <Typography
                                variant="h6"
                                className={classes.typographyTitle}
                              >
                                Tegund þjónustu
                              </Typography>
                              {renderInfo(
                                typeOfServiceInfo,
                                namesTypeOfServiceInfo,
                                values,
                                handleChange,
                                handleBlur,
                                setFieldValue
                              )}
                            </Paper>
                            <Paper className={classes.paperSections}>
                              <Typography
                                variant="h6"
                                className={classes.typographyTitle}
                              >
                                Opnunartími
                              </Typography>
                              {renderInfo(
                                openingHours,
                                namesOpeningHours,
                                values,
                                handleChange,
                                handleBlur,
                                setFieldValue
                              )}
                            </Paper>
                            <Paper className={classes.paperSections}>
                              <Typography
                                variant="h6"
                                className={classes.typographyTitle}
                              >
                                Bæta við tengdri þjónustu
                              </Typography>
                              {renderInfo(
                                relatedServiceProvidersInfo,
                                namesRelatedServiceProvidersInfo,
                                values,
                                handleChange,
                                handleBlur,
                                setFieldValue
                              )}
                            </Paper>
                            <Paper className={classes.paperSections}>
                              <Typography
                                variant="h6"
                                className={classes.typographyTitle}
                              >
                                Bæta við tengdum stað
                              </Typography>
                              {renderInfo(
                                placesInfo,
                                namesPlacesInfo,
                                values,
                                handleChange,
                                handleBlur,
                                setFieldValue
                              )}
                            </Paper>
                            {/*                             <Paper className={classes.paperSections}>
                              <Typography
                                variant="h6"
                                className={classes.typographyTitle}
                              >
                                Ferðagjöfin 2020
                              </Typography>
                              {renderInfo(
                                giftcertInfo,
                                namesGiftcertInfo,
                                values,
                                handleChange,
                                handleBlur,
                                setFieldValue
                              )}
                            </Paper>
                                                        <Paper className={classes.paperSections}>
                              <Typography
                                variant="h6"
                                className={classes.typographyTitle}
                              >
                                Clean&Safe
                              </Typography>
                              {renderInfo(
                                cleanAndSafeInfo,
                                namesCleanAndSafeInfo,
                                values,
                                handleChange,
                                handleBlur,
                                setFieldValue
                              )}
                            </Paper> */}
                            <Paper className={classes.paperSections}>
                              <Typography
                                variant="h6"
                                className={classes.typographyTitle}
                              >
                                Gott aðgengi
                              </Typography>
                              {renderInfo(
                                accessibleInfo,
                                namesAccessibleInfo,
                                values,
                                handleChange,
                                handleBlur,
                                setFieldValue
                              )}
                            </Paper>
                            {requiresPermit() && (
                              <div>
                                <Paper className={classes.paperSections}>
                                  <Typography
                                    variant="h6"
                                    className={classes.typographyTitle}
                                  >
                                    Leyfi
                                  </Typography>
                                  {renderInfo(
                                    permitImgInfo,
                                    namesPermitImgInfo,
                                    values,
                                    handleChange,
                                    handleBlur,
                                    setFieldValue
                                  )}
                                </Paper>
                                <Paper className={classes.paperSections}>
                                  <Typography
                                    variant="h6"
                                    className={classes.typographyTitle}
                                  >
                                    Leyfisupplýsingar
                                  </Typography>
                                  {renderInfo(
                                    permitInfo,
                                    namesPermitInfo,
                                    values,
                                    handleChange,
                                    handleBlur,
                                    setFieldValue
                                  )}
                                </Paper>
                              </div>
                            )}
                          </Grid>
                          {renderRightSide(
                            false,
                            true,
                            values,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            dirty
                          )}
                        </Grid>
                      </TabPanel>

                      <TabPanel value={tabValue} index={2}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={8} key={1}>
                            <EditSigns
                              signs={signs}
                              setSigns={setSigns}
                              signsOptions={signsOptions}
                              allSigns={data.Signs}
                              latest={latest}
                              disableEdit={!canEdit()}
                            ></EditSigns>
                            <Paper className={classes.paperSections}>
                              <Typography
                                variant="h6"
                                className={classes.typographyTitle}
                              >
                                Gisting
                              </Typography>
                              {renderInfo(
                                serviceInfo,
                                namesServiceInfo,
                                values,
                                handleChange,
                                handleBlur,
                                setFieldValue
                              )}
                            </Paper>
                            <Paper className={classes.paperSections}>
                              <Typography
                                variant="h6"
                                className={classes.typographyTitle}
                              >
                                Vakinn
                              </Typography>
                              {renderInfo(
                                vakinn,
                                namesVakinn,
                                values,
                                handleChange,
                                handleBlur,
                                setFieldValue
                              )}
                            </Paper>
                            <Paper className={classes.paperSections}>
                              <Typography
                                variant="h6"
                                className={classes.typographyTitle}
                              >
                                Hleðslustöðvar
                              </Typography>
                              {renderInfo(
                                chargingStations,
                                namesChargingStations,
                                values,
                                handleChange,
                                handleBlur,
                                setFieldValue
                              )}
                            </Paper>
                          </Grid>
                          {renderRightSide(
                            false,
                            false,
                            values,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            dirty
                          )}
                        </Grid>
                      </TabPanel>
                      <TabPanel value={tabValue} index={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={8} key={1}>
                            <EditTranslations
                              nameLabel="Markaðsheiti"
                              translations={values.translations}
                              addTranslation={translation =>
                                addTranslation(translation, formik)
                              }
                              editTranslation={async (translation, index) =>
                                editTranslations(translation, index, formik)
                              }
                              deleteTranslation={async index =>
                                removeTranslation(index, formik)
                              }
                              disableEdit={!canEdit()}
                            />
                          </Grid>
                          {renderRightSide(
                            false,
                            false,
                            values,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            dirty
                          )}
                        </Grid>
                      </TabPanel>
                      {isAdminEditor && (
                        <TabPanel value={tabValue} index={4}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={8} key={1}>
                              <EditCollection
                                categories={serviceProvider.categories}
                                collections={serviceProvider.collections}
                                latest={latest}
                                addCollection={addCollection}
                                editCollection={editCollection}
                                deleteCollection={deleteCollection}
                                infoOnClick={event =>
                                  handleInfoClick(
                                    event,
                                    assistanceTexts.collections
                                  )
                                }
                                disableEdit={!canEdit()}
                              ></EditCollection>
                            </Grid>
                            {renderRightSide(
                              false,
                              false,
                              values,
                              handleChange,
                              handleBlur,
                              setFieldValue,
                              dirty
                            )}
                          </Grid>
                        </TabPanel>
                      )}

                      <TabPanel value={tabValue} index={isAdminEditor ? 5 : 4}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={8} key={1}>
                            <EditPhotos
                              photos={values.photos}
                              latest={latest}
                              isSorting={latest && isSortingPhotos}
                              setSorting={() =>
                                setSortingPhotos(!isSortingPhotos)
                              }
                              uploadImage={uploadImage}
                              deleteImage={deleteImage}
                              updateImage={updateImage}
                              movePhoto={e => movePhoto(e, formik)}
                              cancelMovePhoto={() => cancelMove(formik)}
                              infoOnClick={event =>
                                handleInfoClick(event, assistanceTexts.photos)
                              }
                              disableEdit={!canEdit()}
                            ></EditPhotos>
                          </Grid>
                          {renderRightSide(
                            false,
                            false,
                            values,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            dirty
                          )}
                        </Grid>
                      </TabPanel>

                      {isAdminEditor && (
                        <TabPanel value={tabValue} index={6}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={8} key={1}>
                              <EditPhotoCollection
                                serviceProviderId={serviceProviderId}
                                categories={serviceProvider.categories}
                                serviceProvider={serviceProvider}
                                latest={latest}
                                disableEdit={!canEdit()}
                              ></EditPhotoCollection>
                            </Grid>
                            {renderRightSide(
                              false,
                              false,
                              values,
                              handleChange,
                              handleBlur,
                              setFieldValue,
                              dirty
                            )}
                          </Grid>
                        </TabPanel>
                      )}
                    </Form>
                    <SavePrompt formik={formik} error={hasErrors} />
                  </React.Fragment>
                );
              }}
            </Formik>
          </fieldset>
        </div>
        <ConfirmDialog
          open={!!deletePermitImg}
          message="Ertu viss um að þú viljir eyða leyfismynd?"
          confirmText="Eyða leyfismynd"
          onConfirm={() => {
            permitImageDelete({
              variables: {
                serviceProviderId: serviceProvider.serviceProviderId
              }
            })
              .then(() => {
                notifier.notify('Leyfismynd eytt', { variant: 'success' });
              })
              .catch(e => {
                notifier.notify('Villa kom upp', { variant: 'error' });
              })
              .finally(() => {
                setDeletePermitImg(false);
              });
          }}
          onCancel={() => setDeletePermitImg(false)}
        />
      </Section>
    );
  }
  return null;

  function editTranslations(
    translation: ITranslation,
    index: number,
    formik: IFormik
  ) {
    const { values, setFieldValue } = formik;
    const trans: ITranslation = {
      locale: translation.locale,
      name: translation.name,
      description: translation.description,
      seoTitle: translation.seoTitle,
      seoDescription: translation.seoDescription,
      seoKeywords: translation.seoKeywords
    };

    let newTranslations: ITranslation[] = values.translations;
    newTranslations[index] = trans;
    setFieldValue('translations', newTranslations);
  }

  function addTranslation(translation: ITranslation, formik: IFormik) {
    const { values, setFieldValue } = formik;
    const trans: ITranslation = {
      locale: translation.locale,
      name: translation.name,
      description: translation.description,
      seoTitle: translation.seoTitle,
      seoDescription: translation.seoDescription,
      seoKeywords: translation.seoKeywords
    };

    let newTranslations: Array<ITranslation> = values.translations;
    newTranslations.push(trans);
    setFieldValue('translations', newTranslations);
  }

  function removeTranslation(index: number, formik: IFormik) {
    const { values, setFieldValue } = formik;
    const test: ITranslation[] = remove(
      values.translations,
      (val, index2) => index !== index2
    );
    setFieldValue('translations', test);
  }

  function movePhoto(
    { newIndex, oldIndex }: { newIndex: number; oldIndex: number },
    formik: IFormik
  ) {
    const { values, setFieldValue } = formik;
    const counter = photoMovedCounter + 1;
    const newArray: IPhoto[] = arrayMove(values.photos, oldIndex, newIndex);
    setFieldValue('photos', newArray);
    changePhotoMoved(counter);
  }

  function cancelMove(formik: IFormik) {
    const { setFieldValue } = formik;
    const counter = photoMovedCounter + 1;
    const oldPhotos: IPhoto[] = serviceProvider.photos;
    setFieldValue('photos', oldPhotos);
    changePhotoMoved(counter);
  }

  function renderInfo(
    allowed: string[],
    names: string[],
    values: any,
    handleChange: any,
    handleBlur: any,
    setFieldValue: any
  ) {
    return (
      <Grid container spacing={0}>
        {allowed.map((key, index) => {
          if (key === 'translations' && values && values.translations) {
            values.translations.sort((a: ITranslation, b: ITranslation) =>
              a.locale > b.locale ? -1 : 1
            );
            return (
              <Fragment>
                {values.translations.map(
                  (translation: ITranslation, index2) => {
                    return (
                      <Grid item xs={12} sm={6} key={index + index2}>
                        <Paper className={classes.paper} elevation={0}>
                          <TextField
                            disabled={true}
                            autoComplete="new-password"
                            fullWidth
                            id={key}
                            label={`*Markaðsheiti ${translation.locale.split('-')[0]
                              }`}
                            variant="outlined"
                            value={translation.name}
                            InputProps={{
                              endAdornment: renderAssistanceAdornment(
                                'name' + translation.locale.split('-')[0]
                              )
                            }}
                          >
                            {translation.name}
                          </TextField>
                        </Paper>
                      </Grid>
                    );
                  }
                )}
                {values.translations.length > 0 && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    key={'seeText'}
                    className={classes.seeMoreGrid}
                  >
                    <Typography className={classes.typographyDisabled}>
                      *Sjá textalýsing
                    </Typography>
                  </Grid>
                )}
              </Fragment>
            );
          }
          if (key === 'chargingStationIds') {
            var selstations: IChargingStation[];
            if (values[key]) {
              const ids = values[key].map(v => v.value);
              selstations = stations.filter(c =>
                ids.includes(c.chargingStationId)
              );
            }
            return (
              <Grid item xs={12} sm={12} key={index}>
                {canEdit() && (
                  <Paper className={classes.paper} elevation={0}>
                    <SelectChargingStation
                      id={`checkboxes-${key}`}
                      values={values[key]}
                      error={false}
                      label="Hleðslustöð"
                      onChange={val => {
                        setFieldValue(key, val);
                      }}
                      fullWidth
                      disabled={!canEdit()}
                    />
                  </Paper>
                )}

                {selstations && (
                  <Paper>
                    <Grid item xs={12} sm={12}>
                      <ChargingStationTable
                        showChangeButton={false}
                        categories={categories}
                        providers={providers}
                        stations={selstations}
                      />
                    </Grid>
                  </Paper>
                )}
              </Grid>
            );
          }
          if (key === 'latitude' || key === 'longitude') {
            var isEservice =
              values['eService'] === 'true' || values['eService'] === 'True'
                ? true
                : false;
            return (
              <Grid item xs={12} sm={6} key={index}>
                <Paper className={classes.paper} elevation={0}>
                  <LocationInput
                    location={{
                      latitude: values.latitude,
                      longitude: values.longitude
                    }}
                    type={key}
                    label={names[index]}
                    error={
                      !isEservice
                        ? schema.validate({ [key]: values[key] }).error
                          ? true
                          : false
                        : false
                    }
                    required={!isEservice}
                    disabled={!latest || !!disabled[key] || !canEdit()}
                    onChange={location => {
                      setFieldValue('latitude', location.latitude);
                      setFieldValue('longitude', location.longitude);
                    }}
                    endAdornment={renderAssistanceAdornment(key)}
                  />
                </Paper>
              </Grid>
            );
          }
          if (key === 'website' || key === 'website2') {
            return (
              <Grid item xs={12} sm={6} key={index}>
                <Grid container justify="center" alignItems="center">
                  <Grid
                    item
                    xs={10}
                    sm={!!values[key] ? 10 : 12}
                    key={index + '1'}
                  >
                    <Paper className={classes.paper} elevation={0}>
                      <TextField
                        autoComplete="new-password"
                        disabled={!latest || !!disabled[key] || !canEdit()}
                        fullWidth
                        id={key}
                        label={names[index]}
                        variant="outlined"
                        value={values[key]}
                        required={includes(required, key)}
                        error={
                          includes(required, key)
                            ? schema.validate({ [key]: values[key] }).error
                              ? true
                              : false
                            : false
                        }
                        multiline={includes(multiline, key)}
                        rows={6}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                          endAdornment: renderAssistanceAdornment(key)
                        }}
                      />
                    </Paper>
                  </Grid>
                  {!!values[key] && (
                    <Grid item xs={2} sm={2} key={index + '2'}>
                      <Button
                        color="primary"
                        variant="outlined"
                        href={
                          //remove https:// in front of string before applying https to ignore duplicates, https must be included to redirect to external link
                          'https://' + values[key].replace(/(^\w+:|^)\/\//, '')
                        }
                        target="_blank"
                        style={{ padding: 0, height: 56 }}
                      >
                        Prófa
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            );
          }
          if (key === 'permitIssued') {
            return (
              <Grid item xs={12} sm={6} key={index}>
                <Paper className={classes.paper} elevation={0}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      format="DD/MM/YYYY"
                      //margin="normal"
                      color="primary"
                      label={names[index]}
                      id={key}
                      value={values[key]}
                      onChange={e => setFieldValue(key, moment(e))}
                      onBlur={handleBlur(key)}
                      disabled={!latest || !!disabled[key] || !canEdit()}
                    />
                  </MuiPickersUtilsProvider>
                </Paper>
              </Grid>
            );
          }
          if (key === 'aliases') {
            return (
              <Grid item xs={12} sm={6} key={index}>
                <Paper className={classes.paper} elevation={0}>
                  <Typography variant="h6">Hjáheiti</Typography>
                  {aliasList.map(alias => {
                    return (
                      <li key={alias.key} className={classes.aliasList}>
                        <Chip
                          label={alias.label}
                          onDelete={() => {
                            var list = handleDeleteAlias(alias);
                            setFieldValue('aliases', list);
                          }}
                          className={classes.aliasChip}
                        />
                      </li>
                    );
                  })}
                  {canEdit() && (
                    <TextField
                      autoComplete="new-password"
                      disabled={!latest || !!disabled[key] || !canEdit()}
                      fullWidth
                      id={key}
                      label={names[index]}
                      variant="outlined"
                      value={values[key]}
                      onKeyPress={event => {
                        if (event.key === 'Enter') {
                          var list = addAlias(newAlias);
                          setFieldValue('aliases', list);
                        }
                      }}
                      required={includes(required, key)}
                      error={
                        includes(required, key)
                          ? schema.validate({ [key]: values[key] }).error
                            ? true
                            : false
                          : false
                      }
                      multiline={includes(multiline, key)}
                      rows={6}
                      onChange={handleAliasChange}
                      onBlur={handleBlur}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={event => {
                                var list = addAlias(newAlias);
                                setFieldValue('aliases', list);
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                </Paper>
              </Grid>
            );
          }
          if (key === 'permitImg') {
            return (
              <Grid item xs={12} sm={6} key={index}>
                <Paper className={classes.paper} elevation={0}>
                  {serviceProvider.permitImgUri && (
                    <div>
                      <img
                        src={serviceProvider.permitImgUri}
                        style={{ width: '100%' }}
                      />
                      {canEdit() && (
                        <Button
                          onClick={() => setDeletePermitImg(true)}
                          disabled={emailLoading}
                          color="secondary"
                          variant="contained"
                        >
                          Eyða
                        </Button>
                      )}
                    </div>
                  )}
                  {!serviceProvider.permitImgUri && canEdit() && (
                    <Dropzone
                      accept="image/jpeg, image/png"
                      multiple={false}
                      onDrop={onPermitImgDrop}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div>
                          <section>
                            <div
                              {...getRootProps()}
                              style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: '16px',
                                borderWidth: 2,
                                borderRadius: 5,
                                borderColor: '#213b76',
                                borderStyle: 'dashed',
                                backgroundColor: '#fafafa'
                              }}
                            >
                              <input {...getInputProps()} />
                              <Typography>
                                {' '}
                                Dragðu og slepptu skrá hingað, eða smelltu til
                                að velja skrá{' '}
                              </Typography>
                            </div>
                          </section>
                        </div>
                      )}
                    </Dropzone>
                  )}
                </Paper>
              </Grid>
            );
          }
          if (key === 'address') {
            var isEservice =
              values['eService'] === 'true' || values['eService'] === 'True'
                ? true
                : false;
            return (
              <Grid item xs={12} sm={6} key={index}>
                <Paper className={classes.paper} elevation={0}>
                  {includes(selectAllowedList, key) ? (
                    renderSelectInfo(
                      key,
                      names[index],
                      values,
                      handleChange,
                      handleBlur,
                      setFieldValue
                    )
                  ) : (
                    <TextField
                      autoComplete="new-password"
                      disabled={!latest || !!disabled[key] || !canEdit()}
                      fullWidth
                      id={key}
                      label={names[index]}
                      variant="outlined"
                      value={values[key]}
                      required={!isEservice}
                      error={
                        !isEservice
                          ? schema.validate({ [key]: values[key] }).error
                            ? true
                            : false
                          : false
                      }
                      multiline={includes(multiline, key)}
                      rows={6}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        endAdornment: renderAssistanceAdornment(key)
                      }}
                    />
                  )}
                </Paper>
              </Grid>
            );
          }
          return (
            <Grid item xs={12} sm={6} key={index}>
              <Paper className={classes.paper} elevation={0}>
                {includes(selectAllowedList, key) ? (
                  renderSelectInfo(
                    key,
                    names[index],
                    values,
                    handleChange,
                    handleBlur,
                    setFieldValue
                  )
                ) : (
                  <TextField
                    autoComplete="new-password"
                    disabled={!latest || !!disabled[key] || !canEdit()}
                    fullWidth
                    id={key}
                    label={names[index]}
                    variant="outlined"
                    value={values[key]}
                    required={includes(required, key)}
                    error={
                      includes(required, key)
                        ? schema.validate({ [key]: values[key] }).error
                          ? true
                          : false
                        : false
                    }
                    multiline={includes(multiline, key)}
                    rows={6}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      endAdornment: renderAssistanceAdornment(key)
                    }}
                  />
                )}
              </Paper>
            </Grid>
          );
        })}
        {names.includes('Clean&Safe') && canEdit() ? (
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper} elevation={0}>
              <Typography variant="h6">Linkur að formi:</Typography>
              <Typography variant="caption">
                {'https://db.ferdamalastofa.is/clean/' + serviceProviderId}
              </Typography>

              {emailLoading ? (
                <CircularProgress size={50} color="primary" />
              ) : (
                <Button
                  onClick={() => sendCleanEmail()}
                  disabled={emailLoading}
                  color="primary"
                  variant="contained"
                >
                  Senda email
                </Button>
              )}
            </Paper>
          </Grid>
        ) : null}
      </Grid>
    );
  }
  function renderAssistanceAdornment(key: string) {
    return (
      <div>
        {assistanceTexts[key] !== undefined && (
          <div>
            <InputAdornment position="end">
              <IconButton
                onClick={event => handleInfoClick(event, assistanceTexts[key])}
              >
                <InfoIcon />
              </IconButton>
            </InputAdornment>
          </div>
        )}
      </div>
    );
  }
  function renderSelectInfo(
    key: string,
    name: string,
    values: any,
    handleChange: any,
    handleBlur: any,
    setFieldValue: any
  ) {
    if (key === 'stars') {
      return (
        <FormControl
          /* className={classes.formControl} */ fullWidth
          variant={'outlined'}
          required={includes(required, key)}
        >
          <InputLabel id={key + '-label'}>{name}</InputLabel>
          <Select
            id={key}
            labelId={key + '-label'}
            labelWidth={labelWidth[key]}
            value={values[key]}
            onChange={handleChange(key)}
            onBlur={handleBlur(key)}
            autoComplete="new-password"
            disabled={!latest || !!disabled[key] || !canEdit()}
          >
            <MenuItem value={'0'}>0</MenuItem>
            <MenuItem value={'1'}>1</MenuItem>
            <MenuItem value={'2'}>2</MenuItem>
            <MenuItem value={'3'}>3</MenuItem>
            <MenuItem value={'4'}>4</MenuItem>
            <MenuItem value={'5'}>5</MenuItem>
          </Select>
        </FormControl>
      );
    } else if (key === 'ecoLabel') {
      return (
        <FormControl
          /* className={classes.formControl} */ fullWidth
          variant={'outlined'}
          required={includes(required, key)}
        >
          <InputLabel id={key + '-label'}>{name}</InputLabel>
          <Select
            id={key}
            labelId={key + '-label'}
            labelWidth={labelWidth[key]}
            value={values[key]}
            onChange={handleChange(key)}
            onBlur={handleBlur(key)}
            autoComplete="new-password"
            disabled={!latest || !!disabled[key] || !canEdit()}
          >
            <MenuItem value={''}>Ekkert</MenuItem>
            <MenuItem value={'Gold'}>Gull</MenuItem>
            <MenuItem value={'Silver'}>Silfur</MenuItem>
            <MenuItem value={'Bronze'}>Brons</MenuItem>
          </Select>
        </FormControl>
      );
    } else if (key === 'accessible') {
      return (
        <FormControl
          /* className={classes.formControl} */ fullWidth
          variant={'outlined'}
          required={includes(required, key)}
          disabled={!latest || !!disabled[key] || !canEdit()}
        >
          <InputLabel id={key + '-label'}>{name}</InputLabel>
          <Select
            id={key}
            labelId={key + '-label'}
            labelWidth={labelWidth[key]}
            value={values[key]}
            onChange={handleChange(key)}
            onBlur={handleBlur(key)}
            autoComplete="new-password"
            disabled={!latest || !!disabled[key] || !canEdit()}
            endAdornment={renderAssistanceAdornment(key)}
          >
            <MenuItem value={''}>Ekkert</MenuItem>
            <MenuItem value={'Mobility'}>Hjólastóll</MenuItem>
            <MenuItem value={'MobilityVisibility'}>
              Hjólastóll-Sjónskerðing
            </MenuItem>
            <MenuItem value={'MobilityHearing'}>
              Hjólastóll-Heyrnarskerðing
            </MenuItem>
            <MenuItem value={'MobilityVisibilityHearing'}>
              Hjólastóll-Heyrnarskerðing-Sjónskerðing
            </MenuItem>
          </Select>
        </FormControl>
      );
    } else if (key === 'from' || key === 'to') {
      return values.seasonal === 'true' ? (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            fullWidth
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            format="DD/MM/YYYY"
            //margin="normal"
            color="primary"
            label={name}
            id={key}
            value={values[key]}
            onChange={e => setFieldValue(key, moment(e))}
            onBlur={handleBlur(key)}
            disabled={!latest || !!disabled[key] || !canEdit()}
          />
        </MuiPickersUtilsProvider>
      ) : null;
    } else if (key === 'places') {
      return (
        <SelectPlace
          id={`checkboxes-${key}`}
          values={values[key]}
          error={false}
          label="Tengdir staðir"
          onChange={val => setFieldValue(key, val)}
          fullWidth
          disabled={!latest || !!disabled[key] || !canEdit()}
        />
      );
    } else if (key === 'serviceProviderIds') {
      return (
        <SelectProvider
          id={`checkboxes-${key}`}
          values={values[key]}
          error={false}
          label="Tengdar þjónustur"
          onChange={val => setFieldValue(key, val)}
          fullWidth
          disabled={!latest || !!disabled[key] || !canEdit()}
        />
      );
    } else if (key === 'regions' || key === 'purhcasedRegistration') {
      return (
        <Autocomplete
          multiple
          disableCloseOnSelect
          id={`checkboxes-${key}`}
          options={optionsList[key]}
          onChange={(_, value) => setFieldValue(key, value)}
          onBlur={handleBlur(key)}
          value={values[key]}
          getOptionLabel={option => option.label}
          disabled={!latest || !!disabled[key] || !canEdit()}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </React.Fragment>
          )}
          style={{ width: '100%' }}
          renderInput={params => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password'
              }}
              variant="outlined"
              label={name + (includes(required, key) ? ' *' : '')}
              error={
                includes(required, key)
                  ? schema.validate({ [key]: values[key] }).error
                    ? true
                    : false
                  : false
              }
              disabled={!latest || !!disabled[key] || !canEdit()}
            />
          )}
        />
      );
    } else if (key === 'zipCode' || key === 'townCode') {
      var isEservice =
        values['eService'] === 'true' || values['eService'] === 'True'
          ? true
          : false;
      return (
        <Autocomplete
          id={`checkboxes-${key}`}
          options={optionsList[key]}
          onChange={(_, value) => setFieldValue(key, value)}
          onBlur={handleBlur(key)}
          value={values[key]}
          getOptionLabel={option => option.label}
          disabled={!latest || !!disabled[key] || !canEdit()}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </React.Fragment>
          )}
          style={{ width: '100%' }}
          renderInput={params => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password'
              }}
              variant="outlined"
              label={name}
              required={!isEservice}
              error={
                !isEservice && key != 'townCode'
                  ? schema.validate({ [key]: values[key] }).error
                    ? true
                    : false
                  : false
              }
              disabled={!latest || !!disabled[key] || !canEdit()}
            />
          )}
        />
      );
    } else if (key === 'active') {
      return (
        <FormControlLabel
          key={key}
          control={
            <Switch
              id={key}
              checked={values[key]}
              onChange={handleChange}
              color="primary"
              disabled={!latest || !!disabled[key] || !canEdit()}
            />
          }
          label={values[key] ? 'Í birtingu' : 'Ekki í birtingu'}
        />
      );
    } else {
      return (
        <FormControl
          /* className={classes.formControl} */ fullWidth
          variant="outlined"
          required={includes(required, key)}
          disabled={!canEdit()}
        >
          <InputLabel id={key + '-label'}>{name}</InputLabel>
          <Select
            id={key}
            labelId={key + '-label'}
            labelWidth={labelWidth[key]}
            value={values[key]}
            onChange={handleChange(key)}
            onBlur={handleBlur(key)}
            autoComplete="new-password"
            disabled={!latest || !!disabled[key] || !canEdit()}
            error={
              includes(required, key)
                ? schema.validate({ [key]: values[key] }).error
                  ? true
                  : false
                : false
            }
            endAdornment={renderAssistanceAdornment(key)}
          >
            <MenuItem value={'true'}>Já</MenuItem>
            <MenuItem value={'false'}>Nei</MenuItem>
          </Select>
        </FormControl>
      );
    }
  }

  function renderRightSide(
    landshluti,
    flokkar,
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    dirty: boolean
  ) {
    return (
      <Grid item xs={12} sm={4} key={2}>
        {landshluti ? (
          <Paper className={classes.paperSections}>
            <Grid container direction="row">
              <Typography variant="h6" className={classes.typographyTitle}>
                Landshluti
              </Typography>
              <IconButton
                onClick={event =>
                  handleInfoClick(event, assistanceTexts['regions'])
                }
              >
                <InfoIcon />
              </IconButton>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} key={'landshluti'}>
                <Paper className={classes.paper} elevation={0}>
                  {renderSelectInfo(
                    'regions',
                    'Landshluti',
                    values,
                    handleChange,
                    handleBlur,
                    setFieldValue
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        ) : flokkar ? (
          <Paper className={classes.paperSections}>
            <Grid container direction="row">
              <Typography variant="h6" className={classes.typographyTitle}>
                Flokkar
              </Typography>
              <IconButton
                onClick={event =>
                  handleInfoClick(event, assistanceTexts['groups'])
                }
              >
                <InfoIcon />
              </IconButton>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} key={'Flokkar'}>
                <Paper className={classes.paper} elevation={0}>
                  {data.Categories.map(category => {
                    const allSubCategories = category.subCategories.map(
                      cat => cat.id
                    );
                    const selecedSubCategories = allSubCategories.filter(
                      cat => values.categories[cat]
                    );
                    return (
                      <ExpansionPanel
                        key={category.translations[0].name}
                        defaultExpanded={selecedSubCategories.length > 0}
                      >
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon color="primary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="h6">
                            {category.translations[0].name}
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <FormGroup>
                            {category.subCategories.map(sub => {
                              return (
                                <FormControlLabel
                                  key={sub.id}
                                  control={
                                    <Checkbox
                                      checked={values.categories[sub.id]}
                                      onChange={() => {
                                        setFieldValue(
                                          `categories.${sub.id}`,
                                          !values.categories[sub.id]
                                        );
                                      }}
                                      onBlur={handleBlur(
                                        `categories.${sub.id}`
                                      )}
                                      color="primary"
                                    />
                                  }
                                  label={sub.translations[0].name}
                                  disabled={!latest || !canEdit()}
                                />
                              );
                            })}
                          </FormGroup>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    );
                  })}
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        ) : null}
        <Paper className={classes.paperSections}>
          <Typography variant="h6" className={classes.typographyTitle}>
            Staða
          </Typography>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} key={'staða'}>
              <Paper className={classes.paper} elevation={0}>
                {renderSelectInfo(
                  'active',
                  'Í birtingu',
                  values,
                  handleChange,
                  handleBlur,
                  setFieldValue
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} key={'skráning'}>
              <Paper className={classes.paper} elevation={0}>
                {renderSelectInfo(
                  'purhcasedRegistration',
                  'Keypt skráning',
                  values,
                  handleChange,
                  handleBlur,
                  setFieldValue
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} key={'netfang-eiganda'}>
              <Paper className={classes.paper} elevation={0}>
                <TextField
                  autoComplete="new-password"
                  fullWidth
                  id={'ownerEmail'}
                  label={'Netfang eiganda'}
                  variant="outlined"
                  value={values.ownerEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!latest || !canEdit()}
                  InputProps={{
                    endAdornment: renderAssistanceAdornment('ownerEmail')
                  }}
                />
              </Paper>
            </Grid>
          </Grid>
          <Typography variant="h6" className={classes.typographyTitle}>
            Útgáfa
          </Typography>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} key={'breytt'}>
              <Paper className={classes.paper} elevation={0}>
                <Typography>{`Síðast breytt: ${moment(
                  serviceProvider.updatedAt
                ).format(DATE_AND_TIME_FORMAT)}`}</Typography>
                <Typography>{`Af: ${serviceProvider.updatedBy}`}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} key={'Útgáfa'}>
              <Paper className={classes.paper} elevation={0}>
                <ButtonGroup
                  color="primary"
                  aria-label="outlined primary button group"
                  fullWidth
                >
                  <Button
                    variant={latestVersion ? 'contained' : 'outlined'}
                    onClick={() => {
                      handleVersionChange(true);
                      setLatestVersion(true);
                    }}
                  >
                    Nýjasta útgáfa
                  </Button>
                  <Button
                    variant={latestVersion ? 'outlined' : 'contained'}
                    onClick={() => {
                      handleVersionChange(false);
                      setLatestVersion(false);
                    }}
                    disabled={!hasLatestVersion || !canEdit()}
                  >
                    Síðasta útgáfa
                  </Button>
                </ButtonGroup>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
        {latest && canEdit() && (
          <Grid container spacing={2} className={classes.paperSections}>
            <Grid item xs={12}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                style={{ padding: 10 }}
              >
                Uppfæra þjónustu
              </Button>
            </Grid>
            {isAdmin && validateServiceProvider && (
              <Grid item xs={12}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth
                  style={{ padding: 10 }}
                  onClick={() => validateServiceProvider()}
                >
                  Staðfesta upplýsingar
                </Button>
              </Grid>
            )}
            {isAdmin && deleteServiceProvider && (
              <Grid item xs={12}>
                <Button
                  color="secondary"
                  variant="contained"
                  fullWidth
                  style={{ padding: 10 }}
                  onClick={() => setDeleteDialogOpen(true)}
                >
                  Eyða þjónustu
                </Button>
                <ConfirmDialog
                  open={deleteDialogOpen}
                  message="Ertu viss um að þú viljir eyða þjónustu?"
                  confirmText="Eyða"
                  onConfirm={() => {
                    deleteServiceProvider();
                    setDeleteDialogOpen(false);
                  }}
                  onCancel={() => setDeleteDialogOpen(false)}
                />
              </Grid>
            )}
            {!pending && (
              <Grid item xs={12}>
                <Button
                  color="default"
                  variant="contained"
                  fullWidth
                  style={{ padding: 10 }}
                  onClick={() => history.push('/service-providers')}
                >
                  Hætta við
                </Button>
              </Grid>
            )}
          </Grid>
        )}
        {(!latest && !editServiceProvider) || (pending && !dirty) ? (
          <Grid container spacing={2} className={classes.paperSections}>
            <Grid item xs={12} sm={6} key={'hafna'}>
              <Button
                color="secondary"
                variant="contained"
                fullWidth
                style={{ padding: 10 }}
                onClick={() => rejectServiceProvider()}
              >
                hafna
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} key={'samþykkja'}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                style={{ padding: 10 }}
                onClick={() => confirmServiceProvider()}
              >
                Samþykkja
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="default"
                variant="contained"
                fullWidth
                style={{ padding: 10 }}
                onClick={() => history.push('/pending')}
              >
                Hætta við
              </Button>
            </Grid>
          </Grid>
        ) : null}

        <Popover
          id={popoverId}
          open={popoverOpen}
          anchorReference="anchorPosition"
          anchorPosition={
            popoverAnchor != null
              ? { top: popoverAnchor.y + 25, left: popoverAnchor.x }
              : { top: 0, left: 0 }
          }
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <Typography className={classes.popoverTypography}>
            {popoverText}
          </Typography>
        </Popover>
      </Grid>
    );
  }
};

export default EditServiceProvider;

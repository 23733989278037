import React, { useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Layout from '../components/Layout';
import Section from '../components/Section';
import GeofenceEdit from '../components/GeofenceEdit';
import { IS_LOCALE, EN_LOCALE } from '../utils/locale';

const GeofenceNew: React.FC<RouteComponentProps> = () => {
  const newGeofence = useMemo(
    () => ({
      id: null,
      translations: [
        { locale: IS_LOCALE, name: '' },
        { locale: EN_LOCALE, name: '' }
      ],
      regions: [],
      path: []
    }),
    []
  );

  return (
    <Layout useDefaultSpacing>
      <Section title={'Ný Landmörk'}>
        <GeofenceEdit initialGeofence={newGeofence} />
      </Section>
    </Layout>
  );
};

export default GeofenceNew;

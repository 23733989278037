import { gql } from 'apollo-boost';
import {
  userFragment,
  rolesFragment,
  regionsFragment,
  userByRoleFragment
} from '../fragments/UserFragments';

export const USERS = gql`
  query getUsers($name: String) {
    Users(name: $name) {
      ...user
    }
  }
  ${userFragment}
`;

export const USER_DETAILS = gql`
  query getUserDetails($userId: String!) {
    User(userId: $userId) {
      ...user
    }
  }
  ${userFragment}
`;

export const USER_ROLES = gql`
  {
    Roles {
      ...roles
    }
  }
  ${rolesFragment}
`;

export const REGIONS = gql`
  {
    Regions {
      ...regions
    }
  }
  ${regionsFragment}
`;

export const USER_BY_ROLE = gql`
  query getUsersByRoles($roleId: String!, $per_page: Int, $page: Int) {
    UsersInRole(roleId: $roleId, per_page: $per_page, page: $page) {
      ...userByRole
    }
  }
  ${userByRoleFragment}
`;

import { IAuthState, IAppMetadata, IUserMetadata } from './types';
import { CUSTOM_CLAIM_NAMESPACE } from '../../constants';

export const isLoggedIn = (state: IAuthState) => state.isLoggedIn;
export const isReady = (state: IAuthState) => state.isReady;
export const getAccessToken = (state: IAuthState) => state.accessToken;
export const getUser = (state: IAuthState) => state.user;
export const getTokenExpiryTimestamp = (state: IAuthState) => state.expiresAt;

export const selectAppMetadata = (state: IAuthState): IAppMetadata => {
  const user = getUser(state);

  let appMetadata = {};

  if (user) {
    appMetadata = user[CUSTOM_CLAIM_NAMESPACE + 'app_metadata'] || {};
  }

  return appMetadata;
};

export const selectUserMetadata = (state: IAuthState): IUserMetadata => {
  const user = getUser(state);

  let userMetadata = {};

  if (user) {
    userMetadata = user[CUSTOM_CLAIM_NAMESPACE + 'user_metadata'] || {};
  }

  return userMetadata;
};

export const selectIsAdmin = (state: IAuthState) => {
  const user = getUser(state);
  let roles: Array<string> = [];
  if (user) {
    const metaData = user[CUSTOM_CLAIM_NAMESPACE + 'app_metadata'] || {};
    roles = metaData.roles;
  }
  return roles.some(r => r === 'Administrator');
};
export const selectIsEditor = (state: IAuthState) => {
  const user = getUser(state);
  let roles: Array<string> = [];
  if (user) {
    const metaData = user[CUSTOM_CLAIM_NAMESPACE + 'app_metadata'] || {};
    roles = metaData.roles;
  }
  return roles.some(r => r === 'Editor');
};

export const selectEmailVerified = (state: IAuthState) => {
  const user = getUser(state);

  return user ? user.email_verified : false;
};

export const selectUserEmail = (state: IAuthState) => {
  const user = getUser(state);

  return user ? user.email : '';
};

export const selectUserRegions = (state: IAuthState) => {
  const user = getUser(state);
  if (user) {
    const metaData = user[CUSTOM_CLAIM_NAMESPACE + 'app_metadata'] || {};
    return metaData.regionIds;
  }
  return null;
}

/* export const selectUserRole = (state: IAuthState) => {
  const user = getUser(state);
  return user ? user.
} */

import React from 'react';
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
  Paper,
  Grid,
  makeStyles,
  Theme,
  createStyles,
  CircularProgress,
  Typography
} from '@material-ui/core';
import cx from 'classnames';
import { IProvider } from '../interfaces/ServiceProvider/ServiceProvider';
import moment from 'moment';

interface IProps {
  serviceProviders: IProvider[];
  showrecentlyValidated?: boolean;
  onClick: (serviceProviderId: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      padding: theme.spacing(1)
    },
    typographyTitle: {
      padding: 20,
      paddingBottom: 5,
      paddingTop: 10
    },
    active: {
      color: 'white',
      backgroundColor: theme.palette.success.main,
      borderRadius: 5,
      padding: 2,
      fontWeight: theme.typography.fontWeightMedium,
      width: 40,
      paddingLeft: 8
    },
    inactive: {
      backgroundColor: theme.palette.error.main,
      width: 40,
      paddingLeft: 9
    }
  })
);

const ServiceProvidersTable: React.FC<IProps> = ({
  serviceProviders,
  showrecentlyValidated,
  onClick
}) => {
  const classes = useStyles({});

  function renderActiveCell(active: boolean) {
    return (
      <Typography
        className={cx(classes.active, { [classes.inactive]: !active })}
      >
        {active ? 'Já' : 'Nei'}
      </Typography>
    );
  }

  function recentlyValidated(provider: IProvider) {
    const yearAgo = moment().subtract(1, 'years');
    const parsedMoment = moment(provider.updatedAt);
    const isOlderThanYear = parsedMoment.isBefore(yearAgo);
    return !isOlderThanYear;
  }

  if (serviceProviders) {
    console.log("serviceProviders", serviceProviders);
    return (
      <Paper>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nafn</TableCell>
                <TableCell>Heimilisfang</TableCell>
                <TableCell>Póstnúmer</TableCell>
                <TableCell>Netfang</TableCell>
                <TableCell>Kennitala</TableCell>
                <TableCell>Síðast breytt</TableCell>
                <TableCell>Í birtingu</TableCell>
                <TableCell>Keypt skráning</TableCell>
                {showrecentlyValidated && <TableCell>Staðfest á árinu</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {serviceProviders.map((provider, index) => {
                return (
                  <TableRow
                    key={index}
                    style={{ cursor: 'pointer' }}
                    hover
                    onClick={() => onClick(provider.serviceProviderId)}
                  >
                    <TableCell>{provider.legalName}</TableCell>
                    <TableCell>{provider.address}</TableCell>
                    <TableCell>{provider.zipCode}</TableCell>
                    <TableCell>{provider.email}</TableCell>
                    <TableCell>{provider.SSN}</TableCell>
                    <TableCell>
                      {moment(
                        provider.updatedAt
                          ? provider.updatedAt
                          : provider.createdAt
                      ).format('DD.MM.YYYY')}
                    </TableCell>
                    <TableCell>{renderActiveCell(provider.active)}</TableCell>
                    <TableCell>
                      {provider.purchasedRegistration &&
                        renderActiveCell(
                          provider.purchasedRegistration.length > 0
                        )}
                    </TableCell>
                    {showrecentlyValidated &&
                      <TableCell>
                        {renderActiveCell(provider.recentlyValidated)}
                      </TableCell>
                    }
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  } else {
    return (
      <Grid container direction="column" justify="center" alignContent="center">
        <CircularProgress size={100} />
      </Grid>
    );
  }
};

export default ServiceProvidersTable;

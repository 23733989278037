import React from 'react';
import Typography from '@material-ui/core/Typography';
import Layout from '../components/Layout';
import Grid from '@material-ui/core/Grid';

const LoginRequired: React.FC = props => {
  return (
    <Layout>
      <Grid container justify="center">
        <Grid item justify="center" style={{ height: '90vh', display: 'flex' }}>
          <Typography
            variant="h4"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            Þú þarft að vera skráður notandi til að fá aðgang að kerfinu.{' '}
          </Typography>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default LoginRequired;

import React, { useState, ReactNode } from 'react';
import moment from 'moment';
import { useQuery, useMutation } from 'react-apollo';
import { FormikProps, useFormik } from 'formik';
import {
  makeStyles,
  Grid,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Paper,
  Button,
  FormControlLabel,
  Switch,
  TextField
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import {
  IMunicipality,
  IMunicipalityLite,
  IMunicipalityTranslation,
  IMunicipalityLitePhotos
} from '../interfaces/Municipality/Municipality';
import { IPhotoForm } from './PhotoItem';
import {
  ImageFile,
  IPhoto,
  IPhotoUpdate
} from '../interfaces/ServiceProvider/ServiceProvider';
import { IData } from '../interfaces/Misc/zipCodes';
import { DATE_AND_TIME_FORMAT } from '../constants';
import { GET_DATA } from '../graphql/queries/Misc';
import {
  UPDATE_MUNICIPALITY,
  ADD_MUNICIPALITY_PHOTO,
  REMOVE_MUNICIPALITY_PHOTO,
  EDIT_MUNICIPALITY_PHOTO,
  CREATE_MUNICIPALITY
} from '../graphql/mutations/MunicipalityMutations';
import Autocomplete, { IOption } from './Autocomplete';
import LocationInput from './LocationInput';
import SelectPlace from './SelectPlace';
import EditPhotos from './EditPhotos';
import EditTranslations from './EditTranslations';
import SavePrompt from './SavePrompt';
import useNotifier from '../hooks/useNotifier';
import { getIcelandicName, IS_LOCALE, EN_LOCALE } from '../utils/locale';
import arrayMove from 'array-move';
import remove from 'lodash/remove';

interface IProps {
  municipality?: IMunicipality;
  newMunicipality: boolean;
}

interface ITab {
  key: string;
  label: string;
  render: (formik: IFormik) => ReactNode;
}

interface IFormField {
  key: string;
  translation?: string;
  locale?: typeof IS_LOCALE | typeof EN_LOCALE;
  label: string;
  type?: 'text' | 'select' | 'multiSelect' | 'number';
  required?: boolean;
  disabled?: boolean;
  options?: Array<{ value: string; label: string }>;
}

interface IFormValues {
  zipCodes: IOption[];
  townCode: IOption;
  population: number;
  active: boolean;
  latitude: string;
  longitude: string;
  region: IOption;
  relatedPlaces: IOption[];
  photos: IPhotoUpdate[];
  translations: IMunicipalityTranslation[];
}

type IFormik = FormikProps<IFormValues>;

const useStyles = makeStyles(theme => ({
  tabContent: {
    marginTop: theme.spacing(1)
  },
  section: {
    minHeight: 200,
    marginBottom: theme.spacing(3),
    padding: 20,
    paddingTop: 10,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0
    }
  },
  sectionTitle: {
    marginBottom: theme.spacing(3)
  },
  statusTitle: {
    marginBottom: theme.spacing(1)
  },
  buttons: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3)
    }
  },
  button: {
    padding: 10
  },
  active: {
    marginBottom: theme.spacing(2)
  },
  removeTopMargin: {
    marginTop: 0
  },
  seeMoreGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 10
  },
  typographyDisabled: {
    color: 'rgba(0, 0, 0, 0.5)'
  }
}));

const createSchema = Yup.object().shape({
  translations: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required(),
        locale: Yup.string().required()
      })
    )
    .min(2, 'too short')
    .required(),
  region: Yup.object().required(),
  population: Yup.number().integer()
});

const updateSchema = Yup.object().shape({
  translations: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required(),
        locale: Yup.string().required(),
        description: Yup.string().required(),
        seoDescription: Yup.string(),
        seoTitle: Yup.string(),
        seoKeywords: Yup.string(),
        website: Yup.string()
      })
    )
    .min(2, 'too short')
    .required(),
  region: Yup.object().required(),
  population: Yup.number().integer()
});

const MunicipalityForm: React.FC<IProps> = ({
  municipality,
  newMunicipality
}) => {
  const formik = useFormik<IFormValues>({
    initialValues: getInitialValues(),
    onSubmit: onSubmit,
    validationSchema: newMunicipality ? createSchema : updateSchema,
    validateOnBlur: !!municipality,
    validateOnChange: !!municipality
  });
  const classes = useStyles();
  const notifier = useNotifier();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(0);
  const [isSortingPhotos, setSortingPhotos] = useState<boolean>(false);
  const [photoMovedCounter, changePhotoMoved] = useState<number>(0);

  const { data } = useQuery<IData>(GET_DATA);
  const [updateMunicipality] = useMutation(UPDATE_MUNICIPALITY, {
    refetchQueries: ['getMunicipalityDetails']
  });
  const [addMunicipalityPhoto] = useMutation<{
    addMunicipalityImage: IMunicipalityLitePhotos;
  }>(ADD_MUNICIPALITY_PHOTO, {
    refetchQueries: ['getMunicipalityDetails'],
    onCompleted(data) {
      if (
        data &&
        data.addMunicipalityImage &&
        data.addMunicipalityImage.photos
      ) {
        resetPhotos(data.addMunicipalityImage.photos);
      }
    }
  });
  const [removeMunicipalityPhoto] = useMutation<{
    removePlacePhoto: IMunicipalityLitePhotos;
  }>(REMOVE_MUNICIPALITY_PHOTO, {
    refetchQueries: ['getMunicipalityDetails'],
    onCompleted(data) {
      if (data && data.removePlacePhoto && data.removePlacePhoto.photos) {
        resetPhotos(data.removePlacePhoto.photos);
      }
    }
  });
  const [editMunicipalityPhoto] = useMutation(EDIT_MUNICIPALITY_PHOTO, {
    refetchQueries: ['getMunicipalityDetails']
  });
  const [createMunicipality] = useMutation(CREATE_MUNICIPALITY);

  const { zipCodes, townCodes, regions } = getFieldData(data);
  const tabs = getTabs();
  const municipalityName = municipality
    ? getIcelandicName(municipality.translations)
    : '';

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <AppBar position="static">
          <Tabs
            value={currentTab}
            onChange={(_, val) => {
              setCurrentTab(val);
            }}
            variant="fullWidth"
            scrollButtons="auto"
          >
            {tabs.map(({ key, label }) => (
              <Tab key={key} label={label} />
            ))}
          </Tabs>
        </AppBar>
        {renderTabContent(tabs[currentTab])}
        {!municipality && renderButtons(true)}
      </form>
      <SavePrompt formik={formik} />
    </React.Fragment>
  );

  function renderTabContent({ key, render }: ITab) {
    return (
      <Grid key={key} container spacing={3} className={classes.tabContent}>
        <Grid
          item
          xs={12}
          md={municipality ? 7 : undefined}
          lg={municipality ? 8 : undefined}
        >
          {render(formik)}
        </Grid>
        {municipality ? (
          <Grid item xs={12} md={5} lg={4}>
            {renderStatusArea()}
          </Grid>
        ) : null}
      </Grid>
    );
  }

  function renderStatusArea() {
    return (
      <Grid container direction="column">
        <Grid item>
          <Paper className={classes.section}>
            <Typography variant="h6" className={classes.statusTitle}>
              Staða
            </Typography>
            <FormControlLabel
              label={formik.values.active ? 'Í birtingu' : 'Ekki í birtingu'}
              className={classes.active}
              control={
                <Switch
                  id="active"
                  checked={formik.values.active}
                  onChange={formik.handleChange}
                  color="primary"
                />
              }
            />
            <Typography variant="h6" className={classes.statusTitle}>
              Útgáfa
            </Typography>
            <Typography gutterBottom>
              Síðast breytt:{' '}
              {municipality.updatedAt
                ? moment(municipality.updatedAt).format(DATE_AND_TIME_FORMAT)
                : 'Aldrei'}
            </Typography>
            {municipality.updatedBy && (
              <Typography gutterBottom>Af: {municipality.updatedBy}</Typography>
            )}
          </Paper>
        </Grid>
        <Grid item>{renderButtons()}</Grid>
      </Grid>
    );
  }

  function renderButtons(create?: boolean) {
    const disableSubmit =
      !formik.dirty || formik.isSubmitting || (!formik.isValid && !create);
    return (
      <Grid container spacing={2} className={classes.buttons}>
        <Grid item xs={12} md={create ? 6 : undefined}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            disabled={disableSubmit && !isSortingPhotos}
            className={classes.button}
          >
            Vista
          </Button>
        </Grid>
        <Grid item xs={12} md={create ? 6 : undefined}>
          <Button
            color="default"
            variant="contained"
            fullWidth
            disabled={formik.isSubmitting}
            className={classes.button}
            onClick={() => history.push('/municipalities')}
          >
            Hætta við
          </Button>
        </Grid>
      </Grid>
    );
  }

  function renderInfoTab() {
    const { values, errors, setFieldValue } = formik;

    return (
      <React.Fragment>
        <Paper className={classes.section}>
          <Typography variant="h6" className={classes.sectionTitle}>
            Grunnupplýsingar
          </Typography>
          <Grid container spacing={4}>
            {getInfoFields().map(field => (
              <Grid item xs={12} sm={6} key={field.key + field.locale}>
                {renderInfoField(field)}
              </Grid>
            ))}
          </Grid>
          {values.translations.length > 0 && !newMunicipality && (
            <Grid
              item
              xs={12}
              sm={12}
              key={'seeText'}
              className={classes.seeMoreGrid}
            >
              <Typography className={classes.typographyDisabled}>
                *Sjá textalýsing
              </Typography>
            </Grid>
          )}
        </Paper>
        <Paper className={classes.section}>
          <Typography variant="h6" className={classes.sectionTitle}>
            Tengt efni
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <SelectPlace
                id="relatedPlaces"
                values={values.relatedPlaces}
                error={!!errors.relatedPlaces}
                label="Tengdir staðir"
                onChange={val => setFieldValue('relatedPlaces', val)}
                fullWidth
              />
            </Grid>
          </Grid>
        </Paper>
      </React.Fragment>
    );
  }

  function renderInfoField(field: IFormField) {
    const {
      key,
      translation,
      locale,
      label,
      type,
      options,
      disabled,
      required
    } = field;
    const { values, errors, handleChange, handleBlur, setFieldValue } = formik;
    if (type === 'select' || type === 'multiSelect') {
      return (
        <Autocomplete
          id={key}
          value={type === 'select' ? values[key] : undefined}
          values={type === 'multiSelect' ? values[key] : undefined}
          error={!!errors[key]}
          label={label}
          items={options}
          onChange={val => {
            setFieldValue(key, val);
          }}
          disabled={disabled}
          required={required}
          fullWidth
        />
      );
    }

    if (key === 'latitude' || key === 'longitude') {
      return (
        <LocationInput
          location={{
            latitude: values.latitude,
            longitude: values.longitude
          }}
          type={key}
          label={label}
          error={!!errors[key]}
          required={required}
          disabled={disabled}
          initialSearch={municipalityName}
          onChange={location => {
            setFieldValue('latitude', location.latitude);
            setFieldValue('longitude', location.longitude);
          }}
        />
      );
    }
    if (key === 'name' || key === 'website') {
      return (
        <TextField
          id={translation}
          type={type}
          label={label}
          value={getTranslationsField(key, locale)}
          error={!!errors[translation]}
          onChange={e => setTranslationsValue(e.target.value, locale, key)}
          onBlur={handleBlur}
          variant="outlined"
          disabled={disabled}
          required={required}
          fullWidth
        />
      );
    }

    return (
      <TextField
        id={key}
        type={type}
        label={label}
        value={values[key]}
        error={!!errors[key]}
        onChange={handleChange}
        onBlur={handleBlur}
        variant="outlined"
        disabled={disabled}
        required={required}
        fullWidth
      />
    );
  }

  function setTranslationsValue(
    val: string,
    locale: IFormField['locale'],
    key: string
  ) {
    const { values, setFieldValue } = formik;
    const translationsIndex = values.translations.findIndex(
      val => val.locale === locale
    );
    if (translationsIndex === -1) {
      return;
    } else {
      const lastTranslations: IMunicipalityTranslation[] = Array.from(
        values.translations
      );
      lastTranslations[translationsIndex][key] = val;
      setFieldValue('translations', lastTranslations);
    }
  }

  function getTranslationsField(key: 'website' | 'name', locale: string) {
    const { values } = formik;
    const translationsIndex = values.translations.findIndex(
      val => val.locale === locale
    );
    if (translationsIndex === -1) {
      return '';
    }
    return values.translations[translationsIndex][key];
  }

  function renderContentTab() {
    const { values } = formik;
    return (
      <EditTranslations
        hasWebsiteField
        translations={values.translations}
        className={classes.removeTopMargin}
        addTranslation={translation => addTranslation(translation)}
        editTranslation={(translation, index) =>
          editTranslations(translation, index)
        }
        deleteTranslation={index => removeTranslation(index)}
        disableEdit={false}
      />
    );
  }

  function editTranslations(
    translation: IMunicipalityTranslation,
    index: number
  ) {
    const { values, setFieldValue } = formik;
    const trans: IMunicipalityTranslation = {
      locale: translation.locale,
      name: translation.name,
      description: translation.description,
      seoTitle: translation.seoTitle,
      seoDescription: translation.seoDescription,
      seoKeywords: translation.seoKeywords,
      website: translation.website
    };

    const newTranslations: IMunicipalityTranslation[] = Array.from(
      values.translations
    );

    newTranslations[index] = trans;
    setFieldValue('translations', newTranslations);
  }

  function addTranslation(translation: IMunicipalityTranslation) {
    const { values, setFieldValue } = formik;
    const trans: IMunicipalityTranslation = {
      locale: translation.locale,
      name: translation.name,
      description: translation.description,
      seoTitle: translation.seoTitle,
      seoDescription: translation.seoDescription,
      seoKeywords: translation.seoKeywords,
      website: translation.website
    };

    const newTranslations: IMunicipalityTranslation[] = Array.from(
      values.translations
    );
    newTranslations.push(trans);
    setFieldValue('translations', newTranslations);
  }

  function removeTranslation(index: number) {
    const { values, setFieldValue } = formik;
    const newTranslations: IMunicipalityTranslation[] = remove(
      values.translations,
      (val, index2) => index !== index2
    );
    setFieldValue('translations', newTranslations);
  }

  function renderPhotoTab() {
    const { values } = formik;
    const photos: IPhoto[] = [];
    // eslint-disable-next-line array-callback-return
    values.photos.map(photo => {
      // eslint-disable-next-line array-callback-return
      municipality.photos.map(munPhoto => {
        if (photo.photoId === munPhoto.photoId) {
          photos.push(munPhoto);
        }
      });
    });
    return (
      <EditPhotos
        latest
        photos={photos ? photos : []}
        isSorting={isSortingPhotos}
        setSorting={() => setSortingPhotos(!isSortingPhotos)}
        uploadImage={uploadImage}
        deleteImage={deleteImage}
        updateImage={editImage}
        movePhoto={e => movePhoto(e)}
        cancelMovePhoto={() => cancelMove()}
        className={classes.removeTopMargin}
        disableEdit={false}
      />
    );
  }
  function resetPhotos(photos: IPhoto[]) {
    const { setFieldValue } = formik;
    const photoUpdate = photos.map(photo => ({
      alt: photo.alt,
      title: photo.title,
      photoId: photo.photoId
    }));
    setFieldValue('photos', photoUpdate);
  }

  function movePhoto({
    newIndex,
    oldIndex
  }: {
    newIndex: number;
    oldIndex: number;
  }) {
    const { values, setFieldValue } = formik;
    const counter = photoMovedCounter + 1;
    const newArray = arrayMove(values.photos, oldIndex, newIndex);
    setFieldValue('photos', newArray);
    changePhotoMoved(counter);
  }

  function cancelMove() {
    const { setFieldValue } = formik;
    const counter = photoMovedCounter + 1;
    const oldPhotos: IPhotoUpdate[] = municipality.photos.map(photo => ({
      alt: photo.alt,
      title: photo.title,
      photoId: photo.photoId
    }));
    setFieldValue('photos', oldPhotos);
    changePhotoMoved(counter);
  }

  function getFieldData(data: IData) {
    return {
      zipCodes: data ? data.ZipCodes : [],
      townCodes: data ? data.TownCodes : [],
      regions: data
        ? data.Regions.map(r => ({
          value: r.id,
          label: getIcelandicName(r.translations)
        }))
        : []
    };
  }

  function getTabs(): ITab[] {
    const createTabs = [
      { key: 'info', label: 'Grunnupplýsingar', render: renderInfoTab }
    ];

    if (!municipality) {
      return createTabs;
    }

    return [
      ...createTabs,
      { key: 'translation', label: 'Textalýsing', render: renderContentTab },
      { key: 'photos', label: 'Myndir', render: renderPhotoTab }
    ];
  }

  function getInitialValues() {
    return {
      translations: municipality
        ? municipality.translations
        : createDefaultTranslation(),
      active: !municipality || municipality.active,
      population: municipality ? municipality.population : 0,
      townCode:
        municipality && municipality.townCode
          ? { value: municipality.townCode }
          : undefined,
      longitude:
        municipality && municipality.location
          ? municipality.location.coordinates[0].toString()
          : '',
      latitude:
        municipality && municipality.location
          ? municipality.location.coordinates[1].toString()
          : '',
      zipCodes:
        municipality && municipality.zipCodes
          ? municipality.zipCodes.map(zc => ({ value: zc }))
          : [],
      region:
        municipality && municipality.region
          ? {
            value: municipality.region.id,
            label: getIcelandicName(municipality.region.translations)
          }
          : undefined,
      relatedPlaces:
        municipality && municipality.relatedPlaces
          ? municipality.relatedPlaces.map(p => ({
            value: p.placeId,
            label: getIcelandicName(p.translations)
          }))
          : [],
      photos:
        municipality && municipality.photos && municipality.photos.length > 0
          ? municipality.photos.map(photo => ({
            alt: photo.alt,
            title: photo.title,
            photoId: photo.photoId
          }))
          : []
    };
  }

  function createDefaultTranslation() {
    const newTranslations: IMunicipalityTranslation[] = [
      {
        name: '',
        locale: IS_LOCALE,
        description: '',
        seoTitle: '',
        seoDescription: '',
        seoKeywords: '',
        website: ''
      },
      {
        name: '',
        locale: EN_LOCALE,
        description: '',
        seoTitle: '',
        seoDescription: '',
        seoKeywords: '',
        website: ''
      }
    ];
    return newTranslations;
  }

  function getInfoFields(): IFormField[] {
    return [
      {
        key: 'name',
        translation: 'name',
        locale: IS_LOCALE,
        label: newMunicipality ? 'Nafn' : '*Nafn',
        required: newMunicipality,
        disabled: !newMunicipality
      },
      {
        key: 'name',
        translation: 'nameEn',
        locale: EN_LOCALE,
        label: newMunicipality ? 'Nafn en' : '*Nafn En',
        required: newMunicipality,
        disabled: !newMunicipality
      },
      {
        key: 'zipCodes',
        label: 'Póstnúmer',
        type: 'multiSelect',
        options: zipCodes
      },
      {
        key: 'townCode',
        label: 'Sveitafélagsnúmer',
        type: 'select',
        options: townCodes
      },
      {
        key: 'region',
        label: 'Landshluti',
        type: 'select',
        required: true,
        options: regions
      },
      {
        key: 'population',
        label: 'Íbúafjöldi',
        type: 'number'
      },
      {
        key: 'latitude',
        label: 'Breiddargráða'
      },
      {
        key: 'longitude',
        label: 'Lengdargráða'
      },
      {
        key: 'website',
        translation: 'website',
        label: newMunicipality ? 'Vefsíða' : '*Vefsíða',
        locale: IS_LOCALE,
        disabled: !newMunicipality
      },
      {
        key: 'website',
        translation: 'websiteEn',
        label: newMunicipality ? 'Vefsíða En' : '*Vefsíða En',
        locale: EN_LOCALE,
        disabled: !newMunicipality
      }
    ];
  }

  async function onSubmit(values: IFormValues) {
    if (municipality) {
      await updateMunicipalityDetails(values);
      setSortingPhotos(false);
    } else {
      await createNewMunicipality(values);
    }
  }

  async function updateMunicipalityDetails(values: IFormValues) {
    try {
      const {
        active,
        townCode,
        population,
        region,
        latitude,
        longitude,
        translations,
        relatedPlaces,
        photos
      } = values;

      await updateMunicipality({
        variables: {
          municipalityId: municipality.municipalityId,
          values: {
            active,
            population: population || 0,
            townCode: townCode ? townCode.value : '',
            regionId: region ? region.value : '',
            zipCodes: values.zipCodes.map(zc => zc.value),
            placeIds: relatedPlaces.map(p => p.value),
            location: parseCoordinates(longitude, latitude),
            translations: translations,
            photos
          }
        }
      });
      notifier.notify('Bæjarfélag uppfært', { variant: 'success' });
    } catch {
      notifier.notify('Mistókst að uppfæra bæjarfélag', { variant: 'error' });
    }
  }

  async function createNewMunicipality(values: IFormValues) {
    try {
      const {
        townCode,
        population,
        region,
        latitude,
        longitude,
        relatedPlaces,
        translations
      } = values;

      const res = await createMunicipality({
        variables: {
          values: {
            population: population || 0,
            townCode: townCode ? townCode.value : '',
            regionId: region ? region.value : '',
            zipCodes: values.zipCodes.map(zc => zc.value),
            placeIds: relatedPlaces.map(p => p.value),
            location: parseCoordinates(longitude, latitude),
            translations
          }
        }
      });

      const newMunicipality: IMunicipalityLite = res.data.createMunicipality;
      notifier.notify('Nýtt bæjarfélag skráð', { variant: 'success' });
      history.push(`/municipality/${newMunicipality.municipalityId}`);
    } catch {
      notifier.notify('Mistókst að skrá nýtt bæjarfélag', { variant: 'error' });
    }
  }

  function parseCoordinates(lng: string, lat: string) {
    return lng && lat
      ? { type: 'Point', coordinates: [parseFloat(lng), parseFloat(lat)] }
      : undefined;
  }

  async function uploadImage(image: ImageFile, info: IPhotoForm) {
    addMunicipalityPhoto({
      variables: {
        municipalityId: municipality.municipalityId,
        image,
        title: info.title,
        alt: info.alt
      }
    });
  }

  async function deleteImage(imageId: string) {
    removeMunicipalityPhoto({
      variables: { municipalityId: municipality.municipalityId, imageId }
    });
  }

  async function editImage(imageId: string, info: IPhotoForm) {
    editMunicipalityPhoto({
      variables: {
        municipalityId: municipality.municipalityId,
        imageId,
        title: info.title,
        alt: info.alt
      }
    });
  }
};

export default MunicipalityForm;

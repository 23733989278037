import * as themeSelectors from './theme/selectors';
import * as authSelectors from './auth/selectors';
import { IApplicationState } from './types';


export const getAuthConfig = () => ({
  auth0ClientId: 'TDu2kS8L8Kwz7asfWx6fasaCL2xV1Zpo',
  auth0Domain: 'itb-service-providers-dev.eu.auth0.com'
});
export const getLoginStatus = (state: IApplicationState) =>
  authSelectors.isLoggedIn(state.auth);
export const getAuthState = (state: IApplicationState) => state.auth;
export const getRouterState = (state: IApplicationState) => state.router;
export const selectApiToken = (state: IApplicationState) =>
  authSelectors.getAccessToken(state.auth);
export const getTokenExpiryTimestamp = (state: IApplicationState) =>
  authSelectors.getTokenExpiryTimestamp(state.auth);

export function selectTheme(state: IApplicationState) {
  return themeSelectors.getTheme(state.theme);
}

export function selectAppMetadata(state: IApplicationState) {
  return authSelectors.selectAppMetadata(state.auth);
}

export function selectUserMetadata(state: IApplicationState) {
  return authSelectors.selectUserMetadata(state.auth);
}

export function selectIsAdmin(state: IApplicationState) {
  return authSelectors.selectIsAdmin(state.auth);
}
export function selectIsEditor(state: IApplicationState) {
  return authSelectors.selectIsEditor(state.auth);
}

export function selectEmailVerified(state: IApplicationState) {
  return authSelectors.selectEmailVerified(state.auth);
}

export function selectUserEmail(state: IApplicationState) {
  return authSelectors.selectUserEmail(state.auth);
}

export function selectRouterState(state: IApplicationState) {
  return state.router;
}

export function selectCurrentPathname(state: IApplicationState) {
  return selectRouterState(state).location.pathname;
}

export function selectUserRegions(state: IApplicationState) {
  return authSelectors.selectUserRegions(state.auth);
}
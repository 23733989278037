/* global google */
import React, { memo, useContext } from 'react';
import MapsContext from './Context';
import { MouseEventHandler, IRendererProps, TPolygonStyle } from './types';
import { ILatLng } from '../../common-types';

interface IProps {
  onPolygonComplete?: (paths: ILatLng[]) => void;
  polygonStyle?: TPolygonStyle;
  onMouseOver?: MouseEventHandler;
}

interface IState {
  drawingManager: google.maps.drawing.DrawingManager | null;
}

class DrawingManagerRenderer extends React.PureComponent<
  IProps & IRendererProps,
  IState
> {
  state: IState = {
    drawingManager: null
  };

  componentDidMount() {
    const { map, maps, onPolygonComplete } = this.props;

    const drawingManager = new maps.drawing.DrawingManager(this.getOptions());

    drawingManager.addListener(
      'polygoncomplete',
      (polygon: google.maps.Polygon) => {
        if (onPolygonComplete) {
          const path = polygon
            .getPath()
            .getArray()
            .map(l => ({ lat: l.lat(), lng: l.lng() }));
          onPolygonComplete(path);
        }
        polygon.setMap(null);
      }
    );

    drawingManager.setMap(map);
    this.setState({ drawingManager });
  }

  componentDidUpdate() {
    const { drawingManager } = this.state;

    if (drawingManager) {
      drawingManager.setOptions(this.getOptions());
    }
  }

  componentWillUnmount() {
    const { maps } = this.props;
    const { drawingManager } = this.state;

    if (drawingManager) {
      maps.event.clearInstanceListeners(drawingManager);
      drawingManager.setMap(null);
    }
  }

  render() {
    return null;
  }

  getOptions(): google.maps.drawing.DrawingManagerOptions {
    const { polygonStyle } = this.props;

    return {
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON]
      },
      polygonOptions: polygonStyle
    };
  }
}

const DrawingManager: React.FC<IProps> = props => {
  const { map, maps, isLoaded } = useContext(MapsContext);

  if (!isLoaded || !map || !maps) {
    return null;
  }

  return <DrawingManagerRenderer map={map} maps={maps} {...props} />;
};

export default memo(DrawingManager);

/* global google */
import React, { useState, useEffect } from 'react';
import GoogleMapReact, { ChangeEventValue } from 'google-map-react';

import MapsContext, { IMapsContext } from './Context';
import { TBounds } from './types';
import { ILatLng } from '../../common-types';

interface IMapProps {
  center?: ILatLng;
  zoom?: number;
  draggable?: boolean;
  bounds?: TBounds | null;
  onChange?: (value: ChangeEventValue) => void;
  onLoaded?: () => void;
  onClick?: (mouse: IMapMouseEvent) => void;
  onChildMouseUp?: (key: any, props: any, mouse: IMapMouseEvent) => void;
  onChildMouseDown?: (key: any, props: any, mouse: IMapMouseEvent) => void;
  onChildMouseMove?: (key: any, props: any, mouse: IMapMouseEvent) => void;
}

export interface IMapMouseEvent {
  lat: number;
  lng: number;
}

// Center of Iceland
const defaultCenter = { lat: 64.9631, lng: -19.0208 };
const defaultZoom = 6.5;

const GoogleMap: React.FC<IMapProps> = ({
  children,
  center,
  zoom,
  draggable,
  bounds,
  onChange,
  onLoaded,
  onClick,
  onChildMouseUp,
  onChildMouseDown,
  onChildMouseMove
}) => {
  const [state, setState] = useState<IMapsContext>({
    map: null,
    maps: null,
    isLoaded: false
  });

  useEffect(() => {
    if (state.map && bounds) {
      state.map.fitBounds(bounds);
    }
  }, [state.map, bounds]);

  return (
    <MapsContext.Provider value={state}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
          libraries: ['drawing']
        }}
        center={center || defaultCenter}
        zoom={zoom || defaultZoom}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={onMapLoaded}
        onChange={onChange}
        options={{ fullscreenControl: false, mapTypeControl: true }}
        draggable={draggable}
        onClick={onClick}
        onChildMouseUp={onChildMouseUp}
        onChildMouseDown={onChildMouseDown}
        onChildMouseMove={onChildMouseMove}
      >
        {children}
      </GoogleMapReact>
    </MapsContext.Provider>
  );

  function onMapLoaded({
    map,
    maps
  }: {
    map: google.maps.Map;
    maps: typeof google.maps;
  }) {
    setState({
      map,
      maps,
      isLoaded: true
    });

    if (onLoaded) {
      onLoaded();
    }
  }
};

export default GoogleMap;

export const IS_LOCALE = 'is-IS';
export const EN_LOCALE = 'en-US';
export const ES_LOCALE = 'es-ES';
export const DE_LOCALE = 'de-DE';
export const FR_LOCALE = 'fr-FR';


export function getIcelandicTranslation<T extends { locale: string }>(
  translations: T[]
): T | null {
  for (const translation of translations) {
    if (translation.locale === IS_LOCALE) {
      return translation;
    }
  }
  return null;
}

export function getIcelandicName<T extends { locale: string; name: string }>(
  translations: T[]
): string {
  for (const translation of translations) {
    if (translation.locale === IS_LOCALE) {
      return translation.name;
    }
  }
  return '';
}

export function getEnglishTranslation<T extends { locale: string }>(
  translations: T[]
): T | null {
  for (const translation of translations) {
    if (translation.locale === EN_LOCALE) {
      return translation;
    }
  }
  return null;
}

export function getEnglishName<T extends { locale: string; name: string }>(
  translations: T[]
): string {
  for (const translation of translations) {
    if (translation.locale === EN_LOCALE) {
      return translation.name;
    }
  }
  return '';
}

import { all, takeLatest } from 'redux-saga/effects';
import { LOCATION_CHANGE, CALL_HISTORY_METHOD } from 'connected-react-router';

function* navigationSaga() {
  yield all([
    takeLatest(LOCATION_CHANGE, function* locationChange(arg) {
      yield true;
    }),
    takeLatest(CALL_HISTORY_METHOD, function* callHistoryMethod(arg) {
      yield true;
    })
  ]);
}

export default navigationSaga;

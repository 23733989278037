import React, { useState, useEffect } from 'react';
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Button,
  TableContainer,
  Paper,
  TablePagination,
  TextField,
  TableSortLabel,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  makeStyles,
  Theme,
  createStyles,
  CircularProgress,
  Typography
} from '@material-ui/core';
import { useQuery, useLazyQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import { Prompt } from 'react-router-dom';

import {
  SERVICE_PROVIDERS,
  SERVICE_PROVIDERS_DETAILS
} from '../graphql/queries/ServiceProviderQueries';
import {
  IServiceProvidersLite,
  IServiceProvidersDetails,
  IFilter,
  IProviderLite
} from '../interfaces/ServiceProvider/ServiceProvider';
import { GET_DATA_WITH_GEO, GET_DATA } from '../graphql/queries/Misc';
import { ICodes, IDataWithFences } from '../interfaces/Misc/zipCodes';
import moment from 'moment';
import { Autocomplete } from '@material-ui/lab';
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';
import { TABLE_ROWS_OPTIONS } from '../constants';
import { exportServiceProviders, IExportProviderStatus } from '../utils/export';
import useNotifier from '../hooks/useNotifier';
import TablePaginationActions from './TablePaginationActions';
import { getIcelandicName } from '../utils/locale';
import useAdminChecker from '../hooks/useAdminChecker';
import useEditorChecker from '../hooks/useEditorChecker';
import { getLastSearch, setLastSearch, clearLastSearch } from '../store/localStorage/lastSearch';

interface IProps { }
enum Order {
  ASC = 'ASC',
  DESC = 'DESC'
}

const getFilter = (filterObject) => {
  const filter: IFilter = {
    text: filterObject.text === '' ? null : filterObject.text,
    categoryIds:
      filterObject.categoryIds.length === 0
        ? null
        : filterObject.categoryIds.map(cat => cat.value),
    regionIds:
      filterObject.regionIds.length === 0
        ? null
        : filterObject.regionIds.map(region => region.value),

    zipCodes:
      filterObject.zipCode.length === 0
        ? null
        : filterObject.zipCode.map(zip => zip.value),
    purchasedRegionIds:
      filterObject.purchasedRegionIds.length === 0
        ? null
        : filterObject.purchasedRegionIds.map(cat => cat.value),
    exactCategories: filterObject.exactCategories === 'true' ? true : false,
    fenceId: filterObject.fenceId ? filterObject.fenceId : null,
    cleanAndSafe:
      filterObject.cleanAndSafe === 'true'
        ? true
        : filterObject.cleanAndSafe === 'false'
          ? false
          : null,
    lastValidatedYear: filterObject.lastValidatedYear
      ? filterObject.lastValidatedYear
      : null,
  };
  return filter;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      padding: theme.spacing(1)
    },
    typographyTitle: {
      padding: 20,
      paddingBottom: 5,
      paddingTop: 10
    },
    active: {
      color: 'white',
      backgroundColor: theme.palette.success.main,
      borderRadius: 5,
      padding: 2,
      fontWeight: theme.typography.fontWeightMedium,
      width: 40,
      paddingLeft: 8
    },
    inactive: {
      backgroundColor: theme.palette.error.main,
      width: 40,
      paddingLeft: 9
    }
  })
);

const EXPORT_MAX = 1000;

const ViewServiceProviders: React.FC<IProps> = () => {
  const history = useHistory();
  const notifier = useNotifier();
  const isAdmin = useAdminChecker();
  const isEditor = useEditorChecker();
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(25);
  const [page, setPage] = useState(0);
  const [active, setActive] = useState<boolean | undefined>(undefined);
  const [approved, setApproved] = useState(true);
  const [zipCodeOptions, setZipCodeOptions] = useState<ICodes[] | null>(null);
  const [regionsOptions, setRegionsOptions] = useState<ICodes[] | null>(null);
  const [fenceOptions, setFenceOptions] = useState<ICodes[] | null>(null);
  const [categoriesOptions, setCategoriesOptions] = useState<any>(null);

  const initialFilterObject = {
    text: '',
    categoryIds: [],
    regionIds: [],
    zipCode: [],
    purchasedRegionIds: [],
    exactCategories: 'false',
    fenceId: '',
    cleanAndSafe: 'undefined',
    lastValidatedYear: null
  };
  const [filterObject, setFilterObject] = useState(getLastSearch("serviceProvider") !== null ? getLastSearch("serviceProvider") : initialFilterObject);
  const [submittedFilter, submitFilter] = useState(getLastSearch("serviceProvider") !== null ? getFilter(filterObject) : {});
  const emptySort = {
    name: null,
    legalName: null,
    address: null,
    zipCode: null,
    email: null,
    SSN: null,
    updatedAt: null
  };
  const initalActiveApprove = {
    active: 'undefined',
    approved: 'true'
  };
  const [activeAndApproved, setActiveAndApproved] = useState(
    initalActiveApprove
  );
  const [sort, setSorting] = useState({ ...emptySort, legalName: 'ASC' });
  const [exportStatus, setExportStatus] = useState<IExportProviderStatus>({
    isExporting: false,
    itemsToFetch: 0,
    providers: [],
    filter: { ...submittedFilter },
    sort: { ...sort },
    active,
    approved
  });

  const classes = useStyles({});
  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" color="primary" />;

  const { loading, error, data } = useQuery<IServiceProvidersLite>(
    SERVICE_PROVIDERS,
    {
      variables: {
        skip,
        take,
        filter: submittedFilter,
        sort,
        active,
        approved
      }
    }
  );

  const exportSkip = exportStatus.providers.length;
  const exportRemaining = exportStatus.itemsToFetch - exportSkip;
  const exportTake =
    exportRemaining < EXPORT_MAX ? exportRemaining : EXPORT_MAX;
  const [getServiceProvidersDetails] = useLazyQuery<IServiceProvidersDetails>(
    SERVICE_PROVIDERS_DETAILS,
    {
      variables: {
        skip: exportSkip,
        take: exportTake,
        filter: exportStatus.filter,
        sort: exportStatus.sort,
        active: exportStatus.active,
        approved: exportStatus.approved
      },
      onCompleted: exportData,
      onError: () => {
        setExportStatus(prevState => ({ ...prevState, isExporting: false }));
        notifier.notify('Villa kom upp', { variant: 'error' });
      }
    }
  );

  const {
    loading: loadingCodes,
    error: errorCodes,
    data: dataCodes
  } = useQuery<IDataWithFences>(
    isAdmin || isEditor ? GET_DATA_WITH_GEO : GET_DATA
  );

  useEffect(() => {
    if (dataCodes) {
      setZipCodeOptions(dataCodes.ZipCodes);
      const newRegions = dataCodes.Regions.map(region => {
        return { label: region.translations[0].name, value: region.id };
      });
      if (isAdmin || isEditor) {
        const newFences = dataCodes.GeoFences.map(fence => ({
          label: getIcelandicName(fence.properties.translations),
          value: fence.id
        }));
        if (newFences && newFences.length > 0) {
          setFenceOptions([{ label: 'Engin', value: '' }, ...newFences]);
        } else setFenceOptions([]);
      }
      setRegionsOptions(newRegions);
      var newCategoriesOptions = [];
      dataCodes.Categories.forEach(category => {
        category.subCategories.forEach(sub => {
          newCategoriesOptions.push({
            value: sub.id,
            label: sub.translations[0].name
          });
        });
      });
      setCategoriesOptions(newCategoriesOptions);
    }
  }, [dataCodes, isAdmin, isEditor]);

  const submitSearch = () => {
    const filter = getFilter(filterObject);
    setLastSearch("serviceProvider", filterObject);
    submitFilter(filter);
    submitActiveApproved();
  };

  const submitActiveApproved = () => {
    const active: undefined | boolean =
      activeAndApproved.active === 'undefined'
        ? undefined
        : activeAndApproved.active === 'true'
          ? true
          : false;
    const approved: boolean =
      activeAndApproved.approved === 'true' ? true : false;
    setActive(active);
    setApproved(approved);
  };

  function renderActiveCell(active: boolean) {
    return (
      <Typography
        className={cx(classes.active, { [classes.inactive]: !active })}
      >
        {active ? 'Já' : 'Nei'}
      </Typography>
    );
  }

  const minYear = 2019;
  const maxYear = new Date().getFullYear();
  const yearList = [];

  for (var i = maxYear; i >= minYear; i--) {
    yearList.push(i);
  }

  if (loading || loadingCodes) {
    return (
      <Grid container direction="column" justify="center" alignContent="center">
        <CircularProgress size={100} />
      </Grid>
    );
  }

  if (error) {
    return <p>{error.message}</p>;
  }
  if (errorCodes) {
    return <p>{errorCodes.message}</p>;
  }
  if (categoriesOptions && data) {
    return (
      <div>
        <Paper style={{ paddingTop: 30 }}>
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              sm={(isAdmin || isEditor) && fenceOptions.length > 0 ? 4 : 6}
            >
              <Paper className={classes.paper} elevation={0}>
                <TextField
                  id="text"
                  label="Nafn, Markaðsheiti, Heimilisfang, Netfang, Kennitala"
                  variant="outlined"
                  fullWidth
                  autoComplete="new-password"
                  value={filterObject.text}
                  onChange={event => {
                    setFilterObject({
                      ...filterObject,
                      text: event.currentTarget.value
                    });
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      submitSearch();
                      setSkip(0);
                      setPage(0);
                    }
                  }}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Paper className={classes.paper} elevation={0}>
                <Autocomplete
                  id={'zipCode'}
                  multiple
                  disableCloseOnSelect
                  options={zipCodeOptions}
                  onChange={(_, value) =>
                    setFilterObject({
                      ...filterObject,
                      zipCode: value as ICodes[]
                    })
                  }
                  value={filterObject.zipCode}
                  getOptionLabel={option => option.label}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.label}
                    </React.Fragment>
                  )}
                  style={{ width: '100%' }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password'
                      }}
                      variant="outlined"
                      label={'Póstnúmer'}
                    />
                  )}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Paper className={classes.paper} elevation={0}>
                <FormControl fullWidth variant={'outlined'}>
                  <InputLabel id={'virkur-label'}>Í birtingu</InputLabel>
                  <Select
                    id={'virkur'}
                    labelId={'virkur-label'}
                    labelWidth={65}
                    value={activeAndApproved.active}
                    onChange={event =>
                      setActiveAndApproved({
                        ...activeAndApproved,
                        active: event.target.value as string
                      })
                    }
                    autoComplete="new-password"
                  >
                    <MenuItem value={'true'}>Já</MenuItem>
                    <MenuItem value={'false'}>Nei</MenuItem>
                    <MenuItem value={'undefined'}>Já og Nei</MenuItem>
                  </Select>
                </FormControl>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Paper className={classes.paper} elevation={0}>
                <FormControl fullWidth variant={'outlined'}>
                  <InputLabel id={'samþykkt-label'}>{'Samþykkt'}</InputLabel>
                  <Select
                    id={'samþykkt'}
                    labelId={'samþykkt-label'}
                    labelWidth={75}
                    value={activeAndApproved.approved}
                    onChange={event =>
                      setActiveAndApproved({
                        ...activeAndApproved,
                        approved: event.target.value as string
                      })
                    }
                    autoComplete="new-password"
                  >
                    <MenuItem value={'true'}>Já</MenuItem>
                    <MenuItem value={'false'}>Nei</MenuItem>
                  </Select>
                </FormControl>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Paper className={classes.paper} elevation={0}>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  id={'region'}
                  options={regionsOptions}
                  onChange={(_, value) =>
                    setFilterObject({
                      ...filterObject,
                      purchasedRegionIds: value as ICodes[]
                    })
                  }
                  value={filterObject.purchasedRegionIds}
                  getOptionLabel={option => option.label}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.label}
                    </React.Fragment>
                  )}
                  style={{ width: '100%' }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password'
                      }}
                      variant="outlined"
                      label={'Keypt skráning'}
                    />
                  )}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Paper className={classes.paper} elevation={0}>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  id={'category'}
                  options={categoriesOptions}
                  onChange={(_, value) =>
                    setFilterObject({
                      ...filterObject,
                      categoryIds: value as ICodes[]
                    })
                  }
                  value={filterObject.categoryIds}
                  getOptionLabel={option => option.label}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.label}
                    </React.Fragment>
                  )}
                  style={{ width: '100%' }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password'
                      }}
                      variant="outlined"
                      label={'Flokkur'}
                    />
                  )}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Paper className={classes.paper} elevation={0}>
                <FormControl fullWidth variant={'outlined'}>
                  <InputLabel id={'exactCategories-label'}>
                    {'Nákvæmir flokkar'}
                  </InputLabel>
                  <Select
                    id={'exactCategories'}
                    labelId={'exactCategories-label'}
                    labelWidth={130}
                    value={filterObject.exactCategories}
                    onChange={event =>
                      setFilterObject({
                        ...filterObject,
                        exactCategories: event.target.value as string
                      })
                    }
                    autoComplete="new-password"
                  >
                    <MenuItem value={'true'}>Já</MenuItem>
                    <MenuItem value={'false'}>Nei</MenuItem>
                  </Select>
                </FormControl>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Paper className={classes.paper} elevation={0}>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  id={'region'}
                  options={regionsOptions}
                  onChange={(_, value) =>
                    setFilterObject({
                      ...filterObject,
                      regionIds: value as ICodes[]
                    })
                  }
                  value={filterObject.regionIds}
                  getOptionLabel={option => option.label}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.label}
                    </React.Fragment>
                  )}
                  style={{ width: '100%' }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password'
                      }}
                      variant="outlined"
                      label={'Landshluti'}
                    />
                  )}
                />
              </Paper>
            </Grid>
            {(isAdmin || isEditor) && fenceOptions.length > 0 && (
              <Grid item xs={12} sm={2}>
                <Paper className={classes.paper} elevation={0}>
                  <FormControl fullWidth variant={'outlined'}>
                    <InputLabel id={'virkur-label'}>Landmörk</InputLabel>
                    <Select
                      id={'geofence'}
                      labelId={'fence-label'}
                      labelWidth={65}
                      value={filterObject.fenceId}
                      onChange={event =>
                        setFilterObject({
                          ...filterObject,
                          fenceId: event.target.value as string
                        })
                      }
                      autoComplete="new-password"
                    >
                      {fenceOptions.map(fence => {
                        return (
                          <MenuItem value={fence.value}>{fence.label}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Paper>
              </Grid>
            )}
            <Grid item xs={12} sm={2}>
              <Paper className={classes.paper} elevation={0}>
                <FormControl fullWidth variant={'outlined'}>
                  <InputLabel id={'clean-label'}>{'Clean&Safe'}</InputLabel>
                  <Select
                    id={'clean'}
                    labelId={'clean-label'}
                    labelWidth={75}
                    value={filterObject.cleanAndSafe}
                    onChange={event =>
                      setFilterObject({
                        ...filterObject,
                        cleanAndSafe: event.target.value as string
                      })
                    }
                    autoComplete="new-password"
                  >
                    <MenuItem value={'true'}>Já</MenuItem>
                    <MenuItem value={'false'}>Nei</MenuItem>
                    <MenuItem value={'undefined'}>Ekkert valið</MenuItem>
                  </Select>
                </FormControl>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Paper className={classes.paper} elevation={0}>
                <FormControl fullWidth variant={'outlined'}>
                  <InputLabel id={'clean-label'}>{'Síðast staðfest'}</InputLabel>
                  <Select
                    id={'lastValidated'}
                    labelId={'last-validated-label'}
                    labelWidth={75}
                    value={filterObject.lastValidatedYear}
                    onChange={event => {
                      setFilterObject({
                        ...filterObject,
                        lastValidatedYear: event.target.value as number
                      })
                    }

                    }
                  >
                    {yearList.map(year => (
                      <MenuItem value={year}>{year}</MenuItem>
                    ))}
                    <MenuItem value={undefined}>Ekkert valið</MenuItem>
                  </Select>
                </FormControl>
              </Paper>
            </Grid>
            <Grid item sm={12} md={12}>
              <Paper
                className={classes.paper}
                elevation={0}
                style={{ textAlign: 'right' }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  style={{ padding: 16 }}
                  onClick={() => {
                    submitSearch();
                    setSkip(0);
                    setPage(0);
                  }}
                >
                  leita
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  style={{ padding: 16, marginLeft: 8 }}
                  onClick={() => {
                    setFilterObject({
                      ...initialFilterObject
                    });
                    submitFilter({});
                    clearLastSearch("serviceProvider");
                    setActiveAndApproved({ ...initalActiveApprove });
                    setActive(undefined);
                    setApproved(true);
                  }}
                >
                  Hreinsa
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  style={{ padding: 16, marginLeft: 8 }}
                  onClick={getExportData}
                  disabled={exportStatus.isExporting}
                >
                  {exportStatus.isExporting ? 'Exporting' : 'Export'}
                </Button>
              </Paper>
            </Grid>
          </Grid>

          <Paper className={classes.paper} elevation={0}>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={!!sort.legalName}
                        direction={
                          sort.legalName === Order.DESC ? 'desc' : 'asc'
                        }
                        onClick={() => {
                          if (sort.legalName === Order.ASC) {
                            setSorting({
                              ...emptySort,
                              legalName: Order.DESC
                            });
                          } else
                            setSorting({
                              ...emptySort,
                              legalName: Order.ASC
                            });
                        }}
                      >
                        Nafn
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={!!sort.name}
                        direction={sort.name === Order.DESC ? 'desc' : 'asc'}
                        onClick={() => {
                          if (sort.name === Order.ASC) {
                            setSorting({
                              ...emptySort,
                              name: Order.DESC
                            });
                          } else
                            setSorting({
                              ...emptySort,
                              name: Order.ASC
                            });
                        }}
                      >
                        Markaðsheiti
                      </TableSortLabel>
                    </TableCell>
                    <TableCell /* align="right" */>
                      <TableSortLabel
                        active={!!sort.address}
                        direction={sort.address === Order.DESC ? 'desc' : 'asc'}
                        onClick={() => {
                          if (sort.address === Order.ASC) {
                            setSorting({ ...emptySort, address: Order.DESC });
                          } else
                            setSorting({ ...emptySort, address: Order.ASC });
                        }}
                      >
                        Heimilisfang
                      </TableSortLabel>
                    </TableCell>
                    <TableCell /* align="right" */>
                      <TableSortLabel
                        active={!!sort.zipCode}
                        direction={sort.zipCode === Order.DESC ? 'desc' : 'asc'}
                        onClick={() => {
                          if (sort.zipCode === Order.ASC) {
                            setSorting({ ...emptySort, zipCode: Order.DESC });
                          } else
                            setSorting({ ...emptySort, zipCode: Order.ASC });
                        }}
                      >
                        Póstnúmer
                      </TableSortLabel>
                    </TableCell>
                    <TableCell /* align="right" */>
                      <TableSortLabel
                        active={!!sort.email}
                        direction={sort.email === Order.DESC ? 'desc' : 'asc'}
                        onClick={() => {
                          if (sort.email === Order.ASC) {
                            setSorting({ ...emptySort, email: Order.DESC });
                          } else setSorting({ ...emptySort, email: Order.ASC });
                        }}
                      >
                        Netfang
                      </TableSortLabel>
                    </TableCell>
                    <TableCell /* align="right" */>
                      <TableSortLabel
                        active={!!sort.SSN}
                        direction={sort.SSN === Order.DESC ? 'desc' : 'asc'}
                        onClick={() => {
                          if (sort.SSN === Order.ASC) {
                            setSorting({ ...emptySort, SSN: Order.DESC });
                          } else setSorting({ ...emptySort, SSN: Order.ASC });
                        }}
                      >
                        Kennitala
                      </TableSortLabel>
                    </TableCell>
                    <TableCell /* align="right" */>
                      <TableSortLabel
                        active={!!sort.updatedAt}
                        direction={
                          sort.updatedAt === Order.DESC ? 'desc' : 'asc'
                        }
                        onClick={() => {
                          if (sort.updatedAt === Order.ASC) {
                            setSorting({
                              ...emptySort,
                              updatedAt: Order.DESC
                            });
                          } else
                            setSorting({
                              ...emptySort,
                              updatedAt: Order.ASC
                            });
                        }}
                      >
                        Síðast breytt
                      </TableSortLabel>
                    </TableCell>
                    <TableCell /* align="right" */>Í birtingu</TableCell>
                    <TableCell /* align="right" */>Keypt skráning</TableCell>
                    <TableCell /* align="right" */>Staðfest á árinu</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!data &&
                    !!data.ServiceProviders &&
                    data.ServiceProviders.ServiceProviders.map(
                      (provider: IProviderLite, index) => {
                        const translationIndex = provider.translations.findIndex(
                          trans => trans.locale === 'is-IS'
                        );
                        return (
                          <TableRow
                            key={index}
                            style={{ cursor: 'pointer' }}
                            hover
                            onClick={() => {
                              history.push(
                                `/service-providers/${provider.serviceProviderId}`
                              )
                            }
                            }
                            onMouseDown={(event) => {
                              if (event.button === 1) {
                                window.open(`/service-providers/${provider.serviceProviderId}`);
                                window.focus();
                              }
                            }
                            }
                          >
                            <TableCell>{provider.legalName}</TableCell>
                            <TableCell>
                              {translationIndex !== -1
                                ? provider.translations[translationIndex].name
                                : provider.translations[0].name
                                  ? provider.translations[0].name
                                  : ''}
                            </TableCell>
                            <TableCell /* align="right" */>
                              {provider.address}
                            </TableCell>
                            <TableCell /* align="right" */>
                              {provider.zipCode}
                            </TableCell>
                            <TableCell /* align="right" */>
                              {provider.email}
                            </TableCell>
                            <TableCell /* align="right" */>
                              {provider.SSN}
                            </TableCell>
                            <TableCell /* align="right" */>
                              {moment(
                                provider.updatedAt
                                  ? provider.updatedAt
                                  : provider.createdAt
                              ).format('DD.MM.YYYY')}
                            </TableCell>
                            <TableCell /* align="right" */>
                              {renderActiveCell(provider.active)}
                            </TableCell>
                            <TableCell /* align="right" */>
                              {renderActiveCell(
                                provider.purchasedRegistration &&
                                provider.purchasedRegistration.length > 0
                              )}
                            </TableCell>
                            <TableCell>
                              {renderActiveCell(
                                provider.recentlyValidated //moment().diff(provider.updatedAt, 'months') < 3
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              labelRowsPerPage="Fjöldi raða fyrir síðu"
              rowsPerPageOptions={TABLE_ROWS_OPTIONS}
              component="div"
              count={data.ServiceProviders.count}
              rowsPerPage={take}
              page={page}
              onChangePage={(_, changePage) => {
                setPage(changePage);
                setSkip(changePage * take);
              }}
              onChangeRowsPerPage={take => {
                setPage(0);
                setSkip(0);
                setTake(parseInt(take.target.value, 10));
              }}
              ActionsComponent={TablePaginationActions}
            />
          </Paper>
        </Paper>
        <Prompt
          when={exportStatus.isExporting}
          message="Export er ennþá í gangi"
        />
      </div >
    );
  } else {
    return (
      <Grid container direction="column" justify="center" alignContent="center">
        <CircularProgress size={100} />
      </Grid>
    );
  }

  function getExportData() {
    setExportStatus({
      isExporting: true,
      itemsToFetch: data ? data.ServiceProviders.count : 0,
      providers: [],
      filter: { ...submittedFilter },
      sort: { ...sort },
      active,
      approved
    });
    getServiceProvidersDetails();
  }

  function exportData(detailsData: IServiceProvidersDetails) {
    if (exportStatus.isExporting) {
      const newProviders = detailsData
        ? detailsData.ServiceProviders.ServiceProviders
        : [];
      if (exportRemaining <= EXPORT_MAX) {
        exportServiceProviders([...exportStatus.providers, ...newProviders]);
        setExportStatus(prevState => ({ ...prevState, isExporting: false }));
      } else {
        setExportStatus(prevState => ({
          ...prevState,
          providers: [...prevState.providers, ...newProviders]
        }));
        getServiceProvidersDetails();
      }
    }
  }
};

export default ViewServiceProviders;

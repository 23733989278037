import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import {
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Paper,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormGroup,
  FormControlLabel,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { ICategory, ISubCategory } from '../interfaces/Category/Category';
import {
  CREATE_CATEGORY,
  UPDATE_CATEGORY,
  REMOVE_CATEGORY,
  CREATE_SUB_CATEGORIES,
  REMOVE_SUB_CATEGORIES,
  UPDATE_SUB_CATEGORY
} from '../graphql/mutations/categories';
import {
  getIcelandicName,
  IS_LOCALE,
  getEnglishName,
  EN_LOCALE
} from '../utils/locale';
import useNotifier from '../hooks/useNotifier';
import ConfirmDialog from './ConfirmDialog';

interface IProps {
  categories: ICategory[];
}

interface ICategoryFormValues {
  name: string;
  nameEn: string;
}

interface ISubCategoryFormValues {
  name: string;
  nameEn: string;
  category: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2)
    },
    typographyTitle: {
      padding: 20,
      paddingBottom: 5,
      paddingTop: 10
    },
    paperSections: {
      marginTop: 20
    },
    typography: {
      display: 'inline'
    }
  })
);

const categorySchema = Yup.object().shape({
  name: Yup.string().required(),
  nameEn: Yup.string().required()
});

const subCategorySchema = Yup.object().shape({
  name: Yup.string().required(),
  nameEn: Yup.string().required(),
  category: Yup.string().required()
});

const EditCategories: React.FC<IProps> = ({ categories }) => {
  const classes = useStyles();
  const notifier = useNotifier();
  const [editCategory, setEditCategory] = useState<ICategory | null>(null);
  const [editSubCategory, setEditSubCategory] = useState<{
    categoryId: string;
    subCategory: ISubCategory;
  } | null>(null);
  const [deleteCategoryId, setDeleteCategoryId] = useState<string>('');
  const [deleteSubCategoryIds, setDeleteSubCategoryIds] = useState<{
    sub: string;
    cat: string;
  } | null>(null);

  const [createCategory] = useMutation(CREATE_CATEGORY, {
    refetchQueries: ['getCategories']
  });
  const [updateCategory] = useMutation(UPDATE_CATEGORY, {
    refetchQueries: ['getCategories']
  });
  const [updateSubCategory] = useMutation(UPDATE_SUB_CATEGORY, {
    refetchQueries: ['getCategories']
  });
  const [removeCategory] = useMutation(REMOVE_CATEGORY, {
    refetchQueries: ['getCategories']
  });
  const [createSubCategories] = useMutation(CREATE_SUB_CATEGORIES, {
    refetchQueries: ['getCategories']
  });
  const [removeSubCategories] = useMutation(REMOVE_SUB_CATEGORIES, {
    refetchQueries: ['getCategories']
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} key={1}>
        {renderCategoryForm()}
        {renderSubCategoryForm()}
      </Grid>
      <Grid item xs={12} sm={6} key={2}>
        <Paper className={classes.paperSections}>
          <Typography variant="h6" className={classes.typographyTitle}>
            Skráðir flokkar
          </Typography>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} key={'Flokkar'}>
              <Paper className={classes.paper} elevation={0}>
                {categories.map(renderCategory)}
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <ConfirmDialog
        open={!!deleteCategoryId}
        message="Ertu viss um að þú viljir eyða þessum flokki?"
        confirmText="Eyða flokki"
        onConfirm={() => {
          deleteCategory(deleteCategoryId);
          setDeleteCategoryId('');
        }}
        onCancel={() => setDeleteCategoryId('')}
      />
      {deleteSubCategoryIds && (
        <ConfirmDialog
          open
          message="Ertu viss um að þú viljir eyða þessum undirflokki?"
          confirmText="Eyða flokki"
          onConfirm={() => {
            deleteSubCategory(
              deleteSubCategoryIds.cat,
              deleteSubCategoryIds.sub
            );
            setDeleteSubCategoryIds(null);
          }}
          onCancel={() => setDeleteSubCategoryIds(null)}
        />
      )}
    </Grid>
  );

  function renderCategoryForm() {
    const editCategoryName = editCategory
      ? getIcelandicName(editCategory.translations)
      : '';
    const editCategoryNameEn = editCategory
      ? getEnglishName(editCategory.translations)
      : '';

    return (
      <Paper className={classes.paperSections}>
        <Typography variant="h6" className={classes.typographyTitle}>
          {editCategory ? `Breyta: ${editCategoryName}` : 'Nýr yfirflokkur'}
        </Typography>
        <Formik
          enableReinitialize
          initialValues={{ name: editCategoryName, nameEn: editCategoryNameEn }}
          validationSchema={categorySchema}
          onSubmit={submitCategory}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            dirty
          }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={6} key={'Yfirflokkur-nafn'}>
                  <Paper className={classes.paper} elevation={0}>
                    <TextField
                      fullWidth
                      id={'name'}
                      label={'Nafn'}
                      variant="outlined"
                      value={values.name}
                      required={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} key={'Yfirflokkur-nafn-en'}>
                  <Paper className={classes.paper} elevation={0}>
                    <TextField
                      fullWidth
                      id={'nameEn'}
                      label={'Nafn en'}
                      variant="outlined"
                      value={values.nameEn}
                      required={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} key={'name-submit'}>
                  <Paper
                    className={classes.paper}
                    style={{ textAlign: 'right' }}
                    elevation={0}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={!isValid || !dirty}
                    >
                      {editCategory ? 'Uppfæra' : 'Bæta við'}
                    </Button>
                    {editCategory && (
                      <Button
                        color="default"
                        variant="contained"
                        onClick={() => setEditCategory(null)}
                        style={{ marginLeft: 16 }}
                      >
                        Hætta við
                      </Button>
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    );
  }

  function renderSubCategoryForm() {
    const editCategoryName = editSubCategory
      ? getIcelandicName(editSubCategory.subCategory.translations)
      : '';
    const editCategoryNameEn = editSubCategory
      ? getEnglishName(editSubCategory.subCategory.translations)
      : '';

    return (
      <Paper className={classes.paperSections}>
        <Typography variant="h6" className={classes.typographyTitle}>
          {editSubCategory ? `Breyta: ${editCategoryName}` : 'Nýr undirflokkur'}
        </Typography>
        <Formik
          enableReinitialize
          initialValues={{
            name: editCategoryName,
            nameEn: editCategoryNameEn,
            category: editSubCategory ? editSubCategory.categoryId : ''
          }}
          validationSchema={subCategorySchema}
          onSubmit={submitSubCategory}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            dirty
          }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={6} key={'name'}>
                  <Paper className={classes.paper} elevation={0}>
                    <TextField
                      fullWidth
                      id={'name'}
                      label={'Nafn'}
                      variant="outlined"
                      value={values.name}
                      required={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} key={'name-en'}>
                  <Paper className={classes.paper} elevation={0}>
                    <TextField
                      fullWidth
                      id={'nameEn'}
                      label={'Nafn en'}
                      variant="outlined"
                      value={values.nameEn}
                      required={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} key={'Yfirflokkar'}>
                  <Paper className={classes.paper} elevation={0}>
                    <FormControl fullWidth variant={'outlined'} required>
                      <InputLabel id={'category'}>Yfirflokkur</InputLabel>
                      <Select
                        id={'category-key'}
                        labelId={'category'}
                        labelWidth={90}
                        value={values.category}
                        onChange={handleChange('category')}
                        onBlur={handleBlur('category')}
                        disabled={!!editSubCategory}
                      >
                        {categories.map(cat => (
                          <MenuItem value={cat.id} key={cat.id}>
                            {getIcelandicName(cat.translations)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} key={'sub-name-submit'}>
                  <Paper
                    className={classes.paper}
                    style={{ textAlign: 'right' }}
                    elevation={0}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={!isValid || !dirty}
                    >
                      {editSubCategory ? 'Uppfæra' : 'Bæta við'}
                    </Button>
                    {editSubCategory && (
                      <Button
                        color="default"
                        variant="contained"
                        onClick={() => setEditSubCategory(null)}
                        style={{ marginLeft: 16 }}
                      >
                        Hætta við
                      </Button>
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    );
  }

  function renderCategory(category: ICategory) {
    return (
      <ExpansionPanel key={category.id}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon color="primary" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={event => event.stopPropagation()}
            onFocus={event => event.stopPropagation()}
            control={
              <React.Fragment>
                <Typography variant="h6">
                  {getIcelandicName(category.translations)}
                </Typography>
                <Button
                  color="secondary"
                  style={{ padding: 0 }}
                  onClick={() => setDeleteCategoryId(category.id)}
                >
                  Eyða
                </Button>
                <Button
                  color="primary"
                  style={{ padding: 0 }}
                  onClick={() => setEditCategory(category)}
                >
                  Breyta
                </Button>
              </React.Fragment>
            }
            label={''}
          />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <FormGroup>
            {category.subCategories.map(sub =>
              renderSubCategory(sub, category.id)
            )}
          </FormGroup>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }

  function renderSubCategory(sub: ISubCategory, categoryId: string) {
    return (
      <FormControlLabel
        key={sub.id}
        label={''}
        control={
          <React.Fragment>
            <Typography>{getIcelandicName(sub.translations)}</Typography>
            <Button
              color="secondary"
              style={{ padding: 0 }}
              onClick={() =>
                setDeleteSubCategoryIds({ sub: sub.id, cat: categoryId })
              }
            >
              Eyða
            </Button>
            <Button
              color="primary"
              style={{ padding: 0 }}
              onClick={() =>
                setEditSubCategory({ categoryId, subCategory: sub })
              }
            >
              Breyta
            </Button>
          </React.Fragment>
        }
      />
    );
  }

  async function submitCategory(
    values: ICategoryFormValues,
    helpers: FormikHelpers<ICategoryFormValues>
  ) {
    if (editCategory) {
      await editCategorySubmit(editCategory.id, values);
    } else {
      await createCategorySubmit(values, helpers);
    }
  }

  async function createCategorySubmit(
    values: ICategoryFormValues,
    { resetForm }: FormikHelpers<ICategoryFormValues>
  ) {
    try {
      const newCategory = {
        translations: [
          { locale: IS_LOCALE, name: values.name },
          { locale: EN_LOCALE, name: values.nameEn }
        ]
      };
      await createCategory({
        variables: { category: newCategory, subCategories: [] }
      });
      notifier.notify('Yfirflokki bætt við', { variant: 'success' });
      resetForm();
    } catch {
      notifier.notify('Villa kom upp', { variant: 'error' });
    }
  }

  async function editCategorySubmit(
    categoryId: string,
    values: ICategoryFormValues
  ) {
    try {
      const updatedCategory = {
        translations: [
          { locale: IS_LOCALE, name: values.name },
          { locale: EN_LOCALE, name: values.nameEn }
        ]
      };
      await updateCategory({
        variables: { categoryId, values: updatedCategory }
      });
      notifier.notify('Yfirflokkur uppfærður', { variant: 'success' });
      setEditCategory(null);
    } catch {
      notifier.notify('Villa kom upp', { variant: 'error' });
    }
  }

  async function editSubCategorySubmit(
    categoryId: string,
    subCategoryId: string,
    values: ISubCategoryFormValues
  ) {
    try {
      const updatedCategory = {
        translations: [
          { locale: IS_LOCALE, name: values.name },
          { locale: EN_LOCALE, name: values.nameEn }
        ]
      };
      await updateSubCategory({
        variables: { categoryId, subCategoryId, values: updatedCategory }
      });
      notifier.notify('Undirflokkur uppfærður', { variant: 'success' });
      setEditSubCategory(null);
    } catch {
      notifier.notify('Villa kom upp', { variant: 'error' });
    }
  }

  async function submitSubCategory(
    values: ISubCategoryFormValues,
    { resetForm }: FormikHelpers<ISubCategoryFormValues>
  ) {
    try {
      const newSubCategory = {
        translations: [
          { locale: IS_LOCALE, name: values.name },
          { locale: EN_LOCALE, name: values.nameEn }
        ]
      };
      if (editSubCategory) {
        await editSubCategorySubmit(
          values.category,
          editSubCategory.subCategory.id,
          values
        );
        notifier.notify(`Undirflokkur uppfærður`, { variant: 'success' });
      } else {
        await createSubCategories({
          variables: {
            categoryId: values.category,
            subCategories: [newSubCategory]
          }
        });
        notifier.notify(`Undirflokki bætt við`, { variant: 'success' });
      }
      resetForm();
    } catch {
      notifier.notify('Villa kom upp', { variant: 'error' });
    }
  }

  async function deleteCategory(categoryId: string) {
    try {
      await removeCategory({ variables: { categoryId } });
      notifier.notify('Yfirflokki eytt', { variant: 'success' });
    } catch {
      notifier.notify('Villa kom upp', { variant: 'error' });
    }
  }

  async function deleteSubCategory(categoryId: string, subCategoryId: string) {
    try {
      await removeSubCategories({
        variables: { categoryId, subcategoryIds: [subCategoryId] }
      });
      notifier.notify('Undirflokki eytt', { variant: 'success' });
    } catch {
      notifier.notify('Villa kom upp', { variant: 'error' });
    }
  }
};

export default EditCategories;

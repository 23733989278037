import React, { useState, useCallback } from 'react';
import { useQuery } from 'react-apollo';

import { SERVICE_PROVIDERS } from '../graphql/queries/ServiceProviderQueries';
import Autocomplete, { IOption } from '../components/Autocomplete';
import debounce from 'lodash/debounce';
import { IServiceProvidersLite } from '../interfaces/ServiceProvider/ServiceProvider';

interface IProps {
  id?: string;
  value?: IOption;
  values?: IOption[];
  label: string;
  onChange: (value: unknown) => void;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
  fullWidth?: boolean;
  className?: string;
  multiSelect?: boolean;
}

const SelectProvider: React.FC<IProps> = ({
  id,
  value,
  values,
  label,
  onChange,
  error,
  disabled,
  required,
  fullWidth,
  className
}) => {
  const [searchText, setSearchText] = useState('');
  const { data } = useQuery<IServiceProvidersLite>(SERVICE_PROVIDERS, {
    variables: {
      skip: 0,
      take: 25,
      active: true,
      sort: { legalName: 'ASC' },
      filter: { text: searchText }
    }
  });
  const providers = data ? data.ServiceProviders.ServiceProviders : [];

  const searchDebounced = useCallback(
    debounce((text: string) => setSearchText(text), 300),
    []
  );

  return (
    <Autocomplete
      id={id}
      value={value}
      values={values}
      label={label}
      items={providers.map(p => ({
        value: p.serviceProviderId,
        label: p.legalName
      }))}
      onChange={onChange}
      error={error}
      disabled={disabled}
      required={required}
      fullWidth={fullWidth}
      className={className}
      inputValue={searchText}
      onInputChange={searchDebounced}
    />
  );
};

export default SelectProvider;

import { gql } from 'apollo-boost';

const categoryFragment = gql`
  fragment category on ICategory {
    id
    translations {
      locale
      name
    }
    subCategories {
      id
      translations {
        locale
        name
      }
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation createCategory(
    $category: CategoryInput!
    $subCategories: [SubCategoryInput!]!
  ) {
    createCategory(category: $category, subCategories: $subCategories) {
      ...category
    }
  }
  ${categoryFragment}
`;

export const UPDATE_CATEGORY = gql`
  mutation updateCategory($categoryId: String!, $values: CategoryUpdate!) {
    updateCategory(categoryId: $categoryId, values: $values) {
      ...category
    }
  }
  ${categoryFragment}
`;

export const UPDATE_SUB_CATEGORY = gql`
  mutation updateSubCategory(
    $categoryId: String!
    $subCategoryId: String!
    $values: CategoryUpdate!
  ) {
    updateSubCategory(
      categoryId: $categoryId
      subCategoryId: $subCategoryId
      values: $values
    ) {
      ...category
    }
  }
  ${categoryFragment}
`;

export const REMOVE_CATEGORY = gql`
  mutation removeCategory($categoryId: String!) {
    removeCategory(categoryId: $categoryId) {
      success
    }
  }
`;

export const CREATE_SUB_CATEGORIES = gql`
  mutation createSubCategories(
    $categoryId: String!
    $subCategories: [SubCategoryInput!]!
  ) {
    createSubCategories(
      categoryId: $categoryId
      subCategories: $subCategories
    ) {
      ...category
    }
  }
  ${categoryFragment}
`;

export const REMOVE_SUB_CATEGORIES = gql`
  mutation removeSubCategories(
    $categoryId: String!
    $subcategoryIds: [String!]!
  ) {
    removeSubCategories(
      categoryId: $categoryId
      subcategoryIds: $subcategoryIds
    ) {
      ...category
    }
  }
  ${categoryFragment}
`;

export const LOGIN_ROUTE = '/login';
export const CALLBACK_ROUTE = '/callback';
export const SSN_ROUTE = '/ssn';
export const CUSTOM_CLAIM_NAMESPACE = 'https://itb-database.com/';
export const DEFAULT_PALETTE_PRIMARY = '#213b76';
export const DEFAULT_PALETTE_SECONDARY = '#d14a41';
export const NAVBAR_HEIGHT = 64;
export const DATE_FORMAT = 'DD.MM.YYYY';
export const TIME_FORMAT = 'HH:mm';
export const DATE_AND_TIME_FORMAT = 'DD.MM.YYYY - HH:mm';
export const TABLE_ROWS_OPTIONS = [10, 25, 50, 100, 200];
export const TABLE_ROWS_DEFAULT = 25;

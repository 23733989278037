import { getType } from 'typesafe-actions';

import createReducer from '../createReducer';
import * as A from './actions';
import { IAuthState } from './types';

const initialState: IAuthState = {
  isReady: false,
  isLoggedIn: false,
  idToken: null,
  accessToken: null,
  expiresAt: 0,
  user: null
};

const authReducer = createReducer(initialState, {
  [getType(A.authReady)]: state => ({
    ...state,
    isReady: true
  }),
  [getType(A.loggedIn)]: (state, { payload }: A.LoggedInAction) => ({
    ...state,
    isLoggedIn: true,
    idToken: payload.idToken || null,
    accessToken: payload.accessToken || null,
    expiresAt: (payload.expiresIn || 0) * 1000 + new Date().getTime(),
    user: payload.idTokenPayload
  }),
  [getType(A.loggedOut)]: state => ({
    ...state,
    isLoggedIn: false,
    idToken: null,
    accessToken: null,
    expiresAt: 0,
    user: null
  })
});

export default authReducer;

/* global google */
import { createContext } from 'react';

export interface IMapsContext {
  map: google.maps.Map | null;
  maps: typeof google.maps | null;
  isLoaded: boolean;
}

const MapsContext = createContext<IMapsContext>({
  map: null,
  maps: null,
  isLoaded: false
});

MapsContext.displayName = 'MapsContext';

export default MapsContext;

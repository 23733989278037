import { gql } from 'apollo-boost';

export const GET_CHARGINGSTATIONS = gql`
query getChargingStations(
  $skip: Int
  $take: Int
  $filter: ChargingStationFilter
)
{
    ChargingStations(
      skip: $skip
      take: $take
      filter: $filter
    ) {
      count
      ChargingStations {
        chargingStationId, 
        connectors, 
        location, 
        name,
        geo,
        provider,
        type,
        kw
      }
      Categories {
        _id, 
        category
      }
      Providers {
        _id,
        name
      }
    }
}
`;

export const STATION_DETAILS = gql`
  query getChargingStationDetails($chargingStationId: String!) {
    ChargingStation(chargingStationId: $chargingStationId) {
      chargingStationId, 
      connectors, 
      location, 
      name,
      geo,
      provider,
      type,
      kw
    }
  }
`;

export const GET_PROVIDERS = gql`
{
  ChargingStationProviders {
    Providers {
      _id, 
      name
    }
  }
}
`;


export const GET_CATEGORIES = gql`
{
  ChargingStationCategories {
    Categories {
      _id, 
      category
    }
  }
}
`;
import React, { useEffect, useState } from 'react';
import securePage from '../hocs/securePage';
import Layout from '../components/Layout';
import Section from '../components/Section';
import ChargingStationForm from '../components/ChargingStationForm';
import { GET_PROVIDERS, GET_CATEGORIES } from '../graphql/queries/ChargingStations';
import {
  IChargingStationCategory,
  IChargingStationProvider,
  IChargingStationProviders,
  IChargingStationCategories
} from '../interfaces/ChargingStation/ChargingStation';
import { useQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';

const CreateChargingStation: React.FC = () => {
  const { data: pData } = useQuery<IChargingStationProviders>(GET_PROVIDERS);
  const { data: cData } = useQuery<IChargingStationCategories>(GET_CATEGORIES);
  const [categories, setCategories] = useState<IChargingStationCategory[]>();
  const [providers, setProviders] = useState<IChargingStationProvider[]>();
  const history = useHistory();

  useEffect(() => {
    if (pData) {
      setProviders(pData["ChargingStationProviders"].Providers);
    }
  }, [pData]);

  useEffect(() => {
    if (cData) {
      setCategories(cData["ChargingStationCategories"].Categories);
    }
  }, [cData]);
  return (
    <Layout useDefaultSpacing>
      <Section title="Ný hleðslustöð">
        <ChargingStationForm
          newChargingStation={true}
          categories={categories}
          providers={providers}
          created={(url) => history.location = url}
        />
      </Section>
    </Layout>
  );
};

export default securePage(CreateChargingStation);

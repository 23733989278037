import React from 'react';

import securePage from '../hocs/securePage';
import Layout from '../components/Layout';
import Section from '../components/Section';
import MunicipalityForm from '../components/MunicipalityForm';

const CreateMunicipality: React.FC = () => {
  return (
    <Layout useDefaultSpacing>
      <Section title="Nýtt bæjarfélag">
        <MunicipalityForm newMunicipality={true} />
      </Section>
    </Layout>
  );
};

export default securePage(CreateMunicipality);

export const getLastSearch = (type) => {
  var str = localStorage.getItem("lastSearch-" + type);
  if (str === null) {
    return null;
  }
  try {
    var obj = JSON.parse(str);
  }
  catch {
    console.log("Error parsing lastSearch-" + type + ", clearing local storage");
    console.log("lastSearch-" + type + " string", str);
    clearLastSearch(type);
    return null;
  }
  return obj;
};

export const setLastSearch = (type, search) => {
  localStorage.setItem("lastSearch-" + type, JSON.stringify(search));
};

export const clearLastSearch = (type) => {
  localStorage.removeItem("lastSearch-" + type);
};
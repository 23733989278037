import { useDispatch } from 'react-redux';
import { OptionsObject } from 'notistack';
import { enqueueNotification } from './../store/notifier/actions';
import { renderDismissButton } from '../components/Notifier';

function useNotifier(dismissable = false, options?: OptionsObject) {
  const dispatch = useDispatch();

  return {
    notify: enqueue
  };

  function enqueue(message: React.ReactText, msgOptions?: OptionsObject) {
    const key = options && options.key;

    dispatch(
      enqueueNotification(message, {
        action: dismissable ? k => renderDismissButton('Dismiss', k) : null,
        key: key || new Date().getTime() + Math.random(),
        autoHideDuration: 2000,
        ...options,
        ...msgOptions
      })
    );
  }
}

export default useNotifier;

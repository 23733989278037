import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import {
  makeStyles,
  Grid,
  Typography,
  Paper,
  Button,
  TextField
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {
  IChargingStation, IChargingStationCategory, IChargingStationProvider
} from '../interfaces/ChargingStation/ChargingStation';
import {
  UPDATE_CHARGING_STATION,
  CREATE_CHARGING_STATION,
  REMOVE_CHARGING_STATION
} from '../graphql/mutations/ChargingStationMutations';
import Autocomplete, { IOption } from './Autocomplete';
import LocationInput from './LocationInput';
import useNotifier from '../hooks/useNotifier';
import { IS_LOCALE, EN_LOCALE } from '../utils/locale';
import { Formik } from 'formik';
import ConfirmDialog from './ConfirmDialog';
import useAdminChecker from '../hooks/useAdminChecker';
import SelectProvider from './SelectProvider';

interface IProps {
  chargingStation?: IChargingStation;
  newChargingStation: boolean;
  categories: IChargingStationCategory[];
  providers: IChargingStationProvider[];
  created?: (string) => void;
}

interface IFormField {
  key: string;
  translation?: string;
  locale?: typeof IS_LOCALE | typeof EN_LOCALE;
  label: string;
  type?: 'text' | 'select' | 'multiSelect' | 'number';
  required?: boolean;
  disabled?: boolean;
  options?: Array<{ value: string; label: string }>;
  section: number;
}

interface IFormValues {
  provider: IOption;
  category: IOption;
  latitude: string;
  longitude: string;
  kw: number;
  name: string;
  location: string;
  connectors: number;
  serviceProviderId: IOption;
}

const useStyles = makeStyles(theme => ({
  tabContent: {
    marginTop: theme.spacing(1)
  },
  section: {
    minHeight: 200,
    marginBottom: theme.spacing(3),
    padding: 20,
    paddingTop: 10,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0
    }
  },
  sectionTitle: {
    marginBottom: theme.spacing(3)
  },
  statusTitle: {
    marginBottom: theme.spacing(1)
  },
  buttons: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3)
    }
  },
  button: {
    padding: 10
  },
  active: {
    marginBottom: theme.spacing(2)
  },
  removeTopMargin: {
    marginTop: 0
  },
  seeMoreGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 10
  },
  typographyDisabled: {
    color: 'rgba(0, 0, 0, 0.5)'
  }
}));

const ChargingStationForm: React.FC<IProps> = ({
  chargingStation,
  newChargingStation,
  categories,
  providers,
  created
}) => {
  const classes = useStyles();
  const notifier = useNotifier();
  const providerOptions = providers ? providers.map(p => {
    return { value: p._id, label: p.name };
  }) : [];
  const categoryOptions = categories ? categories.map(c => {
    return { value: c._id, label: c.category };
  }) : [];

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [updateChargingStation] = useMutation(UPDATE_CHARGING_STATION)
  const [createChargingStation] = useMutation(CREATE_CHARGING_STATION);
  const [removeChargingStation] = useMutation(REMOVE_CHARGING_STATION);
  const isAdmin = useAdminChecker();
  const history = useHistory();



  async function onSubmit(values: IFormValues) {
    if (chargingStation) {
      //update
      try {
        values.connectors = parseInt(values.connectors.toString());
        await updateChargingStation({
          variables: {
            chargingStationId: chargingStation.chargingStationId,
            values: {
              connectors: values.connectors,
              kw: values.kw,
              geo: [parseFloat(values.longitude), parseFloat(values.latitude)],
              location: values.location,
              name: values.name,
              provider: values.provider ? values.provider.value : null,
              type: values.category ? values.category.value : null
            }
          }
        });
        notifier.notify('Hleðslustöð uppfærð', { variant: 'success' });
      } catch (e) {
        console.log(e);
        notifier.notify('Mistókst að uppfæra hleðslustöð', { variant: 'error' });
      }
    }
    else {
      //Create
      try {
        values.connectors = parseInt(values.connectors.toString());
        const createResult = await createChargingStation({
          variables: {
            values: {
              connectors: values.connectors ? values.connectors : 0,
              kw: values.kw ? values.kw : 0,
              geo: values.latitude && values.longitude ? [parseFloat(values.longitude), parseFloat(values.latitude)] : null,
              location: values.location ? values.location : "",
              name: values.name ? values.name : "",
              provider: values.provider ? values.provider.value : null,
              type: values.category ? values.category.value : null
            }
          }
        });
        notifier.notify('Hleðslustöð búin til', { variant: 'success' });
        if (created) {
          created('chargingstations');
        }
      }
      catch (e) {
        console.log(e);
        notifier.notify('Mistókst að búa til hleðslustöð', { variant: 'error' });
      }
    }
  }

  function getInfoFields(): IFormField[] {
    return [
      {
        key: 'name',
        translation: 'name',
        locale: IS_LOCALE,
        label: 'Lýsing',
        required: newChargingStation,
        section: 1
      },
      {
        key: 'location',
        translation: 'location',
        locale: IS_LOCALE,
        label: 'Staðsetning',
        required: newChargingStation,
        section: 1
      },
      {
        key: 'kw',
        translation: 'kw',
        locale: IS_LOCALE,
        label: 'Stærð stöðvar (KW)',
        required: newChargingStation,
        type: 'number',
        section: 1
      },
      {
        key: 'provider',
        label: 'Þjónustuaðili',
        type: 'select',
        required: true,
        options: providerOptions,
        section: 2
      },
      {
        key: 'category',
        label: 'Tengill',
        type: 'select',
        required: true,
        options: categoryOptions,
        section: 1
      },
      {
        key: 'latitude',
        label: 'Breiddargráða',
        section: 1
      },
      {
        key: 'longitude',
        label: 'Lengdargráða',
        section: 1
      },
      {
        key: 'connectors',
        label: 'Fjöldi tengla',
        required: newChargingStation,
        section: 1
      }
    ];
  }

  function renderInfoField(
    field: IFormField,
    values: any,
    handleChange: any,
    handleBlur: any,
    setFieldValue: any
  ) {
    const {
      key,
      translation,
      locale,
      label,
      type,
      options,
      disabled,
      required
    } = field;

    if (key === 'serviceProviderId') {
      return (
        <SelectProvider
          id={`checkboxes-${key}`}
          value={values[key]}
          error={false}
          label="Tengdur rekstraraðili"
          onChange={val => {
            setFieldValue(key, val);
          }}
          fullWidth
        />
      );
    }
    if (type === 'select' || type === 'multiSelect') {
      return (
        <Autocomplete
          id={key}
          value={type === 'select' ? values[key] : undefined}
          values={type === 'multiSelect' ? values[key] : undefined}
          //error={!!errors[key]}
          label={label}
          items={options}
          onChange={val => {
            setFieldValue(key, val);
          }}
          disabled={disabled}
          required={required}
          fullWidth
        />
      );
    }

    if (key === 'latitude' || key === 'longitude') {
      return (
        <LocationInput
          location={{
            latitude: values.latitude,
            longitude: values.longitude
          }}
          type={key}
          label={label}
          // error={!!errors[key]}
          required={required}
          disabled={disabled}
          //initialSearch={municipalityName}
          onChange={location => {
            setFieldValue('latitude', location.latitude);
            setFieldValue('longitude', location.longitude);
          }}
        />
      );
    }

    return (
      <TextField
        id={key}
        type={type}
        label={label}
        value={values[key]}
        //error={!!errors[key]}
        onChange={handleChange}
        onBlur={handleBlur}
        variant="outlined"
        disabled={disabled}
        required={required}
        fullWidth
      />
    );
  }

  async function deleteStation() {
    try {
      await removeChargingStation({
        variables: {
          chargingStationId: chargingStation.chargingStationId
        }
      });
      notifier.notify('Hleðslustöð eytt', { variant: 'success' });
      history.push('/chargingstations');
    }
    catch {
      notifier.notify("Villa kom upp", { variant: 'error' });
    }

  }

  function renderButtons(formik: any, create: boolean) {
    return (
      <Grid container spacing={2} className={classes.buttons}>
        <Grid item xs={12} md={create ? 6 : undefined}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            disabled={!formik.dirty || formik.isSubmitting || (!formik.isValid && !create)}
            className={classes.button}
          >
            Vista
          </Button>
        </Grid>
        {isAdmin && !create && (
          <Grid item xs={12} md={create ? 6 : undefined}>
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              style={{ padding: 10 }}
              onClick={() => setDeleteDialogOpen(true)}
            >
              Eyða stöð
            </Button>
            <ConfirmDialog
              open={deleteDialogOpen}
              message="Ertu viss um að þú viljir eyða þessari hleðslustöð?"
              confirmText="Eyða"
              onConfirm={() => {
                deleteStation();
                setDeleteDialogOpen(false);
              }}
              onCancel={() => setDeleteDialogOpen(false)}
            />
          </Grid>
        )}
        <Grid item xs={12} md={create ? 6 : undefined}>
          <Button
            color="default"
            variant="contained"
            fullWidth
            disabled={formik.isSubmitting}
            className={classes.button}
            onClick={() => history.push('/places')}
          >
            Hætta við
          </Button>
        </Grid>
      </Grid>
    );
  }

  function getProviderValue(provider: IChargingStationProvider) {
    if (provider) {
      return {
        value: provider._id,
        label: provider.name
      } as IOption;
    }
    return null;
  }

  function getCategoryValue(category: IChargingStationCategory) {
    if (category) {
      return {
        value: category._id,
        label: category.category
      } as IOption;
    }
    return null;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        kw: chargingStation && chargingStation.kw
          ? chargingStation.kw
          : undefined,
        provider: chargingStation && chargingStation.provider && providers
          ? getProviderValue(providers.find(p => p._id == chargingStation.provider))
          : undefined,
        category: chargingStation && chargingStation.type && categories
          ? getCategoryValue(categories.find(c => c._id == chargingStation.type))
          : undefined,
        location: chargingStation && chargingStation.location
          ? chargingStation.location
          : '',
        name: chargingStation && chargingStation.name
          ? chargingStation.name
          : '',
        connectors: chargingStation && chargingStation.connectors
          ? chargingStation.connectors
          : 0,
        latitude:
          chargingStation && chargingStation.geo
            ? chargingStation.geo[1].toString()
            : '',
        longitude:
          chargingStation && chargingStation.geo
            ? chargingStation.geo[0].toString()
            : '',
        serviceProviderId:
          chargingStation && chargingStation.serviceProviderId
            ? {
              value: chargingStation.serviceProviderId,
              label: 'sp test'
            }
            : undefined
      }}
      onSubmit={onSubmit}>
      {formik => {
        {
          const {
            values,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
            dirty
          } = formik;

          return (
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2} direction="row">
                <Grid item xs={8}>
                  <Paper className={classes.section}>
                    <Typography variant="h6" className={classes.sectionTitle}>
                      Grunnupplýsingar
                    </Typography>
                    <Grid container spacing={4}>
                      {getInfoFields().filter(f => f.section === 1).map(field => (
                        <Grid item xs={12} sm={6} md={6} key={field.key + field.locale}>
                          {
                            renderInfoField(field, values, handleChange, handleBlur, setFieldValue)
                          }
                        </Grid>
                      ))}

                    </Grid>

                  </Paper>
                  <Paper className={classes.section}>
                    <Typography variant="h6" className={classes.sectionTitle}>
                      Rekstraraðili
                    </Typography>
                    <Grid container spacing={4}>
                      {getInfoFields().filter(f => f.section === 2).map(field => (
                        <Grid item xs={12} sm={6} md={6} key={field.key + field.locale}>
                          {
                            renderInfoField(field, values, handleChange, handleBlur, setFieldValue)
                          }
                        </Grid>
                      ))}
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={4}>
                  {renderButtons(formik, newChargingStation)}
                </Grid>
              </Grid>
            </form>
          )
        }
      }}
    </Formik>
  );
}

export default ChargingStationForm;
import React, { useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import securePage from '../hocs/securePage';
import useNotifier from '../hooks/useNotifier';
import EditServiceProvider from '../components/EditServiceProvider';
import Layout from '../components/Layout';
import { useQuery, useMutation, useApolloClient } from 'react-apollo';
import {
  IServiceProvider,
  IProvider,
  ISign
} from '../interfaces/ServiceProvider/ServiceProvider';
import { SERVICE_PROVIDER } from '../graphql/queries/ServiceProviderQueries';
import { Grid, CircularProgress, Typography } from '@material-ui/core';
import {
  ACCEPT_SERVICE_PROVIDER,
  REJECT_SERVICE_PROVIDER
} from '../graphql/mutations/serviceProvider';
import TextFieldDialog from '../components/TextFieldDialog';

const NewServiceProvider: React.FC<RouteComponentProps<{ id: string }>> = ({
  match
}) => {
  const serviceProviderId = match.params.id;
  const [AcceptServiceProvider] = useMutation(ACCEPT_SERVICE_PROVIDER);
  const [RejectServiceProvider] = useMutation(REJECT_SERVICE_PROVIDER);

  const apolloClient = useApolloClient();
  const [showDialog, setShowDialog] = useState(false);
  const notifier = useNotifier();
  const history = useHistory();

  const confirmServiceProvider = async () => {
    await AcceptServiceProvider({ variables: { serviceProviderId } })
      .then(res => {
        notifier.notify('Ferðaþjónusta var samþykkt', { variant: 'success' });
        // apolloClient.resetStore();
        const acceptedProvider: IProvider = res.data.acceptServiceProvider;
        history.push(
          `/service-providers/${acceptedProvider.serviceProviderId}`
        );
      })
      .catch(e => {
        notifier.notify('Villa kom upp', { variant: 'error' });
      });
  };

  const rejectServiceProvider = async () => {
    setShowDialog(true);
  };

  const rejectServiceProviderConfirm = async (message: string) => {
    await RejectServiceProvider({
      variables: {
        serviceProviderId,
        title: `Ferðaþjónustu þinni ${data.ServiceProvider.legalName} var hafnað`,
        message
      }
    })
      .then(data => {
        notifier.notify('Ferðaþjónustu var hafnað', { variant: 'success' });
        apolloClient.resetStore();
        history.push(`/pending`);
      })
      .catch(e => {
        notifier.notify('Villa kom upp', { variant: 'error' });
      });
  };

  const handleFormSubmit = (values: any, signs: ISign[] | []) => {
    // console.log(values);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  const { loading, error, data } = useQuery<IServiceProvider>(
    SERVICE_PROVIDER,
    {
      variables: { serviceProviderId }
    }
  );

  if (loading) {
    return (
      <Grid container direction="column" justify="center" alignContent="center">
        <CircularProgress size={100} />
      </Grid>
    );
  }
  if (error) {
    return <Typography variant="h2">{error.message}</Typography>;
  }
  if (data) {
    return (
      <Layout useDefaultSpacing>
        <EditServiceProvider
          serviceProviderId={serviceProviderId}
          serviceProvider={data.ServiceProvider}
          handleFormSubmit={handleFormSubmit}
          latest={false}
          hasLatestVersion={false}
          handleVersionChange={() => null}
          editServiceProvider={false}
          confirmServiceProvider={confirmServiceProvider}
          rejectServiceProvider={rejectServiceProvider}
        ></EditServiceProvider>
        {showDialog && (
          <TextFieldDialog
            closeDialog={closeDialog}
            dialogTitle={
              'Ertu viss um að þú viljir hafna þessari ferðaþjónustu?'
            }
            onSuccess={rejectServiceProviderConfirm}
          ></TextFieldDialog>
        )}
      </Layout>
    );
  }
};

export default securePage(NewServiceProvider);

import { gql } from 'apollo-boost';
import { placeFragment } from '../fragments/PlaceFragments';

export const CREATE_PLACE = gql`
  mutation createPlace($values: PlaceInput!) {
    createPlace(values: $values) {
      ...place
    }
  }
  ${placeFragment}
`;

export const UPDATE_PLACE = gql`
  mutation updatePlace($placeId: String!, $values: PlaceUpdate!) {
    updatePlace(placeId: $placeId, values: $values) {
      ...place
    }
  }
  ${placeFragment}
`;

export const REMOVE_PLACE = gql`
  mutation removePlace($placeId: String!) {
    removePlace(placeId: $placeId) {
      success
    }
  }
`;

export const ADD_PLACE_PHOTO = gql`
  mutation addPlacePhoto(
    $placeId: String!
    $image: Upload!
    $title: String
    $alt: String
  ) {
    addPlaceImage(placeId: $placeId, image: $image, title: $title, alt: $alt) {
      ...place
      photos {
        photoUri
        photoId
        alt
        title
      }
    }
  }
  ${placeFragment}
`;

export const REMOVE_PLACE_PHOTO = gql`
  mutation removePlacePhoto($placeId: String!, $imageId: String!) {
    removePlacePhoto(placeId: $placeId, imageId: $imageId) {
      ...place
      photos {
        photoUri
        photoId
        alt
        title
      }
    }
  }
  ${placeFragment}
`;

export const EDIT_PLACE_PHOTO = gql`
  mutation editPlacePhoto(
    $placeId: String!
    $imageId: String!
    $title: String
    $alt: String
  ) {
    editPlacePhoto(
      placeId: $placeId
      imageId: $imageId
      title: $title
      alt: $alt
    ) {
      photoUri
      photoId
      alt
      title
    }
  }
`;

import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import {
  IServiceProviders,
  IFilter
} from '../interfaces/ServiceProvider/ServiceProvider';
import {
  SERVICE_PROVIDERS,
} from '../graphql/queries/ServiceProviderQueries';
import securePage from '../hocs/securePage';
import Layout from '../components/Layout';
import { RouteComponentProps } from 'react-router-dom';
import ServiceProvidersTable from '../components/ServiceProvidersTable';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  VALIDATE_SERVICE_PROVIDER
} from '../graphql/mutations/serviceProvider';
import { useMutation } from '@apollo/react-hooks';

import ConfirmDialog from '../components/ConfirmDialog';
import useNotifier from '../hooks/useNotifier';

interface IProps { }

const ServiceProvidersForEmail: React.FC<RouteComponentProps<{ filterStr: string; }>> = (filterStr) => {
  const notifier = useNotifier();
  const emptySort = {
    name: null,
    legalName: null,
    address: null,
    zipCode: null,
    email: null,
    SSN: null,
    updatedAt: null
  };

  const getFilter = () => {
    const filter: IFilter = {
      text: filterStr.match.params.filterStr,
      categoryIds: null,
      regionIds: null,
      zipCodes: null,
      purchasedRegionIds: null,
      exactCategories: null,
      fenceId: null,
      cleanAndSafe: null,
      lastValidatedYear: null
    };
    return filter;
  }

  const [validateServiceProvider] = useMutation(VALIDATE_SERVICE_PROVIDER, {
    refetchQueries: ['getServiceProvider']
  });

  const useStyles = makeStyles(theme => ({
    button: {
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.body1.fontSize
    },
    grid: {
      textAlign: 'right',
      marginTop: theme.spacing(4)
    }
  }));

  const classes = useStyles({});

  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(100);
  const [active, setActive] = useState<boolean | undefined>(undefined);
  const [approved, setApproved] = useState(true);
  const [sort, setSorting] = useState({ ...emptySort, legalName: 'ASC' });
  const history = useHistory();
  const [confirmUpdateDialogOpen, setConfirmUpdateDialogOpen] = useState(false);

  const onClick = (serviceProviderId: string) => {
    history.push(`/service-providers/${serviceProviderId}`);
  };

  const { loading, error, data, refetch } = useQuery<IServiceProviders>(
    SERVICE_PROVIDERS,
    {
      variables: {
        skip,
        take,
        filter: getFilter(),
        sort,
        active,
        approved
      }
    }
  );

  const validateProviders = () => {
    let promises = [];
    data.ServiceProviders.ServiceProviders.forEach(provider => {
      promises.push(validateServiceProvider({ variables: { serviceProviderId: provider.serviceProviderId } }));
    });
    Promise.all(promises)
      .then(() => notifier.notify('Þjónustur staðfestar', { variant: 'success' }))
      .catch(e => notifier.notify("Villa kom upp", { variant: 'error' }))
      .finally(() => {
        refetch();
      });
  }

  return (
    <Layout useDefaultSpacing>
      {!!data && !!data.ServiceProviders &&
        <ServiceProvidersTable
          serviceProviders={data.ServiceProviders.ServiceProviders}
          showrecentlyValidated={true}
          onClick={onClick}
        />

      }

      <Grid container className={classes.grid}>
        <Grid item xs={12} sm={12}>
          <Button
            color="primary"
            variant={'contained'}
            className={classes.button}
            onClick={() => setConfirmUpdateDialogOpen(true)}
          >
            Staðfesta allar þjónustur
          </Button>
          <ConfirmDialog
            open={confirmUpdateDialogOpen}
            message="Hefur þú yfirfarið og staðfest að allar upplýsingar þessara þjónusta eru réttar?"
            confirmText="Staðfesta"
            onConfirm={() => {
              validateProviders();
              setConfirmUpdateDialogOpen(false);
            }}
            onCancel={() => setConfirmUpdateDialogOpen(false)}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default securePage(ServiceProvidersForEmail);

import { gql } from 'apollo-boost';

export const UPDATE_CHARGING_STATION = gql`
  mutation updateChargingStation($chargingStationId: String!, $values: ChargingStationUpdate!) {
    updateChargingStation(chargingStationId: $chargingStationId, values: $values) {
      connectors, 
      kw, 
      geo,
      location, 
      name, 
      provider, 
      type
    }
  }
`;

export const CREATE_CHARGING_STATION = gql`
  mutation createChargingStation($values: ChargingStationUpdate!) {
    createChargingStation(values: $values) {
      chargingStationId,
      connectors, 
      kw, 
      geo,
      location, 
      name, 
      provider, 
      type
    }
  }
`;

export const REMOVE_CHARGING_STATION = gql`
  mutation removeChargingStation($chargingStationId: String!) {
    removeChargingStation(chargingStationId: $chargingStationId) {
      success
    }
  }
`;
import * as Yup from 'yup';

export const LatLng = Yup.object().shape({
  lat: Yup.number(),
  lng: Yup.number()
});

export default Yup.object().shape({
  id: Yup.string().nullable(),
  path: Yup.array().of(LatLng).required('Landmörk er óvalkvæm'),
  regions: Yup.array().of(
    Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.string().required()
    })
  ),
  translations: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(),
      locale: Yup.string().required()
    })
  )
});

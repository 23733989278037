import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import securePage from '../hocs/securePage';
import Section from '../components/Section';
import {
  Paper,
  TableRow,
  TableCell,
  TablePagination,
} from '@material-ui/core';

import { useQuery } from 'react-apollo';
import { GET_CHARGINGSTATIONS } from '../graphql/queries/ChargingStations';
import {
  IChargingStation,
  IChargingStationCategory,
  IChargingStationProvider,
  IChargingStations
} from '../interfaces/ChargingStation/ChargingStation';
import { TABLE_ROWS_DEFAULT } from '../constants';
import { TABLE_ROWS_OPTIONS } from '../constants';
import TablePaginationActions from '../components/TablePaginationActions';
import { useHistory } from 'react-router-dom';
import ChargingStationTable from '../components/ChargingStationsTable';

interface ITableCell {
  id: string;
  label: string;
  align?: 'left' | 'right' | 'center';
  sortable?: boolean;
  render: (chargingStation: IChargingStation) => React.ReactNode;
  cellClass?: string;
}

export type ISort = { [field: string]: 'ASC' | 'DESC' };

export interface IFilters {
  text: string;
  categoryIds?: string[];
  providerIds?: string[];
}



const ChargingStations: React.FC = () => {

  const [sort, setSort] = useState<ISort>({ name: 'ASC' });
  const [stations, setStations] = useState<IChargingStation[]>([]);
  const [count, setCount] = useState(0);
  const [categories, setCategories] = useState<IChargingStationCategory[]>([]);
  const [providers, setProviders] = useState<IChargingStationProvider[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROWS_DEFAULT);
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState<IFilters>({
    text: ''
  });
  const {
    text,
    categoryIds,
    providerIds
  } = filters;
  const history = useHistory();
  const { loading, error, data } = useQuery<IChargingStations>(GET_CHARGINGSTATIONS, {
    variables: {
      skip: currentPage * rowsPerPage,
      take: rowsPerPage,
      filter: {
        text,
        categoryIds,
        providerIds
      }
    }
  });
  useEffect(() => {
    if (data) {
      setStations(data.ChargingStations.ChargingStations);
      setCount(data.ChargingStations.count);
      setCategories(data.ChargingStations.Categories);
      setProviders(data.ChargingStations.Providers);
    }
  }, [data]);


  const getTableCells = function (): ITableCell[] {
    return [
      {
        id: 'location',
        label: 'Staður',
        sortable: true,
        render: ({ location }) => location
      },
      {
        id: 'name',
        label: 'Nafn',
        sortable: true,
        render: ({ name }) => name
      },
      {
        id: 'category',
        label: 'Tegund',
        sortable: true,
        render: ({ type }) => {
          var category = categories.find(c => c._id == type);
          if (category) {
            return category.category;
          }
          return '';
        }
      },
      {
        id: 'provider',
        label: 'Þjónustuaðili',
        sortable: true,
        render: ({ provider }) => {
          var p = providers.find(p => p._id == provider);
          if (p) {
            return p.name;
          }
          return '';
        }
      }
    ];
  }

  const renderTableRow = function (station: IChargingStation) {
    return (
      <TableRow
        key={station.chargingStationId}
        hover
        style={{ cursor: 'pointer' }}
        onClick={() => history.push(`/chargingstations/${station.chargingStationId}`)}
      >
        {getTableCells().map(cell => (
          <TableCell
            key={cell.id}
            align={cell.align}
            className={cell.cellClass}
          >
            {cell.render(station)}
          </TableCell>
        ))}
      </TableRow>
    );
  }
  return (

    <Layout useDefaultSpacing>
      <Section
        title="Hleðslustöðvar"
        linkText="Skrá nýja stöð"
        link="/chargingstation/new">
        <Paper>
          <ChargingStationTable
            categories={categories}
            stations={stations}
            providers={providers}
            showChangeButton={false}
            initialFilters={filters}
            searchForStations={setFilters}
            setCurrentPage={setCurrentPage}
          />
          <TablePagination
            labelRowsPerPage="Fjöldi raða fyrir síðu"
            rowsPerPageOptions={TABLE_ROWS_OPTIONS}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onChangePage={(_, p) => {
              setCurrentPage(p)
            }}
            onChangeRowsPerPage={e => {
              setCurrentPage(0);
              setRowsPerPage(parseInt(e.target.value, 10));
            }}
            ActionsComponent={TablePaginationActions}
          />
        </Paper>
      </Section>
    </Layout>
  );
}

export default securePage(ChargingStations);
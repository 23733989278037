import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Layout from '../components/Layout';

const useStyles = makeStyles(theme => ({
  alignCenter: {
    textAlign: 'center'
  }
}));

const EmailVerificationComplete: React.FC = props => {
  const classes = useStyles({});
  return (
    <Layout contentClassName={classes.alignCenter}>
      <Typography variant="h2">Email verified successfully</Typography>
      <Typography variant="body1">
        Thank you for verifying your email. Now you can securely access all your
        bookings and edit them.
      </Typography>
    </Layout>
  );
};

export default EmailVerificationComplete;

import { gql } from 'apollo-boost';

export const GET_REGIONS = gql`
{
  Regions {
    id
    translations {
      locale
      name
    }
    email
  }
}
`;
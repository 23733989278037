import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Layout from '../components/Layout';

const useStyles = makeStyles({
  root: {
    textAlign: 'center'
  }
});

const EmailVerificationRequired: React.FC = () => {
  const classes = useStyles({});

  const [isSending, setSending] = useState(false);
  const [response, setResponse] = useState<string>('');

  return (
    <Layout contentClassName={classes.root}>
      <Typography variant="h2" gutterBottom>
        Email verification required
      </Typography>
      <Typography variant="body1" gutterBottom>
        You must verify your email to view this page. Please click the link in
        the verification email you got when signing up.
      </Typography>
      <Typography variant="body2">Did the email get lost? </Typography>
      <Button disabled={isSending || !!response} onClick={handleSendClick}>
        Re-Send verification email
      </Button>
      {response && <Typography variant="body2">{response}</Typography>}
    </Layout>
  );

  async function handleSendClick() {
    setSending(true);
    try {
      //todo send email verification
      /*       setResponse(res.message);
       */
    } catch (err) {
      setResponse(err.response.data.message);
    } finally {
      setSending(false);
    }
  }
};

export default EmailVerificationRequired;

import React from 'react';

import securePage from '../hocs/securePage';
import Layout from '../components/Layout';
import ChangesServiceProviders from '../components/ChangesServiceProviders';
import NewServiceProviders from '../components/NewServiceProviders';
import {
  NEW_SERVICE_PROVIDERS,
  PENDING_SERVICE_PROVIDERS
} from '../graphql/queries/ServiceProviderQueries';
import {
  IServiceProviders,
  IPendingProviders
} from '../interfaces/ServiceProvider/ServiceProvider';
import { useQuery } from 'react-apollo';
import LoadingPage from '../components/LoadingPage';

interface IProps {}

const PendingChanges: React.FC<IProps> = () => {
  const { loading: newLoading, error: newError, data: newData } = useQuery<
    IServiceProviders
  >(NEW_SERVICE_PROVIDERS, { fetchPolicy: 'network-only' });
  const {
    loading: pendingLoading,
    error: pendingError,
    data: pendingData
  } = useQuery<IPendingProviders>(PENDING_SERVICE_PROVIDERS, {
    fetchPolicy: 'network-only'
  });
  const loading = newLoading || pendingLoading;
  const error = newError || pendingError;

  if (loading || error) {
    return <LoadingPage error={error ? error.message : ''} />;
  }

  return (
    <Layout useDefaultSpacing>
      <NewServiceProviders
        serviceProviders={newData.ServiceProviders.ServiceProviders}
      />
      <div style={{ marginTop: 50 }}>
        <ChangesServiceProviders
          serviceProviders={
            pendingData.pendingServiceProviders.ServiceProviders
          }
        />
      </div>
    </Layout>
  );
};

export default securePage(PendingChanges);

import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';

import withSplashScreen from './hocs/withSplash';
import Dashboard from './pages/Dashboard';
import Places from './pages/Places';
import PlaceDetails from './pages/PlaceDetails';
import CreatePlace from './pages/CreatePlace';
import Municipalities from './pages/Municipalities';
import MunicipalityDetails from './pages/MunicipalityDetails';
import CreateMunicipality from './pages/CreateMunicipality';
import ServiceProviders from './pages/ServiceProviders';
import Users from './pages/Users';
import UserDetails from './pages/UserDetails';
import UserProfile from './pages/UserProfile';
import CreateUser from './pages/CreateUser';
import Pending from './pages/PendingChanges';
import GeofenceDetails from './pages/GeofenceDetails';
import GeofenceNew from './pages/GeofenceNew';
import Callback from './pages/Callback';
import Settings from './pages/Settings';
import ChargingStations from './pages/ChargingStations';
import Login from './pages/Login';
import ServiceProviderDetails from './pages/ServiceProviderDetails';
import EmailVerificationComplete from './pages/EmailVerificationComplete';
import PageNotFound from './pages/PageNotFound';
import NewServiceProvider from './pages/NewServiceProvider';
import CreateServiceProvider from './pages/CreateServiceProvider';
import { LOGIN_ROUTE, CALLBACK_ROUTE } from './constants';
import useAdminChecker from './hooks/useAdminChecker';
import useEditorChecker from './hooks/useEditorChecker';
import CreateCategory from './pages/CreateCategory';
import SSNInput from './pages/SSNInput';
import CleanAndSafe from './pages/CleanAndSafe';
import UpdateProviderSurvey from './pages/UpdateProviderSurvey';
import CleanAndSafeList from './pages/CleanAndSafeList';
import CleanThankyou from './pages/CleanThankyou';
import GeoFences from './pages/GeoFences';
import ChargingStationDetails from './pages/ChargingStationDetails';
import CreateChargingStation from './pages/CreateChargingStation';
import ServiceProvidersForEmail from './pages/ServiceProvidersForEmail';

const Routes = withSplashScreen(() => {
  const isAdmin = useAdminChecker();
  const isEditor = useEditorChecker();

  return (
    <Switch>
      <Redirect exact from="/" to="/service-providers" />
      <Route path="/dashboard" exact component={Dashboard} />
      {(isAdmin || isEditor) && (
        <Route path="/places" exact component={Places} />
      )}
      {(isAdmin || isEditor) && (
        <Route path="/place/new" exact component={CreatePlace} />
      )}
      {(isAdmin || isEditor) && (
        <Route path="/place/:id" exact component={PlaceDetails} />
      )}
      {(isAdmin || isEditor) && (
        <Route path="/municipalities" exact component={Municipalities} />
      )}
      {(isAdmin || isEditor) && (
        <Route path="/municipality/new" exact component={CreateMunicipality} />
      )}
      {(isAdmin || isEditor) && (
        <Route path="/municipality/:id" exact component={MunicipalityDetails} />
      )}
      {isAdmin && <Route path="/category" exact component={CreateCategory} />}
      <Route path="/service-providers" exact component={ServiceProviders} />
      <Route
        path="/service-providers/:id"
        exact
        component={ServiceProviderDetails}
      />
      {(isAdmin || isEditor) && (
        <Route path="/geofences" exact component={GeoFences} />
      )}
      {isAdmin && <Route path="/geofences/new" exact component={GeofenceNew} />}
      {(isAdmin || isEditor) && (
        <Route path="/geofences/:id" exact component={GeofenceDetails} />
      )}
      {isAdmin && <Route path="/settings" exact component={Settings} />}
      <Route
        path="/service-provider/new"
        exact
        component={CreateServiceProvider}
      />
      {isAdmin && <Route path="/users" exact component={Users} />}
      {isAdmin && <Route path="/user/new" exact component={CreateUser} />}
      {isAdmin && <Route path="/user/:id" exact component={UserDetails} />}
      <Route path="/profile" exact component={UserProfile} />
      {(isAdmin || isEditor) && (
        <Route path="/pending" exact component={Pending} />
      )}
      <Route path="/pending/:id" exact component={NewServiceProvider} />
      <Route path={LOGIN_ROUTE} exact component={Login} />
      <Route path={CALLBACK_ROUTE} exact component={Callback} />
      <Route path="/chargingstations" exact component={ChargingStations} />
      <Route path="/chargingstations/:id" exact component={ChargingStationDetails} />
      <Route path="/chargingstation/new" exact component={CreateChargingStation} />
      <Route
        path="/email_verified"
        exact
        component={EmailVerificationComplete}
      />
      <Route path="/ssn/:id" component={SSNInput} />
      <Route path="/clean/thank-you" exact component={CleanThankyou} />
      <Route path="/clean/:id" exact component={CleanAndSafe} />
      <Route path="/update/:id" exact component={UpdateProviderSurvey} />
      <Route path="/clean/:ssn/list" exact component={CleanAndSafeList} />
      <Route path="/service-providers/filter/:filterStr" exact component={ServiceProvidersForEmail} />
      <Route component={PageNotFound} />
    </Switch>
  );
});

export default Routes;

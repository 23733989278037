import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Paper,
  Typography,
  Grid,
  Button,
  TextField,
  List,
  IconButton,
} from '@material-ui/core';
import Dropzone from 'react-dropzone';
import InfoIcon from '@material-ui/icons/Info';
import { useApolloClient } from '@apollo/react-hooks';
import cx from 'classnames';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import {
  ImageFile,
  IPhoto
} from '../interfaces/ServiceProvider/ServiceProvider';
import ConfirmDialog from './ConfirmDialog';
import PhotoItem, { IPhotoForm } from './PhotoItem';
import useNotifier from '../hooks/useNotifier';
import CropImage from '../components/CropImage';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      padding: theme.spacing(2)
    },
    headline: {
      padding: theme.spacing(2)
    },
    imgRoot: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden'
    },
    gridList: {
      flexWrap: 'nowrap',
      transform: 'translateZ(0)',
      width: '100%'
    },
    title: {
      color: theme.palette.primary.light
    },
    titleBar: {
      //background: 'none'
      background:
        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
    },
    formControl: {
      minWidth: 200
    },
    textField: {
      //minWidth: 200,
      width: 200
    },
    typographyTitle: {
      padding: 20,
      paddingBottom: 5,
      paddingTop: 10
    },
    paperSections: {
      marginTop: 20
    },
    sortingSections: {
      marginTop: 15
    },
    typography: {
      display: 'inline'
    },
    typographyStrong: {
      fontWeight: 700,
      display: 'inline'
    }
  })
);

interface IProps {
  photos: IPhoto[];
  latest: boolean;
  isSorting?: boolean;
  setSorting?: () => void;
  uploadImage: (image: ImageFile, info: IPhotoForm) => Promise<void>;
  deleteImage: (imageId: string) => Promise<void>;
  updateImage: (imageId: string, info: IPhotoForm) => Promise<void>;
  movePhoto?: ({
    newIndex,
    oldIndex
  }: {
    newIndex: number;
    oldIndex: number;
  }) => void;
  cancelMovePhoto?: () => void;
  className?: string;
  infoOnClick?: (event: React.MouseEvent) => void;
  disableEdit: boolean;
}

const initialPhotoInfo = { title: '', alt: '' };

const EditPhotos: React.FC<IProps> = ({
  photos,
  latest,
  isSorting,
  setSorting,
  uploadImage,
  deleteImage,
  updateImage,
  movePhoto,
  cancelMovePhoto,
  className,
  infoOnClick,
  disableEdit
}) => {
  const classes = useStyles();
  const apolloClient = useApolloClient();
  const notifier = useNotifier();

  const [selectedPhoto, setSelectedPhoto] = useState<ImageFile | null>(null);
  const [deleteImageId, setDeleteImageId] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [selectedPhotoInfo, setSelectedPhotoInfo] = useState<IPhotoForm>(
    initialPhotoInfo
  );
  const [uploadedImage, setUploadedImage] = useState<File | null>(null);
  const [croppedImage, setCroppedImage] = useState<File | null>(null);

  const [showCrop, setShowCrop] = useState<boolean>(false);
  const [cropped, setCropped] = useState<boolean>(false);
  const [photoInfo, setPhotoInfo] = useState<{
    [photoId: string]: IPhotoForm;
  }>({});


  useEffect(() => {
    setPhotoInfo(prevInfo => {
      const newInfo: { [photoId: string]: IPhotoForm } = {};
      (photos || []).forEach(photo => {
        newInfo[photo.photoId] = prevInfo[photo.photoId] || {
          title: photo.title || '',
          alt: photo.alt || ''
        };
      });
      return newInfo;
    });
  }, [photos]);

  const onDrop = ([file]) => {
    if (file.size > 10000000) {
      notifier.notify('Of stór mynd - myndin þarf að vera minni en 10Mb', {
        variant: 'error'
      });
    } else {
      setSelectedPhoto(file);
      setUploadedImage(file);
      setCropped(false);
    }
  };


  const onUploadImage = () => {
    uploadImage(selectedPhoto, selectedPhotoInfo)
      //imageUpload({ variables: { image: selectedPhoto, serviceProviderId } })
      .then(() => {
        setSelectedPhoto(null);
        notifier.notify('Mynd bætt við', { variant: 'success' });
        apolloClient.resetStore();
      })
      .catch(e => {
        notifier.notify('Villa kom upp', { variant: 'error' });
      });
  };

  const onDeleteImage = (imageId: string) => {
    deleteImage(imageId)
      .then(() => {
        apolloClient.resetStore();
        setShowDialog(false);
        notifier.notify('Mynd eytt', { variant: 'success' });
      })
      .catch(e => {
        notifier.notify('Villa kom upp', { variant: 'error' });
      });
  };

  const onUpdateImage = (imageId: string) => {
    updateImage(imageId, photoInfo[imageId] || initialPhotoInfo)
      .then(() => {
        notifier.notify('Mynd uppfærð', { variant: 'success' });
      })
      .catch(e => {
        notifier.notify('Villa kom upp', { variant: 'error' });
      });
  };

  const renderDialog = imageId => {
    setDeleteImageId(imageId);
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  const SortableItem = SortableElement(
    ({ photo, key }: { photo: IPhoto; key: string; index: number }) => (
      <PhotoItem
        key={key}
        photo={photo}
        formValues={photoInfo[photo.photoId] || initialPhotoInfo}
        disabled={true}
        disableEdit={disableEdit}
        onChange={val => {
          setPhotoInfo({
            ...photoInfo,
            [photo.photoId]: val
          });
        }}
        editPhoto={() => onUpdateImage(photo.photoId)}
        deletePhoto={() => renderDialog(photo.photoId)}
      />
    )
  );

  const SortableList = SortableContainer(({ items }: { items: IPhoto[] }) => {
    return (
      <List>
        {items.map((photo, index) => (
          <SortableItem key={photo.photoId} index={index} photo={photo} />
        ))}
        <ConfirmDialog
          open={showDialog}
          message="Ertu viss um að þú viljir eyða þessari mynd?"
          confirmText="Eyða mynd"
          onConfirm={() => onDeleteImage(deleteImageId)}
          onCancel={closeDialog}
        />
      </List>
    );
  });
  return (
    <div>
      {latest && (
        <Grid container direction="row" justify="flex-end" spacing={2}>
          {!isSorting && (
            <Grid item xs={12}>
              <Paper className={cx(classes.paperSections, className)}>
                <Grid container direction="row">
                  <Typography variant="h6" className={classes.typographyTitle}>
                    Myndir
                  </Typography>
                  {
                    infoOnClick &&
                    <IconButton onClick={(event) => {
                      infoOnClick(event);
                    }}>
                      <InfoIcon />
                    </IconButton>
                  }
                </Grid>
                {!disableEdit && myDropzone()}
              </Paper>
            </Grid>
          )}
        </Grid>
      )}
      {renderPhotos()}
      {latest && photos.length > 1 && setSorting && (
        <Grid container direction="row" justify="flex-end" spacing={2}>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            {!isSorting && movePhoto && cancelMovePhoto && !disableEdit && (
              <Button
                color="primary"
                variant="contained"
                style={{ margin: '1em' }}
                onClick={() => setSorting()}
              >
                Endurraða myndum
              </Button>
            )}
            {isSorting && movePhoto && cancelMovePhoto && (
              <Button
                color="primary"
                variant="contained"
                style={{ margin: '1em' }}
                onClick={() => {
                  setSorting();
                }}
              >
                Endurraða
              </Button>
            )}
            {isSorting && movePhoto && cancelMovePhoto && (
              <Button
                color="secondary"
                variant="contained"
                style={{ margin: '1em' }}
                onClick={() => {
                  cancelMovePhoto();
                  setSorting();
                }}
              >
                Hætta við
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </div>
  );

  function renderPhotos() {
    return isSorting && movePhoto && cancelMovePhoto && photos.length > 1 ? (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <SortableList items={photos} onSortEnd={movePhoto} useDragHandle />
        </Grid>
      </Grid>
    ) : (
      <React.Fragment>
        {(photos || []).map(photo => (
          <PhotoItem
            key={photo.photoId}
            photo={photo}
            formValues={photoInfo[photo.photoId] || initialPhotoInfo}
            disabled={!latest || disableEdit}
            onChange={val =>
              setPhotoInfo({
                ...photoInfo,
                [photo.photoId]: val
              })
            }
            editPhoto={() => onUpdateImage(photo.photoId)}
            deletePhoto={() => renderDialog(photo.photoId)}
          />
        ))}
        <ConfirmDialog
          open={showDialog}
          message="Ertu viss um að þú viljir eyða þessari mynd?"
          confirmText="Eyða mynd"
          onConfirm={() => onDeleteImage(deleteImageId)}
          onCancel={closeDialog}
        />
      </React.Fragment>
    );
  }

  function myDropzone() {
    return (
      <React.Fragment>
        <Paper className={classes.paper} elevation={0}>
          <Dropzone
            accept="image/jpeg, image/png"
            multiple={false}
            onDrop={onDrop}
          >
            {({ getRootProps, getInputProps }) => (
              <div>
                <section>
                  <div
                    {...getRootProps()}
                    style={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      padding: '16px',
                      borderWidth: 2,
                      borderRadius: 5,
                      borderColor: '#213b76',
                      borderStyle: 'dashed',
                      backgroundColor: '#fafafa'
                    }}
                  >
                    <input {...getInputProps()} />
                    <p>
                      Dragðu og slepptu skrá hingað, eða smelltu til að velja
                      skrá
                    </p>
                  </div>
                </section>
              </div>
            )}
          </Dropzone>
          <CropImage
            file={uploadedImage}
            setCroppedImage={(c) => {
              setCroppedImage(c);
            }}
            confirmCrop={() => {
              onDrop([croppedImage]);
              setShowCrop(false);
              setCropped(true);
            }}
            cancelCrop={() => {
              setShowCrop(false);
            }}
            showCrop={showCrop}
          />

          {selectedPhoto && !showCrop && !cropped &&
            <div>
              <Alert severity="info">Myndir verða birtar í hlutföllunum 12:8. Myndir í öðrum hlutföllum munu ekki birtast að fullu. Við mælum með að kroppa út svæði í réttu hlutfalli úr slíkum myndum.</Alert>
              <Paper
                className={classes.paper}
                elevation={0}
                style={{ textAlign: 'right' }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setShowCrop(true)}>
                  Kroppa
                </Button>
              </Paper>
            </div>
          }
        </Paper>



        {selectedPhoto && (
          <Grid container>
            <Grid item xs={12}>
              <Grid container direction="row">
                <Grid item xs={12} sm={6}>
                  <Paper className={classes.paper} elevation={0}>
                    <TextField
                      fullWidth
                      id="title"
                      label="Titill"
                      variant="outlined"
                      value={selectedPhotoInfo.title}
                      onChange={e =>
                        setSelectedPhotoInfo({
                          ...selectedPhotoInfo,
                          title: e.target.value
                        })
                      }
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper className={classes.paper} elevation={0}>
                    <TextField
                      fullWidth
                      id="alt"
                      label="Alt"
                      variant="outlined"
                      value={selectedPhotoInfo.alt}
                      onChange={e =>
                        setSelectedPhotoInfo({
                          ...selectedPhotoInfo,
                          alt: e.target.value
                        })
                      }
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={8}>
              <Paper className={classes.paper} elevation={0}>
                <Typography variant="h6">Valin skrá</Typography>
                <ul>
                  {selectedPhoto && (
                    <li key={selectedPhoto.path}>
                      {selectedPhoto.path} - {selectedPhoto.size} bytes
                    </li>
                  )}
                </ul>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={8}>

            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper
                className={classes.paper}
                elevation={0}
                style={{ textAlign: 'right' }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => onUploadImage()}
                >
                  Bæta við
                </Button>
              </Paper>
            </Grid>
          </Grid>
        )}
      </React.Fragment>
    );
  }
};

export default EditPhotos;

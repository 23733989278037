import React, { useState } from 'react';
import {
  Paper,
  Grid,
  makeStyles,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import Autocomplete, { IOption } from './Autocomplete';
import { GET_DATA } from '../graphql/queries/Misc';
import { IData } from '../interfaces/Misc/zipCodes';
import { IGeoFenceList } from '../interfaces/GeoFence/GeoFence';
import { useQuery } from '@apollo/react-hooks/lib/useQuery';
import { getIcelandicName } from '../utils/locale';

interface IProps {
  geofences: IGeoFenceList[];
  initialFilter: Array<string>;
  searchGeofences: (regionIds: Array<string>) => void;
}

interface ITableCell {
  id: string;
  label: string;
  align?: 'left' | 'right' | 'center';
  sortable?: boolean;
  render: (geoFence: IGeoFenceList) => React.ReactNode;
  cellClass?: string;
}

const useStyles = makeStyles(theme => ({
  filters: {
    margin: theme.spacing(0),
    width: '100%'
  },
  button: {
    padding: theme.spacing(2, 3)
  }
}));

const GeoFenceTable: React.FC<IProps> = ({
  geofences,
  initialFilter,
  searchGeofences
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [regionIds, setRegionIds] = useState<string[]>(initialFilter);
  const { data } = useQuery<IData>(GET_DATA);
  const { regions } = getFieldData(data);
  const tableCells = getTableCells();
  return (
    <Paper>
      {renderFilterArea()}
      <TableContainer>
        <Table aria-label="geofence table">
          <TableHead>
            <TableRow>
              {tableCells.map(cell => (
                <TableCell key={cell.id} align={cell.align}>
                  {cell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{geofences.map(renderTableRow)}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );

  function renderFilterArea() {
    return (
      <Grid
        container
        spacing={2}
        className={classes.filters}
        alignItems="center"
        justify="space-between"
      >
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Autocomplete
                id="regions"
                values={(regionIds || []).map(r => ({ value: r }))}
                label="Landshluti"
                items={regions}
                fullWidth
                onChange={opt => setRegionIds(getArrayFilter(opt))}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => searchGeofences(regionIds)}
                className={classes.button}
              >
                Leita
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  setRegionIds([]);
                  searchGeofences([]);
                }}
                className={classes.button}
              >
                Hreinsa leit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function renderTableRow(geoFence: IGeoFenceList) {
    return (
      <TableRow
        key={geoFence.id}
        hover
        style={{ cursor: 'pointer' }}
        onClick={() => history.push(`/geofences/${geoFence.id}`)}
        onMouseDown={(event) => {
          if (event.button === 1) {
            window.open(`/geofences/${geoFence.id}`);
            window.focus();
          }
        }}
      >
        {tableCells.map(cell => (
          <TableCell
            key={cell.id}
            align={cell.align}
            className={cell.cellClass}
          >
            {cell.render(geoFence)}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  function getTableCells(): ITableCell[] {
    return [
      {
        id: 'name',
        label: 'Nafn',
        sortable: false,
        render: ({ properties }) => getIcelandicName(properties.translations)
      },
      {
        id: 'regions',
        label: 'Landshlutar',
        render: ({ regions }) =>
          (regions || []).map(r => getIcelandicName(r.translations)).join(', ')
      }
    ];
  }

  function getArrayFilter(opt: unknown) {
    const options = opt as IOption[];
    return options.length ? options.map(o => o.value) : undefined;
  }

  function getFieldData(data: IData) {
    return {
      regions: data
        ? data.Regions.map(r => ({
          value: r.id,
          label: getIcelandicName(r.translations)
        }))
        : []
    };
  }
};
export default GeoFenceTable;

import { gql } from 'apollo-boost';
import {
  serviceProviderFragment,
  serviceProviderLiteFragment,
  serviceProviderDetailsFragment
} from '../fragments/ServiceProviderFragments';

export const SERVICE_PROVIDER = gql`
  query getServiceProvider(
    $serviceProviderId: String!
    $collectionCategory: String
  ) {
    ServiceProvider(serviceProviderId: $serviceProviderId) {
      ...serviceProvider
      collections(categoryId: $collectionCategory) {
        id
        category
        content {
          locale
          title
          text
          seoKeywords
          seoDescription
        }
      }
      photoCollections {
        id
        name
        category
        photos {
          photoUri
          photoId
          alt
          title
        }
      }
      revision {
        ...serviceProvider
        collections {
          id
          category
          content {
            locale
            title
            text
            seoKeywords
            seoDescription
          }
        }
        photoCollections {
          id
          name
          category
          photos {
            photoUri
            photoId
            alt
            title
          }
        }
      }
      pendingRevision {
        ...serviceProvider
        collections {
          id
          category
          content {
            locale
            title
            text
            seoKeywords
            seoDescription
          }
        }
        photoCollections {
          id
          name
          category
          photos {
            photoUri
            photoId
            alt
            title
          }
        }
      }
    }
  }
  ${serviceProviderFragment}
`;

export const SERVICE_PROVIDERS = gql`
  query getServiceProviders(
    $skip: Int!
    $take: Int!
    $filter: ServiceProviderFilter
    $sort: ServiceProviderSort
    $active: Boolean
    $approved: Boolean
  ) {
    ServiceProviders(
      skip: $skip
      take: $take
      filter: $filter
      sort: $sort
      active: $active
      approved: $approved
    ) {
      count
      ServiceProviders {
        ...serviceProviderLite
      }
    }
  }
  ${serviceProviderLiteFragment}
`;

export const SERVICE_PROVIDERS_DETAILS = gql`
  query getServiceProvidersDetails(
    $skip: Int!
    $take: Int!
    $filter: ServiceProviderFilter
    $sort: ServiceProviderSort
    $active: Boolean
    $approved: Boolean
  ) {
    ServiceProviders(
      skip: $skip
      take: $take
      filter: $filter
      sort: $sort
      active: $active
      approved: $approved
    ) {
      count
      ServiceProviders {
        ...serviceProviderDetails
      }
    }
  }
  ${serviceProviderDetailsFragment}
`;

export const ALL_SERVICE_PROVIDERS = gql`
  {
    ServiceProviders {
      count
      ServiceProviders {
        serviceProviderId
        legalName
      }
    }
  }
`;

export const NEW_SERVICE_PROVIDERS = gql`
  {
    ServiceProviders(active: false, approved: false) {
      count
      ServiceProviders {
        serviceProviderId
        legalName
        phoneNr
        address
        email
      }
    }
  }
`;

export const PENDING_SERVICE_PROVIDERS = gql`
  {
    pendingServiceProviders {
      count
      ServiceProviders {
        ...serviceProvider
      }
    }
  }
  ${serviceProviderFragment}
`;

export const CLEAN_AND_SAFE_EMAIL = gql`
  query sendCleanEmail($serviceProviderId: String!) {
    CleanAndSafeEmail(serviceProviderId: $serviceProviderId) {
      success
    }
  }
`;

import React, { useState, ReactNode } from 'react';
import moment from 'moment';
import { useQuery, useMutation } from 'react-apollo';
import { FormikProps, useFormik } from 'formik';
import {
  makeStyles,
  Grid,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Paper,
  Button,
  FormControlLabel,
  Switch,
  TextField
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import {
  IPlace,
  IPlaceCategories,
  IPlaceLite,
  ITranslation,
  IPlaceLitePhotos
} from '../interfaces/Place/Place';
import { IPhotoForm } from './PhotoItem';
import {
  ImageFile,
  IPhotoUpdate,
  IPhoto
} from '../interfaces/ServiceProvider/ServiceProvider';
import { IData } from '../interfaces/Misc/zipCodes';
import { DATE_AND_TIME_FORMAT } from '../constants';
import { GET_DATA } from '../graphql/queries/Misc';
import { PLACE_CATEGORIES } from '../graphql/queries/PlaceQueries';
import {
  UPDATE_PLACE,
  ADD_PLACE_PHOTO,
  REMOVE_PLACE_PHOTO,
  EDIT_PLACE_PHOTO,
  CREATE_PLACE,
  REMOVE_PLACE
} from '../graphql/mutations/PlaceMutations';
import Autocomplete, { IOption } from './Autocomplete';
import LocationInput from './LocationInput';
import SelectPlace from './SelectPlace';
import SelectMunicipality from './SelectMunicipality';
import EditPhotos from './EditPhotos';
import EditTranslations from './EditTranslations';
import SavePrompt from './SavePrompt';
import useNotifier from '../hooks/useNotifier';
import { getIcelandicName, IS_LOCALE, EN_LOCALE } from '../utils/locale';
import ConfirmDialog from './ConfirmDialog';
import useAdminChecker from '../hooks/useAdminChecker';
import arrayMove from 'array-move';
import remove from 'lodash/remove';

interface IProps {
  place?: IPlace;
  newPlace: boolean;
}

interface ITab {
  key: string;
  label: string;
  render: (formik: IFormik) => ReactNode;
}

interface IFormField {
  key: string;
  label: string;
  translation?: string;
  locale?: typeof IS_LOCALE | typeof EN_LOCALE;
  type?: 'text' | 'select' | 'multiSelect' | 'number';
  required?: boolean;
  disabled?: boolean;
  options?: Array<{ value: string; label: string }>;
}

interface IFormValues {
  translations: ITranslation[];
  zipCode: IOption;
  townCode: IOption;
  active: boolean;
  roadCode: string;
  grade: number;
  latitude: string;
  longitude: string;
  region: IOption;
  categories: IOption[];
  relatedPlaces: IOption[];
  relatedMunicipalities: IOption[];
  photos: IPhotoUpdate[];
}

type IFormik = FormikProps<IFormValues>;

const useStyles = makeStyles(theme => ({
  tabContent: {
    marginTop: theme.spacing(1)
  },
  section: {
    minHeight: 200,
    marginBottom: theme.spacing(3),
    padding: 20,
    paddingTop: 10,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0
    }
  },
  sectionTitle: {
    marginBottom: theme.spacing(3)
  },
  statusTitle: {
    marginBottom: theme.spacing(1)
  },
  buttons: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3)
    }
  },
  button: {
    padding: 10
  },
  active: {
    marginBottom: theme.spacing(2)
  },
  removeTopMargin: {
    marginTop: 0
  },
  seeMoreGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 10
  },
  typographyDisabled: {
    color: 'rgba(0, 0, 0, 0.5)'
  }
}));

const createSchema = Yup.object().shape({
  translations: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required(),
        locale: Yup.string().required()
      })
    )
    .min(2, 'too short')
    .required(),
  grade: Yup.number().integer().min(0).max(5),
  region: Yup.object().required(),
  categories: Yup.array().min(1)
});

const updateSchema = Yup.object().shape({
  translations: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required(),
        locale: Yup.string().required(),
        description: Yup.string().required(),
        seoDescription: Yup.string(),
        seoTitle: Yup.string(),
        seoKeywords: Yup.string()
      })
    )
    .min(2, 'too short')
    .required(),
  grade: Yup.number().integer().min(0).max(5),
  region: Yup.object().required(),
  categories: Yup.array().min(1)
});

const PlaceForm: React.FC<IProps> = ({ place, newPlace }) => {
  const formik = useFormik<IFormValues>({
    initialValues: getInitialValues(),
    onSubmit: onSubmit,
    validationSchema: newPlace ? createSchema : updateSchema,
    validateOnBlur: !!place,
    validateOnChange: !!place
  });
  const classes = useStyles();
  const notifier = useNotifier();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(0);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const isAdmin = useAdminChecker();
  const [isSortingPhotos, setSortingPhotos] = useState<boolean>(false);
  const [photoMovedCounter, changePhotoMoved] = useState<number>(0);

  const { data } = useQuery<IData>(GET_DATA);
  const { data: categoriesData } = useQuery<IPlaceCategories>(PLACE_CATEGORIES);
  const [updatePlace] = useMutation(UPDATE_PLACE, {
    refetchQueries: ['getPlaceDetails']
  });

  const [removePlace] = useMutation(REMOVE_PLACE);

  const [addPlacePhoto] = useMutation<{ addPlaceImage: IPlaceLitePhotos }>(
    ADD_PLACE_PHOTO,
    {
      refetchQueries: ['getPlaceDetails'],
      onCompleted(data) {
        if (data && data.addPlaceImage && data.addPlaceImage.photos) {
          resetPhotos(data.addPlaceImage.photos);
        }
      }
    }
  );
  const [removePlacePhoto] = useMutation<{
    removePlacePhoto: IPlaceLitePhotos;
  }>(REMOVE_PLACE_PHOTO, {
    refetchQueries: ['getPlaceDetails'],
    onCompleted(data) {
      if (data && data.removePlacePhoto && data.removePlacePhoto.photos) {
        resetPhotos(data.removePlacePhoto.photos);
      }
    }
  });
  const [editPlacePhoto] = useMutation(EDIT_PLACE_PHOTO, {
    refetchQueries: ['getPlaceDetails']
  });
  const [createPlace] = useMutation(CREATE_PLACE);

  const { zipCodes, townCodes, regions } = getFieldData(data);
  const placeCategories = categoriesData ? categoriesData.PlaceCategories : [];
  const tabs = getTabs();
  const placeName = place ? getIcelandicName(place.translations) : '';

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <AppBar position="static">
          <Tabs
            value={currentTab}
            onChange={(_, val) => setCurrentTab(val)}
            variant="fullWidth"
            scrollButtons="auto"
          >
            {tabs.map(({ key, label }) => (
              <Tab key={key} label={label} />
            ))}
          </Tabs>
        </AppBar>
        {renderTabContent(tabs[currentTab])}
        {!place && renderButtons(true)}
      </form>
      <SavePrompt formik={formik} />
    </React.Fragment>
  );

  function renderTabContent({ key, render }: ITab) {
    return (
      <Grid key={key} container spacing={3} className={classes.tabContent}>
        <Grid
          item
          xs={12}
          md={place ? 7 : undefined}
          lg={place ? 8 : undefined}
        >
          {render(formik)}
        </Grid>
        {place ? (
          <Grid item xs={12} md={5} lg={4}>
            {renderStatusArea()}
          </Grid>
        ) : null}
      </Grid>
    );
  }

  function renderStatusArea() {
    return (
      <Grid container direction="column">
        <Grid item>
          <Paper className={classes.section}>
            <Typography variant="h6" className={classes.statusTitle}>
              Staða
            </Typography>
            <FormControlLabel
              label={formik.values.active ? 'Í birtingu' : 'Ekki í birtingu'}
              className={classes.active}
              control={
                <Switch
                  id="active"
                  checked={formik.values.active}
                  onChange={formik.handleChange}
                  color="primary"
                />
              }
            />
            <Typography variant="h6" className={classes.statusTitle}>
              Útgáfa
            </Typography>
            <Typography gutterBottom>
              Síðast breytt:{' '}
              {place.updatedAt
                ? moment(place.updatedAt).format(DATE_AND_TIME_FORMAT)
                : 'Aldrei'}
            </Typography>
            {place.updatedBy && (
              <Typography gutterBottom>Af: {place.updatedBy}</Typography>
            )}
          </Paper>
        </Grid>
        <Grid item>{renderButtons()}</Grid>
      </Grid>
    );
  }

  function renderButtons(create?: boolean) {
    const disableSubmit =
      !formik.dirty || formik.isSubmitting || (!formik.isValid && !create);

    return (
      <Grid container spacing={2} className={classes.buttons}>
        <Grid item xs={12} md={create ? 6 : undefined}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            disabled={disableSubmit}
            className={classes.button}
          >
            Vista
          </Button>
        </Grid>
        {isAdmin && !create && (
          <Grid item xs={12} md={create ? 6 : undefined}>
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              style={{ padding: 10 }}
              onClick={() => setDeleteDialogOpen(true)}
            >
              Eyða stað
            </Button>
            <ConfirmDialog
              open={deleteDialogOpen}
              message="Ertu viss um að þú viljir eyða áhugaverðum stað?"
              confirmText="Eyða"
              onConfirm={() => {
                deletePlace();
                setDeleteDialogOpen(false);
              }}
              onCancel={() => setDeleteDialogOpen(false)}
            />
          </Grid>
        )}
        <Grid item xs={12} md={create ? 6 : undefined}>
          <Button
            color="default"
            variant="contained"
            fullWidth
            disabled={formik.isSubmitting}
            className={classes.button}
            onClick={() => history.push('/places')}
          >
            Hætta við
          </Button>
        </Grid>
      </Grid>
    );
  }

  function renderInfoTab() {
    const { values } = formik;
    return (
      <Paper className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle}>
          Grunnupplýsingar
        </Typography>
        <Grid container spacing={4}>
          {getInfoFields().map(field => (
            <Grid item xs={12} sm={6} key={field.key}>
              {renderInfoField(field)}
            </Grid>
          ))}
          {values.translations.length > 0 && !newPlace && (
            <Grid
              item
              xs={12}
              sm={12}
              key={'seeText'}
              className={classes.seeMoreGrid}
            >
              <Typography className={classes.typographyDisabled}>
                *Sjá textalýsing
              </Typography>
            </Grid>
          )}
        </Grid>
      </Paper>
    );
  }

  function renderInfoField(field: IFormField) {
    const {
      key,
      label,
      locale,
      translation,
      type,
      options,
      disabled,
      required
    } = field;
    const { values, errors, handleChange, handleBlur, setFieldValue } = formik;
    if (type === 'select' || type === 'multiSelect') {
      return (
        <Autocomplete
          id={key}
          value={type === 'select' ? values[key] : undefined}
          values={type === 'multiSelect' ? values[key] : undefined}
          error={!!errors[key]}
          label={label}
          items={options}
          onChange={val => setFieldValue(key, val)}
          disabled={disabled}
          required={required}
          fullWidth
        />
      );
    }

    if (key === 'latitude' || key === 'longitude') {
      return (
        <LocationInput
          location={{
            latitude: values.latitude,
            longitude: values.longitude
          }}
          type={key}
          label={label}
          error={!!errors[key]}
          required={required}
          disabled={disabled}
          initialSearch={placeName}
          onChange={location => {
            setFieldValue('latitude', location.latitude);
            setFieldValue('longitude', location.longitude);
          }}
        />
      );
    }

    if (key === 'name') {
      return (
        <TextField
          id={translation}
          type={type}
          label={label}
          value={getTranslationsField(key, locale)}
          error={!!errors[translation]}
          onChange={e => setTranslationsValue(e.target.value, locale, key)}
          onBlur={handleBlur}
          variant="outlined"
          disabled={disabled}
          required={required}
          fullWidth
        />
      );
    }

    return (
      <TextField
        id={key}
        type={type}
        label={label}
        value={values[key]}
        error={!!errors[key]}
        onChange={handleChange}
        onBlur={handleBlur}
        variant="outlined"
        disabled={disabled}
        required={required}
        fullWidth
      />
    );
  }

  function setTranslationsValue(
    val: string,
    locale: IFormField['locale'],
    key: string
  ) {
    const { values, setFieldValue } = formik;
    const translationsIndex = values.translations.findIndex(
      val => val.locale === locale
    );
    if (translationsIndex === -1) {
      return;
    } else {
      const newValues: ITranslation[] = Array.from(values.translations);
      newValues[translationsIndex][key] = val;
      setFieldValue('translations', newValues);
    }
  }

  function getTranslationsField(key: 'name', locale: string) {
    const { values } = formik;
    const translationsIndex = values.translations.findIndex(
      val => val.locale === locale
    );
    if (translationsIndex === -1) {
      return '';
    }
    return values.translations[translationsIndex][key];
  }

  function renderRelatedTab() {
    const placesKey = 'relatedPlaces';
    const munKey = 'relatedMunicipalities';
    const { values, errors, setFieldValue } = formik;
    return (
      <Paper className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle}>
          Tengt efni
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <SelectPlace
              id={placesKey}
              values={values[placesKey]}
              error={!!errors[placesKey]}
              label="Tengdir staðir"
              onChange={val => setFieldValue(placesKey, val)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <SelectMunicipality
              id={munKey}
              values={values[munKey]}
              error={!!errors[munKey]}
              label="Tengd sveitafélög"
              onChange={val => setFieldValue(munKey, val)}
              fullWidth
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }

  function renderTranslationTab() {
    const { values } = formik;
    return (
      <EditTranslations
        translations={values.translations}
        className={classes.removeTopMargin}
        addTranslation={translation => addTranslation(translation)}
        editTranslation={(translation, index) =>
          editTranslations(translation, index)
        }
        deleteTranslation={index => removeTranslation(index)}
        disableEdit={false}
      />
    );
  }

  function editTranslations(translation: ITranslation, index: number) {
    const { values, setFieldValue } = formik;
    const trans: ITranslation = {
      locale: translation.locale,
      name: translation.name,
      description: translation.description,
      seoTitle: translation.seoTitle,
      seoDescription: translation.seoDescription,
      seoKeywords: translation.seoKeywords
    };

    const newTranslations: ITranslation[] = Array.from(values.translations);
    newTranslations[index] = trans;
    setFieldValue('translations', newTranslations);
  }

  function addTranslation(translation: ITranslation) {
    const { values, setFieldValue } = formik;
    const trans: ITranslation = {
      locale: translation.locale,
      name: translation.name,
      description: translation.description,
      seoTitle: translation.seoTitle,
      seoDescription: translation.seoDescription,
      seoKeywords: translation.seoKeywords
    };

    const newTranslations: ITranslation[] = Array.from(values.translations);
    newTranslations.push(trans);
    setFieldValue('translations', newTranslations);
  }

  function removeTranslation(index: number) {
    const { values, setFieldValue } = formik;
    const translations: ITranslation[] = remove(
      values.translations,
      (val, index2) => index !== index2
    );
    setFieldValue('translations', translations);
  }

  function renderPhotoTab() {
    const { values } = formik;
    const photos: IPhoto[] = [];
    // eslint-disable-next-line array-callback-return
    values.photos.map(photo => {
      // eslint-disable-next-line array-callback-return
      place.photos.map(placePhoto => {
        if (photo.photoId === placePhoto.photoId) {
          photos.push(placePhoto);
        }
      });
    });
    return (
      <EditPhotos
        latest
        photos={photos ? photos : []}
        isSorting={isSortingPhotos}
        setSorting={() => setSortingPhotos(!isSortingPhotos)}
        uploadImage={uploadImage}
        deleteImage={deleteImage}
        updateImage={editImage}
        movePhoto={e => movePhoto(e)}
        cancelMovePhoto={() => cancelMove()}
        className={classes.removeTopMargin}
        disableEdit={false}
      />
    );
  }

  function resetPhotos(photos: IPhoto[]) {
    const { setFieldValue } = formik;
    const photoUpdate = photos.map(photo => ({
      alt: photo.alt,
      title: photo.title,
      photoId: photo.photoId
    }));
    setFieldValue('photos', photoUpdate);
  }

  function movePhoto({
    newIndex,
    oldIndex
  }: {
    newIndex: number;
    oldIndex: number;
  }) {
    const { values, setFieldValue } = formik;
    const counter = photoMovedCounter + 1;
    const newArray = arrayMove(values.photos, oldIndex, newIndex);
    setFieldValue('photos', newArray);
    changePhotoMoved(counter);
  }

  function cancelMove() {
    const { setFieldValue } = formik;
    const counter = photoMovedCounter + 1;
    const oldPhotos: IPhotoUpdate[] = place.photos.map(photo => ({
      alt: photo.alt,
      title: photo.title,
      photoId: photo.photoId
    }));
    setFieldValue('photos', oldPhotos);
    changePhotoMoved(counter);
  }

  function getFieldData(data: IData) {
    return {
      zipCodes: data ? data.ZipCodes : [],
      townCodes: data ? data.TownCodes : [],
      regions: data
        ? data.Regions.map(r => ({
          value: r.id,
          label: getIcelandicName(r.translations)
        }))
        : []
    };
  }

  function getTabs(): ITab[] {
    const createTabs = [
      { key: 'info', label: 'Grunnupplýsingar', render: renderInfoTab },
      { key: 'related', label: 'Tengt efni', render: renderRelatedTab }
    ];

    if (!place) {
      return createTabs;
    }

    return [
      ...createTabs,
      {
        key: 'translation',
        label: 'Textalýsing',
        render: renderTranslationTab
      },
      { key: 'photos', label: 'Myndir', render: renderPhotoTab }
    ];
  }

  function getInitialValues() {
    return {
      /*       name: placeName,
      nameEn: place ? getEnglishName(place.translations) : '', */
      translations:
        place && place.translations && place.translations.length > 0
          ? place.translations
          : createDefaultTranslation(),
      zipCode: place && place.zipCode ? { value: place.zipCode } : undefined,
      townCode: place && place.townCode ? { value: place.townCode } : undefined,
      active: !place || place.active,
      roadCode: place ? place.roadCode : '',
      grade: place ? place.grade : 0,
      longitude:
        place && place.location ? place.location.coordinates[0].toString() : '',
      latitude:
        place && place.location ? place.location.coordinates[1].toString() : '',
      region:
        place && place.region
          ? {
            value: place.region.id,
            label: getIcelandicName(place.region.translations)
          }
          : undefined,
      categories:
        place && place.categories
          ? place.categories.map(c => ({
            value: c.id,
            label: getIcelandicName(c.translations)
          }))
          : [],
      relatedPlaces:
        place && place.relatedPlaces
          ? place.relatedPlaces.map(p => ({
            value: p.placeId,
            label: getIcelandicName(p.translations)
          }))
          : [],
      relatedMunicipalities:
        place && place.relatedMunicipalities
          ? place.relatedMunicipalities.map(m => ({
            value: m.municipalityId,
            label: getIcelandicName(m.translations)
          }))
          : [],
      photos:
        place && place.photos && place.photos.length > 0
          ? place.photos.map(photo => ({
            alt: photo.alt,
            title: photo.title,
            photoId: photo.photoId
          }))
          : []
    };
  }

  function createDefaultTranslation() {
    const newTranslations: ITranslation[] = [
      {
        name: '',
        locale: IS_LOCALE,
        description: '',
        seoTitle: '',
        seoDescription: '',
        seoKeywords: ''
      },
      {
        name: '',
        locale: EN_LOCALE,
        description: '',
        seoTitle: '',
        seoDescription: '',
        seoKeywords: ''
      }
    ];
    return newTranslations;
  }

  function getInfoFields(): IFormField[] {
    return [
      {
        key: 'name',
        translation: 'name',
        locale: IS_LOCALE,
        label: newPlace ? 'Nafn' : '*Nafn',
        required: newPlace,
        disabled: !newPlace
      },
      {
        key: 'name',
        translation: 'nameEn',
        locale: EN_LOCALE,
        label: newPlace ? 'Nafn En' : '*Nafn En',
        required: newPlace,
        disabled: !newPlace
      },
      {
        key: 'zipCode',
        label: 'Póstnúmer',
        type: 'select',
        options: zipCodes
      },
      {
        key: 'townCode',
        label: 'Sveitafélagsnúmer',
        type: 'select',
        options: townCodes
      },
      {
        key: 'roadCode',
        label: 'Veganúmer'
      },
      {
        key: 'grade',
        label: 'Einkunn',
        type: 'number'
      },
      {
        key: 'latitude',
        label: 'Breiddargráða'
      },
      {
        key: 'longitude',
        label: 'Lengdargráða'
      },
      {
        key: 'region',
        label: 'Landshluti',
        type: 'select',
        required: true,
        options: regions
      },
      {
        key: 'categories',
        label: 'Flokkar',
        type: 'multiSelect',
        required: true,
        options: placeCategories.map(c => ({
          value: c.id,
          label: getIcelandicName(c.translations)
        }))
      }
    ];
  }

  async function onSubmit(values: IFormValues) {
    if (place) {
      await updatePlaceDetails(values);
      setSortingPhotos(false);
    } else {
      await createNewPlace(values);
    }
  }

  async function updatePlaceDetails(values: IFormValues) {
    try {
      const {
        translations,
        zipCode,
        townCode,
        active,
        roadCode,
        grade,
        region,
        latitude,
        longitude,
        relatedPlaces,
        relatedMunicipalities,
        photos
      } = values;

      await updatePlace({
        variables: {
          placeId: place.placeId,
          values: {
            active,
            roadCode,
            grade: grade || 0,
            zipCode: zipCode ? zipCode.value : '',
            townCode: townCode ? townCode.value : '',
            regionId: region ? region.value : '',
            categoryIds: values.categories.map(c => c.value),
            placeIds: relatedPlaces.map(p => p.value),
            municipalityIds: relatedMunicipalities.map(m => m.value),
            location: parseCoordinates(longitude, latitude),
            translations: translations,
            photos
            /* photos: values.photos.map(photo => ({
              alt: photo.alt,
              title: photo.title,
              photoId: photo.photoId
            })) */
          }
        }
      });
      notifier.notify('Staður uppfærður', { variant: 'success' });
    } catch {
      notifier.notify('Mistókst að uppfæra stað', { variant: 'error' });
    }
  }

  async function createNewPlace(values: IFormValues) {
    try {
      const {
        translations,
        zipCode,
        townCode,
        roadCode,
        grade,
        region,
        latitude,
        longitude,
        relatedPlaces,
        relatedMunicipalities
      } = values;

      const res = await createPlace({
        variables: {
          values: {
            roadCode,
            grade: grade || 0,
            zipCode: zipCode ? zipCode.value : '',
            townCode: townCode ? townCode.value : '',
            regionId: region ? region.value : '',
            categoryIds: values.categories.map(c => c.value),
            placeIds: relatedPlaces.map(p => p.value),
            municipalityIds: relatedMunicipalities.map(m => m.value),
            location: parseCoordinates(longitude, latitude),
            translations: translations
          }
        }
      });

      const newPlace: IPlaceLite = res.data.createPlace;
      notifier.notify('Nýr staður skráður', { variant: 'success' });
      history.push(`/place/${newPlace.placeId}`);
    } catch {
      notifier.notify('Mistókst að skrá nýjan stað', { variant: 'error' });
    }
  }

  function parseCoordinates(lng: string, lat: string) {
    return lng && lat
      ? { type: 'Point', coordinates: [parseFloat(lng), parseFloat(lat)] }
      : undefined;
  }

  async function uploadImage(image: ImageFile, info: IPhotoForm) {
    addPlacePhoto({
      variables: {
        placeId: place.placeId,
        image,
        title: info.title,
        alt: info.alt
      }
    });
  }

  async function deletePlace() {
    try {
      await removePlace({ variables: { placeId: place.placeId } });
      notifier.notify('Áhugaverðum stað eytt', { variant: 'success' });
      history.push('/places');
    } catch {
      notifier.notify('Villa kom upp', { variant: 'error' });
    }
  }

  async function deleteImage(imageId: string) {
    removePlacePhoto({ variables: { placeId: place.placeId, imageId } });
  }

  async function editImage(imageId: string, info: IPhotoForm) {
    editPlacePhoto({
      variables: {
        placeId: place.placeId,
        imageId,
        title: info.title,
        alt: info.alt
      }
    });
  }
};

export default PlaceForm;

import React from 'react';
import { IProvider } from '../interfaces/ServiceProvider/ServiceProvider';
import { useHistory } from 'react-router-dom';
import Section from '../components/Section';
import ServiceProvidersTable from '../components/ServiceProvidersTable';

interface IProps {
  serviceProviders: IProvider[];
}

const NewServiceProviders: React.FC<IProps> = ({ serviceProviders }) => {
  const history = useHistory();

  const onClick = (serviceProviderId: string) => {
    history.push(`/pending/${serviceProviderId}`);
  };

  return (
    <Section title="Nýjar þjónustur">
      <ServiceProvidersTable
        serviceProviders={serviceProviders}
        onClick={onClick}
      ></ServiceProvidersTable>
    </Section>
  );
};

export default NewServiceProviders;

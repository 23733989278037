import { polygon, Polygon } from '@turf/helpers';
import bbox from '@turf/bbox';
import { ILatLng } from '../common-types';

export function latLng2PolygonGeometry(latLngArray: ILatLng[]): Polygon {
  if (latLngArray.length < 3) {
    return polygon([]).geometry;
  }

  const positions = latLngArray.map(latLng => [latLng.lng, latLng.lat]);
  const first = positions[0];
  const last = positions[positions.length - 1];

  if (!objectLiteralCompare(first, last)) {
    positions.push(first);
  }

  if (positions.length < 4) {
    // Return empty polygon
    return latLng2PolygonGeometry([]);
  }

  return polygon([positions]).geometry;
}

export function polygonGeometry2LatLng(polygonGeometry: Polygon): ILatLng[] {
  const latLng = polygonGeometry.coordinates[0].map(pos => ({
    lat: pos[1],
    lng: pos[0]
  }));

  if (!latLng.length) {
    return [];
  }

  const first = latLng[0];
  const last = latLng[latLng.length - 1];

  if (objectLiteralCompare(first, last)) {
    latLng.pop();
  }

  return latLng;
}

export function getBoundsFromLatLngPath(path: ILatLng[]) {
  const [west, south, east, north] = bbox(latLng2PolygonGeometry(path));
  return { west, south, east, north };
}

function objectLiteralCompare(o1: any, o2: any) {
  return JSON.stringify(o1) === JSON.stringify(o2);
}

import * as Sentry from '@sentry/browser';

const sentryDSN = process.env.REACT_APP_SENTRY_DSN;
const env = process.env.NODE_ENV;
const isProduction = process.env.NODE_ENV === 'production';

export function initializeSentry() {
  if (isProduction) {
    Sentry.init({ dsn: sentryDSN, environment: env });
  }
}

export function captureException(
  error: Error,
  errorInfo?: { [key: string]: any }
) {
  if (isProduction) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo || {});
      Sentry.captureException(error);
    });
  }
}

import React from 'react';
import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core';

interface IProps {
  open: boolean;
  message: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmDialog: React.FC<IProps> = ({
  open,
  message,
  confirmText = 'Staðfesta',
  cancelText = 'Hætta við',
  onConfirm,
  onCancel
}) => {
  return (
    <div>
      <Dialog open={open} onClose={onCancel}>
        <DialogTitle>{message}</DialogTitle>
        <DialogActions>
          <Button onClick={onCancel} color="primary" variant="outlined">
            {cancelText}
          </Button>
          <Button
            onClick={onConfirm}
            color="primary"
            variant="contained"
            autoFocus
          >
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmDialog;

import React from 'react';

import securePage from '../hocs/securePage';
import Layout from '../components/Layout';
import Section from '../components/Section';
import UserForm from '../components/UserForm';

const CreateUser: React.FC = () => {
  return (
    <Layout useDefaultSpacing>
      <Section title="Nýr notandi">
        <UserForm />
      </Section>
    </Layout>
  );
};

export default securePage(CreateUser);

import React from 'react';
import cx from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Navbar from './NavBar';

interface IProps {
  contentClassName?: string;
  useDefaultSpacing?: boolean;
  simpleNav?: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100vh',
    overflowX: 'hidden',
    backgroundColor: '#f4f4f7'
  },
  content: {
    flex: 1
  },
  defaultSpacing: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(8),
    padding: theme.spacing(0, 3),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(0, 2)
    }
  }
}));

const Layout: React.FC<IProps> = ({
  children,
  contentClassName,
  useDefaultSpacing,
  simpleNav
}) => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <Navbar simpleNav={simpleNav} />
      <section
        className={cx(
          classes.content,
          { [classes.defaultSpacing]: useDefaultSpacing },
          contentClassName
        )}
      >
        {children}
      </section>
    </div>
  );
};

export default Layout;

import React, { useState } from 'react';
import {
  makeStyles,
  Typography,
  TextField,
  Button,
  Grid,
  Link
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import useNotifier from '../hooks/useNotifier';

interface IProps {
  initialSSN: string;
  companyName: string;
  submitSSN: (ssn: string) => void;
}

interface IFormValues {
  ssn: string;
}

const useStyles = makeStyles(theme => ({
  input: {
    margin: theme.spacing(4, 0)
  },
  button: {
    padding: 10
  }
}));

const schema = Yup.object().shape({
  ssn: Yup.string()
    .matches(/^[0-9]{10}$/)
    .required()
});

const SSNForm: React.FC<IProps> = ({ initialSSN, companyName, submitSSN }) => {
  const classes = useStyles();
  const notifier = useNotifier();
  const [success, setSuccess] = useState(false);

  return success ? (
    renderSuccess()
  ) : (
    <Formik
      initialValues={{ ssn: initialSSN }}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid
      }) => (
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={8} lg={6}>
              {getInfoText()}
              <TextField
                id="ssn"
                type="text"
                label="Kennitala"
                value={values.ssn}
                error={!!errors.ssn}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                className={classes.input}
                required
                fullWidth
              />
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                disabled={isSubmitting || !isValid}
                className={classes.button}
              >
                Staðfesta
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );

  function renderSuccess() {
    return (
      <Grid container>
        <Grid item xs={12} md={8} lg={6}>
          <Typography>Kennitala uppfærð. Takk fyrir!</Typography>
        </Grid>
      </Grid>
    );
  }

  async function onSubmit(values: IFormValues) {
    try {
      await submitSSN(values.ssn);
      setSuccess(true);
    } catch {
      notifier.notify('Villa kom upp', { variant: 'error' });
    }
  }

  function getInfoText() {
    return (
      <React.Fragment>
        <Typography>
          Ferðaþjónustufyrirtæki munu þurfa að skrá sig til að taka á móti
          ferðaávísun stjórnvalda og er unnið að endanlegri útfærslu þess. Til
          að flýta ferlinu þegar þar að kemur, auðvelda samkeyrslu við
          gagnagrunn Ferðamálastofu og birtingu á{' '}
          <Link href="https://ferdalag.is/" target="_blank" rel="noopener">
            ferdalag.is
          </Link>
          , þurfum við að vera með rétta kennitölu skráða.
        </Typography>
        <br />
        <Typography>
          Vinsamlega fyllið út kennitölu <strong>{companyName}</strong>. Skrifið
          kennitöluna einungis með tölustöfum.
        </Typography>
      </React.Fragment>
    );
  }
};

export default SSNForm;

import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useQuery } from 'react-apollo';

import securePage from '../hocs/securePage';
import { IMunicipalityDetails } from '../interfaces/Municipality/Municipality';
import { MUNICIPALITY_DETAILS } from '../graphql/queries/MunicipalityQueries';
import Layout from '../components/Layout';
import LoadingPage from '../components/LoadingPage';
import Section from '../components/Section';
import MunicipalityForm from '../components/MunicipalityForm';
import { getIcelandicName } from '../utils/locale';

const MunicipalityDetails: React.FC<RouteComponentProps<{ id: string }>> = ({
  match
}) => {
  const { loading, error, data } = useQuery<IMunicipalityDetails>(
    MUNICIPALITY_DETAILS,
    {
      variables: { municipalityId: match.params.id }
    }
  );
  const municipality = data ? data.Municipality : null;

  if (loading || error) {
    return <LoadingPage error={error ? error.message : ''} />;
  }

  return (
    <Layout useDefaultSpacing>
      <Section title={getIcelandicName(municipality.translations)}>
        <MunicipalityForm municipality={municipality} newMunicipality={false} />
      </Section>
    </Layout>
  );
};

export default securePage(MunicipalityDetails);

import * as XLSX from 'xlsx';
import moment from 'moment';

import { IProviderDetails } from '../interfaces/ServiceProvider/ServiceProvider';
import {
  getIcelandicName,
  getEnglishName,
  getIcelandicTranslation,
  getEnglishTranslation
} from './locale';
import { IPlace } from '../interfaces/Place/Place';

export interface IExportProviderStatus {
  isExporting: boolean;
  itemsToFetch: number;
  providers: IProviderDetails[];
  filter: object;
  sort: object;
  active: boolean | undefined;
  approved: boolean;
}
export interface IExportPlaceStatus {
  isExporting: boolean;
  itemsToFetch: number;
  places: IPlace[];
  filter: object;
  sort: object;
  active: boolean | undefined;
}

const spFields: Array<{
  label: string;
  getInfo: (sp: IProviderDetails) => string;
}> = [
    { label: 'Id', getInfo: sp => sp.serviceProviderId },
    { label: 'Lögheiti', getInfo: sp => sp.legalName },
    { label: 'Markaðsheiti', getInfo: sp => getIcelandicName(sp.translations) },
    { label: 'Markaðsheiti en', getInfo: sp => getEnglishName(sp.translations) },
    {
      label: 'Textalýsing íslensku',
      getInfo: sp => {
        var translation = getIcelandicTranslation(sp.translations);
        if (translation === null) {
          return "";
        }
        return translation.description;
      }
    },
    {
      label: 'Textalýsing ensku',
      getInfo: sp => {
        const englishTranslations = getEnglishTranslation(sp.translations);
        if (englishTranslations) {
          return englishTranslations.description;
        }
        return '';
      }
    },
    { label: 'Kennitala', getInfo: sp => sp.SSN },
    { label: 'Heimilisfang', getInfo: sp => sp.address },
    { label: 'Pósthólf', getInfo: sp => sp.poBox },
    { label: 'Póstnúmer', getInfo: sp => sp.zipCode },
    { label: 'Sveitafélagsnúmer', getInfo: sp => sp.townCode },
    { label: 'Í birtingu', getInfo: sp => (sp.active ? 'Já' : 'Nei') },
    {
      label: 'Landshluti',
      getInfo: sp =>
        (sp.regions || []).map(r => getIcelandicName(r.translations)).join(', ')
    },
    {
      label: 'Keypt skráning',
      getInfo: sp =>
        (sp.purchasedRegistration || [])
          .map(pr => getIcelandicName(pr.translations))
          .join(', ')
    },
    { label: 'Netfang eiganda', getInfo: sp => sp.ownerEmail },
    {
      label: 'Síðast breytt',
      getInfo: sp =>
        sp.updatedAt ? moment(sp.updatedAt).format('DD/MM/YYYY - HH:mm') : ''
    },
    { label: 'Breytt af', getInfo: sp => sp.updatedBy },
    {
      label: 'Breiddargráða',
      getInfo: sp => (sp.location ? sp.location.coordinates[1].toString() : '')
    },
    {
      label: 'Lengdargráða',
      getInfo: sp => (sp.location ? sp.location.coordinates[0].toString() : '')
    },
    { label: 'Netfang', getInfo: sp => sp.email },
    { label: 'Netfang 2', getInfo: sp => sp.email2 },
    { label: 'Símanúmer', getInfo: sp => sp.phoneNr },
    { label: 'Símanúmer 2', getInfo: sp => sp.phoneNr2 },
    { label: 'Vefsíða', getInfo: sp => sp.website },
    { label: 'Vefsíða 2', getInfo: sp => sp.website2 },
    { label: 'Facebook', getInfo: sp => sp.facebook },
    { label: 'Instagram', getInfo: sp => sp.instagram },
    { label: 'Tripadvisor', getInfo: sp => sp.tripadvisor },
    { label: 'Youtube', getInfo: sp => sp.youtube },
    {
      label: 'Tengiliður',
      getInfo: sp => (sp.contactInfo ? sp.contactInfo.name : '')
    },
    {
      label: 'Netfang tengiliðs',
      getInfo: sp => (sp.contactInfo ? sp.contactInfo.email : '')
    },
    {
      label: 'Sími tengiliðs',
      getInfo: sp => (sp.contactInfo ? sp.contactInfo.phone : '')
    },
    {
      label: 'Athugasemd',
      getInfo: sp => (sp.contactInfo ? sp.contactInfo.comment : '')
    },
    {
      label: 'Eingöngu rafræn þjónusta',
      getInfo: sp => (sp.eService ? 'Já' : 'Nei')
    },
    {
      label: 'Árstíðarbundið',
      getInfo: sp => (sp.openingHours && sp.openingHours.seasonal ? 'Já' : 'Nei')
    },
    {
      label: 'Frá',
      getInfo: sp =>
        sp.openingHours && sp.openingHours.from
          ? moment(sp.openingHours.from).format('DD/MM')
          : ''
    },
    {
      label: 'Til',
      getInfo: sp =>
        sp.openingHours && sp.openingHours.to
          ? moment(sp.openingHours.to).format('DD/MM')
          : ''
    },
    {
      label: 'Yfirflokkar',
      getInfo: sp =>
        sp.categories
          ? sp.categories
            .map(cat => getIcelandicName(cat.translations))
            .sort((cat1, cat2) => cat1.localeCompare(cat2))
            .join(', ')
          : ''
    },
    {
      label: 'Undirflokkar',
      getInfo: sp => {
        const subCategories: string[] = [];
        (sp.categories || []).forEach(c =>
          (c.subCategories || []).forEach(sc =>
            subCategories.push(getIcelandicName(sc.translations))
          )
        );
        return subCategories
          .sort((sub1, sub2) => sub1.localeCompare(sub2))
          .join(', ');
      }
    },
    {
      label: 'Tákn',
      getInfo: sp =>
        (sp.signs || []).map(s => getIcelandicName(s.translations)).join(', ')
    },
    {
      label: 'Fjöldi húsa',
      getInfo: sp => (sp.nrOfHouses ? sp.nrOfHouses.toString() : '')
    },
    {
      label: 'Fjöldi íbúða',
      getInfo: sp => (sp.nrOfApartments ? sp.nrOfApartments.toString() : '')
    },
    {
      label: 'Fjöldi herbergja',
      getInfo: sp => (sp.nrOfRooms ? sp.nrOfRooms.toString() : '')
    },
    {
      label: 'Fjöldi rúma',
      getInfo: sp => (sp.nrOfBeds ? sp.nrOfBeds.toString() : '')
    },
    {
      label: 'Er viðurkenndur veitingastaður',
      getInfo: sp => (sp.vakinn && sp.vakinn.isApprovedRestaurant ? 'Já' : 'Nei')
    },
    {
      label: 'Er viðurkennd ferðaþjónusta',
      getInfo: sp =>
        sp.vakinn && sp.vakinn.isApprovedTourismService ? 'Já' : 'Nei'
    },
    {
      label: 'Umhverfismerki',
      getInfo: sp => (sp.vakinn ? sp.vakinn.ecoLabel : '')
    },
    {
      label: 'Stjörnur',
      getInfo: sp =>
        sp.vakinn && sp.vakinn.stars ? sp.vakinn.stars.toString() : ''
    },
    {
      label: 'Superior',
      getInfo: sp => (sp.vakinn && sp.vakinn.superior ? 'Já' : 'Nei')
    },
    {
      label: 'Gæðavottað hostel',
      getInfo: sp => (sp.vakinn && sp.vakinn.iso ? 'Já' : 'Nei')
    },
    {
      label: 'Gæðavottað orlofshús- íbúð',
      getInfo: sp => (sp.vakinn && sp.vakinn.swan ? 'Já' : 'Nei')
    },
    {
      label: 'Gæðavottað tjaldsvæði',
      getInfo: sp => (sp.vakinn && sp.vakinn.blueFlag ? 'Já' : 'Nei')
    },
    {
      label: 'Gæðavottað gistiheimili',
      getInfo: sp => (sp.vakinn && sp.vakinn.earthcheck ? 'Já' : 'Nei')
    },
    {
      label: 'Gæðavottuð heimagisting',
      getInfo: sp => (sp.vakinn && sp.vakinn.greenKey ? 'Já' : 'Nei')
    },
    {
      label: 'Gott aðgengi',
      getInfo: sp => (sp.accessible ? 'Já' : 'Nei')
    },
    {
      label: 'Uppfært á árinu',
      getInfo: sp => updatedThisYear(sp)
    },
    {
      label: "Staðfest á árinu",
      getInfo: sp => sp.recentlyValidated ? "Já" : "Nei"
    },
    {
      label: 'Hleðlustöð',
      getInfo: sp => sp.chargingStationIds && sp.chargingStationIds.length > 0 ? 'Já' : 'Nei'
    }
  ];

function updatedThisYear(sp) {
  const date = moment(sp.updatedAt);
  const now = moment();
  const diff = now.diff(date, 'day');
  if (diff < 366) {
    return "Já";
  }
  return "Nei";
}

export async function exportServiceProviders(
  serviceProviders: IProviderDetails[]
) {
  const currentTime = moment(Date.now()).format('DD.MM.YYYY - HH.mm.ss');
  const spData = serviceProviders.map(sp => spFields.map(f => f.getInfo(sp)));
  const wb = XLSX.utils.book_new();
  const wsAll = XLSX.utils.aoa_to_sheet([
    spFields.map(f => f.label),
    ...spData
  ]);
  XLSX.utils.book_append_sheet(wb, wsAll);
  XLSX.writeFile(wb, `Service Providers ${currentTime}.xlsx`);
}

const placeFields: Array<{ label: string; getInfo: (p: IPlace) => string }> = [
  {
    label: 'Id',
    getInfo: p => p.placeId
  },
  {
    label: 'Nafn',
    getInfo: p => getIcelandicName(p.translations)
  },
  {
    label: 'Nafn en',
    getInfo: p => getEnglishName(p.translations)
  },
  {
    label: 'Póstnúmer',
    getInfo: p => p.zipCode
  },
  {
    label: 'Sveitafélagsnúmer',
    getInfo: p => p.townCode
  },
  {
    label: 'Vegnúmer',
    getInfo: p => p.roadCode
  },
  {
    label: 'Einkunn',
    getInfo: p => p.grade.toString()
  },
  {
    label: 'Í birtingu',
    getInfo: p => (p.active ? 'Já' : 'Nei')
  },
  {
    label: 'Landshluti',
    getInfo: p => getIcelandicName(p.region.translations)
  },
  {
    label: 'Síðast breytt',
    getInfo: p =>
      p.updatedAt ? moment(p.updatedAt).format('DD/MM/YYYY - HH:mm') : ''
  },
  { label: 'Breytt af', getInfo: p => p.updatedBy },
  {
    label: 'Breiddargráða',
    getInfo: p => (p.location ? p.location.coordinates[1].toString() : '')
  },
  {
    label: 'Lengdargráða',
    getInfo: p => (p.location ? p.location.coordinates[0].toString() : '')
  },
  {
    label: 'Flokkar',
    getInfo: p =>
      p.categories.map(cat => getIcelandicName(cat.translations)).join(', ')
  },
  {
    label: 'Tengdir staðir',
    getInfo: p =>
      p.relatedPlaces.map(r => getIcelandicName(r.translations)).join(', ')
  },
  {
    label: 'Tengd sveitarfélög',
    getInfo: p =>
      p.relatedMunicipalities
        .map(r => getIcelandicName(r.translations))
        .join(', ')
  }
];

export async function exportPlaces(places: IPlace[]) {
  const currentTime = moment(Date.now()).format('DD.MM.YYYY - HH.mm.ss');
  const placeData = places.map(p => placeFields.map(field => field.getInfo(p)));

  const wb = XLSX.utils.book_new();
  const wsAll = XLSX.utils.aoa_to_sheet([
    placeFields.map(p => p.label),
    ...placeData
  ]);
  XLSX.utils.book_append_sheet(wb, wsAll);
  XLSX.writeFile(wb, `Places ${currentTime}.xlsx`);
}

import React, { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from 'react-apollo';
import {
  makeStyles,
  Grid,
  TextField,
  Button,
  Tabs,
  Tab,
  Typography,
  Box
} from '@material-ui/core';

import securePage from '../hocs/securePage';
import { IUser, IUsers, IUsersInRole } from '../interfaces/User/User';
import { USER_BY_ROLE, USERS } from '../graphql/queries/UserQueries';
import Layout from '../components/Layout';
import Section from '../components/Section';
import UserTable from '../components/UserTable';
import { TABLE_ROWS_DEFAULT } from '../constants';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const useStyles = makeStyles(theme => ({
  searchArea: {
    margin: theme.spacing(0),
    width: '100%'
  },
  button: {
    padding: theme.spacing(2, 3)
  }
}));

const Users: React.FC = () => {
  const classes = useStyles();
  const [searchField, setSearchField] = useState('');
  const [searching, setSearching] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [tabValue, setTabValue] = useState(0);

  const [searchPage, setSearchPage] = useState(0);
  const [searchUsersPerPage, setSearchUsersPerPage] = useState(
    TABLE_ROWS_DEFAULT
  );

  const [adminPage, setAdminPage] = useState(0);
  const [adminPerPage, setAdminPerPage] = useState(TABLE_ROWS_DEFAULT);

  const [editorPage, setEditorPage] = useState(0);
  const [editorPerPage, setEditorPerPage] = useState(10);

  const [userPage, setUserPage] = useState(0);
  const [userPerPage, setUserPerPage] = useState(TABLE_ROWS_DEFAULT);

  const [searchUsers, { data, loading, error }] = useLazyQuery<IUsers>(USERS, {
    variables: { name: searchText }
  });

  const [
    getAdmins,
    { data: adminsData, loading: adminLoading, error: adminError }
  ] = useLazyQuery<IUsersInRole>(USER_BY_ROLE, {
    variables: {
      roleId: 'rol_fM3C9cz77O34VImv',
      page: adminPage,
      per_page: adminPerPage
    }
  });
  const [
    getEditors,
    { data: editorsData, loading: editorsLoading, error: editorError }
  ] = useLazyQuery<IUsersInRole>(USER_BY_ROLE, {
    variables: {
      roleId: 'rol_AfNP27KHmmzIKLiD',
      page: editorPage,
      per_page: editorPerPage
    }
  });
  const [
    getUsers,
    { data: usersData, loading: usersLoading, error: userError }
  ] = useLazyQuery<IUsersInRole>(USER_BY_ROLE, {
    variables: {
      roleId: 'rol_OdoldjlS6WEDUr11',
      page: userPage,
      per_page: userPerPage
    }
  });

  const defaultUserOBject: {
    users: IUser[];
    role: string;
    total: number;
    limit: number;
    start: number;
  } = { users: [], total: 0, limit: 0, start: 0, role: '' };
  const { users: admins, total: adminTotal, role: adminRole } = adminsData
    ? adminsData.UsersInRole
    : defaultUserOBject;
  const { users: editors, total: editorTotal, role: editorRole } = editorsData
    ? editorsData.UsersInRole
    : defaultUserOBject;
  const { users, total: userTotal, role: userRole } = usersData
    ? usersData.UsersInRole
    : defaultUserOBject;

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(getAdmins, []);

  return (
    <Layout useDefaultSpacing>
      <Section
        title="Notendur"
        link="/user/new"
        linkText="Skrá nýjan notanda"
        //loading={adminLoading || editorsLoading || usersLoading}
        //error={error ? error.message : ''}
      >
        {renderSearchArea()}
        {searching ? (
          <UserTable
            users={data && data.Users ? data.Users : []}
            totalUsers={data && data.Users ? data.Users.length : 0}
            loading={loading}
            currentPage={searchPage}
            rowsPerPage={searchUsersPerPage}
            setCurrentPage={setSearchPage}
            setRowsPerPage={setSearchUsersPerPage}
          />
        ) : (
          <React.Fragment>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="fullWidth"
              scrollButtons="auto"
              aria-label="full width tabs example"
            >
              <Tab
                label="Admins"
                onClick={() => admins.length < 1 && getAdmins()}
              />
              <Tab
                label="Editors"
                onClick={() => editors.length < 1 && getEditors()}
              />
              <Tab
                label="Users"
                onClick={() => users.length < 1 && getUsers()}
              />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <UserTable
                users={admins.map(user => ({
                  ...user,
                  roles: [{ id: '', name: adminRole, description: '' }]
                }))}
                totalUsers={adminTotal}
                loading={adminLoading}
                currentPage={adminPage}
                rowsPerPage={adminPerPage}
                setCurrentPage={setAdminPage}
                setRowsPerPage={setAdminPerPage}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <UserTable
                users={editors.map(user => ({
                  ...user,
                  roles: [{ id: '', name: editorRole, description: '' }]
                }))}
                totalUsers={editorTotal}
                loading={editorsLoading}
                currentPage={editorPage}
                rowsPerPage={editorPerPage}
                setCurrentPage={setEditorPage}
                setRowsPerPage={setEditorPerPage}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <UserTable
                users={users.map(user => ({
                  ...user,
                  roles: [{ id: '', name: userRole, description: '' }]
                }))}
                totalUsers={userTotal}
                loading={usersLoading}
                currentPage={userPage}
                rowsPerPage={userPerPage}
                setCurrentPage={setUserPage}
                setRowsPerPage={setUserPerPage}
              />
            </TabPanel>
          </React.Fragment>
        )}
      </Section>
    </Layout>
  );

  function renderSearchArea() {
    return (
      <Grid
        container
        spacing={2}
        className={classes.searchArea}
        alignItems="center"
        justify="space-between"
      >
        <Grid item xs={12} md={4} lg={3}>
          <TextField
            id="search"
            label="Leit"
            value={searchField}
            variant="outlined"
            onChange={e => setSearchField(e.target.value)}
            onKeyDown={e => e.key === 'Enter' && search()}
            fullWidth
          />
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={search}
                className={classes.button}
              >
                Leita
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                onClick={clearSearch}
                className={classes.button}
              >
                Hreinsa leit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function search() {
    setSearching(true);
    setSearchText(searchField);
    searchUsers();
  }

  function clearSearch() {
    setSearchField('');
    setSearchText('');
    setSearching(false);
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={0}>{children}</Box>}
      </Typography>
    );
  }
};

export default securePage(Users);

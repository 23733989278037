import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import axios from 'axios';
import cx from 'classnames';
import React, { useState } from 'react';

import GoogleMap, { IMapMouseEvent, Marker } from './GoogleMap';

const OSM_BASE_URL = 'https://nominatim.openstreetmap.org/search';
const OSM_PARAMS = 'countrycodes=is&format=json';

interface IProps {
  location: ILocation;
  type: 'latitude' | 'longitude';
  label: string;
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
  initialSearch?: string;
  onChange: (location: ILocation) => void;
  endAdornment?: JSX.Element;
}

interface ILocation {
  latitude: string;
  longitude: string;
}

interface ISearchResult {
  place_id: number;
  lat: string;
  lon: string;
  display_name: string;
}

const useStyles = makeStyles(theme => ({
  input: {
    cursor: 'pointer'
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  searchInput: {
    flex: 1,
    marginRight: theme.spacing(2)
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  results: {
    flex: 1,
    padding: theme.spacing(2, 1.5, 2, 3),
    boxSizing: 'content-box',
    [theme.breakpoints.up('md')]: {
      height: 400,
      overflowY: 'scroll'
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 3, 0, 3)
    }
  },
  mapContainer: {
    flex: 1,
    height: 400,
    boxSizing: 'content-box',
    padding: theme.spacing(2, 3, 2, 1.5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3)
    }
  },
  resultItem: {
    padding: theme.spacing(1.5, 2),
    borderRadius: 4,
    border: `1px solid ${theme.palette.grey[300]}`,
    cursor: 'pointer',
    marginBottom: theme.spacing(1),
    backgroundColor: 'white'
  },
  selected: {
    backgroundColor: theme.palette.grey[200]
  },
  itemTitle: {
    fontWeight: theme.typography.fontWeightMedium
  },
  cancelButton: {
    marginRight: theme.spacing(1)
  }
}));

const LocationInput: React.FC<IProps> = ({
  location,
  type,
  label,
  error,
  required,
  disabled,
  initialSearch,
  onChange,
  endAdornment
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  const [dialogOpen, setDialogOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [latText, setLatText] = useState('');
  const [lonText, setLonText] = useState('');
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [searchResults, setSearchResults] = useState<ISearchResult[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [moveLat, setMoveLat] = useState<number | null>(null);
  const [moveLon, setMoveLon] = useState<number | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const inputColor = !disabled && !error ? 'rgba(0, 0, 0, 0.65)' : undefined;
  const latNum = getFloatFromString(latText);
  const lonNum = getFloatFromString(lonText);

  return (
    <React.Fragment>
      <TextField
        id={type}
        label={label}
        value={location[type]}
        error={error}
        variant="outlined"
        required={required}
        onClick={() => !disabled && setDialogOpen(true)}
        inputProps={{
          className: classes.input,
          style: { color: inputColor }
        }}
        InputProps={{
          endAdornment: endAdornment
        }}
        InputLabelProps={{ style: { color: inputColor } }}
        disabled={disabled}
        fullWidth
      />
      {renderDialog()}
    </React.Fragment>
  );

  function renderDialog() {
    return (
      <Dialog
        open={dialogOpen}
        onClose={onCancel}
        onEnter={onOpen}
        maxWidth="md"
        fullWidth
        fullScreen={xs}
      >
        <DialogTitle>{renderDialogTitleArea()}</DialogTitle>
        <DialogContent dividers style={{ padding: 0 }}>
          <div className={classes.content}>
            <div className={classes.results}>{renderSearchResults()}</div>
            <div className={classes.mapContainer}>
              {latNum && lonNum ? (
                <GoogleMap
                  center={{ lat: latNum, lng: lonNum }}
                  zoom={8}
                  draggable={!isDragging}
                  onChildMouseDown={() => setIsDragging(true)}
                  onChildMouseUp={onDragEnd}
                  onChildMouseMove={(_key, _props, mouse) => {
                    setMoveLat(mouse.lat);
                    setMoveLon(mouse.lng);
                  }}
                >
                  <Marker
                    lat={moveLat || latNum}
                    lng={moveLon || lonNum}
                    isDragging={isDragging}
                  />
                </GoogleMap>
              ) : (
                <GoogleMap
                  onClick={mouse => {
                    setLatText(mouse.lat.toString());
                    setLonText(mouse.lng.toString());
                  }}
                />
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} className={classes.cancelButton}>
            Hætta við
          </Button>
          <Button variant="contained" color="primary" onClick={onConfirm}>
            Staðfesta
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  function renderDialogTitleArea() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.searchContainer}>
          <TextField
            id="search"
            label="Leit"
            variant="outlined"
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            onKeyDown={e => e.key === 'Enter' && searchForPlaces()}
            className={classes.searchInput}
            fullWidth
            autoFocus
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => searchForPlaces()}
          >
            Leita
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="latitude-edit"
            label="Breiddargráða"
            value={latText}
            onChange={e => setLatText(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="longitude-edit"
            label="Lengdargráða"
            value={lonText}
            onChange={e => setLonText(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }

  function renderSearchResults() {
    if (loading) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: '100%' }}
        >
          <CircularProgress size={50} />
        </Grid>
      );
    }

    if (!searchResults.length) {
      return (
        <Typography color="textSecondary">
          {hasSearched ? 'Engar niðurstöður' : 'Leitarniðurstöður'}
        </Typography>
      );
    }

    return (
      <Grid container direction="column">
        {searchResults.map(renderResultItem)}
      </Grid>
    );
  }

  function renderResultItem(item: ISearchResult, index: number) {
    return (
      <Grid
        item
        key={item.place_id}
        className={cx(classes.resultItem, {
          [classes.selected]: index === selectedIndex
        })}
        onClick={() => onSelect(index)}
      >
        <Typography className={classes.itemTitle}>
          {item.display_name}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {item.lat}, {item.lon}
        </Typography>
      </Grid>
    );
  }

  function getFloatFromString(str: string) {
    const val = parseFloat(str);
    return !isNaN(val) ? val : undefined;
  }

  async function searchForPlaces(query?: string) {
    setLoading(true);
    const search = query || searchText;
    const res = await axios.get(`${OSM_BASE_URL}?q=${search}&${OSM_PARAMS}`);
    setSearchResults(res.data);
    setHasSearched(true);
    setLoading(false);
  }

  function onSelect(index: number) {
    setSelectedIndex(index);
    const selected = searchResults[index];
    if (selected) {
      setLatText(selected.lat);
      setLonText(selected.lon);
    }
  }

  function onConfirm() {
    onChange({ latitude: latText, longitude: lonText });
    onCancel();
  }

  function onOpen() {
    if (initialSearch) {
      setSearchText(initialSearch);
      searchForPlaces(initialSearch);
    }
    setLatText(location.latitude);
    setLonText(location.longitude);
  }

  function onCancel() {
    setDialogOpen(false);
    setSearchText('');
    setLatText('');
    setLonText('');
    setSelectedIndex(null);
    setSearchResults([]);
    setHasSearched(false);
  }

  function onDragEnd(_key: any, _props: any, mouse: IMapMouseEvent) {
    setLatText(mouse.lat.toString());
    setLonText(mouse.lng.toString());
    setMoveLat(null);
    setMoveLon(null);
    setIsDragging(false);
  }
};

export default LocationInput;

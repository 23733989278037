import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { login, logout } from '../store/auth/actions';

function useAuthActions() {
  const dispatch = useDispatch();

  return {
    login: useCallback(() => dispatch(login()), [dispatch]),
    logout: useCallback(() => dispatch(logout()), [dispatch])
  };
}

export default useAuthActions;

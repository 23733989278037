import React, { useState, useEffect } from 'react';
import GoogleMap, { Polygon, DrawingManager } from '../components/GoogleMap';
import { TBounds } from '../components/GoogleMap/types';
import { ILatLng } from '../common-types';
import { getBoundsFromLatLngPath } from '../utils/geo';

interface IProps {
  initialPath?: ILatLng[];
  isAdmin: boolean;
  onPathChanged?: (coordinates: ILatLng[]) => void;
}

const polygonStyle = {
  strokeColor: '#FF0000',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#FF0000',
  fillOpacity: 0.35
};

const GeofenceEditorMap: React.FC<IProps> = ({
  isAdmin,
  initialPath = [],
  onPathChanged
}) => {
  const [geofencePath, setGeofencePath] = useState(initialPath);
  const [bounds, setBounds] = useState<TBounds | null>(null);

  useEffect(() => {
    if (initialPath.length) {
      setBounds(getBoundsFromLatLngPath(initialPath));
    }
  }, [initialPath]);

  const drawingManagerElement = (
    <DrawingManager
      polygonStyle={polygonStyle}
      onPolygonComplete={handlePolygonComplete}
    />
  );
  const polygonElement = (
    <Polygon
      polygonStyle={polygonStyle}
      editable={!isAdmin}
      draggable={!isAdmin}
      path={geofencePath}
      onPathChange={handlePathChange}
    />
  );

  return (
    <div style={{ height: '650px', width: '100%' }}>
      <GoogleMap bounds={bounds}>
        {!geofencePath.length && drawingManagerElement}
        {geofencePath.length && polygonElement}
      </GoogleMap>
    </div>
  );

  function handlePathChange(path: ILatLng[]) {
    if (onPathChanged) {
      onPathChanged(path);
    }

    if (!path.length) {
      setGeofencePath([]);
    }
  }

  function handlePolygonComplete(path: ILatLng[]) {
    setGeofencePath(path);
    setBounds(getBoundsFromLatLngPath(path));

    if (onPathChanged) {
      onPathChanged(path);
    }
  }
};

export default GeofenceEditorMap;

import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import securePage from '../hocs/securePage';
import { useHistory } from 'react-router-dom';

import EditServiceProvider from '../components/EditServiceProvider';
import Layout from '../components/Layout';
import moment from 'moment';
import { diff } from 'deep-diff';
import { useQuery } from 'react-apollo';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import {
  IServiceProvider,
  IProvider,
  IUpdateProvider,
  IPhoto
} from '../interfaces/ServiceProvider/ServiceProvider';
import { SERVICE_PROVIDER } from '../graphql/queries/ServiceProviderQueries';
import { Grid, CircularProgress, Typography } from '@material-ui/core';
import keys from 'lodash/keys';
import {
  UPDATE_SERVICE_PROVIDER,
  REJECT_CHANGES_SERVICE_PROVIDER,
  ACCEPT_CHANGES_SERVICE_PROVIDER,
  REMOVE_SERVICE_PROVIDER,
  VALIDATE_SERVICE_PROVIDER
} from '../graphql/mutations/serviceProvider';
import { ISign } from '../interfaces/ServiceProvider/ServiceProvider';
import useNotifier from '../hooks/useNotifier';
import TextFieldDialog from '../components/TextFieldDialog';

const ServiceProviderDetails: React.FC<RouteComponentProps<{
  id: string;
}>> = ({ match, location }) => {
  const serviceProviderId = match.params.id;
  const pending = location.search === '?pending=true';
  const [updateServiceProvider] = useMutation(UPDATE_SERVICE_PROVIDER, {
    refetchQueries: [`getServiceProvider`]
  });
  /*   const [updateServiceProviderRefetch] = useMutation(UPDATE_SERVICE_PROVIDER, {
    refetchQueries: [`getServiceProvider`]
  }); */
  const [acceptChanges] = useMutation(ACCEPT_CHANGES_SERVICE_PROVIDER, {
    refetchQueries: [`pendingServiceProviders`]
  });
  const [declineChanges] = useMutation(REJECT_CHANGES_SERVICE_PROVIDER, {
    refetchQueries: [`pendingServiceProviders`]
  });
  const [removeProvider] = useMutation(REMOVE_SERVICE_PROVIDER);
  const [validateProvider] = useMutation(VALIDATE_SERVICE_PROVIDER);

  const apolloClient = useApolloClient();
  const [currentVersion, setCurrentVersion] = useState<null | IProvider>(null);
  const [latestVersion, setLatestVersion] = useState<null | IProvider>(null);
  const [selectedVersion, setSelectedVersion] = useState<null | IProvider>(
    null
  );
  const [showDialog, setShowDialog] = useState(false);
  const notifier = useNotifier();
  const history = useHistory();

  const { loading, error, data } = useQuery<IServiceProvider>(
    SERVICE_PROVIDER,
    {
      variables: { serviceProviderId }
    }
  );
  const handleFormSubmit = (values: any, signs: ISign[]) => {
    const newServiceProvider: IUpdateProvider = {
      SSN: values.SSN,
      legalName: values.legalName,
      address: values.address,
      poBox: values.poBox,
      zipCode: values.zipCode ? values.zipCode.value : "",
      translations: values.translations,
      townCode: values.townCode ? values.townCode.value : null,
      eService: values.eService === 'true' ? true : false,
      phoneNr: values.phoneNr,
      phoneNr2: values.phoneNr2 === '' ? null : values.phoneNr2,
      email: values.email,
      email2: values.email2 === '' ? null : values.email2,
      website: values.website === '' ? null : values.website,
      website2: values.website2 === '' ? null : values.website2,
      facebook: values.facebook === '' ? null : values.facebook,
      instagram: values.instagram === '' ? null : values.instagram,
      tripadvisor: values.tripadvisor === '' ? null : values.tripadvisor,
      youtube: values.youtube === '' ? null : values.youtube,
      giftcert: values.giftcert === 'true' ? true : false,
      cleanAndSafe: values.cleanAndSafe === 'true' ? true : false,
      accessible: values.accessible ? values.accessible.toUpperCase() : null,
      isat: values.isat === '' ? null : values.isat,
      nrOfRooms: values.nrOfRooms === '' ? null : parseInt(values.nrOfRooms),
      nrOfBeds: values.nrOfBeds === '' ? null : parseInt(values.nrOfBeds),
      nrOfApartments:
        values.nrOfApartments === '' ? null : parseInt(values.nrOfApartments),
      nrOfHouses: values.nrOfHouses === '' ? null : parseInt(values.nrOfHouses),
      nrOfSleepingBagSpace:
        values.nrOfSleepingBagSpace === ''
          ? null
          : parseInt(values.nrOfSleepingBagSpace),
      vakinn: {
        isApprovedTourismService:
          values.isApprovedTourismService === 'true' ? true : false,
        isApprovedRestaurant:
          values.isApprovedRestaurant === 'true' ? true : false,
        stars: values.stars === '' ? null : parseInt(values.stars),
        ecoLabel: values.ecoLabel ? values.ecoLabel.toUpperCase() : null,
        superior: values.superior === 'true' ? true : false,
        iso: values.iso === 'true' ? true : false,
        swan: values.swan === 'true' ? true : false,
        blueFlag: values.blueFlag === 'true' ? true : false,
        earthcheck: values.earthcheck === 'true' ? true : false,
        greenKey: values.greenKey === 'true' ? true : false
      },
      signItems: signs.map(s => ({ id: s.id, value: s.value })),
      active: values.active,
      ownerEmail: values.ownerEmail,
      openingHours: {
        seasonal: values.seasonal === 'true' ? true : false,
        from: values.from === null ? null : moment(values.from).toISOString(),
        to: values.to === null ? null : moment(values.to).toISOString()
      },
      contactInfo: {
        name: values.contactName === '' ? null : values.contactName,
        email: values.contactEmail === '' ? null : values.contactEmail,
        phone: values.contactPhone === '' ? null : values.contactPhone,
        comment: values.contactComment === '' ? null : values.contactComment
      },
      content: data.ServiceProvider.content,
      categoryIds: keys(values.categories).filter(
        key => !!values.categories[key]
      ),
      serviceProviderIds: values.serviceProviderIds.map(
        provider => provider.value
      ),
      purchasedRegionIds: values.purhcasedRegistration.map(
        region => region.value
      ),
      photos: values.photos.map((photo: IPhoto) => ({
        alt: photo.alt,
        title: photo.title,
        photoId: photo.photoId
      })),
      regionIds: values.regions.map(region => region.value),
      placeIds: values.places.map(place => place.value),
      location:
        values.latitude && values.longitude
          ? {
            type: 'Point',
            coordinates: [
              parseFloat(values.longitude),
              parseFloat(values.latitude)
            ]
          }
          : undefined,
      permitId: values.permitId,
      permitIssued: values.permitIssued,
      aliases: values.aliases,
      permitImgUri: values.permitImgUri,
      chargingStationIds: values.chargingStationIds.map(v => v.value)
    };

    const differences: any = diff(data.ServiceProvider, newServiceProvider);
    const filterDiffrences = differences.filter(
      diff =>
        diff.kind === 'E' ||
        (diff.kind === 'N' && diff.path[0] === 'serviceProviderIds')
    );
    if (filterDiffrences.length > 0) {
      updateServiceProvider({
        variables: { serviceProviderId, values: newServiceProvider }
      })
        .then(() => {
          /*           apolloClient.resetStore();
           */ notifier.notify('Ferðaþjónusta uppfærð', { variant: 'success' });
          history.push(`/service-providers/${serviceProviderId}`);
          //history.push(`/service-providers`);
        })
        .catch(e => {
          notifier.notify('Villa kom upp', { variant: 'error' });
        });
    }
  };

  /*   const updateTranslations = async (translations: ITranslation[]) => {
    await updateServiceProviderRefetch({
      variables: {
        serviceProviderId,
        values: { translations }
      }
    });
  }; */

  const rejectServiceProvider = async () => {
    setShowDialog(true);
  };

  const rejectServiceProviderConfirm = async (message: string) => {
    await declineChanges({
      variables: {
        serviceProviderId,
        title: `Breytingum þínum á þjónustuna ${currentVersion.legalName} var hafnað`,
        message
      }
    })
      .then(() => {
        setShowDialog(false);
        notifier.notify('Breytingum hafnað', { variant: 'success' });
        apolloClient.resetStore();
        history.push(`/service-providers/${serviceProviderId}`);
      })
      .catch(e => {
        notifier.notify('Villa kom upp', { variant: 'error' });
      });
  };

  const confirmServiceProvider = async () => {
    await acceptChanges({ variables: { serviceProviderId } })
      .then(() => {
        notifier.notify('Breytingar samþykktar', { variant: 'success' });
        // apolloClient.resetStore();
        history.push(`/service-providers/${serviceProviderId}`);
      })
      .catch(e => {
        notifier.notify('Villa kom upp', { variant: 'error' });
      });
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  const handleVersionChange = latest => {
    if (latest) {
      setSelectedVersion(currentVersion);
    } else {
      setSelectedVersion(latestVersion);
    }
  };

  const handleServiceProviderDelete = async () => {
    try {
      await removeProvider({ variables: { serviceProviderId } });
      notifier.notify('Þjónustu eytt', { variant: 'success' });
      history.push('/service-providers');
    } catch {
      notifier.notify('Villa kom upp', { variant: 'error' });
    }
  };

  const handleServiceProviderValidate = async () => {
    try {
      await validateProvider({ variables: { serviceProviderId } });
      notifier.notify('Þjónusta staðfest', { variant: 'success' });
      history.push('/service-providers');
    } catch {
      notifier.notify('Villa kom upp', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (data) {
      if (pending && data.ServiceProvider.pendingRevision) {
        setCurrentVersion(data.ServiceProvider.pendingRevision);
        setSelectedVersion(data.ServiceProvider.pendingRevision);
        setLatestVersion(data.ServiceProvider);
      } else {
        setCurrentVersion(data.ServiceProvider);
        setSelectedVersion(data.ServiceProvider);
        if (data.ServiceProvider.revision) {
          setLatestVersion(data.ServiceProvider.revision);
        }
      }
    }
    // TODO: fix warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) {
    return (
      <Grid container direction="column" justify="center" alignContent="center">
        <CircularProgress size={100} />
      </Grid>
    );
  }
  if (error) {
    return <Typography variant="h2">{error.message}</Typography>;
  }

  if (selectedVersion) {
    return (
      <Layout useDefaultSpacing>
        <EditServiceProvider
          serviceProviderId={serviceProviderId}
          serviceProvider={selectedVersion}
          handleFormSubmit={handleFormSubmit}
          latest={
            (selectedVersion.isPrimary && !pending) ||
            (!selectedVersion.isPrimary && pending)
          }
          hasLatestVersion={latestVersion === null ? false : true}
          handleVersionChange={handleVersionChange}
          editServiceProvider={true}
          confirmServiceProvider={confirmServiceProvider}
          rejectServiceProvider={rejectServiceProvider}
          deleteServiceProvider={handleServiceProviderDelete}
          validateServiceProvider={handleServiceProviderValidate}
          /*           updateTranslations={updateTranslations}
           */ pending={pending}
        ></EditServiceProvider>
        {showDialog && (
          <TextFieldDialog
            closeDialog={closeDialog}
            dialogTitle={'Ertu viss um að þú viljir hafna þessum breytingum?'}
            onSuccess={rejectServiceProviderConfirm}
          ></TextFieldDialog>
        )}
      </Layout>
    );
  } else {
    return (
      <Grid container direction="column" justify="center" alignContent="center">
        <CircularProgress size={100} />
      </Grid>
    );
  }
};

export default securePage(ServiceProviderDetails);

import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid, TextField, Paper, Button, Checkbox } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';
import {
  Formik,
  Form,
  FieldProps,
  FormikHelpers,
  Field,
  FormikProps
} from 'formik';

import LoadingPage from '../LoadingPage';
import GeofenceEditorMap from '../GeofenceEditorMap';
import { IGeofence } from './types';
import useStyles from './styles';
import schema from './schema';
import { IS_LOCALE, EN_LOCALE, getIcelandicName } from '../../utils/locale';
import { ITranslationLite } from '../../interfaces/Place/Place';
import { Autocomplete } from '@material-ui/lab';
import { useQuery } from 'react-apollo';
import { REGIONS } from '../../graphql/queries/Misc';
import { ICodes } from '../../interfaces/Misc/zipCodes';
import useAdminChecker from '../../hooks/useAdminChecker';

interface IProps {
  initialValues: IGeofence;
  onSubmit: (geofence: IGeofence) => void;
}

interface IFormField {
  key: string;
  translation?: string;
  locale?: typeof IS_LOCALE | typeof EN_LOCALE;
  label: string;
  required?: boolean;
  options?: ICodes[];
  type?: 'text' | 'select' | 'multiSelect' | 'number';
}

type IFormik = FormikProps<IGeofence>;

const GeofenceForm: React.FC<IProps> = ({ initialValues, onSubmit }) => {
  const classes = useStyles();
  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" color="primary" />;
  const isAdmin = useAdminChecker();

  const { data, loading } = useQuery<{
    Regions: Array<{
      id: string;
      translations: Array<{
        locale: string;
        name: string;
      }>;
    }>;
  }>(REGIONS);
  if (loading) {
    return <LoadingPage />;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {formik => (
        <Form>
          <Paper className={classes.section}>
            <Typography variant="h6" className={classes.sectionTitle}>
              Upplýsingar
            </Typography>
            <Grid container spacing={4}>
              {getInfoFields().map(field => (
                <Grid item xs={12} sm={6} key={field.key + field.locale}>
                  {renderInfoField(field, formik)}
                </Grid>
              ))}
            </Grid>

            <Field name="path">
              {({ form, meta }: FieldProps<IGeofence['path']>) => {
                return (
                  <FormControl
                    margin="normal"
                    required
                    fullWidth
                    disabled={!isAdmin}
                    error={Boolean(meta.touched && meta.error)}
                  >
                    <FormLabel htmlFor="path">Landmörk</FormLabel>
                    <GeofenceEditorMap
                      isAdmin
                      initialPath={meta.initialValue}
                      onPathChanged={path => {
                        form.setFieldValue('path', path, true);
                        form.setFieldTouched('path', true, true);
                      }}
                    />
                    <FormHelperText id="path-error-text">
                      {meta.error}
                    </FormHelperText>
                  </FormControl>
                );
              }}
            </Field>
          </Paper>
          {isAdmin && (
            <Button
              type="submit"
              variant="contained"
              className={classes.submitButton}
            >
              Submit
            </Button>
          )}
        </Form>
      )}
    </Formik>
  );

  function getInfoFields(): IFormField[] {
    return [
      {
        key: 'name',
        translation: 'name',
        locale: IS_LOCALE,
        label: 'Nafn',
        required: true
      },
      {
        key: 'name',
        translation: 'nameEn',
        locale: EN_LOCALE,
        label: 'Nafn en',
        required: true
      },
      {
        key: 'regions',
        label: 'Landshluti',
        type: 'select',
        required: true,
        options: data.Regions.map(region => ({
          label: getIcelandicName(region.translations),
          value: region.id
        }))
      }
    ];
  }
  function setTranslationsValue(
    val: string,
    locale: IFormField['locale'],
    key: string,
    formik: IFormik
  ) {
    const { values, setFieldValue } = formik;
    const translationsIndex = values.translations.findIndex(
      val => val.locale === locale
    );
    if (translationsIndex === -1) {
      return;
    } else {
      const lastTranslations: ITranslationLite[] = Array.from(
        values.translations
      );
      /* 
      values.translations.map(val => {
        test.push(val);
      }); */
      lastTranslations[translationsIndex][key] = val;
      setFieldValue('translations', lastTranslations);
    }
  }

  function getTranslationsField(key: string, locale: string, formik: IFormik) {
    const { values } = formik;
    const translationsIndex = values.translations.findIndex(
      val => val.locale === locale
    );
    if (translationsIndex === -1) {
      return '';
    }
    return values.translations[translationsIndex][key];
  }

  function handleSubmit(
    values: IGeofence,
    formikHelpers: FormikHelpers<IGeofence>
  ) {
    onSubmit(values);
  }

  function renderInfoField(field: IFormField, formik: IFormik) {
    const { key, translation, locale, label, type, options, required } = field;
    const { values, errors, handleChange, handleBlur, setFieldValue } = formik;
    if (type === 'select' || type === 'multiSelect') {
      return (
        <Autocomplete
          multiple
          disableCloseOnSelect
          id={`checkboxes-${key}`}
          options={options}
          onChange={(_, val: ICodes[]) => {
            setFieldValue(key, val);
          }}
          disabled={!isAdmin}
          onBlur={handleBlur(key)}
          value={values[key]}
          getOptionLabel={option => option.label}
          getOptionSelected={(option: ICodes, value: ICodes) => {
            if (option.value === value.value) {
              return true;
            }
            return false;
          }}
          renderOption={(option, { selected }) => {
            return (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </React.Fragment>
            );
          }}
          style={{ width: '100%' }}
          renderInput={params => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password'
              }}
              variant="outlined"
              label={label}
            />
          )}
        />
      );
    }

    if (key === 'name') {
      return (
        <TextField
          id={translation}
          type={type}
          label={label}
          value={getTranslationsField(key, locale, formik)}
          error={!!errors[translation]}
          onChange={e =>
            setTranslationsValue(e.target.value, locale, key, formik)
          }
          onBlur={handleBlur}
          variant="outlined"
          required={required}
          disabled={!isAdmin}
          fullWidth
        />
      );
    }

    return (
      <TextField
        id={key}
        type={type}
        label={label}
        value={values[key]}
        error={!!errors[key]}
        onChange={handleChange}
        onBlur={handleBlur}
        variant="outlined"
        disabled={!isAdmin}
        required={required}
        fullWidth
      />
    );
  }
};

export default GeofenceForm;

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Layout from '../components/Layout';
import Section from '../components/Section';

interface IProps {
  error?: string;
  simpleNav?: boolean;
}

const useStyles = makeStyles(theme => ({
  contentHeight: {
    height: '100%',
    display: 'flex'
  }
}));

const LoadingPage: React.FC<IProps> = ({ error, simpleNav }) => {
  const classes = useStyles();

  return (
    <Layout
      useDefaultSpacing
      simpleNav={simpleNav}
      contentClassName={classes.contentHeight}
    >
      {error ? (
        <Section title="Error">
          <Typography>{error}</Typography>
        </Section>
      ) : (
        <Grid container justify="center" alignItems="flex-start">
          <CircularProgress size={100} />
        </Grid>
      )}
    </Layout>
  );
};

export default LoadingPage;

import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';

import Layout from '../components/Layout';
import LoadingPage from '../components/LoadingPage';
import Section from '../components/Section';
import GeofenceEdit from '../components/GeofenceEdit';
import { IGeofence as IFormValues } from '../components/GeofenceForm/types';

import { polygonGeometry2LatLng } from '../utils/geo';
import { getIcelandicName } from '../utils/locale';
import { IGeoFence } from '../interfaces/GeoFence/GeoFence';
import { GEOFENCE } from '../graphql/queries/GeoFenceQueries';
import Maybe from 'graphql/tsutils/Maybe';

const GeofenceDetails: React.FC<RouteComponentProps<{ id: string }>> = ({
  match
}) => {
  const { data, loading, error } = useQuery<{ GeoFence: IGeoFence }>(GEOFENCE, {
    variables: { fenceId: match.params.id }
  });

  const [formValues, setFormValues] = useState<Maybe<IFormValues>>(null);

  useEffect(() => {
    if (!data) return;

    const Geofence = data.GeoFence;
    setFormValues({
      id: Geofence.id,
      translations: Geofence.properties.translations,
      regions: Geofence.regions.map(reg => ({
        label: getIcelandicName(reg.translations),
        value: reg.id
      })),
      path: polygonGeometry2LatLng(Geofence.geometry)
    });
  }, [data]);

  if (loading || error) {
    return <LoadingPage error={error ? error.message : ''} />;
  }

  if (!formValues) {
    return <LoadingPage error={'Something went wrong'} />;
  }

  return (
    <Layout useDefaultSpacing>
      {formValues && (
        <Section title={formValues.translations[0].name}>
          <GeofenceEdit initialGeofence={formValues} />
        </Section>
      )}
    </Layout>
  );
};

export default GeofenceDetails;

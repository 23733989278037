import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Layout from '../components/Layout';

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: theme.dimensions.maxLayoutWidth,
    margin: 'auto',
    marginBottom: 200
  }
}));

const PageNotFound: React.FC = () => {
  const classes = useStyles({});
  return (
    <Layout useDefaultSpacing>
      <Typography variant="h4" className={classes.container}>
        404 Page Not Found
      </Typography>
      {/* Add graphic */}
    </Layout>
  );
};

export default PageNotFound;

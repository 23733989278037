import React from 'react';

import securePage from '../hocs/securePage';
import Layout from '../components/Layout';
import Section from '../components/Section';
import PlaceForm from '../components/PlaceForm';

const CreatePlace: React.FC = () => {
  return (
    <Layout useDefaultSpacing>
      <Section title="Nýr staður">
        <PlaceForm newPlace={true} />
      </Section>
    </Layout>
  );
};

export default securePage(CreatePlace);

import { gql } from 'apollo-boost';
import {
  placeFragment,
  placeDetailsFragment,
  translationFragment
} from '../fragments/PlaceFragments';

export const PLACES = gql`
  query getPlaces(
    $skip: Int
    $take: Int
    $active: Boolean
    $sort: PlaceSort
    $filter: PlaceFilter
  ) {
    Places(
      skip: $skip
      take: $take
      active: $active
      sort: $sort
      filter: $filter
    ) {
      count
      Places {
        ...place
      }
    }
  }
  ${placeFragment}
`;

export const PLACE_EXPORT = gql`
  query getPlacesExport(
    $skip: Int
    $take: Int
    $active: Boolean
    $sort: PlaceSort
    $filter: PlaceFilter
  ) {
    Places(
      skip: $skip
      take: $take
      active: $active
      sort: $sort
      filter: $filter
    ) {
      count
      Places {
        ...placeDetails
      }
    }
  }
  ${placeDetailsFragment}
`;

export const PLACE_DETAILS = gql`
  query getPlaceDetails($placeId: String!) {
    Place(placeId: $placeId) {
      ...placeDetails
    }
  }
  ${placeDetailsFragment}
`;

export const PLACE_CATEGORIES = gql`
  {
    PlaceCategories {
      id
      translations {
        ...translation
      }
    }
  }
  ${translationFragment}
`;

import React, { useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper,
  TextField,
  Grid
} from '@material-ui/core';
import Joi from '@hapi/joi';
import includes from 'lodash/includes';

interface IProps {
  dialogTitle: string;
  closeDialog: () => void;
  onSuccess: (message: string) => void;
}

const TextFieldDialog: React.FC<IProps> = ({
  dialogTitle,
  closeDialog,
  onSuccess
}) => {
  const [initalForm, setInitalForm] = useState({ text: '' });
  const [invalidFields, setInvalidFields] = useState([]);

  const schema = Joi.object({
    text: Joi.string().required()
  });

  const handleCloseSuccess = () => {
    const joiValidate = schema.validate(initalForm, { abortEarly: false });
    if (joiValidate.error) {
      const newInvalidFields = joiValidate.error.details.map(
        field => field.path[0]
      );
      setInvalidFields(newInvalidFields);
    } else {
      onSuccess(initalForm.text);
    }
  };

  const handleClose = () => {
    closeDialog();
  };

  return (
    <div>
      <Dialog
        open
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} style={{ marginBottom: 20 }}>
            {/* <Grid item xs={12} sm={12}>
              <Paper>
                <TextField
                  autoComplete="new-password"
                  fullWidth
                  id={'title'}
                  label={'Titill'}
                  variant="outlined"
                  value={initalForm.title}
                  required
                  error={includes(invalidFields, 'title')}
                  onChange={event =>
                    setInitalForm({ ...initalForm, title: event.target.value })
                  }
                />
              </Paper>
            </Grid> */}
            <Grid item xs={12} sm={12}>
              <Paper>
                <TextField
                  autoComplete="new-password"
                  fullWidth
                  id={'text'}
                  label={'Skýring'}
                  variant="outlined"
                  value={initalForm.text}
                  required
                  error={includes(invalidFields, 'text')}
                  multiline
                  rows={10}
                  onChange={event =>
                    setInitalForm({ ...initalForm, text: event.target.value })
                  }
                />
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => closeDialog()}
            color="primary"
            variant={'outlined'}
          >
            Hætta við
          </Button>
          <Button
            onClick={handleCloseSuccess}
            color="primary"
            variant={'contained'}
            autoFocus
          >
            Hafna breytingum
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TextFieldDialog;

import React from 'react';

import securePage from '../hocs/securePage';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { useQuery } from 'react-apollo';
import EditCategories from '../components/EditCategories';
import { IDataCategories } from '../interfaces/Misc/zipCodes';
import { GET_CATEGORIES } from '../graphql/queries/Misc';

const CreateCategory: React.FC = () => {
  const { loading, error, data } = useQuery<IDataCategories>(GET_CATEGORIES);
  const categories = data ? data.Categories : [];

  return (
    <Layout useDefaultSpacing>
      <Section
        title="Skrá nýjan flokk"
        loading={loading}
        error={error ? error.message : ''}
      >
        <EditCategories categories={categories} />
      </Section>
    </Layout>
  );
};

export default securePage(CreateCategory);

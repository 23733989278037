import React from 'react';
import { Typography } from '@material-ui/core';
import useAuthState from '../hooks/useAuthState';
import Layout from '../components/Layout';
import Section from '../components/Section';

const Dashboard = () => {
  const { user, isLoggedIn, isReady } = useAuthState();

  if (!isReady) {
    return (
      <Layout>
        <Typography variant="h4" component="h1">
          Dashboard loading
        </Typography>
        <hr />
      </Layout>
    );
  }
  return (
    <Layout useDefaultSpacing>
      <Section title="Dashboard">
        {isLoggedIn && <h3>{user.name} is logged in</h3>}
      </Section>
    </Layout>
  );
};

export default Dashboard;

import React, { useState } from 'react';
import { useQuery } from 'react-apollo';

import securePage from '../hocs/securePage';
import { IMunicipalities } from '../interfaces/Municipality/Municipality';
import { MUNICIPALITIES } from '../graphql/queries/MunicipalityQueries';
import Layout from '../components/Layout';
import Section from '../components/Section';
import MunicipalityTable from '../components/MunicipalityTable';
import { TABLE_ROWS_DEFAULT } from '../constants';

export interface IFilters {
  text: string;
  active?: boolean;
  zipCodes?: string[];
  regions?: string[];
  fenceId?: string;
}

export type ISort = { [field: string]: 'ASC' | 'DESC' };

const Municipalities: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROWS_DEFAULT);
  const [filters, setFilters] = useState<IFilters>({ text: '' });
  const [sort, setSort] = useState<ISort>({ name: 'ASC' });
  const { text, active, zipCodes, regions, fenceId } = filters;

  const { data, loading, error } = useQuery<IMunicipalities>(MUNICIPALITIES, {
    variables: {
      active,
      skip: currentPage * rowsPerPage,
      take: rowsPerPage,
      sort,
      filter: { text, zipCodes, regionIds: regions, fenceId }
    }
  });
  const municipalities = data ? data.Municipalities.Municipalities : [];
  const count = data ? data.Municipalities.count : 0;

  return (
    <Layout useDefaultSpacing>
      <Section
        title="Bæjarfélög"
        link="/municipality/new"
        linkText="Skrá nýtt bæjarfélag"
        loading={loading}
        error={error ? error.message : ''}
      >
        <MunicipalityTable
          municipalities={municipalities}
          count={count}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          initialFilters={filters}
          sort={sort}
          setCurrentPage={setCurrentPage}
          setRowsPerPage={setRowsPerPage}
          searchForMunicipalities={setFilters}
          updateSort={setSort}
        />
      </Section>
    </Layout>
  );
};

export default securePage(Municipalities);

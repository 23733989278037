import React from "react";
import cx from "classnames";
import {
  Typography,
  makeStyles,
  Grid,
  useTheme,
  useMediaQuery,
  Link,
} from "@material-ui/core";
import { useQueryParam, StringParam } from "use-query-params";

import logo from "../assets/cleanlogo.png";
import logoEN from "../assets/cleanlogo-en.png";

const useStyles = makeStyles((theme) => ({
  typoGraphyContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  textContainer: {
    width: "35%",
  },
  textContainerMobile: {
    margin: "0px 5% 20px",
  },
  imageContainer: {
    marginBottom: "50px",
  },
  imageContainerMobile: {
    marginBottom: "25px",
  },
  heading1: {},
}));
const CleanThankyou: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [name, setName] = useQueryParam("n", StringParam);
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div style={{ height: "100vh", paddingTop: "5%", color: "#000" }}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        alignContent="center"
      >
        <Grid item>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid
              item
              xs={4}
              style={{ display: "flex", justifyContent: "center" }}
              className={cx(
                { [classes.imageContainer]: !md },
                { [classes.imageContainerMobile]: md }
              )}
            >
              <img
                alt="logo"
                src={logo}
                style={{ width: "175px", paddingRight: "17.5px" }}
              ></img>
              <img
                alt="logo"
                src={logoEN}
                style={{ width: "175px", paddingLeft: "17.5px" }}
              ></img>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.typoGraphyContainer}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Typography variant="h4" align="center">
                Takk fyrir {name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          className={cx(
            { [classes.textContainer]: !md },
            { [classes.textContainerMobile]: md }
          )}
        >
          <Grid container direction="column">
            <Grid item xs={12} className={classes.typoGraphyContainer}>
              <Typography variant="subtitle1" align="center">
                {"Ferðamálastofa þakkar þér fyrir að taka þátt í verkefninu"}
              </Typography>
              <Typography variant="subtitle1" align="center">
                {"Hreint og öruggt."}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.typoGraphyContainer}>
              <Typography variant="subtitle1" align="center">
                {
                  "Þátttakan er loforð til viðskiptavina þinna um að þrifum og sóttvörnum sé sinnt af samviskusemi og að öllum reglum yfirvalda sé fylgt."
                }
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                borderTop: "1px solid #090909",
                paddingTop: "30px",
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              <Typography variant="subtitle1" align="justify">
                {
                  "Hér getur þú nálgast merki Hreint og öruggt sem ætluð eru til nota á vef og í kynningarefni. Ennfremur getur þú nálgast skjalið Loforð til viðskiptavina sem skal vera sýnilegt fyrir viðskiptavini."
                }
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              className={classes.typoGraphyContainer}
            >
              <Grid
                container
                direction="column"
                xs={12}
                md={4}
                style={{ marginBottom: "20px" }}
              >
                <Typography variant="subtitle1">
                  <strong>Merki á íslensku</strong>
                </Typography>
                <Typography variant="subtitle1">
                  <Grid item>
                    <Link
                      component="a"
                      href="https://www.ferdamalastofa.is/static/files/cs/2022/hreint_og_oruggt_2022.pdf"
                      underline="always"
                      color="inherit"
                      download="Hreint og öruggt"
                      target="_blank"
                      rel="noopener"
                    >
                      Hreint og öruggt - pdf
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      component="a"
                      href="https://www.ferdamalastofa.is/static/files/cs/2022/hreint_og_oruggt_2022.png"
                      underline="always"
                      color="inherit"
                      download="Hreint og öruggt"
                      target="_blank"
                      rel="noopener"
                    >
                      Hreint og öruggt - png
                    </Link>
                  </Grid>
                </Typography>
              </Grid>
              <Grid
                container
                direction="column"
                xs={12}
                md={4}
                style={{ marginBottom: "20px" }}
              >
                <Typography variant="subtitle1">
                  <strong>Merki á ensku</strong>
                </Typography>
                <Typography variant="subtitle1">
                  <Grid item>
                    <Link
                      component="a"
                      href="https://www.ferdamalastofa.is/static/files/cs/2022/clean_-_safe_2022.pdf"
                      underline="always"
                      color="inherit"
                      download="Loforð"
                      target="_blank"
                      rel="noopener"
                    >
                      Clean &amp; Safe - pdf
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      component="a"
                      href="https://www.ferdamalastofa.is/static/files/cs/2022/clean_-_safe_2022.png"
                      underline="always"
                      color="inherit"
                      download="Loforð"
                      target="_blank"
                      rel="noopener"
                    >
                      Clean &amp; Safe - png
                    </Link>
                  </Grid>
                </Typography>
              </Grid>
              <Grid
                container
                direction="column"
                xs={12}
                md={4}
                style={{ marginBottom: "20px" }}
              >
                <Typography variant="subtitle1">
                  <strong>Skjöl</strong>
                </Typography>
                <Typography variant="subtitle1">
                  <Grid item>
                    <Link
                      component="a"
                      href="https://www.ferdamalastofa.is/static/files/cs/2022/loford_2022.pdf"
                      underline="always"
                      color="inherit"
                      download="Loforð til viðskiptavina"
                      target="_blank"
                      rel="noopener"
                    >
                      Loforð til viðskiptavina - pdf
                    </Link>
                  </Grid>
                  <Grid item style={{ marginBottom: "20px" }}>
                    <Link
                      component="a"
                      href="https://www.ferdamalastofa.is/static/files/cs/2022/pledge_2022.pdf"
                      underline="always"
                      color="inherit"
                      download="Pledge to our customers"
                      target="_blank"
                      rel="noopener"
                    >
                      Pledge to our customers - pdf
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      component="a"
                      href="https://www.ferdamalastofa.is/static/files/cs/2022/skilmalar_2022.pdf"
                      underline="always"
                      color="inherit"
                      download="Skilmálar"
                      target="_blank"
                      rel="noopener"
                    >
                      Skilmálar - pdf
                    </Link>
                  </Grid>
                  <Grid item style={{ marginBottom: "20px" }}>
                    <Link
                      component="a"
                      href="https://www.ferdamalastofa.is/static/files/cs/2022/terms-conditions_2022.pdf"
                      underline="always"
                      color="inherit"
                      download="Terms and conditions"
                      target="_blank"
                      rel="noopener"
                    >
                      Terms and conditions - pdf
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      component="a"
                      href="https://www.ferdamalastofa.is/static/files/cs/2022/gatlisti_2022.pdf"
                      underline="always"
                      color="inherit"
                      download="Gátlisti"
                      target="_blank"
                      rel="noopener"
                    >
                      Gátlisti - pdf
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      component="a"
                      href="https://www.ferdamalastofa.is/static/files/cs/2022/checklist_2022.pdf"
                      underline="always"
                      color="inherit"
                      download="Checklist"
                      target="_blank"
                      rel="noopener"
                    >
                      Checklist - pdf
                    </Link>
                  </Grid>
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.typoGraphyContainer}>
              <Typography variant="subtitle1" align="left">
                English below:
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              className={classes.typoGraphyContainer}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Typography variant="h4" align="center">
                Thank you {name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          className={cx(
            { [classes.textContainer]: !md },
            { [classes.textContainerMobile]: md }
          )}
        >
          <Grid container direction="column">
            <Grid item xs={12} className={classes.typoGraphyContainer}>
              <Typography variant="subtitle1" align="center">
                {
                  "Icelandic Tourist Board thanks you for participating in the project"
                }
              </Typography>
              <Typography variant="subtitle1" align="center">
                {"Clean & Safe."}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.typoGraphyContainer}>
              <Typography variant="subtitle1" align="center">
                {
                  "The participation is a pledge to your customers that cleaning and infection control is done conscientiously and all the rules from the authorities are followed."
                }
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                borderTop: "1px solid #090909",
                paddingTop: "30px",
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              <Typography variant="subtitle1" align="justify">
                {
                  "Here you can access the logos of Clean & Safe which are intended to be used on the web and in promotional material. You can also access the document Pledge to our customers which should be visible to customers"
                }
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              className={classes.typoGraphyContainer}
            >
              <Grid
                container
                direction="column"
                xs={12}
                md={4}
                style={{ marginBottom: "20px" }}
              >
                <Typography variant="subtitle1">
                  <strong>Logos in Icelandic</strong>
                </Typography>
                <Typography variant="subtitle1">
                  <Grid item>
                    <Link
                      component="a"
                      href="https://www.ferdamalastofa.is/static/files/cs/2022/hreint_og_oruggt_2022.pdf"
                      underline="always"
                      color="inherit"
                      download="Hreint og öruggt"
                      target="_blank"
                      rel="noopener"
                    >
                      Hreint og öruggt - pdf
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      component="a"
                      href="https://www.ferdamalastofa.is/static/files/cs/2022/hreint_og_oruggt_2022.png"
                      underline="always"
                      color="inherit"
                      download="Hreint og öruggt"
                      target="_blank"
                      rel="noopener"
                    >
                      Hreint og öruggt - png
                    </Link>
                  </Grid>
                </Typography>
              </Grid>
              <Grid
                container
                direction="column"
                xs={12}
                md={4}
                style={{ marginBottom: "20px" }}
              >
                <Typography variant="subtitle1">
                  <strong>Logos in English</strong>
                </Typography>
                <Typography variant="subtitle1">
                  <Grid item>
                    <Link
                      component="a"
                      href="https://www.ferdamalastofa.is/static/files/cs/2022/clean_-_safe_2022.pdf"
                      underline="always"
                      color="inherit"
                      download="Loforð"
                      target="_blank"
                      rel="noopener"
                    >
                      Clean &amp; Safe - pdf
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      component="a"
                      href="https://www.ferdamalastofa.is/static/files/cs/2022/clean_-_safe_2022.png"
                      underline="always"
                      color="inherit"
                      download="Loforð"
                      target="_blank"
                      rel="noopener"
                    >
                      Clean &amp; Safe - png
                    </Link>
                  </Grid>
                </Typography>
              </Grid>
              <Grid
                container
                direction="column"
                xs={12}
                md={4}
                style={{ marginBottom: "20px" }}
              >
                <Typography variant="subtitle1">
                  <strong>Documents</strong>
                </Typography>
                <Typography variant="subtitle1">
                  <Grid item>
                    <Link
                      component="a"
                      href="https://www.ferdamalastofa.is/static/files/cs/2022/loford_2022.pdf"
                      underline="always"
                      color="inherit"
                      download="Loforð til viðskiptavina"
                      target="_blank"
                      rel="noopener"
                    >
                      Loforð til viðskiptavina - pdf
                    </Link>
                  </Grid>
                  <Grid item style={{ marginBottom: "20px" }}>
                    <Link
                      component="a"
                      href="https://www.ferdamalastofa.is/static/files/cs/2022/pledge_2022.pdf"
                      underline="always"
                      color="inherit"
                      download="Pledge to our customers"
                      target="_blank"
                      rel="noopener"
                    >
                      Pledge to our customers - pdf
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      component="a"
                      href="https://www.ferdamalastofa.is/static/files/cs/2022/skilmalar_2022.pdf"
                      underline="always"
                      color="inherit"
                      download="Skilmálar"
                      target="_blank"
                      rel="noopener"
                    >
                      Skilmálar - pdf
                    </Link>
                  </Grid>
                  <Grid item style={{ marginBottom: "20px" }}>
                    <Link
                      component="a"
                      href="https://www.ferdamalastofa.is/static/files/cs/2022/terms-conditions_2022.pdf"
                      underline="always"
                      color="inherit"
                      download="Terms and conditions"
                      target="_blank"
                      rel="noopener"
                    >
                      Terms and conditions - pdf
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      component="a"
                      href="https://www.ferdamalastofa.is/static/files/cs/2022/gatlisti_2022.pdf"
                      underline="always"
                      color="inherit"
                      download="Gátlisti"
                      target="_blank"
                      rel="noopener"
                    >
                      Gátlisti - pdf
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      component="a"
                      href="https://www.ferdamalastofa.is/static/files/cs/2022/checklist_2022.pdf"
                      underline="always"
                      color="inherit"
                      download="Checklist"
                      target="_blank"
                      rel="noopener"
                    >
                      Checklist - pdf
                    </Link>
                  </Grid>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CleanThankyou;

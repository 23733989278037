import auth0, { AuthorizeOptions } from 'auth0-js';
import store from 'store';
import { getFullUrlPath } from './path';
import { captureException } from './sentry';
import { CALLBACK_ROUTE } from './../constants';

const LOGGED_IN_KEY = 'isLoggedIn';
const RETURN_TO_KEY = 'returnTo';

const getBaseUrl = () => `${window.location.protocol}//${window.location.host}`;
const getOptions = (): AuthorizeOptions => ({
  responseType: 'token id_token',
  redirectUri: `${getBaseUrl() + CALLBACK_ROUTE}`,
  scope: 'openid profile email admin editor user',
  audience: 'http://itb-database.com'
});

interface IAuthConfig {
  auth0ClientId: string;
  auth0Domain: string;
}

export function webAuth({ auth0ClientId, auth0Domain }: IAuthConfig) {
  return new auth0.WebAuth({ clientID: auth0ClientId, domain: auth0Domain });
}

export function parseHash(authConfig: IAuthConfig) {
  return new Promise((resolve, reject) => {
    webAuth(authConfig).parseHash(
      { __enableIdPInitiatedLogin: true },
      (err, result) => {
        if (err) {
          console.error('Something happened with the Sign In request', err);
          captureException(new Error('parseHash errored out'), err);
          reject(err);
          return;
        }
        if (!result || !result.idToken) {
          reject(err);
          return;
        }

        resolve(result);
      }
    );
  });
}

export function renewSession(authConfig: IAuthConfig) {
  return new Promise((resolve, reject) => {
    webAuth(authConfig).checkSession(getOptions(), async (err, authResult) => {
      if (err) {
        reject(err);
        return;
      }

      if (authResult && authResult.accessToken && authResult.idToken) {
        resolve(authResult);
      } else {
        reject(new Error('Unknown error while renewing session'));
      }
    });
  });
}

export function login(authConfig: IAuthConfig) {
  webAuth(authConfig).authorize(getOptions());
}

export function logout(authConfig: IAuthConfig) {
  webAuth(authConfig).logout({ returnTo: getBaseUrl() });
}

export function setSession() {
  store.set(LOGGED_IN_KEY, true);
}

export function unsetSession() {
  store.remove(LOGGED_IN_KEY);
}

export function isLoggedIn() {
  return Boolean(store.get(LOGGED_IN_KEY));
}

export function setReturnTo() {
  store.set(RETURN_TO_KEY, getFullUrlPath());
}

export function unsetReturnTo() {
  store.remove(RETURN_TO_KEY);
}

export function getReturnTo() {
  return store.get(RETURN_TO_KEY);
}

import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Switch,
  FormControlLabel,
  TextField
} from '@material-ui/core';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import cx from 'classnames';

interface IProps {
  id: string;
  label: string;
  value: string;
  required?: boolean;
  error?: boolean;
  disabled?: boolean;
  minHeight?: number;
  maxHeight?: number;
  rows?: number;
  onChange: (text: string) => void;
}

const useStyles = makeStyles(theme => ({
  toolbar: {
    borderColor: theme.palette.grey[400],
    borderRadius: 4
  },
  wrapper: {},
  editor: {
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 4,
    padding: theme.spacing(0, 2),
    fontSize: 16,
    boxSizing: 'content-box'
  },
  error: {
    borderColor: theme.palette.error.main
  },
  switch: {
    marginBottom: theme.spacing(1)
  }
}));

const RichText: React.FC<IProps> = ({
  id,
  label,
  value,
  required,
  error,
  disabled,
  minHeight = 250,
  maxHeight = 500,
  rows = 12,
  onChange
}) => {
  const classes = useStyles();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  let showRichTextInitialValue = true;
  if (value.includes('<iframe')) {
    showRichTextInitialValue = false;
  }
  const [showRichText, setShowRichText] = useState(showRichTextInitialValue);

  useEffect(() => {
    setEditorState(prevState => {
      if (value !== getMarkup(prevState)) {
        const { contentBlocks, entityMap } = htmlToDraft(value);
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        return EditorState.createWithContent(contentState);
      }
      return prevState;
    });
  }, [value]);

  return (
    <React.Fragment>
      <FormControlLabel
        label={showRichText ? 'Rich text' : 'Plain text'}
        className={classes.switch}
        control={
          <Switch
            color="primary"
            checked={showRichText}
            disabled={disabled}
            onChange={(_, checked) => setShowRichText(checked)}
          />
        }
      />
      {showRichText ? (
        <Editor
          editorState={editorState}
          onEditorStateChange={handleChange}
          readOnly={disabled}
          placeholder={`${label}${required ? ' *' : ''}`}
          toolbar={{
            options: ['inline', 'list', 'link', 'history'],
            inline: {
              options: ['bold', 'italic', 'underline', 'strikethrough']
            }
          }}
          toolbarClassName={cx('toolbarClassName', classes.toolbar)}
          wrapperClassName={cx('wrapperClassName', classes.wrapper)}
          editorClassName={cx('editorClassName', classes.editor, {
            [classes.error]: error
          })}
          editorStyle={{ minHeight, maxHeight }}
          spellCheck={true}
        />
      ) : (
        <TextField
          id={id}
          label={label}
          value={value}
          variant="outlined"
          rows={rows}
          required
          fullWidth
          multiline
          error={error}
          disabled={disabled}
          onChange={e => onChange(e.target.value)}
        />
      )}
    </React.Fragment>
  );

  function handleChange(state: EditorState) {
    setEditorState(state);
    onChange(getMarkup(state));
  }
};

function getMarkup(state: EditorState) {
  const rawContentState = convertToRaw(state.getCurrentContent());
  return draftToHtml(rawContentState);
}

export default RichText;

import { gql } from 'apollo-boost';
import { translationFragment } from './PlaceFragments';

export const rolesFragment = gql`
  fragment roles on UserRole {
    id
    name
    description
  }
`;

export const regionsFragment = gql`
  fragment regions on IRegion {
    id
    translations {
      ...translation
    }
    email
  }
  ${translationFragment}
`;

export const userFragment = gql`
  fragment user on UserType {
    user_id
    name
    email
    blocked
    created_at
    last_login
    roles {
      ...roles
    }
    regions {
      ...regions
    }
    serviceProviders {
      serviceProviderId
      SSN
      active
      translations {
        name
        locale
      }
    }
    app_metadata {
      companySSN
    }
  }
  ${rolesFragment}
  ${regionsFragment}
  ${translationFragment}
`;

export const userByRoleFragment = gql`
  fragment userByRole on UserPageType {
    users {
      user_id
      name
      email
      picture
      regions {
        ...regions
      }
      serviceProviders {
        serviceProviderId
        SSN
        active
        translations {
          name
          locale
        }
      }
      app_metadata {
        companySSN
      }
    }
    role
    start
    limit
    total
  }
  ${regionsFragment}
`;

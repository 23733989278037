import React from 'react';
import cx from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

import RouterLinkForwardRef from '../components/RouterLinkForwardRef';

interface IProps {
  title?: string;
  loading?: boolean;
  error?: string;
  link?: string;
  linkText?: string;
  className?: string;
  extraLink?: string;
  extraLinkText?: string;
}

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    maxWidth: theme.dimensions.maxContentWidth,
    margin: '0 auto'
    //backgroundColor: '#f4f4f7'
  },
  titleArea: {
    marginBottom: theme.spacing(4)
  },
  title: {
    textTransform: 'uppercase'
  },
  button: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.body1.fontSize
  },
  extraButton: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.body1.fontSize,
    marginLeft: 16
  },
  multipleButtons: {
    textAlign: 'right'
  }
}));

const Section: React.FC<IProps> = ({
  title,
  children,
  loading,
  error,
  link,
  linkText,
  className,
  extraLink,
  extraLinkText
}) => {
  const classes = useStyles({});
  const hasTitleArea = title || (link && linkText);

  return (
    <div className={cx(classes.container, className)}>
      {link && linkText && extraLink && extraLinkText ? (
        <Grid container className={classes.titleArea}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h4" className={classes.title}>
              {title}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} className={classes.multipleButtons}>
            <Button
              to={link}
              component={RouterLinkForwardRef}
              color="primary"
              variant={'contained'}
              className={classes.button}
            >
              {linkText}
            </Button>
            <Button
              to={extraLink}
              component={RouterLinkForwardRef}
              color="primary"
              variant={'contained'}
              className={classes.extraButton}
            >
              {extraLinkText}
            </Button>
          </Grid>
        </Grid>
      ) : hasTitleArea ? (
        <Grid
          container
          justify="space-between"
          alignItems="flex-start"
          className={classes.titleArea}
        >
          <Typography variant="h4" className={classes.title}>
            {title}
          </Typography>
          {link && linkText && (
            <Button
              to={link}
              component={RouterLinkForwardRef}
              color="primary"
              variant={'contained'}
              className={classes.button}
            >
              {linkText}
            </Button>
          )}
        </Grid>
      ) : null}
      {renderContent()}
    </div>
  );

  function renderContent() {
    if (error) {
      return <Typography>{error}</Typography>;
    }

    if (loading) {
      return (
        <Grid container justify="center" alignItems="center">
          <CircularProgress size={100} />
        </Grid>
      );
    }

    return children;
  }
};

export default Section;

import React, { useState, useCallback } from 'react';
import { useQuery } from 'react-apollo';
import Autocomplete, { IOption } from '../components/Autocomplete';
import debounce from 'lodash/debounce';
import { IChargingStations, IChargingStation, IChargingStationCategories } from '../interfaces/ChargingStation/ChargingStation';
import { GET_CHARGINGSTATIONS, GET_CATEGORIES } from '../graphql/queries/ChargingStations';

interface IProps {
  id?: string;
  value?: IOption;
  values?: IOption[];
  label: string;
  onChange: (value: unknown) => void;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
  fullWidth?: boolean;
  className?: string;
  multiSelect?: boolean;
}

const SelectChargingStation: React.FC<IProps> = ({
  id,
  value,
  values,
  label,
  onChange,
  error,
  disabled,
  required,
  fullWidth,
  className
}) => {
  const [searchText, setSearchText] = useState('');
  const { data } = useQuery<IChargingStations>(GET_CHARGINGSTATIONS, {
    variables: {
      skip: 0,
      take: 10000,
      active: true,
      sort: { name: 'ASC' },
      filter: { text: searchText }
    }
  });
  const { data: categories } = useQuery<IChargingStationCategories>(GET_CATEGORIES);

  const chargingStations = data ? data.ChargingStations.ChargingStations : [];

  const searchDebounced = useCallback(
    debounce((text: string) => setSearchText(text), 300),
    []
  );

  function getCategory(station: IChargingStation) {
    if (categories) {
      let cat = categories["ChargingStationCategories"].Categories.find(c => c._id == station.type);
      if (cat) {
        return cat.category;
      }
    }
    return '';
  }

  return (
    <Autocomplete
      id={id}
      value={value}
      values={values}
      label={label}
      items={chargingStations.map(p => ({
        value: p.chargingStationId,
        label: p.name,
        label2: p.location,
        label3: getCategory(p)
      }))}
      onChange={onChange}
      error={error}
      disabled={disabled}
      required={required}
      fullWidth={fullWidth}
      className={className}
      inputValue={searchText}
      onInputChange={searchDebounced}
    />
  );
};

export default SelectChargingStation;

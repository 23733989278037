import React, { useState } from 'react';
import { Checkbox, TextField, Grid } from '@material-ui/core';
import {
  Autocomplete as MaterialUIAutocomplete,
  RenderOptionState,
  RenderInputParams
} from '@material-ui/lab';

interface IProps {
  id?: string;
  value?: IOption;
  values?: IOption[];
  label: string;
  items: IOption[];
  onChange: (value: unknown) => void;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
  fullWidth?: boolean;
  className?: string;
  inputValue?: string;
  onInputChange?: (text: string) => void;
}

export interface IOption {
  value: string;
  label?: string;
  label2?: string;
  label3?: string;
}

const Autocomplete: React.FC<IProps> = ({
  id,
  value,
  values,
  label,
  items,
  onChange,
  error,
  disabled,
  required,
  fullWidth,
  className,
  inputValue = '',
  onInputChange
}) => {
  const [searchText, setSearchText] = useState(inputValue);

  if (values) {
    return (
      <MaterialUIAutocomplete
        id={id}
        multiple
        disableCloseOnSelect
        value={values.map(getOptionItemForValue)}
        options={items}
        disabled={disabled}
        className={className}
        style={fullWidth ? { width: '100%' } : {}}
        onChange={(_, val) => {
          onChange(val);
          updateSearch('');
        }}
        getOptionLabel={opt => opt.label}
        renderOption={renderOption}
        renderInput={renderInput}
        inputValue={searchText}
      />
    );
  }

  return (
    <MaterialUIAutocomplete
      id={id}
      value={getOptionItemForValue(value)}
      options={items}
      disabled={disabled}
      className={className}
      style={fullWidth ? { width: '100%' } : {}}
      onChange={(_, val) => onChange(val)}
      getOptionLabel={opt => opt.label}
      renderOption={renderOption}
      renderInput={renderInput}
    />
  );

  function renderOption(option: IOption, { selected }: RenderOptionState) {
    return (
      <React.Fragment>
        <Checkbox
          style={{ marginRight: 8 }}
          checked={selected}
          color="primary"
        />
        {
          (option.label2 || option.label3) &&
          <Grid
            container
            direction='row'
            spacing={1}
          >
            <Grid item >
              {option.label}
            </Grid>
            {
              option.label2 &&
              <Grid item>
                {option.label2}
              </Grid>
            }
            {
              option.label3 &&
              <Grid item>
                {option.label3}
              </Grid>
            }
          </Grid>
        }
        {
          !(option.label2 || option.label3) && option.label
        }
      </React.Fragment>
    );
  }

  function renderInput(params: RenderInputParams) {
    return (
      <TextField
        {...params}
        onChange={e => !!values && updateSearch(e.target.value)}
        variant="outlined"
        label={`${label}${required ? ' *' : ''}`}
        error={error}
        disabled={disabled}
        inputProps={{
          ...params.inputProps,
          autoComplete: 'new-password'
        }}
      />
    );
  }

  function getOptionItemForValue(val: IOption) {
    if (!val) {
      return null;
    }
    for (const item of items) {
      if (val.value === item.value) {
        return item;
      }
    }
    return { value: val.value, label: val.label || '' };
  }

  function updateSearch(text: string) {
    setSearchText(text);
    if (onInputChange) {
      onInputChange(text);
    }
  }
};

export default Autocomplete;

import React from 'react';
import { useMutation } from 'react-apollo';
import { useHistory } from 'react-router-dom';

import GeofenceForm from '../components/GeofenceForm';
import { IGeofence as IGeofenceFormValues } from '../components/GeofenceForm/types';
import { latLng2PolygonGeometry } from '../utils/geo';

import { IGeoFence } from '../interfaces/GeoFence/GeoFence';
import {
  CREATE_GEOFENCE,
  UPDATE_GEOFENCE
} from '../graphql/mutations/GeoFenceMutations';
import useNotifier from '../hooks/useNotifier';
import { captureException } from '../utils/sentry';
import { getIcelandicName } from '../utils/locale';

type TCoordinates = IGeoFence['geometry']['coordinates'];
type TProperties = IGeoFence['properties'];

interface IProps {
  initialGeofence: IGeofenceFormValues;
}

const GeofenceEdit: React.FC<IProps> = ({ initialGeofence }) => {
  const history = useHistory();
  const { notify } = useNotifier();

  const [createGeofence] = useMutation<{ createGeoFence: IGeoFence }>(
    CREATE_GEOFENCE,
    {
      refetchQueries: ['getFences']
    }
  );

  const [updateGeofence] = useMutation<{ updateGeoFence: IGeoFence }>(
    UPDATE_GEOFENCE,
    {
      refetchQueries: ['getFences']
    }
  );

  return (
    <GeofenceForm initialValues={initialGeofence} onSubmit={handleFormSubmit} />
  );

  function handleFormSubmit(geofence: IGeofenceFormValues) {
    ////console.log('GeofenceDetails submit: ', geofence);

    const { coordinates } = latLng2PolygonGeometry(geofence.path);
    const properties = {
      regionIds: geofence.regions.map(reg => reg.value),
      translations: geofence.translations
    };

    ////console.log('Resolved coordinates', coordinates);
    ////console.log('Resolved properties', properties);

    return !geofence.id
      ? createNew(coordinates, properties)
      : updateExisting(geofence.id, coordinates, properties);
  }

  async function createNew(coordinates: TCoordinates, properties: TProperties) {
    ////console.log('Create New Geofence');
    const geofenceName = getIcelandicName(properties.translations);

    try {
      const newGeofence = await createGeofence({
        variables: {
          values: {
            geometry: { coordinates },
            properties
          }
        }
      });
      notify(`Landmörkin '${geofenceName}' hefur verið búin til`, {
        variant: 'success'
      });
      history.replace(`/geofences/${newGeofence.data.createGeoFence.id}`);
    } catch (error) {
      console.log(error);
      notify(`Ekki tókst að búa til landmörk`, { variant: 'error' });
      captureException(error, { reason: 'Create New Geofence Failed' });
    }
  }

  async function updateExisting(
    id: string,
    coordinates: TCoordinates,
    properties: TProperties
  ) {
    ////console.log(`Updating Geofence with ID ${id}`);
    const geofenceName = getIcelandicName(properties.translations);

    try {
      await updateGeofence({
        variables: {
          fenceId: id,
          values: {
            geometry: { coordinates },
            properties
          }
        }
      });
      notify(`Landmörkin '${geofenceName}' uppfærð`, { variant: 'success' });
    } catch (error) {
      notify(`Ekki tókst að uppfæra landmörk '${geofenceName}'`, {
        variant: 'error'
      });
      captureException(error, {
        reason: 'Update Existing Geofence Failed',
        id
      });
    }
  }
};

export default GeofenceEdit;

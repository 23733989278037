import React from 'react';
import { Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ConnectedRouter } from 'connected-react-router';

import { ApolloProvider } from '@apollo/react-hooks';

import CssBaseline from '@material-ui/core/CssBaseline';

import useTheme from './hooks/useTheme';

import Routes from './Routes';
import { MuiThemeProvider } from '@material-ui/core';
import useAuthState from './hooks/useAuthState';
import { SnackbarProvider } from 'notistack';
import Notifier from './components/Notifier';
import DetectLogout from './components/DetectLogout';
import ScrollToTop from './components/ScrollToTop';
import { history } from './store/configureStore';
import { createApolloConfig } from './ApolloConfig';

const App: React.FC = () => {
  const { accessToken } = useAuthState();

  const client = createApolloConfig(accessToken);

  return (
    <MuiThemeProvider theme={useTheme()}>
      <CssBaseline />
      <ApolloProvider client={client}>
        <SnackbarProvider
          maxSnack={1}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Notifier />
          <DetectLogout />
          <ConnectedRouter history={history}>
            <ScrollToTop />
            <QueryParamProvider ReactRouterRoute={Route}>
              <Routes />
            </QueryParamProvider>
          </ConnectedRouter>
        </SnackbarProvider>
      </ApolloProvider>
    </MuiThemeProvider>
  );
};

export default App;

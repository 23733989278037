import React, { useState } from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  Button,
  Grid,
  makeStyles,
  TextField
} from '@material-ui/core';
import Autocomplete, { IOption } from './Autocomplete';
import {
  IChargingStation,
  IChargingStationCategory,
  IChargingStationProvider
} from '../interfaces/ChargingStation/ChargingStation';
import { useHistory } from 'react-router-dom';
import { IFilters } from '../pages/ChargingStations';

interface IProps {
  categories: IChargingStationCategory[],
  providers: IChargingStationProvider[],
  stations: IChargingStation[],
  showChangeButton: boolean,
  initialFilters?: IFilters,
  searchForStations?: (filters: IFilters) => void,
  setCurrentPage?: (page: number) => void
};

interface ITableCell {
  id: string;
  label: string;
  align?: 'left' | 'right' | 'center';
  sortable?: boolean;
  render: (chargingStation: IChargingStation) => React.ReactNode;
  cellClass?: string;
}

export type ISort = { [field: string]: 'ASC' | 'DESC' };

const ChargingStationTable: React.FC<IProps> = ({
  categories,
  providers,
  stations,
  initialFilters,
  showChangeButton,
  searchForStations,
  setCurrentPage
}) => {
  const useStyles = makeStyles(theme => ({
    filters: {
      margin: theme.spacing(0),
      width: '100%'
    },
    button: {
      padding: theme.spacing(2, 3)
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const [sort, setSort] = useState<ISort>({ name: 'ASC' });

  const [filters, setFilters] = useState(initialFilters);

  function chargingStationCategoriesToOptions(categories: IChargingStationCategory[]): IOption[] {
    return categories.map(cat => {
      return { label: cat.category, value: cat._id };
    });
  }

  function chargingStationProvidersToOptions(providers: IChargingStationProvider[]): IOption[] {
    return providers.map(p => {
      return { label: p.name, value: p._id };
    });
  }

  function getArrayFilter(opt: unknown) {
    const options = opt as IOption[];
    var ret = options.length ? options.map(o => o.value) : undefined;
    return ret;
  }

  function renderFilterArea() {
    return (
      <Grid
        container
        spacing={2}
        className={classes.filters}
        alignItems="center"
        justify="space-between"
      >
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                id="name"
                label="Leit"
                value={filters.text}
                variant="outlined"
                fullWidth
                onChange={e =>
                  setFilters({
                    ...filters,
                    text: e.target.value
                  })
                }
                onKeyDown={e => e.key === 'Enter' && search()}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Autocomplete
                id="categories"
                values={(filters.categoryIds || []).map(r => ({ value: r }))}
                label="Tegund"
                items={chargingStationCategoriesToOptions(categories)}
                fullWidth
                onChange={opt =>
                  setFilters({ ...filters, categoryIds: getArrayFilter(opt) })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Autocomplete
                id="providers"
                values={(filters.providerIds || []).map(r => ({ value: r }))}
                label="Þjónustuaðili"
                items={chargingStationProvidersToOptions(providers)}
                fullWidth
                onChange={opt =>
                  setFilters({ ...filters, providerIds: getArrayFilter(opt) })
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={search}
                className={classes.button}
              >
                Leita
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                onClick={clearSearch}
                className={classes.button}
              >
                Hreinsa leit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function search() {
    setCurrentPage(0);
    searchForStations(filters);
  }

  function clearSearch() {
    setCurrentPage(0);
    searchForStations({ text: '' });
  }

  const renderTableRow = function (station: IChargingStation) {
    return (
      <TableRow
        key={station.chargingStationId}
        hover
        style={{ cursor: 'pointer' }}
        onClick={() => history.push(`/chargingstations/${station.chargingStationId}`)}
        onMouseDown={(event) => {
          if (event.button === 1) {
            window.open(`/chargingstations/${station.chargingStationId}`);
            window.focus();
          }
        }}
      >
        {getTableCells().map(cell => (
          <TableCell
            key={cell.id}
            align={cell.align}
            className={cell.cellClass}
          >
            {cell.render(station)}
          </TableCell>
        ))}
        {
          showChangeButton &&
          <TableCell>
            <Button>Breyta</Button>
          </TableCell>
        }

      </TableRow>
    );
  }

  const getTableCells = function (): ITableCell[] {
    return [
      {
        id: 'location',
        label: 'Staður',
        sortable: true,
        render: ({ location }) => location
      },
      {
        id: 'name',
        label: 'Nafn',
        sortable: true,
        render: ({ name }) => name
      },
      {
        id: 'category',
        label: 'Tegund',
        sortable: true,
        render: ({ type }) => {
          var category = categories.find(c => c._id === type);
          if (category) {
            return category.category;
          }
          return '';
        }
      },
      {
        id: 'provider',
        label: 'Þjónustuaðili',
        sortable: true,
        render: ({ provider }) => {
          var p = providers.find(p => p._id === provider);
          if (p) {
            return p.name;
          }
          return '';
        }
      },
      {
        id: 'kw',
        label: 'Kw',
        sortable: true,
        render: ({ kw }) => kw
      },
      {
        id: 'connectors',
        label: 'Tenglar',
        sortable: true,
        render: ({ connectors }) => connectors
      }
    ];
  }
  return (
    <Paper>
      {filters && renderFilterArea()}
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {getTableCells().map(cell => (
                <TableCell key={cell.id} align={cell.align}>
                  {cell.sortable ? (
                    <TableSortLabel
                      active={!!sort[cell.id]}
                      direction={sort[cell.id] === 'DESC' ? 'desc' : 'asc'}
                      onClick={() =>
                        setSort({
                          [cell.id]: sort[cell.id] === 'ASC' ? 'DESC' : 'ASC'
                        })
                      }
                    >
                      {cell.label}
                    </TableSortLabel>
                  ) : (
                    cell.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stations.map(renderTableRow)}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default ChargingStationTable;
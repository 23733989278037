import ApolloClient, { DefaultOptions } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { from } from 'apollo-link';

export const createApolloConfig = (accessToken: string) => {
  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API_URL}/graphql`
  });
  const uploadLink = createUploadLink({
    uri: `${process.env.REACT_APP_API_URL}/graphql`
  });

  const authLink = setContext(async (_, { headers }) => ({
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : ''
    }
  }));

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  };
  //const link = ApolloLink.from([authLink, httpLink, uploadLink]);
  return new ApolloClient({
    link: from([authLink, uploadLink, httpLink]),
    cache: new InMemoryCache({ addTypename: false }),
    defaultOptions: defaultOptions
  });
};

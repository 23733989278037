import React, { useState } from 'react';
import moment from 'moment';
import {
  makeStyles,
  Typography,
  Grid,
  Paper,
  Button,
  TextField,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import { Formik, Form, FormikProps } from 'formik';
import { useQuery, useMutation } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import * as generator from 'generate-password';
import * as Yup from 'yup';
import cx from 'classnames';

import {
  IUser,
  IUserServiceProvider,
  IUserRoles,
  IRegions
} from '../interfaces/User/User';
import { DATE_AND_TIME_FORMAT } from '../constants';
import {
  UPDATE_USER,
  UPDATE_USER_ROLE,
  BLOCK_USER,
  UNBLOCK_USER,
  CREATE_USER,
  REQUEST_NEW_PASSWORD,
  DELETE_USER
} from '../graphql/mutations/UserMutations';
import { USER_ROLES, REGIONS } from '../graphql/queries/UserQueries';
import useAuthActions from '../hooks/useAuthActions';
import useAuthState from '../hooks/useAuthState';
import useNotifier from '../hooks/useNotifier';
import useAdminChecker from '../hooks/useAdminChecker';
import Dropdown from './Dropdown';
import Autocomplete from './Autocomplete';
import SavePrompt from './SavePrompt';
import { getIcelandicName } from '../utils/locale';
import ConfirmDialog from './ConfirmDialog';

interface IProps {
  user?: IUser;
}

interface IFormField {
  key: string;
  label: string;
  type?: 'text' | 'select' | 'multiSelect' | 'switch';
  required?: boolean;
  disabled?: (formik: IFormik) => boolean;
  options?: IOption[];
  switchOffLabel?: string;
}

interface IFormValues {
  name: string;
  email: string;
  companySSN: string;
  role: string;
  regions: IOption[];
  active: boolean;
}

interface IOption {
  value: string;
  label: string;
}

type IFormik = FormikProps<IFormValues>;

const useStyles = makeStyles(theme => ({
  section: {
    minHeight: 200,
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3)
  },
  sectionTitle: {
    marginBottom: theme.spacing(3)
  },
  buttons: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3)
    }
  },
  button: {
    padding: 10
  },
  accessTitle: {
    marginBottom: theme.spacing(1)
  },
  accessField: {
    marginBottom: theme.spacing(3)
  },
  serviceProvider: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(1),
    borderRadius: theme.shape.borderRadius
  },
  active: {
    borderRadius: theme.shape.borderRadius,
    height: 15,
    width: 15,
    margin: theme.spacing(0, 2),
    backgroundColor: theme.palette.success.main
  },
  inactive: {
    backgroundColor: theme.palette.error.main
  }
}));

const schema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().email().required(),
  companySSN: Yup.string().length(10),
  role: Yup.string().required()
});

const UserForm: React.FC<IProps> = ({ user }) => {
  const classes = useStyles();
  const history = useHistory();
  const { login } = useAuthActions();
  const { user: currentUser } = useAuthState();
  const isAdmin = useAdminChecker();
  const notifier = useNotifier();
  const [loadingPassword, setLoadingPassword] = useState(false);
  const [loadingRemoveUser, setLoadingRemoveUser] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: ['getUserDetails']
  });
  const [updateUserRole] = useMutation(UPDATE_USER_ROLE);
  const [blockUser] = useMutation(BLOCK_USER);
  const [unblockUser] = useMutation(UNBLOCK_USER);
  const [createUser] = useMutation(CREATE_USER);
  const [requestNewPassword] = useMutation(REQUEST_NEW_PASSWORD);
  const [removeUser] = useMutation(DELETE_USER);
  const { data: rolesData } = useQuery<IUserRoles>(USER_ROLES);
  const roles = rolesData ? rolesData.Roles : [];
  const { data: regionsData } = useQuery<IRegions>(REGIONS);
  const regions = regionsData ? regionsData.Regions : [];

  const isCurrentUser = user && currentUser.sub === user.user_id;
  const userRole =
    user && user.roles && user.roles.length ? user.roles[0].id : '';
  const { userFields, accessFields } = getFormFields();

  return (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={onSubmit}
      validationSchema={schema}
      validateOnBlur={!!user}
      validateOnChange={!!user}
    >
      {formik => (
        <React.Fragment>
          <Form onSubmit={formik.handleSubmit}>
            <Grid container direction="row" spacing={3}>
              <Grid
                item
                xs={12}
                md={user ? 7 : undefined}
                lg={user ? 8 : undefined}
              >
                {renderMainArea(formik)}
              </Grid>
              {user && (
                <Grid item xs={12} md={5} lg={4}>
                  {renderSideArea(formik)}
                </Grid>
              )}
            </Grid>
            <SavePrompt formik={formik} />
          </Form>
        </React.Fragment>
      )}
    </Formik>
  );

  function renderMainArea(formik: IFormik) {
    return (
      <React.Fragment>
        <Paper className={classes.section}>
          <Typography variant="h6" className={classes.sectionTitle}>
            Notendaupplýsingar
          </Typography>
          <Grid container spacing={3}>
            {userFields.map(field => (
              <Grid item xs={12} md={6} key={field.key}>
                {renderFormField(field, formik)}
              </Grid>
            ))}
          </Grid>
        </Paper>

        <Paper className={classes.section}>
          <Typography variant="h6" className={classes.accessTitle}>
            Aðgangsstýringar
          </Typography>
          <Grid container>
            <Grid item xs={12} lg={6}>
              {accessFields.map((field, index) => (
                <div
                  key={field.key}
                  className={cx({
                    [classes.accessField]: index < accessFields.length - 1
                  })}
                >
                  {renderFormField(field, formik)}
                </div>
              ))}
            </Grid>
          </Grid>
        </Paper>

        {user ? (
          <Paper className={classes.section}>
            <Typography variant="h6" className={classes.sectionTitle}>
              Fyrirtæki
            </Typography>
            {user.serviceProviders && user.serviceProviders.length ? (
              user.serviceProviders.map(renderServiceProviderItem)
            ) : (
              <Typography>Engin fyrirtæki skráð á notanda</Typography>
            )}
          </Paper>
        ) : (
          renderButtons(formik, true)
        )}
      </React.Fragment>
    );
  }

  function renderServiceProviderItem({
    serviceProviderId,
    translations,
    active
  }: IUserServiceProvider) {
    return (
      <Grid
        key={serviceProviderId}
        container
        direction="row"
        alignItems="center"
        className={classes.serviceProvider}
      >
        <Typography style={{ flex: 1 }}>
          {getIcelandicName(translations)}
        </Typography>
        <div className={cx(classes.active, { [classes.inactive]: !active })} />
        <Button
          color="primary"
          style={{ padding: 0 }}
          onClick={() =>
            history.push(`/service-providers/${serviceProviderId}`)
          }
        >
          Breyta
        </Button>
      </Grid>
    );
  }

  function renderFormField(field: IFormField, formik: IFormik) {
    const { key, label, type, options, switchOffLabel, required } = field;
    const { values, errors, handleChange, handleBlur, setFieldValue } = formik;
    const disabled = field.disabled && field.disabled(formik);

    if (type === 'select') {
      return (
        <Dropdown
          id={key}
          key={key}
          value={options && options.length ? values[key] : ''}
          error={!!errors[key]}
          label={label}
          items={options}
          onChange={handleChange}
          disabled={disabled}
          required={required}
          fullWidth
        />
      );
    }

    if (type === 'multiSelect') {
      return (
        <Autocomplete
          id={key}
          values={values[key]}
          error={!!errors[key]}
          label={label}
          items={options}
          onChange={val => setFieldValue(key, val)}
          disabled={disabled}
          required={required}
          fullWidth
        />
      );
    }

    if (type === 'switch') {
      return (
        <FormControlLabel
          key={key}
          label={values[key] || !switchOffLabel ? label : switchOffLabel}
          disabled={disabled}
          control={
            <Switch
              id={key}
              checked={values[key]}
              onChange={handleChange}
              color="primary"
            />
          }
        />
      );
    }

    return (
      <TextField
        id={key}
        key={key}
        label={label}
        value={values[key]}
        error={!!errors[key]}
        onChange={handleChange}
        onBlur={handleBlur}
        variant="outlined"
        disabled={disabled}
        required={required}
        fullWidth
      />
    );
  }

  function renderSideArea(formik: IFormik) {
    return (
      <React.Fragment>
        <Paper className={classes.section}>
          <Typography variant="h6" className={classes.sectionTitle}>
            Upplýsingar
          </Typography>
          <Typography gutterBottom>
            Notandi stofnaður:{' '}
            {moment(user.created_at).format(DATE_AND_TIME_FORMAT)}
          </Typography>
          <Typography gutterBottom>
            Skráður inn síðast:{' '}
            {user.last_login
              ? moment(user.last_login).format(DATE_AND_TIME_FORMAT)
              : 'Aldrei'}
          </Typography>
        </Paper>

        <Paper className={classes.section}>
          <Typography variant="h6" className={classes.sectionTitle}>
            Endurstilla lykilorð
          </Typography>
          <Button
            color="primary"
            variant="contained"
            onClick={requestPassword}
            disabled={loadingPassword}
            fullWidth
            className={classes.button}
          >
            Sækja um nýtt lykilorð
          </Button>
        </Paper>
        <Paper className={classes.section}>
          <Typography variant="h6" className={classes.sectionTitle}>
            Eyða notanda
          </Typography>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setDeleteDialogOpen(true)}
            disabled={loadingRemoveUser}
            fullWidth
            className={classes.button}
          >
            Eyða
          </Button>
          <ConfirmDialog
            open={deleteDialogOpen}
            message="Ertu viss um að þú viljir eyða notanda?"
            confirmText="Eyða"
            onConfirm={() => {
              deleteUser();
              setDeleteDialogOpen(false);
            }}
            onCancel={() => setDeleteDialogOpen(false)}
          />
        </Paper>
        {renderButtons(formik)}
      </React.Fragment>
    );
  }

  function renderButtons(formik: IFormik, create?: boolean) {
    const disableSubmit =
      !formik.dirty || formik.isSubmitting || (!formik.isValid && !create);

    return (
      <Grid container spacing={2} className={classes.buttons}>
        <Grid item xs={12} md={create ? 6 : undefined}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            disabled={disableSubmit}
            className={classes.button}
          >
            Vista
          </Button>
        </Grid>
        <Grid item xs={12} md={create ? 6 : undefined}>
          <Button
            color="default"
            variant="contained"
            fullWidth
            disabled={formik.isSubmitting}
            className={classes.button}
            onClick={() => history.push('/users')}
          >
            Hætta við
          </Button>
        </Grid>
      </Grid>
    );
  }

  function getInitialValues() {
    return {
      name: user ? user.name : '',
      email: user ? user.email : '',
      companySSN: user && user.app_metadata ? user.app_metadata.companySSN : '',
      role: userRole,
      regions:
        user && user.regions
          ? user.regions.map(r => ({
            value: r.id,
            label: getIcelandicName(r.translations)
          }))
          : [],
      active: !user || !user.blocked
    };
  }

  function getFormFields(): { [key: string]: IFormField[] } {
    return {
      userFields: [
        {
          key: 'name',
          label: 'Nafn',
          required: true
        },
        {
          key: 'email',
          label: 'Netfang',
          required: true,
          disabled: () => !isAdmin
        },
        {
          key: 'companySSN',
          label: 'Kennitala fyrirtækis'
        }
      ],
      accessFields: [
        {
          key: 'active',
          label: 'Virkur',
          type: 'switch',
          disabled: () => isCurrentUser,
          switchOffLabel: 'Óvirkur'
        },
        {
          key: 'role',
          label: 'Hlutverk',
          type: 'select',
          required: true,
          disabled: () => isCurrentUser,
          options: roles.map(r => ({ value: r.id, label: r.name }))
        },
        {
          key: 'regions',
          label: 'Landsvæði',
          type: 'multiSelect',
          disabled: formik => isCurrentUser || !editorRoleSelected(formik),
          options: regions.map(r => ({
            value: r.id,
            label: getIcelandicName(r.translations)
          }))
        }
      ]
    };
  }

  function editorRoleSelected(formik: IFormik) {
    let editorId = '';
    roles.forEach(r => {
      if (r.name === 'Editor') {
        editorId = r.id;
      }
    });

    return editorId && formik.values.role === editorId;
  }

  async function onSubmit(values: IFormValues) {
    if (user) {
      await updateUserDetails(values);
    } else {
      await createNewUser(values);
    }
  }

  async function updateUserDetails(values: IFormValues) {
    try {
      const updates = [];
      const { name, email, companySSN, role, active } = values;

      if (role && role !== userRole) {
        updates.push(
          updateUserRole({
            variables: { userId: user.user_id, roleId: role }
          })
        );
      }
      if (active === user.blocked) {
        updates.push(
          active
            ? unblockUser({ variables: { userId: user.user_id } })
            : blockUser({ variables: { userId: user.user_id } })
        );
      }

      await Promise.all(updates);
      await updateUser({
        variables: {
          userId: user.user_id,
          userUpdate: {
            name,
            email,
            companySSN,
            regionIds: values.regions.map(r => r.value)
          }
        }
      });
      notifier.notify('Notandi uppfærður', { variant: 'success' });
    } catch {
      notifier.notify('Mistókst að uppfæra notanda', { variant: 'error' });
    }

    if (isCurrentUser) {
      login();
    }
  }

  async function createNewUser(values: IFormValues) {
    try {
      const { name, email, companySSN, role, active } = values;
      const res = await createUser({
        variables: {
          userInfo: {
            name,
            email,
            companySSN,
            regionIds: values.regions.map(r => r.value),
            password: generator.generate()
          }
        }
      });

      const newUser = res.data.createUser;
      if (newUser) {
        await updateUserRole({
          variables: { userId: newUser.user_id, roleId: role }
        });
        await requestNewPassword({ variables: { email: newUser.email } });
        if (!active) {
          await blockUser({ variables: { userId: newUser.user_id } });
        }
      }

      notifier.notify('Nýr notandi skráður', { variant: 'success' });
      history.push('/users');
    } catch (e) {
      notifier.notify('Mistókst að skrá nýjan notanda', { variant: 'error' });
    }
  }

  async function requestPassword() {
    setLoadingPassword(true);
    try {
      await requestNewPassword({ variables: { email: user.email } });
      notifier.notify('Beiðni um lykilorð send', { variant: 'success' });
    } catch {
      notifier.notify('Mistókst að sækja um lykilorð', { variant: 'error' });
    }
    setLoadingPassword(false);
  }

  async function deleteUser() {
    setLoadingRemoveUser(true);
    try {
      await removeUser({ variables: { userId: user.user_id } });
      notifier.notify('Notanda hefur verið eytt', { variant: 'success' });
      history.push('/users');
    } catch {
      notifier.notify('Mistókst að eyða notanda', { variant: 'error' });
    }
    setLoadingRemoveUser(false);
  }
};

export default UserForm;

import React from 'react';
import useAuthState from '../hooks/useAuthState';
import FullPageLoader from '../components/FullPageLoader';

function withSplashScreen<P extends object>(
  WrappedComponent: React.ComponentType<P>
) {
  const WithSplash: React.FC<P> = props => {
    const authState = useAuthState();

    return !authState.isReady ? (
      <FullPageLoader />
    ) : (
      <WrappedComponent {...props} />
    );
  };

  return WithSplash;
}

export default withSplashScreen;

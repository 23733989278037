/* global google */
import React, { memo, useContext } from 'react';
import MapsContext from './Context';
import { IRendererProps, TPolygonStyle } from './types';
import { ILatLng } from '../../common-types';

interface IProps {
  path: ILatLng[];
  editable?: boolean;
  draggable?: boolean;
  onPathChange?: (path: ILatLng[]) => void;
  polygonStyle?: TPolygonStyle;
}
interface IState {
  polygon: google.maps.Polygon | null;
}

class PolygonRenderer extends React.PureComponent<
  IProps & IRendererProps,
  IState
> {
  state: IState = {
    polygon: null
  };

  componentDidMount() {
    this.renderPolygon();
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.path !== this.props.path) {
      this.renderPolygon();
    }
  }

  componentWillUnmount() {
    const { polygon } = this.state;

    if (polygon) {
      this.removeListeners(polygon);
      polygon.setMap(null);
    }
  }

  render() {
    return null;
  }

  setupListeners(polygon: google.maps.Polygon) {
    const { editable, draggable, maps } = this.props;

    if (editable) {
      polygon
        .getPath()
        .addListener('insert_at', () => this.handlePolygonChanged(polygon));
      polygon
        .getPath()
        .addListener('remove_at', () => this.handlePolygonChanged(polygon));
      polygon
        .getPath()
        .addListener('set_at', () => this.handlePolygonChanged(polygon));
      polygon.addListener('rightclick', (mev: google.maps.PolyMouseEvent) =>
        this.handleRightclick(polygon, mev)
      );
    }

    if (draggable) {
      // The set_at event is triggered repeatedly when dragging the polygon. Remove the set_at
      // event listener while dragging to avoid this.
      polygon.addListener('dragstart', () => {
        maps.event.clearListeners(polygon.getPath(), 'set_at');
      });
      polygon.addListener('dragend', () => {
        this.handlePolygonChanged(polygon);
        polygon
          .getPath()
          .addListener('set_at', () => this.handlePolygonChanged(polygon));
      });
    }
  }

  removeListeners(polygon: google.maps.Polygon) {
    const { maps } = this.props;

    maps.event.clearInstanceListeners(polygon);
    maps.event.clearInstanceListeners(polygon.getPath());
  }

  renderPolygon() {
    const {
      map,
      maps,
      path,
      draggable = false,
      editable = false,
      polygonStyle = {}
    } = this.props;
    const { polygon } = this.state;

    if (polygon) {
      this.removeListeners(polygon);
      polygon.setMap(null);
    }

    const newPolygon = new maps.Polygon(polygonStyle);
    newPolygon.setPath(path);
    newPolygon.setDraggable(draggable);
    newPolygon.setEditable(editable);

    this.setupListeners(newPolygon);
    newPolygon.setMap(map);
    this.setState({ polygon: newPolygon });
  }

  handlePolygonChanged = (polygon: google.maps.Polygon) => {
    const { onPathChange } = this.props;

    if (onPathChange) {
      let path = polygon
        .getPath()
        .getArray()
        .map(latlng => ({ lat: latlng.lat(), lng: latlng.lng() }));

      if (path.length < 3) {
        path = [];
      }

      onPathChange(path);
    }
  };

  handleRightclick = (
    polygon: google.maps.Polygon,
    mev: google.maps.PolyMouseEvent
  ) => {
    if (mev.vertex !== undefined) {
      polygon.getPath().removeAt(mev.vertex);
    }
  };
}

const Polygon: React.FC<IProps> = props => {
  const { map, maps, isLoaded } = useContext(MapsContext);

  if (!isLoaded || !map || !maps) {
    return null;
  }

  return <PolygonRenderer map={map} maps={maps} {...props} />;
};

export default memo(Polygon);

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import themeReducer from './theme/reducer';
import authReducer from './auth/reducer';
import notifierReducer from './notifier/reducer';
import { IApplicationState } from './types';

export default (history: History) =>
  combineReducers<IApplicationState>({
    router: connectRouter(history),
    theme: themeReducer,
    auth: authReducer,
    notifier: notifierReducer
  });

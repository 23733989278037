import { gql } from 'apollo-boost';
import { serviceProviderFragment } from '../fragments/ServiceProviderFragments';

export const UPDATE_SERVICE_PROVIDER = gql`
  mutation updateServiceProvider(
    $serviceProviderId: String!
    $values: ServiceProviderUpdate!
  ) {
    updateServiceProvider(
      serviceProviderId: $serviceProviderId
      values: $values
    ) {
      ...serviceProvider
      categories {
        id
        translations {
          locale
          name
        }
        subCategories {
          id
          translations {
            locale
            name
          }
        }
      }
      relatedServiceProviders {
        ...serviceProvider
      }
      revision {
        ...serviceProvider
      }
      pendingRevision {
        ...serviceProvider
      }
    }
  }
  ${serviceProviderFragment}
`;

export const ACCEPT_SERVICE_PROVIDER = gql`
  mutation accept($serviceProviderId: String!) {
    acceptServiceProvider(serviceProviderId: $serviceProviderId) {
      ...serviceProvider
      categories {
        id
        translations {
          locale
          name
        }
        subCategories {
          id
          translations {
            locale
            name
          }
        }
      }
      relatedServiceProviders {
        ...serviceProvider
      }
      revision {
        ...serviceProvider
      }
    }
  }
  ${serviceProviderFragment}
`;

export const REJECT_SERVICE_PROVIDER = gql`
  mutation rejectServiceProvider(
    $serviceProviderId: String!
    $title: String!
    $message: String!
  ) {
    rejectServiceProvider(
      serviceProviderId: $serviceProviderId
      title: $title
      message: $message
    ) {
      success
    }
  }
`;

export const ACCEPT_CHANGES_SERVICE_PROVIDER = gql`
  mutation accepChanges($serviceProviderId: String!) {
    acceptServiceProviderChanges(serviceProviderId: $serviceProviderId) {
      success
    }
  }
`;

export const REJECT_CHANGES_SERVICE_PROVIDER = gql`
  mutation rejectChanges(
    $serviceProviderId: String!
    $title: String!
    $message: String!
  ) {
    rejectChanges(
      serviceProviderId: $serviceProviderId
      title: $title
      message: $message
    ) {
      success
    }
  }
`;

export const CREATE_SERVICE_PROVIDER = gql`
  mutation create($values: ServiceProviderInput!) {
    createServiceProvider(values: $values) {
      ...serviceProvider
      categories {
        id
        translations {
          locale
          name
        }
        subCategories {
          id
          translations {
            locale
            name
          }
        }
      }
      relatedServiceProviders {
        ...serviceProvider
      }
    }
  }
  ${serviceProviderFragment}
`;

export const REMOVE_SERVICE_PROVIDER = gql`
  mutation removeServiceProvider($serviceProviderId: String!) {
    removeServiceProvider(serviceProviderId: $serviceProviderId) {
      success
    }
  }
`;

export const VALIDATE_SERVICE_PROVIDER = gql`
  mutation verifyServiceProvider($serviceProviderId: String!) {
    verifyServiceProvider(serviceProviderId: $serviceProviderId) {
      success
    }
  }
`;

export const ADD_COLLECTION = gql`
  mutation createCollection(
    $serviceProviderId: String!
    $categoryId: String
    $value: CollectionContentInput!
  ) {
    createCollection(
      serviceProviderId: $serviceProviderId
      categoryId: $categoryId
      value: $value
    ) {
      id
      category
      content {
        locale
        title
        text
        seoKeywords
        seoDescription
      }
    }
  }
`;

export const EDIT_COLLECTION = gql`
  mutation editCollection(
    $serviceProviderId: String!
    $collectionId: String!
    $categoryId: String
    $value: CollectionContentUpdate!
  ) {
    editCollection(
      serviceProviderId: $serviceProviderId
      collectionId: $collectionId
      categoryId: $categoryId
      value: $value
    ) {
      id
      category
      content {
        locale
        title
        text
        seoKeywords
        seoDescription
      }
    }
  }
`;
export const DELETE_COLLECTION = gql`
  mutation removeCollection(
    $serviceProviderId: String!
    $collectionId: String!
  ) {
    removeCollection(
      serviceProviderId: $serviceProviderId
      collectionId: $collectionId
    ) {
      success
    }
  }
`;

export const ADD_PHOTO_COLLECTION = gql`
  mutation createPhotoCollection(
    $serviceProviderId: String!
    $categoryId: String
    $name: String!
  ) {
    createPhotoCollection(
      serviceProviderId: $serviceProviderId
      categoryId: $categoryId
      name: $name
    ) {
      id
      name
      category
      photos {
        photoUri
        photoId
        alt
        title
      }
    }
  }
`;

export const EDIT_PHOTO_COLLECTION = gql`
  mutation editPhotoCollection(
    $serviceProviderId: String!
    $collectionId: String!
    $categoryId: String
    $name: String
  ) {
    editPhotoCollection(
      serviceProviderId: $serviceProviderId
      collectionId: $collectionId
      categoryId: $categoryId
      name: $name
    ) {
      id
      name
      category
      photos {
        photoUri
        photoId
        alt
        title
      }
    }
  }
`;

export const DELETE_PHOTO_COLLECTION = gql`
  mutation removePhotoCollection(
    $serviceProviderId: String!
    $collectionId: String!
  ) {
    removePhotoCollection(
      serviceProviderId: $serviceProviderId
      collectionId: $collectionId
    ) {
      success
    }
  }
`;

export const ADD_PHOTO_TO_COLLECTION = gql`
  mutation addServiceProviderPhoto(
    $title: String!
    $alt: String!
    $photo: Upload!
    $collectionId: String!
  ) {
    addServiceProviderPhoto(
      title: $title
      alt: $alt
      photo: $photo
      collectionId: $collectionId
    ) {
      id
      name
      category
      photos {
        photoUri
        photoId
        alt
        title
      }
    }
  }
`;

export const EDIT_PHOTO_IN_COLLECTION = gql`
  mutation editPhotoInCollection(
    $title: String
    $alt: String
    $photoId: String!
    $collectionId: String!
  ) {
    editPhotoInCollection(
      title: $title
      alt: $alt
      photoId: $photoId
      collectionId: $collectionId
    ) {
      id
      name
      category
      photos {
        photoUri
        photoId
        alt
        title
      }
    }
  }
`;

export const DELETE_PHOTO_IN_COLLECTION = gql`
  mutation removePhotoFromCollection(
    $photoId: String!
    $collectionId: String!
  ) {
    removePhotoFromCollection(photoId: $photoId, collectionId: $collectionId) {
      id
      name
      category
      photos {
        photoUri
        photoId
        alt
        title
      }
    }
  }
`;

export const ADD_PERMIT_IMAGE = gql`
  mutation addPermitImage(
    $image: Upload!
    $serviceProviderId: String!
  ) {
    addPermitImage(
      image: $image
      serviceProviderId: $serviceProviderId
    ) {
      success
    }
  }
`;

export const DELETE_PERMIT_IMAGE = gql`
  mutation deletePermitImage(
    $serviceProviderId: String!
  ) {
    deletePermitImage(serviceProviderId: $serviceProviderId) {
      success
    }
  }
`;

export const CONFIRM_SERVICE_PROVIDER = gql`
  mutation confirmServiceProvider(
    $serviceProviderId: String!
  ) {
    confirmServiceProvider(serviceProviderId: $serviceProviderId) {
      success
    }
  }
`;
import { gql } from 'apollo-boost';

export const translationFragment = gql`
  fragment translation on BaseTranslation {
    locale
    name
  }
`;

export const placeFragment = gql`
  fragment place on IPlace {
    placeId
    active
    zipCode
    translations {
      locale
      name
    }
    region {
      id
      translations {
        ...translation
      }
    }
  }
  ${translationFragment}
`;

export const placeDetailsFragment = gql`
  fragment placeDetails on IPlace {
    ...place
    townCode
    updatedBy
    updatedAt
    roadCode
    grade
    regionId
    categoryIds
    categories {
      id
      translations {
        ...translation
      }
    }
    location {
      type
      coordinates
    }
    photos {
      photoUri
      photoId
      alt
      title
    }
    translations {
      locale
      name
      description
      seoTitle
      seoKeywords
      seoDescription
    }
    relatedPlaces {
      ...place
    }
    relatedMunicipalities {
      municipalityId
      translations {
        locale
        name
      }
    }
  }
  ${placeFragment}
  ${translationFragment}
`;

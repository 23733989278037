import React, { useState } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Paper,
  Typography,
  Grid,
  TextField,
  Checkbox,
  Button
} from '@material-ui/core';
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';
import includes from 'lodash/includes';
import findIndex from 'lodash/findIndex';
import Joi from '@hapi/joi';
import { Autocomplete } from '@material-ui/lab';
import { ICodes, ICodes2, IAllSigns } from '../interfaces/Misc/zipCodes';
import { ISign } from '../interfaces/ServiceProvider/ServiceProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2)
    },
    paperCollection: {
      backgroundColor: '#f4f4f7',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      borderRadius: 0,
      textAlign: 'center',
      marginBottom: 8
    },
    paperImg: {
      backgroundColor: '#f4f4f7',
      paddingTop: 12,
      paddingBottom: 12,
      textAlign: 'center',
      marginLeft: 16,
      borderRadius: 0,
      borderBottomLeftRadius: 4,
      borderTopLeftRadius: 4
    },
    typographyTitle: {
      padding: 20,
      paddingBottom: 5,
      paddingTop: 10
    },
    typographySigns: {
      paddingBottom: 6,
      paddingTop: 6
    },
    paperSections: {
      marginTop: 20
    },
    submitButon: {
      paddingTop: 16,
      paddingBottom: 16
    }
  })
);

interface IProps {
  signs: ISign[] | [];
  setSigns: React.Dispatch<React.SetStateAction<ISign[] | []>>;
  signsOptions: ICodes[];
  allSigns: IAllSigns[];
  latest: boolean;
  disableEdit: boolean;
}

const EditSigns: React.FC<IProps> = ({
  signs,
  setSigns,
  signsOptions,
  allSigns,
  latest,
  disableEdit
}) => {
  const classes = useStyles({});
  var sign: ICodes2 = {};
  const initalSignForm = { name: sign, value: '' };
  const [signForm, setSignForm] = useState(initalSignForm);
  const [invalidFields, setInvalidFields] = useState([]);
  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" color="primary" />;

  const schema = Joi.object({
    name: Joi.object().min(1).required()
  });

  const handleSubmit = () => {
    var validateValues = {
      name: signForm.name
    };

    const joiValidate = schema.validate(validateValues, { abortEarly: false });
    if (joiValidate.error) {
      const newInvalidFields = joiValidate.error.details.map(
        field => field.path[0]
      );
      setInvalidFields(newInvalidFields);
    } else {
      var signIsign: IAllSigns = allSigns.filter(
        sign => sign.id === signForm.name.value
      )[0];
      const signObject: ISign = {
        ...signIsign,
        value: signForm.value === '' ? null : parseFloat(signForm.value)
      };

      var selectedSigns: ISign[] = [...signs];
      selectedSigns.unshift(signObject);

      setSigns(selectedSigns);
      setInvalidFields([]);
    }
  };

  const deleteCollection = id => {
    var selectedSigns: ISign[] = [...signs];
    const deleteSignal = selectedSigns.filter(sign => sign.id === id)[0];
    const index = findIndex(selectedSigns, deleteSignal);
    selectedSigns.splice(index, 1);
    setSigns(selectedSigns);
  };

  return (
    <div>
      <Paper className={classes.paperSections}>
        <Typography variant="h6" className={classes.typographyTitle}>
          Tákn
        </Typography>
        {latest && (
          <Grid container spacing={0}>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper} elevation={0}>
                <Autocomplete
                  id={'sign'}
                  options={signsOptions}
                  onChange={(_, value) =>
                    setSignForm({ ...signForm, name: value })
                  }
                  value={signForm.name}
                  getOptionLabel={option => option.label}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.label}
                    </React.Fragment>
                  )}
                  style={{ width: '100%' }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password'
                      }}
                      variant="outlined"
                      label={'Tákn'}
                      error={includes(invalidFields, 'name')}
                    />
                  )}
                  disabled={disableEdit}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper className={classes.paper} elevation={0}>
                <TextField
                  fullWidth
                  id={'km'}
                  label={'KM'}
                  variant="outlined"
                  value={signForm.value}
                  type="number"
                  error={includes(invalidFields, 'value')}
                  onChange={e =>
                    setSignForm({ ...signForm, value: e.target.value })
                  }
                  disabled={disableEdit}
                />
              </Paper>
            </Grid>
            {
              !disableEdit &&
              <Grid item xs={12} sm={2}>
                <Paper className={classes.paper} elevation={0}>
                  <Button
                    className={classes.submitButon}
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={() => handleSubmit()}
                  >
                    Bæta við
                  </Button>
                </Paper>
              </Grid>
            }

          </Grid>
        )}
        <Grid container spacing={0}>
          {signs &&
            signs.length > 0 &&
            (signs as ISign[]).map((sign, index) => {
              return (
                <React.Fragment key={index}>
                  <Grid item xs={12} sm={2} key={`${sign.icon}-${index}`}>
                    <Paper className={classes.paperImg} elevation={0}>
                      <img
                        alt={sign.icon}
                        src={sign.icon}
                        style={{ height: 30, marginTop: 7 }}
                      ></img>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={4} key={`${sign.id}-${index}`}>
                    <Paper
                      className={classes.paperCollection}
                      /* style={{marginLeft: 8}} */ elevation={0}
                    >
                      <Typography className={classes.typographySigns}>
                        {sign.translations[0].name}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={3} key={`${sign.value}-${index}`}>
                    <Paper className={classes.paperCollection} elevation={0}>
                      <Typography className={classes.typographySigns}>
                        {sign.value} km
                      </Typography>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} sm={3} key={`button-${index}`}>
                    <Paper
                      className={classes.paperCollection}
                      elevation={0}
                      style={{
                        marginRight: 16,
                        borderBottomRightRadius: 4,
                        borderTopRightRadius: 4
                      }}
                    >
                      <Button
                        color="secondary"
                        onClick={() => deleteCollection(sign.id)}
                        disabled={!latest}
                      >
                        Eyða
                      </Button>
                    </Paper>
                  </Grid>
                </React.Fragment>
              );
            })}
        </Grid>
      </Paper>
    </div>
  );
};

export default EditSigns;

import { all, call, put, select } from 'redux-saga/effects';
import * as authActions from './auth/actions';

import { selectCurrentPathname, getAuthConfig } from './selectors';
import { CALLBACK_ROUTE, SSN_ROUTE } from './../constants';
import { parseHash, isLoggedIn } from '../utils/auth';
import { captureException } from '../utils/sentry';
import authSagas from './auth/sagas';
import navigationSaga from './navigationSaga';

// Initializes the app by fetching the tenant configuration,
// and renewing the active user session if available.
function* init() {
  const authConfig = getAuthConfig();

  // The app loading into the callback route needs to be handled separately
  // because the auth information comes from the URL hash
  const pathname = yield select(selectCurrentPathname);
  if (pathname === CALLBACK_ROUTE) {
    try {
      const result = yield call(parseHash, authConfig);
      yield put(authActions.loggedIn(result));
    } catch (err) {
      captureException(new Error('parseHash errored out'), err);
      yield put(authActions.loggedOut());
    }
    return;
  }

  if (pathname.startsWith(SSN_ROUTE)) {
    // Don't trigger the auth logic for the /ssn page since it is outward facing
    // and no authentication is required to view it.
    yield put(authActions.authReady());
    return;
  }

  // If there is a logged in user from a previous session, we need to renew
  // the session. Otherwise, the user is not logged in.
  if (isLoggedIn()) {
    yield put(authActions.renewSession());
  } else {
    yield put(authActions.loggedOut());
  }
}

function* rootSaga() {
  yield all([init(), navigationSaga(), ...authSagas]);
}

export default rootSaga;

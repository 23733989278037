import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import MUILink from '@material-ui/core/Link';
import Toolbar from '@material-ui/core/Toolbar';
import { fade } from '@material-ui/core/styles/colorManipulator';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { Link } from 'react-router-dom';

import { NAVBAR_HEIGHT } from '../constants';
import useAuthState from '../hooks/useAuthState';
import TopNav from './TopNav';

interface IProps {
  simpleNav?: boolean;
}

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: '#fff',
    boxShadow: '0 3px 6px 0 rgba(114, 114, 114, 0.16)'
  },
  toolbar: {
    height: NAVBAR_HEIGHT
  },
  toolbarGrid: {
    maxWidth: theme.dimensions.maxLayoutWidth,
    margin: '0 auto'
  },
  logoLink: {
    minWidth: 50
  },
  logo: {
    height: 40
  },
  grow: {
    flexGrow: 1
  },
  lowerNavContainer: {
    padding: theme.spacing(2, 3),
    width: '100%',
    backgroundColor: fade(theme.palette.primary.main, 0.3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2)
    }
  },
  lowerNav: {
    maxWidth: theme.dimensions.maxLayoutWidth,
    margin: 'auto'
  },
  lowerLink: {
    textDecoration: 'none',
    marginRight: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      margin: 0
    }
  },
  lowerLinkText: {
    borderBottom: '2px solid transparent',
    '&:hover': {
      borderColor: theme.palette.primary.main
    }
  },
  activeLinkText: {
    fontWeight: theme.typography.fontWeightBold,
    borderColor: theme.palette.primary.main
  }
}));

const Navbar: React.FC<IProps> = ({ simpleNav }) => {
  const classes = useStyles({});
  const theme = useTheme();
  const { user } = useAuthState();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <React.Fragment>
      {sm && (
        <div className={classes.toolbar} style={{ visibility: 'hidden' }} />
      )}
      <AppBar className={classes.header} position={sm ? 'fixed' : 'static'}>
        <Toolbar className={classes.toolbar}>
          <Grid
            container
            className={classes.toolbarGrid}
            justify="center"
            alignItems="center"
          >
            <Grid item>
              {simpleNav ? (
                <MUILink
                  href="https://www.ferdamalastofa.is/"
                  target="_blank"
                  rel="noopener"
                  className={classes.logoLink}
                >
                  {renderLogo()}
                </MUILink>
              ) : (
                <Link aria-label="Home" className={classes.logoLink} to="/">
                  {renderLogo()}
                </Link>
              )}
            </Grid>
            <Grid item className={classes.grow} />
            {!simpleNav && <TopNav data-testid="desktop-nav" user={user} />}
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );

  function renderLogo() {
    return (
      <img
        data-testid="logo"
        alt="logo"
        src="/ferdamalastofa_isl_portrait.png"
        className={classes.logo}
      />
    );
  }
};

export default Navbar;

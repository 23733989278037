import { gql } from 'apollo-boost';
import { municipalityFragment } from '../fragments/MunicipalityFragments';

export const CREATE_MUNICIPALITY = gql`
  mutation createMunicipality($values: MunicipalityInput!) {
    createMunicipality(values: $values) {
      ...municipality
    }
  }
  ${municipalityFragment}
`;

export const UPDATE_MUNICIPALITY = gql`
  mutation updateMunicipality(
    $municipalityId: String!
    $values: MunicipalityUpdate!
  ) {
    updateMunicipality(municipalityId: $municipalityId, values: $values) {
      ...municipality
    }
  }
  ${municipalityFragment}
`;

export const ADD_MUNICIPALITY_PHOTO = gql`
  mutation addMunicipalityPhoto(
    $municipalityId: String!
    $image: Upload!
    $title: String
    $alt: String
  ) {
    addMunicipalityImage(
      municipalityId: $municipalityId
      image: $image
      title: $title
      alt: $alt
    ) {
      ...municipality
      photos {
        photoUri
        photoId
        alt
        title
      }
    }
  }
  ${municipalityFragment}
`;

export const REMOVE_MUNICIPALITY_PHOTO = gql`
  mutation removeMunicipalityPhoto(
    $municipalityId: String!
    $imageId: String!
  ) {
    removeMunicipalityPhoto(
      municipalityId: $municipalityId
      imageId: $imageId
    ) {
      ...municipality
    }
  }
  ${municipalityFragment}
`;

export const EDIT_MUNICIPALITY_PHOTO = gql`
  mutation editMunicipalityPhoto(
    $municipalityId: String!
    $imageId: String!
    $title: String
    $alt: String
  ) {
    editMunicipalityPhoto(
      municipalityId: $municipalityId
      imageId: $imageId
      title: $title
      alt: $alt
    ) {
      photoUri
      photoId
      alt
      title
    }
  }
`;

import React, { useState, useEffect } from 'react';
import {
  Button,
  Paper
} from '@material-ui/core';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

interface IProps {
  file: File,
  setCroppedImage: (file: File) => void,
  confirmCrop: () => void,
  cancelCrop: () => void,
  showCrop: boolean
};

interface ICrop {
  width: number,
  aspect: number,
}

const CropImage: React.FC<IProps> = ({
  file,
  setCroppedImage,
  confirmCrop,
  showCrop,
  cancelCrop
}) => {
  const initialCrop: ICrop = {
    //unit: '%',
    width: 30,
    aspect: 12 / 8
  }
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState(initialCrop);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [imageRef, setImageRef] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);

  useEffect(() => {
    if (file) {
      onSelectFile(file);
    }
  }, [file]);


  const onSelectFile = (selectedFile: File) => {
    const reader = new FileReader();
    reader.addEventListener('load', () =>
      setSrc(reader.result)
    );
    reader.readAsDataURL(file);
  };

  // If you setState the crop in here you should return false.
  const onImageLoaded = (image) => {
    setImageRef(image);
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    setCrop(crop);
  };

  const makeClientCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImage = await getCroppedImg(
        imageRef,
        crop,
        'newFile.jpeg'
      );
      const fileReaderInstance = new FileReader();
      fileReaderInstance.readAsDataURL(croppedImage);
      fileReaderInstance.onload = () => {
        setCroppedImageUrl(fileReaderInstance.result as ArrayBuffer);
      }
      //setCroppedImageUrl(croppedImage);
      setCroppedImage(new File([croppedImage], 'croppedImage', { type: 'image/jpeg' }));
    }
  }

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise<Blob>((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            return;
          }
          blob['name'] = fileName;

          if (fileUrl) {
            window.URL.revokeObjectURL(fileUrl);
          }
          setFileUrl(window.URL.createObjectURL(blob));
          const fileReaderInstance = new FileReader();
          fileReaderInstance.readAsDataURL(blob);
          fileReaderInstance.onload = () => {
            //resolve(fileReaderInstance.result as ArrayBuffer);
            resolve(blob);
          }
        },
        'image/jpeg',
        1
      );
    });
  }
  return (
    <div>
      {src && showCrop && (
        <Paper
          elevation={0}
          style={{
            textAlign: 'center',
            padding: '2px'
          }}
        >
          <ReactCrop
            src={src}
            crop={crop}
            ruleOfThirds
            onImageLoaded={onImageLoaded}
            onComplete={onCropComplete}
            onChange={onCropChange}
          />
        </Paper>
      )}
      {croppedImageUrl && showCrop && (
        <Paper
          elevation={0}
          style={{
            textAlign: 'right'
          }}
        >
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              cancelCrop();
            }}>
            Hætta við
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              confirmCrop();
            }}>
            Staðfesta
          </Button>
        </Paper>
      )}
      {!showCrop &&
        <Paper
          elevation={0}
          style={{ textAlign: 'center' }}
        >
          <img style={{ maxWidth: '100%', alignSelf: 'center' }} src={src} />
        </Paper>
      }

    </div>
  );
}

export default CropImage;
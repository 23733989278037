import React from 'react';
import CreateServiceProviderForm from '../components/CreateServiceProviderForm';
import Layout from '../components/Layout';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useMutation } from 'react-apollo';
import keys from 'lodash/keys';
import useNotifier from '../hooks/useNotifier';
import { CREATE_SERVICE_PROVIDER } from '../graphql/mutations/serviceProvider';
import {
  CreateProvider,
  ISign
} from '../interfaces/ServiceProvider/ServiceProvider';
import { IS_LOCALE, EN_LOCALE, ES_LOCALE, DE_LOCALE, FR_LOCALE } from '../utils/locale';

interface IProps { }

const CreateServiceProvider: React.FC<IProps> = () => {
  const [createServiceProvider] = useMutation(CREATE_SERVICE_PROVIDER);
  const history = useHistory();
  const notifier = useNotifier();

  const handleFormSubmit = (values: any, signs: ISign[]) => {
    var translations = [
      { locale: IS_LOCALE, name: values.name },
      { locale: EN_LOCALE, name: values.nameEn }
    ];
    if (values.nameEs) {
      translations.push({
        locale: ES_LOCALE,
        name: values.nameEs
      });
    }
    if (values.nameDe) {
      translations.push({
        locale: DE_LOCALE,
        name: values.nameDe
      });
    }
    if (values.nameFr) {
      translations.push({
        locale: FR_LOCALE,
        name: values.nameFr
      });
    }
    const newServiceProvider: CreateProvider = {
      SSN: values.SSN,
      legalName: values.legalName,
      translations: [
        { locale: IS_LOCALE, name: values.name },
        { locale: EN_LOCALE, name: values.nameEn }
      ],
      address: values.address,
      poBox: values.poBox,
      zipCode: values.zipCode.value,
      townCode: values.townCode ? values.townCode.value : null,
      eService: values.eService === 'true' ? true : false,
      phoneNr: values.phoneNr,
      phoneNr2: values.phoneNr2 === '' ? null : values.phoneNr2,
      email: values.email,
      email2: values.email2 === '' ? null : values.email2,
      website: values.website,
      website2: values.website2 === '' ? null : values.website2,
      facebook: values.facebook === '' ? null : values.facebook,
      instagram: values.instagram === '' ? null : values.instagram,
      tripadvisor: values.tripadvisor === '' ? null : values.tripadvisor,
      youtube: values.youtube === '' ? null : values.youtube,
      giftcert: values.giftcert === 'true' ? true : false,
      cleanAndSafe: values.cleanAndSafe === 'true' ? true : false,
      isat: values.isat === '' ? null : values.isat,
      nrOfRooms: values.nrOfRooms === '' ? null : parseInt(values.nrOfRooms),
      nrOfBeds: values.nrOfBeds === '' ? null : parseInt(values.nrOfBeds),
      nrOfApartments:
        values.nrOfApartments === '' ? null : parseInt(values.nrOfApartments),
      nrOfHouses: values.nrOfHouses === '' ? null : parseInt(values.nrOfHouses),
      nrOfSleepingBagSpace:
        values.nrOfSleepingBagSpace === ''
          ? null
          : parseInt(values.nrOfSleepingBagSpace),
      vakinn: {
        isApprovedTourismService:
          values.isApprovedTourismService === 'true' ? true : false,
        isApprovedRestaurant:
          values.isApprovedRestaurant === 'true' ? true : false,
        stars: values.stars === '' ? null : parseInt(values.stars),
        ecoLabel: values.ecoLabel ? values.ecoLabel.toUpperCase() : null,
        superior: values.superior === 'true' ? true : false,
        iso: values.iso === 'true' ? true : false,
        swan: values.swan === 'true' ? true : false,
        blueFlag: values.blueFlag === 'true' ? true : false,
        earthcheck: values.earthcheck === 'true' ? true : false,
        greenKey: values.greenKey === 'true' ? true : false
      },
      signItems: signs.map(s => ({ id: s.id, value: s.value })),
      openingHours: {
        seasonal: values.seasonal === 'true' ? true : false,
        from: values.from === null ? null : moment(values.from).toISOString(),
        to: values.to === null ? null : moment(values.to).toISOString()
      },
      contactInfo: {
        name: values.contactName,
        email: values.contactEmail,
        phone: values.contactPhone === '' ? null : values.contactPhone,
        comment: values.contactComment === '' ? null : values.contactComment
      },
      purchasedRegionIds: values.purhcasedRegistration.map(
        region => region.value
      ),
      categoryIds: keys(values.categories).filter(
        key => !!values.categories[key]
      ),
      serviceProviderIds: values.serviceProviderIds.map(
        provider => provider.value
      ),
      regionIds: values.regions.map(region => region.value),
      location:
        values.latitude && values.longitude
          ? {
            type: 'Point',
            coordinates: [
              parseFloat(values.longitude),
              parseFloat(values.latitude)
            ]
          }
          : undefined,
      placeIds: values.places.map(place => place.value),
      accessible: values.accessible === "" ? null : values.accessible
    };

    createServiceProvider({
      variables: { values: newServiceProvider }
    })
      .then(res => {
        notifier.notify('Umsókn um nýja ferðaþjónustu tókst', {
          variant: 'success'
        });
        history.push(
          `/service-providers/${res.data.createServiceProvider.serviceProviderId}`
        );
      })
      .catch(e => {
        notifier.notify('Villa kom upp', { variant: 'error' });
      });
  };

  return (
    <Layout useDefaultSpacing>
      <CreateServiceProviderForm
        handleFormSubmit={handleFormSubmit}
      ></CreateServiceProviderForm>
    </Layout>
  );
};

export default CreateServiceProvider;

import React, { useState } from 'react';
import {
  makeStyles,
  Grid,
  Paper,
  Button,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableSortLabel
} from '@material-ui/core';
import cx from 'classnames';
import { useHistory, Prompt } from 'react-router-dom';
import { useQuery } from 'react-apollo';

import { TABLE_ROWS_OPTIONS } from '../constants';
import { GET_DATA_WITH_GEO } from '../graphql/queries/Misc';
import { IPlaceLite, IPlaceCategories } from '../interfaces/Place/Place';
import Dropdown from './Dropdown';
import Autocomplete, { IOption } from './Autocomplete';
import TablePaginationActions from './TablePaginationActions';
import { IDataWithFences } from '../interfaces/Misc/zipCodes';
import { IFilters, ISort } from '../pages/Places';
import { getIcelandicName } from '../utils/locale';
import { IExportPlaceStatus } from '../utils/export';
import { getLastSearch, setLastSearch, clearLastSearch } from '../store/localStorage/lastSearch';

interface IProps {
  places: IPlaceLite[];
  count: number;
  currentPage: number;
  rowsPerPage: number;
  initialFilters: IFilters;
  sort: ISort;
  placeCategories: IPlaceCategories;
  setCurrentPage: (page: number) => void;
  setRowsPerPage: (rows: number) => void;
  searchForPlaces: (filters: IFilters) => void;
  updateSort: (sort: ISort) => void;
  exportData: (filter: IFilters) => void;
  exportStatus: IExportPlaceStatus;
}

interface ITableCell {
  id: string;
  label: string;
  align?: 'left' | 'right' | 'center';
  sortable?: boolean;
  render: (place: IPlaceLite) => React.ReactNode;
  cellClass?: string;
}

const useStyles = makeStyles(theme => ({
  filters: {
    margin: theme.spacing(0),
    width: '100%'
  },
  button: {
    padding: theme.spacing(2, 3)
  },
  activeCell: {
    width: 150
  },
  active: {
    color: 'white',
    backgroundColor: theme.palette.success.main,
    borderRadius: 5,
    padding: 2,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'center'
  },
  inactive: {
    backgroundColor: theme.palette.error.main
  }
}));

const activeOptions = [
  { label: 'Bæði', value: 'both' },
  { label: 'Í birtingu', value: 'active', active: true },
  { label: 'Ekki í birtingu', value: 'inactive', active: false }
];

const categoriesOptions = [
  { label: 'Já', value: 'true', active: true },
  { label: 'Nei', value: 'false', active: false }
];

const PlaceTable: React.FC<IProps> = ({
  places,
  count,
  currentPage,
  rowsPerPage,
  initialFilters,
  sort,
  setCurrentPage,
  setRowsPerPage,
  searchForPlaces,
  updateSort,
  placeCategories,
  exportData,
  exportStatus
}) => {
  const classes = useStyles();
  const history = useHistory();
  const tableCells = getTableCells();
  const [filters, setFilters] = useState(initialFilters);
  const { data } = useQuery<IDataWithFences>(GET_DATA_WITH_GEO);
  const { zipCodes, regions, fenceOptions } = getFieldData(data);

  return (
    <div>
      <Paper>
        {renderFilterArea()}
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {tableCells.map(cell => (
                  <TableCell key={cell.id} align={cell.align}>
                    {cell.sortable ? (
                      <TableSortLabel
                        active={!!sort[cell.id]}
                        direction={sort[cell.id] === 'DESC' ? 'desc' : 'asc'}
                        onClick={() =>
                          updateSort({
                            [cell.id]: sort[cell.id] === 'ASC' ? 'DESC' : 'ASC'
                          })
                        }
                      >
                        {cell.label}
                      </TableSortLabel>
                    ) : (
                      cell.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{places.map(renderTableRow)}</TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage="Fjöldi raða fyrir síðu"
          rowsPerPageOptions={TABLE_ROWS_OPTIONS}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onChangePage={(_, p) => setCurrentPage(p)}
          onChangeRowsPerPage={e => {
            setCurrentPage(0);
            setRowsPerPage(parseInt(e.target.value, 10));
          }}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
      <Prompt
        when={exportStatus.isExporting}
        message="Export er ennþá í gangi"
      />
    </div>
  );

  function renderFilterArea() {
    return (
      <Grid
        container
        spacing={2}
        className={classes.filters}
        alignItems="center"
        justify="space-between"
      >
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={fenceOptions.length > 0 ? 6 : 9}>
              <TextField
                id="name"
                label="Leit"
                value={filters.text}
                variant="outlined"
                fullWidth
                onChange={e =>
                  setFilters({
                    ...filters,
                    text: e.target.value
                  })
                }
                onKeyDown={e => e.key === 'Enter' && search()}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Autocomplete
                id="categories"
                values={(filters.categoryIds || []).map(r => ({ value: r }))}
                label="Flokkar"
                items={placeCategoriesToOptions(placeCategories)}
                fullWidth
                onChange={opt =>
                  setFilters({ ...filters, categoryIds: getArrayFilter(opt) })
                }
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Dropdown
                id="exactCategories"
                value={getActiveString(
                  filters.exactCategories,
                  categoriesOptions
                )}
                label="Nákvæmir flokkar"
                items={categoriesOptions}
                fullWidth
                onChange={e =>
                  setFilters({
                    ...filters,
                    exactCategories: getActiveBoolean(
                      e.target.value as string,
                      categoriesOptions
                    )
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Autocomplete
                id="zipCodes"
                values={(filters.zipCodes || []).map(zc => ({ value: zc }))}
                label="Póstnúmer"
                items={zipCodes}
                fullWidth
                onChange={opt =>
                  setFilters({ ...filters, zipCodes: getArrayFilter(opt) })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Autocomplete
                id="regions"
                values={(filters.regions || []).map(r => ({ value: r }))}
                label="Landshluti"
                items={regions}
                fullWidth
                onChange={opt =>
                  setFilters({ ...filters, regions: getArrayFilter(opt) })
                }
              />
            </Grid>
            {fenceOptions.length > 0 && (
              <Grid item xs={12} sm={6} md={3}>
                <Dropdown
                  id="geoFences"
                  value={filters.fenceId}
                  label="Landmörk"
                  items={[{ label: 'Engin', value: '' }, ...fenceOptions]}
                  fullWidth
                  onChange={event =>
                    setFilters({
                      ...filters,
                      fenceId: event.target.value as string
                    })
                  }
                ></Dropdown>
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={3}>
              <Dropdown
                id="active"
                value={getActiveString(filters.active, activeOptions)}
                label="Í birtingu"
                items={activeOptions}
                fullWidth
                onChange={e =>
                  setFilters({
                    ...filters,
                    active: getActiveBoolean(
                      e.target.value as string,
                      activeOptions
                    )
                  })
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={search}
                className={classes.button}
              >
                Leita
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                onClick={clearSearch}
                className={classes.button}
              >
                Hreinsa leit
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                className={classes.button}
                onClick={() => exportData(filters)}
                disabled={exportStatus.isExporting}
              >
                {exportStatus.isExporting ? 'Exporting' : 'Export'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function renderTableRow(place: IPlaceLite) {
    return (
      <TableRow
        key={place.placeId}
        hover
        style={{ cursor: 'pointer' }}
        onClick={() => history.push(`/place/${place.placeId}`)}
        onMouseDown={(event) => {
          if (event.button === 1) {
            window.open(`/place/${place.placeId}`);
            window.focus();
          }
        }}
      >
        {tableCells.map(cell => (
          <TableCell
            key={cell.id}
            align={cell.align}
            className={cell.cellClass}
          >
            {cell.render(place)}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  function getTableCells(): ITableCell[] {
    return [
      {
        id: 'name',
        label: 'Staður',
        sortable: true,
        render: ({ translations }) => getIcelandicName(translations)
      },
      {
        id: 'zipCode',
        label: 'Póstnúmer',
        sortable: true,
        render: ({ zipCode }) => zipCode
      },
      {
        id: 'regions',
        label: 'Landshluti',
        render: ({ region }) =>
          region ? getIcelandicName(region.translations) : ''
      },
      {
        id: 'active',
        label: 'Í birtingu',
        render: ({ active }) => renderActiveCell(active),
        cellClass: classes.activeCell
      }
    ];
  }

  function renderActiveCell(active: boolean) {
    return (
      <Typography
        className={cx(classes.active, { [classes.inactive]: !active })}
      >
        {active ? 'Í birtingu' : 'Ekki í birtingu'}
      </Typography>
    );
  }

  function search() {
    setCurrentPage(0);
    setLastSearch("place", filters);
    searchForPlaces(filters);
  }

  function clearSearch() {
    setCurrentPage(0);
    clearLastSearch("place");
    searchForPlaces({ text: '' });
  }

  function getActiveString(
    active: boolean,
    options: { label: string; value: string; active?: boolean }[]
  ) {
    let activeStr = '';
    options.forEach(opt => {
      if (active === opt.active) {
        activeStr = opt.value;
      }
    });
    return activeStr;
  }

  function getActiveBoolean(
    activeStr: string,
    options: { label: string; value: string; active?: boolean }[]
  ) {
    let active: boolean | undefined;
    options.forEach(opt => {
      if (activeStr === opt.value) {
        active = opt.active;
      }
    });
    return active;
  }

  function getArrayFilter(opt: unknown) {
    const options = opt as IOption[];
    return options.length ? options.map(o => o.value) : undefined;
  }

  function placeCategoriesToOptions(categories: IPlaceCategories): IOption[] {
    return categories.PlaceCategories.map(cat => {
      const foundIndex = cat.translations.findIndex(
        tran => tran.locale === 'is-IS'
      );
      if (foundIndex !== -1) {
        return { label: cat.translations[foundIndex].name, value: cat.id };
      }
      return { label: '', value: cat.id };
    });
  }

  function getFieldData(data: IDataWithFences) {
    return {
      fenceOptions: data
        ? data.GeoFences.map(fence => ({
          label: getIcelandicName(fence.properties.translations),
          value: fence.id
        }))
        : [],
      zipCodes: data ? data.ZipCodes : [],
      regions: data
        ? data.Regions.map(r => ({
          value: r.id,
          label: getIcelandicName(r.translations)
        }))
        : []
    };
  }
};

export default PlaceTable;

import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Paper,
  Typography,
  Grid,
  TextField,
  Checkbox,
  Button
} from '@material-ui/core';
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';
import includes from 'lodash/includes';
import Joi from '@hapi/joi';
import {
  ADD_PHOTO_COLLECTION,
  EDIT_PHOTO_COLLECTION,
  DELETE_PHOTO_COLLECTION,
  ADD_PHOTO_TO_COLLECTION,
  EDIT_PHOTO_IN_COLLECTION,
  DELETE_PHOTO_IN_COLLECTION
} from '../graphql/mutations/serviceProvider';
import { Autocomplete } from '@material-ui/lab';
import {
  IProvider,
  IPhotoCollection,
  ImageFile
} from '../interfaces/ServiceProvider/ServiceProvider';
import { ICodes, ICodes2 } from '../interfaces/Misc/zipCodes';
import Dropzone from 'react-dropzone';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import useNotifier from '../hooks/useNotifier';
import PhotoItem from './PhotoItem';
import ConfirmDialog from './ConfirmDialog';
import CropImage from '../components/CropImage';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2)
    },
    paperCollection: {
      backgroundColor: '#f4f4f7',
      padding: theme.spacing(2)
    },
    typographyTitle: {
      padding: 20,
      paddingBottom: 5,
      paddingTop: 10
    },
    paperSections: {
      marginTop: 20
    }
  })
);

interface IProps {
  categories: any;
  serviceProviderId: string;
  serviceProvider: IProvider;
  latest: boolean;
  disableEdit: boolean;
}

const EditPhotoCollection: React.FC<IProps> = ({
  categories,
  serviceProviderId,
  serviceProvider,
  latest,
  disableEdit
}) => {
  const [categoriesOptions, setCategoriesOptions] = useState<any | null>(null);
  const classes = useStyles({});
  var cat: ICodes2 = {};
  const initalCollectionForm = { title: '', category: cat };
  const initalPhotoForm = { title: '', alt: '' };
  const [collectionForm, setCollectionForm] = useState(initalCollectionForm);
  const [editCollectionForm, setEditCollectionForm] = useState(
    initalCollectionForm
  );
  const [photoForm, setPhotoForm] = useState(initalPhotoForm);
  const [editPhotoForm, setEditPhotoForm] = useState<any | null>(null);
  const [invalidFields, setInvalidFields] = useState([]);
  const [photoInvalidFields, setPhotoInvalidFields] = useState([]);
  const [
    activeCollection,
    setActiveCollection
  ] = useState<IPhotoCollection | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState<ImageFile | null>(null);
  const [deleteCollectionId, setDeleteCollectionId] = useState('');
  const [deletePhotoId, setDeletePhotoId] = useState('');

  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" color="primary" />;
  const [AddPhotoCollection] = useMutation(ADD_PHOTO_COLLECTION, {
    refetchQueries: [`getServiceProvider`]
  });
  const [EditPhotoCollection] = useMutation(EDIT_PHOTO_COLLECTION);
  const [DeletePhotoCollection] = useMutation(DELETE_PHOTO_COLLECTION);
  const [imageUpload] = useMutation(ADD_PHOTO_TO_COLLECTION);
  const [editImage] = useMutation(EDIT_PHOTO_IN_COLLECTION);
  const [deleteImage] = useMutation(DELETE_PHOTO_IN_COLLECTION);
  const [croppedImage, setCroppedImage] = useState<File | null>(null);
  const [showCrop, setShowCrop] = useState<boolean>(false);
  const [cropped, setCropped] = useState<boolean>(false);
  const [uploadedImage, setUploadedImage] = useState<File | null>(null);
  const apolloClient = useApolloClient();

  const notifier = useNotifier();

  const schema = Joi.object({
    title: Joi.string().required(),
    category: Joi.object().min(1).required()
  });
  const photoSchema = Joi.object({
    title: Joi.string().required(),
    alt: Joi.string().required()
  });
  useEffect(() => {
    var newCategoriesOptions = [];
    categories.forEach(category => {
      category.subCategories.forEach(sub => {
        newCategoriesOptions.push({
          value: sub.id,
          label: sub.translations[0].name
        });
      });
    });
    newCategoriesOptions.sort((option1: ICodes, option2: ICodes) =>
      ('' + option1.label).localeCompare(option2.label)
    );
    setCategoriesOptions(newCategoriesOptions);
  }, [categories]);

  const handleSubmit = () => {
    var validateValues = {
      title: collectionForm.title,
      category: collectionForm.category
    };

    const joiValidate = schema.validate(validateValues, { abortEarly: false });
    if (joiValidate.error) {
      const newInvalidFields = joiValidate.error.details.map(
        field => field.path[0]
      );
      setInvalidFields(newInvalidFields);
    } else {
      AddPhotoCollection({
        variables: {
          serviceProviderId,
          categoryId: collectionForm.category.value,
          name: collectionForm.title
        }
      })
        .then(() => {
          setInvalidFields([]);
          setCollectionForm(initalCollectionForm);
          notifier.notify('Myndasafn varð til', { variant: 'success' });
        })
        .catch(e => {
          notifier.notify('Villa kom upp', { variant: 'error' });
        });
    }
  };

  const handleEditSubmit = () => {
    EditPhotoCollection({
      variables: {
        serviceProviderId,
        categoryId: editCollectionForm.category.value,
        collectionId: activeCollection.id,
        name: editCollectionForm.title
      }
    })
      .then(() => {
        setInvalidFields([]);
        setEditMode(false);
        setCollectionForm(initalCollectionForm);
        setEditCollectionForm(initalCollectionForm);
        notifier.notify('Myndasafn uppfært', { variant: 'success' });
        apolloClient.resetStore();
      })
      .catch(e => {
        notifier.notify('Villa kom upp', { variant: 'error' });
      });
  };

  const deleteCollection = id => {
    DeletePhotoCollection({
      variables: { serviceProviderId, collectionId: id }
    })
      .then(() => {
        notifier.notify('Myndasafni eytt', { variant: 'success' });
        apolloClient.resetStore();
      })
      .catch(e => {
        notifier.notify('Villa kom upp', { variant: 'error' });
      });
  };

  const handleEdit = (collection: IPhotoCollection) => {
    window.scrollTo(0, 0);
    setEditMode(true);
    setInvalidFields([]);
    setActiveCollection(collection);
    setEditCollectionForm({
      title: collection.name,
      category: categoriesOptions.filter(
        cat => cat.label === collection.category
      )[0]
    });
    const newEditPhotoForm = {};
    collection.photos.map(photo => {
      return (newEditPhotoForm[photo.photoId] = {
        title: photo.title,
        alt: photo.alt
      });
    });
    setEditPhotoForm(newEditPhotoForm);

    /*
        const editCollection = {title: collection.content.title, text: collection.content.text, language: 'IS', category: categoriesOptions.filter(cat => cat.label === collection.category)[0] , metaDescription: collection.content.description, metaKeywords: collection.content.keyword}
        setCollectionForm(editCollection) */
  };

  const onDrop = ([file]) => {
    if (file.size > 10000000) {
      notifier.notify('Of stór mynd - myndin þarf að vera minni en 10Mb', {
        variant: 'error'
      });
    } else {
      setSelectedPhoto(file);
      setUploadedImage(file);
      setCropped(false);
    }
  };

  const onUploadImage = () => {
    var validateValues = {
      title: photoForm.title,
      alt: photoForm.alt
    };

    const joiValidate = photoSchema.validate(validateValues, {
      abortEarly: false
    });
    if (joiValidate.error) {
      const newInvalidFields = joiValidate.error.details.map(
        field => field.path[0]
      );
      setPhotoInvalidFields(newInvalidFields);
    } else {
      imageUpload({
        variables: {
          photo: selectedPhoto,
          title: photoForm.title,
          alt: photoForm.alt,
          collectionId: activeCollection.id
        }
      })
        .then(res => {
          setSelectedPhoto(null);
          notifier.notify('Mynd hlaðið upp', { variant: 'success' });
          setPhotoInvalidFields([]);
          //setSnackbarMessage('Mynd hlaðið upp!');
          apolloClient.resetStore();
        })
        .catch(e => {
          notifier.notify('Villa kom upp', { variant: 'error' });
        });
    }
  };

  const editPhoto = photoId => {
    editImage({
      variables: {
        photoId: photoId,
        title: editPhotoForm[photoId].title,
        alt: editPhotoForm[photoId].alt,
        collectionId: activeCollection.id
      }
    })
      .then(res => {
        notifier.notify('Mynd uppfærð', { variant: 'success' });
        setPhotoInvalidFields([]);
        apolloClient.resetStore();
      })
      .catch(e => {
        notifier.notify('Villa kom upp', { variant: 'error' });
      });
  };

  const deletePhoto = photoId => {
    deleteImage({
      variables: {
        photoId: photoId,
        collectionId: activeCollection.id
      }
    })
      .then(res => {
        notifier.notify('Mynd eytt', { variant: 'success' });
        setPhotoInvalidFields([]);
        apolloClient.resetStore();
      })
      .catch(e => {
        notifier.notify('Villa kom upp', { variant: 'error' });
      });
  };

  if (categoriesOptions && !editMode) {
    return (
      <div>
        {latest && (
          <Paper className={classes.paperSections}>
            <Typography variant="h6" className={classes.typographyTitle}>
              Myndasafn
            </Typography>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={6}>
                <Paper className={classes.paper} elevation={0}>
                  <TextField
                    fullWidth
                    id={'title'}
                    label={'Titill'}
                    variant="outlined"
                    value={collectionForm.title}
                    required
                    error={includes(invalidFields, 'title')}
                    onChange={e =>
                      setCollectionForm({
                        ...collectionForm,
                        title: e.target.value
                      })
                    }
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper className={classes.paper} elevation={0}>
                  <Autocomplete
                    id={'category'}
                    options={categoriesOptions}
                    onChange={(_, value) =>
                      setCollectionForm({ ...collectionForm, category: value })
                    }
                    value={collectionForm.category}
                    getOptionLabel={option => option.label}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </React.Fragment>
                    )}
                    style={{ width: '100%' }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password'
                        }}
                        variant="outlined"
                        label={'Flokkur'}
                        required
                        error={includes(invalidFields, 'category')}
                      />
                    )}
                  />
                </Paper>
              </Grid>
              {!disableEdit &&
                <Grid item xs={12} sm={12}>
                  <Paper
                    className={classes.paper}
                    elevation={0}
                    style={{ textAlign: 'right' }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => handleSubmit()}
                      style={{ marginTop: 30 }}
                    >
                      Bæta við
                    </Button>
                  </Paper>
                </Grid>
              }

            </Grid>
          </Paper>
        )}

        <Paper className={classes.paperSections}>
          <Typography variant="h6" className={classes.typographyTitle}>
            Myndasöfn
          </Typography>
          <Grid container spacing={1}>
            {serviceProvider.photoCollections &&
              serviceProvider.photoCollections.map((collection, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={12} sm={5} key={`${collection.id}-1`}>
                    <Paper
                      className={classes.paperCollection}
                      style={{ marginLeft: 8 }}
                      elevation={0}
                    >
                      <Typography onClick={() => handleEdit(collection)}>
                        {collection.name}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={4} key={`${collection.id}-3`}>
                    <Paper className={classes.paperCollection} elevation={0}>
                      <Typography>{collection.category}</Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={3} key={`${collection.id}-4`}>
                    <Paper
                      className={classes.paperCollection}
                      style={{ marginRight: 8, textAlign: 'center' }}
                      elevation={0}
                    >
                      {/* <Typography>Edit | Delete</Typography> */}
                      <Button
                        color="primary"
                        style={{ padding: 0 }}
                        onClick={() => handleEdit(collection)}
                      >
                        Breyta
                      </Button>
                      <Button
                        color="secondary"
                        style={{ padding: 0 }}
                        onClick={() => setDeleteCollectionId(collection.id)}
                        disabled={!latest}
                      >
                        Eyða
                      </Button>
                    </Paper>
                  </Grid>
                </React.Fragment>
              ))}
          </Grid>
        </Paper>
        <ConfirmDialog
          open={!!deleteCollectionId}
          message="Ertu viss um að þú viljir eyða þessu myndasafni?"
          confirmText="Eyða"
          onConfirm={() => {
            deleteCollection(deleteCollectionId);
            setDeleteCollectionId('');
          }}
          onCancel={() => setDeleteCollectionId('')}
        />
      </div>
    );
  } else if (categoriesOptions && editMode && activeCollection) {
    return (
      <div>
        <Paper className={classes.paperSections}>
          <Button
            color="primary"
            variant="outlined"
            style={{ marginLeft: 20, marginTop: 16 }}
            onClick={() => {
              setEditMode(false);
              setSelectedPhoto(null);
            }}
          >
            Til baka
          </Button>
          <Typography variant="h6" className={classes.typographyTitle}>
            Myndasafn
          </Typography>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper} elevation={0}>
                <TextField
                  fullWidth
                  id={'title'}
                  label={'Titill'}
                  variant="outlined"
                  value={editCollectionForm.title}
                  disabled={!latest}
                  onChange={e =>
                    setEditCollectionForm({
                      ...editCollectionForm,
                      title: e.target.value
                    })
                  }
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper} elevation={0}>
                <Autocomplete
                  id={'category'}
                  options={categoriesOptions}
                  onChange={(_, value) =>
                    setEditCollectionForm({
                      ...editCollectionForm,
                      category: value
                    })
                  }
                  value={editCollectionForm.category}
                  getOptionLabel={option => option.label}
                  disabled={!latest}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.label}
                    </React.Fragment>
                  )}
                  style={{ width: '100%' }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password'
                      }}
                      variant="outlined"
                      label={'Flokkur'}
                      disabled={!latest}
                    /* required error={includes(invalidFields, 'category') }  */
                    />
                  )}
                />
              </Paper>
            </Grid>
            {
              !disableEdit &&
              <Grid item xs={12} sm={12}>
                <Paper
                  className={classes.paper}
                  elevation={0}
                  style={{ textAlign: 'right' }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleEditSubmit()}
                    disabled={!latest}
                  >
                    Vista
                  </Button>
                </Paper>
              </Grid>
            }

          </Grid>
        </Paper>
        {latest && !disableEdit && (
          <Paper className={classes.paperSections}>
            <Typography variant="h6" className={classes.typographyTitle}>
              Bæta við mynd
            </Typography>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper} elevation={0}>
                  <Dropzone
                    accept="image/jpeg, image/png"
                    multiple={false}
                    onDrop={onDrop}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div>
                        <section>
                          <div
                            {...getRootProps()}
                            style={{
                              flex: 1,
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              padding: '16px',
                              borderWidth: 2,
                              borderRadius: 5,
                              borderColor: '#213b76',
                              borderStyle: 'dashed',
                              backgroundColor: '#fafafa'
                            }}
                          >
                            <input {...getInputProps()} />
                            <Typography>
                              {' '}
                              Dragðu og slepptu skrá hingað, eða smelltu til að
                              velja skrá{' '}
                            </Typography>
                          </div>
                        </section>
                      </div>
                    )}
                  </Dropzone>
                </Paper>
                <CropImage
                  file={uploadedImage}
                  setCroppedImage={(c) => {
                    setCroppedImage(c);
                  }}
                  confirmCrop={() => {
                    onDrop([croppedImage]);
                    setShowCrop(false);
                    setCropped(true);
                  }}
                  cancelCrop={() => {
                    setShowCrop(false);
                  }}
                  showCrop={showCrop}
                />
                {selectedPhoto && !showCrop && !cropped &&
                  <div>
                    <Alert severity="info">Myndir verða birtar í hlutföllunum 12:8. Myndir í öðrum hlutföllum munu ekki birtast að fullu. Við mælum með að kroppa út svæði í réttu hlutfalli úr slíkum myndum.</Alert>
                    <Paper
                      className={classes.paper}
                      elevation={0}
                      style={{ textAlign: 'right' }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => setShowCrop(true)}>
                        Kroppa
                      </Button>
                    </Paper>
                  </div>
                }
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper className={classes.paper} elevation={0}>
                  <TextField
                    fullWidth
                    id={'title'}
                    label={'Titill'}
                    variant="outlined"
                    value={photoForm.title}
                    required
                    error={includes(photoInvalidFields, 'title')}
                    onChange={e =>
                      setPhotoForm({ ...photoForm, title: e.target.value })
                    }
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper className={classes.paper} elevation={0}>
                  <TextField
                    fullWidth
                    id={'alt'}
                    label={'Alt'}
                    variant="outlined"
                    value={photoForm.alt}
                    required
                    error={includes(photoInvalidFields, 'alt')}
                    onChange={e =>
                      setPhotoForm({ ...photoForm, alt: e.target.value })
                    }
                  />
                </Paper>
              </Grid>
              {selectedPhoto && (
                <React.Fragment>
                  <Grid item xs={12} sm={8}>
                    <Paper className={classes.paper} elevation={0}>
                      <Typography variant="h6">Valin skrá</Typography>
                      <ul>
                        {selectedPhoto && (
                          <li key={selectedPhoto.path}>
                            {selectedPhoto.path} - {selectedPhoto.size} bytes
                          </li>
                        )}
                      </ul>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Paper
                      className={classes.paper}
                      elevation={0}
                      style={{ textAlign: 'right' }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => onUploadImage()}
                      >
                        Bæta við
                      </Button>
                    </Paper>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </Paper>
        )}
        <Typography variant="h6" className={classes.typographyTitle}>
          Myndir
        </Typography>
        {activeCollection.photos &&
          editPhotoForm &&
          activeCollection.photos.map(photo => (
            <PhotoItem
              key={photo.photoId}
              photo={photo}
              formValues={editPhotoForm[photo.photoId]}
              disabled={!latest || disableEdit}
              onChange={val =>
                setEditPhotoForm({
                  ...editPhotoForm,
                  [photo.photoId]: val
                })
              }
              editPhoto={() => editPhoto(photo.photoId)}
              deletePhoto={() => setDeletePhotoId(photo.photoId)}
            />
          ))}
        <ConfirmDialog
          open={!!deletePhotoId}
          message="Ertu viss um að þú viljir eyða þessari mynd?"
          confirmText="Eyða mynd"
          onConfirm={() => {
            deletePhoto(deletePhotoId);
            setDeletePhotoId('');
          }}
          onCancel={() => setDeletePhotoId('')}
        />
      </div>
    );
  } else {
    return null;
  }
};

export default EditPhotoCollection;

import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ArrowIcon from '@material-ui/icons/ArrowDropDown';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { IUser } from '../store/auth/types';
import useAuthActions from '../hooks/useAuthActions';
import RouterLinkForwardRef from './RouterLinkForwardRef';
import useAdmin from '../hooks/useAdminChecker';
import useEditor from '../hooks/useEditorChecker';
import { Badge } from '@material-ui/core';
import {
  NEW_SERVICE_PROVIDERS,
  PENDING_SERVICE_PROVIDERS
} from '../graphql/queries/ServiceProviderQueries';
import { useQuery } from 'react-apollo';
import {
  IServiceProviders,
  IPendingProviders
} from '../interfaces/ServiceProvider/ServiceProvider';

interface IProps {
  user: IUser | null;
}

const useStyles = makeStyles(theme => ({
  disableLinkStyle: {
    textDecoration: 'none',
    outline: 'none'
  },
  buttonText: {
    color: theme.palette.grey[700],
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(2)
  },
  buttonTextPending: {
    color: theme.palette.grey[700],
    fontWeight: theme.typography.fontWeightRegular
  },
  notificationBadge: {
    marginRight: theme.spacing(2)
  },
  profile: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2)
  },
  profileText: {
    margin: theme.spacing(0, 1),
    overflow: 'hidden',
    maxWidth: 200,
    textTransform: 'uppercase',
    lineHeight: 1.5,
    whiteSpace: 'nowrap'
  },
  arrow: {
    opacity: 0.6,
    transition: 'transform 0.2s'
  },
  rotateArrow: {
    transform: 'rotate(180deg)'
  }
}));

const TopNav: React.FC<IProps> = ({ user }) => {
  const classes = useStyles({});
  const { login, logout } = useAuthActions();
  const isAdmin = useAdmin();
  const isEditor = useEditor();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isMenuOpen = Boolean(anchorEl);
  const isLoggedIn = Boolean(user);
  const [notifications, setNotications] = useState(0);

  const { data: newData } = useQuery<IServiceProviders>(NEW_SERVICE_PROVIDERS, {
    fetchPolicy: 'network-only'
  });
  const { data: pendingData } = useQuery<IPendingProviders>(
    PENDING_SERVICE_PROVIDERS,
    {
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    if (newData && pendingData) {
      setNotications(
        newData.ServiceProviders.count +
        pendingData.pendingServiceProviders.count
      );
    }
  }, [newData, pendingData]);

  return (
    <React.Fragment>
      {isLoggedIn && renderNavLinks()}
      {!isLoggedIn && (
        <Grid item>
          <Button
            data-testid="login-button"
            onClick={handleLogin}
            className={classes.buttonText}
          >
            Innskráning
          </Button>
        </Grid>
      )}
      {user && (
        <div className={classes.profile}>
          <Avatar data-testid="avatar" src={user.picture} />
          <Typography
            variant="caption"
            color="textSecondary"
            className={classes.profileText}
          >
            {user.name}
          </Typography>
          <IconButton
            aria-owns={isMenuOpen ? 'material-appbar' : undefined}
            aria-haspopup="true"
            onClick={e => setAnchorEl(e.currentTarget)}
            color="inherit"
            size="small"
            data-testid="arrow"
          >
            <ArrowIcon
              color="action"
              className={cx(classes.arrow, {
                [classes.rotateArrow]: isMenuOpen
              })}
            />
          </IconButton>
        </div>
      )}
      {renderMenu()}
    </React.Fragment>
  );

  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handleLogin() {
    login();
  }

  function handleLogout() {
    logout();
    handleMenuClose();
  }

  function renderNavLinks() {
    const headerlinks = [
      {
        label: 'Ferðaþjónusta',
        path: '/service-providers'
      }
    ];

    if (isAdmin || isEditor) {
      headerlinks.push({
        label: 'Bæjarfélög',
        path: '/municipalities'
      });
      headerlinks.push({
        label: 'Staðir',
        path: '/places'
      });
      if (isAdmin) {
        headerlinks.push({
          label: 'Notendur',
          path: '/users'
        });
      }
      headerlinks.push({
        label: 'Landmörk',
        path: '/geofences'
      });
      headerlinks.push({
        label: 'Hleðslustöðvar',
        path: '/chargingstations'
      });
      headerlinks.push({
        label: 'Bíður samþykkis',
        path: '/pending'
      });
      headerlinks.push({
        label: 'Stillingar',
        path: '/settings'
      });
    }

    return headerlinks.map(({ label, path }) => {
      const isInternal = path.startsWith('/');
      return (
        <Grid item key={label}>
          {isInternal ? (
            path === '/pending' ? (
              <Badge
                badgeContent={notifications}
                color="primary"
                className={classes.notificationBadge}
              >
                <Button
                  to={path}
                  component={RouterLinkForwardRef}
                  className={classes.buttonTextPending}
                >
                  {label}
                </Button>
              </Badge>
            ) : (
              <Button
                to={path}
                component={RouterLinkForwardRef}
                className={classes.buttonText}
              >
                {label}
              </Button>
            )
          ) : (
            <Button href={path} className={classes.buttonText}>
              {label}
            </Button>
          )}
        </Grid>
      );
    });
  }

  function renderMenu() {
    return (
      <Menu
        data-testid="avatar-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
      >
        <Link className={classes.disableLinkStyle} to="/profile">
          <MenuItem onClick={handleMenuClose}>Prófíll</MenuItem>
        </Link>
        <MenuItem data-testid="logout-button" onClick={handleLogout}>
          Skrá út
        </MenuItem>
      </Menu>
    );
  }
};

export default TopNav;

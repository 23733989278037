import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import securePage from '../hocs/securePage';
import Section from '../components/Section';
import {
  Paper,
  Grid,
  TextField,
  Button,
  Typography
} from '@material-ui/core';

import { useQuery } from 'react-apollo';
import {
  IRegions,
  IRegion
} from '../interfaces/User/User';
import { GET_REGIONS } from '../graphql/queries/RegionQueries';
import { SET_REGION_EMAIL } from '../graphql/mutations/RegionMutations';
import { useMutation } from '@apollo/react-hooks';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const Settings: React.FC = () => {
  const { data } = useQuery<IRegions>(GET_REGIONS);
  const [regions, setRegions] = useState<IRegion[]>(null);
  const [setRegionEmail] = useMutation(SET_REGION_EMAIL);

  useEffect(() => {
    if (data) {
      setRegions(data.Regions);
    }
  }, [data]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      paper: {
        padding: theme.spacing(2)
      },
      headline: {
        paddingBottom: theme.spacing(2)
      },
    })
  );
  const classes = useStyles({});

  const onChangeEmail = (regionId, email) => {
    setRegions(regions.map(r => {
      if (r.id === regionId) {
        r.email = email;
      }
      return r;
    }));
  };

  const onSubmit = () => {
    regions.forEach(region => {
      setRegionEmail({ variables: { id: region.id, email: region.email } });
    });
  }

  return (
    <Layout useDefaultSpacing>
      <Section
        title="Netföng Markaðsstofa"
      >
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            {regions && regions.map(region =>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.headline}>
                  {region.translations.find(t => t.locale === 'is-IS').name}
                </Typography>
                <TextField
                  key={region.id}
                  label="Email"
                  value={region.email}
                  fullWidth
                  variant="outlined"
                  onChange={(event) => onChangeEmail(region.id, event.target.value)}
                />
              </Grid>
            )}
          </Grid>
        </Paper>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          fullWidth
          style={{ padding: 10 }}
          onClick={onSubmit}
        >
          Vista
        </Button>
      </Section>
    </Layout >
  )
}

export default securePage(Settings);
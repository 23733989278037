import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import axios from "axios";
import cx from "classnames";
import {
  makeStyles,
  Theme,
  Grid,
  CircularProgress,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Typography,
} from "@material-ui/core";

import logo from "../assets/cleanlogo.png";
import logoEN from "../assets/cleanlogo-en.png";

interface IServiceProviderList {
  serviceProviderId: string;
  SSN: string;
  legalName: string;
  address: string;
  zipCode: string;
  municipality: string;
  cleanAndSafe: boolean;
  url: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  imageContainer: {
    marginTop: "50px",
  },
  tableContainer: {
    marginTop: "50px",
    marginBottom: "50px",
  },
  image: {
    width: "175px",
  },
  status: {
    color: "white",
    borderRadius: "5px",
    padding: "5px 10px",
    margin: "0 auto",
    fontWeight: theme.typography.fontWeightMedium,
  },
  active: {
    backgroundColor: theme.palette.success.main,
  },
  inactive: {
    backgroundColor: theme.palette.error.main,
  },
  link: {
    color: "white",
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const CleanAndSafeList: React.FC<RouteComponentProps<{ ssn: string }>> = ({
  match,
}) => {
  const [providers, setProviders] = useState<IServiceProviderList[]>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const res: { data: { data: IServiceProviderList[] } } = await axios.get(
          `${process.env.REACT_APP_SSN_API_URL}/ssn/${match.params.ssn}/clean`
        );
        sortProviders(res.data.data);
      } catch {
        setError(true);
      }
      setLoading(false);
    };
    fetchInfo();
  }, [match.params.ssn]);

  function sortProviders(providers) {
    setProviders(
      providers.sort((p1, p2) => parseInt(p1.zipCode) - parseInt(p2.zipCode))
    );
  }

  if (loading || error) {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <CircularProgress size={100} />
      </Grid>
    );
  }
  return (
    <div style={{ height: "100vh" }}>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12} direction="row" className={classes.imageContainer}>
          <img
            alt="logo"
            src={logo}
            className={classes.image}
            style={{ paddingRight: "17.5px" }}
          ></img>
          <img
            alt="logo"
            src={logoEN}
            className={classes.image}
            style={{ paddingLeft: "17.5px" }}
          ></img>
        </Grid>
        <Grid item xs={12} className={classes.tableContainer}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Fyrirtæki</TableCell>
                  <TableCell align="left">Gata</TableCell>
                  <TableCell align="left">Póstnúmer</TableCell>
                  <TableCell align="left">Staður</TableCell>
                  <TableCell align="center">Hreint og öruggt</TableCell>
                  <TableCell align="center">Sjálfsmat</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {providers &&
                  providers.map((provider) => (
                    <TableRow key={provider.serviceProviderId}>
                      <TableCell component="th" scope="row">
                        {provider.legalName}
                      </TableCell>
                      <TableCell align="left">{provider.address}</TableCell>
                      <TableCell align="left">{provider.zipCode}</TableCell>
                      <TableCell align="left">
                        {provider.municipality}
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          className={cx(classes.status, {
                            [classes.active]: !!provider.cleanAndSafe,
                            [classes.inactive]: !provider.cleanAndSafe,
                          })}
                        >
                          {!!provider.cleanAndSafe ? "Tilbúið" : "Óútfyllt"}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          color="primary"
                          href={provider.url}
                        >
                          <Typography className={cx(classes.link)}>
                            Opna
                          </Typography>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default CleanAndSafeList;

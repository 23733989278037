import React from 'react';
import { makeStyles } from '@material-ui/core';

interface IProps {
  lat: number;
  lng: number;
  isDragging?: boolean;
}

const useStyles = makeStyles(theme => ({
  marker: {
    position: 'absolute',
    top: -8,
    left: -8,
    transition: 'transform 0.1s ease-in-out',
    width: 16,
    height: 16,
    backgroundColor: 'white',
    borderRadius: '50%',
    border: `4px solid ${theme.palette.primary.main}`,
    boxSizing: 'content-box'
  }
}));

const Marker: React.FC<IProps> = ({ children, isDragging }) => {
  const classes = useStyles();
  const cursor = isDragging ? 'grabbing' : 'grab';

  return (
    <div className={classes.marker} style={{ cursor }}>
      {children}
    </div>
  );
};

export default Marker;


const nameIS = 'Íslenska markaðsheitið, hægt er að breyta reit undir textalýsing, nöfn eru ákvörðuð af fyrirtækjum en nauðsynlegt er fyrir ferðasala dagsferða og ferðaskrifstofur að tilkynna nafnabreytingar til Ferðamálastofu.';
const nameEN = 'Enska markaðsheitið, hægt að breyta reit undir textalýsing, nöfn eru ákvörðuð af fyrirtækjum en nauðsynlegt er fyrir ferðasala dagsferða og ferðaskrifstofur að tilkynna nafnabreytingar til Ferðamálastofu.';
const nameEs = 'Spænska markaðsheitið, hægt að breyta reit undir textalýsing, nöfn eru ákvörðuð af fyrirtækjum en nauðsynlegt er fyrir ferðasala dagsferða og ferðaskrifstofur að tilkynna nafnabreytingar til Ferðamálastofu.';
const nameDe = 'Þýska markaðsheitið, hægt að breyta reit undir textalýsing, nöfn eru ákvörðuð af fyrirtækjum en nauðsynlegt er fyrir ferðasala dagsferða og ferðaskrifstofur að tilkynna nafnabreytingar til Ferðamálastofu.';
const nameFr = 'Franska markaðsheitið, hægt að breyta reit undir textalýsing, nöfn eru ákvörðuð af fyrirtækjum en nauðsynlegt er fyrir ferðasala dagsferða og ferðaskrifstofur að tilkynna nafnabreytingar til Ferðamálastofu.';
export const assistanceTexts = {
  legalName: 'Það nafn fyrirtækis sem birtist í fyrirtækjaskrá. Yfirleitt með ehf. hf. eða ses. endingu.',
  nameis: nameIS,
  name: nameIS,
  nameen: nameEN,
  nameEn: nameEN,
  nameEs: nameEs,
  namees: nameEs,
  nameDe: nameDe,
  namede: nameDe,
  nameFr: nameFr,
  namefr: nameFr,
  SSN: 'Kennitala fyrirtækis eða lögaðila, kennitalan er nýtt sem auðkenni í greiningartilgangi og til þess að tengja saman skyldar skráningar.',
  address: 'Staðsetning fyrirtækis, þar sem ferðamenn geta sótt sér þjónustu.',
  poBox: 'Pósthólf fyrirtækis hjá póstinum',
  zipCode: 'Póstnúmer þess heimilisfangs skráð að ofanverðu.',
  townCode: 'Númer þess sveitarfélags þess heimilisfangs skráð að ofanverðu, númerin eru nýtt til greiningartilgangi.',
  latitude: 'Hluti af GPS punkti staðsetningar fyrirtækisins, til að breyta smellið á reitinn og sláið inn heimilisfang. ',
  longitude: 'Hluti af GPS punkti staðsetningar fyrirtækisins, til að breyta smellið á reitinn og sláið inn heimilisfang.',
  email: 'Netfang þar sem ferðamenn geta haft samband.',
  email2: 'Auka netfang fyrirtækis.',
  phoneNr: 'Símanúmer þar sem ferðamenn geta haft samband.',
  phoneNr2: 'Auka símanúmer fyrirtækis.',
  website: 'Vefsvæði eða vefsíða fyrirtækis.',
  website2: 'Auka vefsíða fyrirtækis.',
  contactName: 'Nafn tengiliðs fyrirtækis, ekki birt neinstaðar og einungis notað til að hafa samband vegna skráningar fyrirtækis.',
  contactEmail: 'Netfang tengiliðs fyrirtækis, ekki birt neinstaðar og einungis notað til að hafa samband vegna skráningar fyrirtækis',
  contactPhone: 'Símanúmer tengiliðs fyrirtækis, ekki birt neinstaðar og einungis notað til að hafa samband vegna skráningar fyrirtækis.',
  contactComment: 'Hér má skrifa athugasemdir, þær birtast einungis hér í grunninum.',
  regions: 'Landshluti þar sem fyrirtæki er með rekstur.',
  purhcasedRegistration: 'Ef fyrirtæki er í samsstarfi með markaðsstofu, veljið þann landshluta sem markaðsstofan er í.',
  ownerEmail: 'Netfang ábyrgðamanns eða eigenda fyrirtækis.',
  eService: 'Ef fyrirtæki selur þjónustu einungis á netinu og ferðamenn geta ekki heimsótt starfstöð fyrirtækisins, skal merkja já',
  seasonal: 'Ef fyrirtæki er ekki opið allt árið, veljið hvenær staðurinn opnar og hvenær lokar á árinu.',
  groups: 'Aðeins skal skrá fyrirtæki í þá þjónustuflokka sem tengjast beinni starfsemi fyrirtækisins. Sumir flokkar eru leyfisskyldir. Fyrir frekari upplýsingar smellið á ítarlegri leiðbeiningar hér. Flokkakerfið er bæði nýtt í markaðslegum tilgangi og til þess að halda utan um leyfismál fyrirtækja í ferðaþjónustu.',
  collections: 'Markaðstexti og markaðsheiti bæði á íslensku og ensku.',
  photos: 'Myndefni sem birtist á markaðsvefjum. Leitast skal við að hafa myndir í landscape formatti, minnst 1200x800 pixla. Best að hafa minnst þrjár myndir. ',
  accessible: "Til að vera merkt sem fyrirtæki með aðgengi fyrir fatlaða þarf að taka þátt í verkefninu Gott aðgengi í ferðaþjónustu. Sjá: www.ferdamalastofa.is/gottadgengi"
}
import React from 'react';
import { makeStyles, Paper, Grid, TextField, Button } from '@material-ui/core';
import DragIndicator from '@material-ui/icons/DragIndicator';
import { SortableHandle } from 'react-sortable-hoc';

import { IPhoto } from '../interfaces/ServiceProvider/ServiceProvider';

interface IProps {
  photo: IPhoto;
  formValues: IPhotoForm;
  disabled?: boolean;
  onChange: (values: IPhotoForm) => void;
  editPhoto: (id: string) => void;
  deletePhoto: (id: string) => void;
  disableEdit?: boolean;
}

export interface IPhotoForm {
  title: string;
  alt: string;
}

const useStyles = makeStyles(theme => ({
  photoItem: {
    marginTop: 20
  },
  paper: {
    padding: theme.spacing(2)
  }
}));

const DragHandle = SortableHandle(() => <DragIndicator />);

const PhotoItem: React.FC<IProps> = ({
  photo,
  formValues,
  disabled,
  onChange,
  editPhoto,
  deletePhoto,
  disableEdit
}) => {
  const classes = useStyles();
  return (
    <Paper className={classes.photoItem}>
      <Grid container spacing={0} justify="center" alignItems="center">
        {disabled && (
          <Grid
            item
            xs={1}
            sm={1}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <DragHandle />
          </Grid>
        )}
        <Grid item xs={disabled ? 11 : 12} sm={disabled ? 3 : 4}>
          <Paper className={classes.paper} elevation={0}>
            <img
              src={photo.photoUri}
              alt={photo.alt}
              style={{ width: '100%' }}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12}>
              <Paper className={classes.paper} elevation={0}>
                <TextField
                  fullWidth
                  id="title"
                  label="Titill"
                  variant="outlined"
                  value={formValues.title}
                  disabled={disabled}
                  onChange={e =>
                    onChange({ ...formValues, title: e.target.value })
                  }
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Paper className={classes.paper} elevation={0}>
                <TextField
                  fullWidth
                  id="alt"
                  label="Alt"
                  variant="outlined"
                  value={formValues.alt}
                  disabled={disabled}
                  onChange={e =>
                    onChange({ ...formValues, alt: e.target.value })
                  }
                />
              </Paper>
            </Grid>
            {!disableEdit &&
              <Grid item xs={12} sm={6}>
                <Paper className={classes.paper} elevation={0}>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => deletePhoto(photo.photoId)}
                    disabled={disabled}
                  >
                    Eyða
                  </Button>
                </Paper>
              </Grid>
            }
            {!disableEdit &&
              <Grid item xs={12} sm={6}>
                <Paper
                  className={classes.paper}
                  elevation={0}
                  style={{ textAlign: 'right' }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => editPhoto(photo.photoId)}
                    disabled={disabled}
                  >
                    Vista
                  </Button>
                </Paper>
              </Grid>
            }

          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PhotoItem;

import React from 'react';

import securePage from '../hocs/securePage';
import ViewServiceProviders from '../components/ViewServiceProviders';
import Layout from '../components/Layout';
import Section from '../components/Section';
import useAdminChecker from '../hooks/useAdminChecker';

interface IProps {}

const ServiceProviders: React.FC<IProps> = () => {
  const isAdmin = useAdminChecker();
  return (
    <Layout useDefaultSpacing>
      {isAdmin ? (
        <Section
          title="Ferðaþjónusta"
          link={`/service-provider/new`}
          linkText={'Skrá nýja þjónustu'}
          extraLink={`/category`}
          extraLinkText={'Þjónustuflokkar'}
        >
          <ViewServiceProviders />
        </Section>
      ) : (
        <Section
          title="Ferðaþjónusta"
          link={`/service-provider/new`}
          linkText={'Skrá nýja þjónustu'}
        >
          <ViewServiceProviders />
        </Section>
      )}
    </Layout>
  );
};

export default securePage(ServiceProviders);

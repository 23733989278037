import React from 'react';
import { Prompt } from 'react-router-dom';
import { FormikProps } from 'formik';

interface IProps {
  formik: FormikProps<any>;
  message?: string;
  error?: boolean;
}

const SavePrompt: React.FC<IProps> = ({ formik, message, error = false }) => {
  // The error prop is needed because some forms don't use the formik errors
  const hasErrors = error || !!Object.keys(formik.errors).length;
  const hasNotSubmitted = !formik.submitCount;

  return (
    <Prompt
      when={formik.dirty && (hasNotSubmitted || hasErrors)}
      message={message || 'Breytingar hafa ekki verið vistaðar'}
    />
  );
};

export default SavePrompt;

import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import axios from 'axios';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

interface ICompanyInfo {
  legalName: string;
  SSN: string;
  email: string;
}

const UpdateProviderSurvey: React.FC<RouteComponentProps<{ id: string }>> = ({
  match
}) => {
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const res: {
          data: { data: ICompanyInfo[] };
        } = await axios.get(
          `${process.env.REACT_APP_SSN_API_URL}/ssn/${match.params.id}`
        );
        const companyInfo =
          res.data.data && res.data.data.length ? res.data.data[0] : null;
        setCompanyName(
          companyInfo && companyInfo.legalName ? companyInfo.legalName : ''
        );
        setEmail(companyInfo && companyInfo.email ? companyInfo.email : '');
      } catch {
        setError(true);
      }
      setLoading(false);
    };
    fetchInfo();
  }, [match.params.id]);

  if (loading || error) {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ height: '100vh' }}
      >
        <CircularProgress size={100} />
      </Grid>
    );
  }

  return (
    <div style={{ height: '100vh' }}>
      <iframe
        height="100%"
        width="100%"
        src={`https://www.surveymonkey.com/r/7KJJYDX?id=${match.params.id
          }&n=${encodeURI(companyName)}&email=${email}`}
        title="Clean and Safe"
      ></iframe>
    </div>
  );
};

export default UpdateProviderSurvey;

import React, { useState, useCallback } from 'react';
import { useQuery } from 'react-apollo';
import debounce from 'lodash/debounce';

import { IMunicipalities } from '../interfaces/Municipality/Municipality';
import { MUNICIPALITIES } from '../graphql/queries/MunicipalityQueries';
import Autocomplete, { IOption } from '../components/Autocomplete';
import { getIcelandicName } from '../utils/locale';

interface IProps {
  id?: string;
  value?: IOption;
  values?: IOption[];
  label: string;
  onChange: (value: unknown) => void;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
  fullWidth?: boolean;
  className?: string;
}

const SelectMunicipality: React.FC<IProps> = ({
  id,
  value,
  values,
  label,
  onChange,
  error,
  disabled,
  required,
  fullWidth,
  className
}) => {
  const [searchText, setSearchText] = useState('');
  const { data } = useQuery<IMunicipalities>(MUNICIPALITIES, {
    variables: {
      take: 25,
      active: true,
      sort: { name: 'ASC' },
      filter: { text: searchText }
    }
  });
  const municipalities = data ? data.Municipalities.Municipalities : [];

  const searchDebounced = useCallback(
    debounce((text: string) => setSearchText(text), 300),
    []
  );

  return (
    <Autocomplete
      id={id}
      value={value}
      values={values}
      label={label}
      items={municipalities.map(m => ({
        value: m.municipalityId,
        label: getIcelandicName(m.translations)
      }))}
      onChange={onChange}
      error={error}
      disabled={disabled}
      required={required}
      fullWidth={fullWidth}
      className={className}
      inputValue={searchText}
      onInputChange={searchDebounced}
    />
  );
};

export default SelectMunicipality;

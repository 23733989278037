import { gql } from 'apollo-boost';
import { translationFragment, placeFragment } from './PlaceFragments';

export const municipalityFragment = gql`
  fragment municipality on IMunicipality {
    municipalityId
    active
    zipCodes
    translations {
      locale
      name
    }
    region {
      id
      translations {
        ...translation
      }
    }
  }
  ${translationFragment}
`;

export const municipalityDetailsFragment = gql`
  fragment municipalityDetails on IMunicipality {
    ...municipality
    population
    townCode
    regionId
    placeIds
    updatedBy
    updatedAt
    photos {
      photoUri
      photoId
      alt
      title
    }
    location {
      type
      coordinates
    }
    relatedPlaces {
      ...place
    }
    translations {
      locale
      name
      description
      seoTitle
      seoKeywords
      seoDescription
      website
    }
  }
  ${municipalityFragment}
  ${placeFragment}
`;

import { gql } from 'apollo-boost';
import {
  municipalityFragment,
  municipalityDetailsFragment
} from '../fragments/MunicipalityFragments';

export const MUNICIPALITIES = gql`
  query getMunicipalities(
    $skip: Int
    $take: Int
    $active: Boolean
    $sort: MunicipalitySort
    $filter: MunicipalityFilter
  ) {
    Municipalities(
      skip: $skip
      take: $take
      active: $active
      sort: $sort
      filter: $filter
    ) {
      count
      Municipalities {
        ...municipality
      }
    }
  }
  ${municipalityFragment}
`;

export const MUNICIPALITY_DETAILS = gql`
  query getMunicipalityDetails($municipalityId: String!) {
    Municipality(municipalityId: $municipalityId) {
      ...municipalityDetails
    }
  }
  ${municipalityDetailsFragment}
`;

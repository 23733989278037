import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useQuery } from 'react-apollo';

import securePage from '../hocs/securePage';
import { IUserDetails } from '../interfaces/User/User';
import { USER_DETAILS } from '../graphql/queries/UserQueries';
import Layout from '../components/Layout';
import LoadingPage from '../components/LoadingPage';
import Section from '../components/Section';
import UserForm from '../components/UserForm';

const UserDetails: React.FC<RouteComponentProps<{ id: string }>> = ({
  match
}) => {
  const { loading, error, data } = useQuery<IUserDetails>(USER_DETAILS, {
    variables: { userId: match.params.id }
  });
  const user = data ? data.User : null;

  if (loading || error) {
    return <LoadingPage error={error ? error.message : ''} />;
  }

  return (
    <Layout useDefaultSpacing>
      <Section title={user.name}>
        <UserForm user={user} />
      </Section>
    </Layout>
  );
};

export default securePage(UserDetails);

import React from 'react';
import { useQuery } from 'react-apollo';

import securePage from '../hocs/securePage';
import useAuthState from '../hooks/useAuthState';
import { IUserDetails } from '../interfaces/User/User';
import { USER_DETAILS } from '../graphql/queries/UserQueries';
import Layout from '../components/Layout';
import LoadingPage from '../components/LoadingPage';
import Section from '../components/Section';
import UserForm from '../components/UserForm';

const UserProfile: React.FC = () => {
  const { user } = useAuthState();
  const { loading, error, data } = useQuery<IUserDetails>(USER_DETAILS, {
    variables: { userId: user.sub }
  });
  const userDetails = data ? data.User : null;

  if (loading || error) {
    return <LoadingPage error={error ? error.message : ''} />;
  }

  return (
    <Layout useDefaultSpacing>
      <Section title={userDetails.name}>
        <UserForm user={userDetails} />
      </Section>
    </Layout>
  );
};

export default securePage(UserProfile);

import { gql } from 'apollo-boost';

export const serviceProviderUpload = gql`
  mutation imageUpload(
    $serviceProviderId: String!
    $image: Upload!
    $title: String
    $alt: String
  ) {
    addServiceProviderImage(
      serviceProviderId: $serviceProviderId
      image: $image
      title: $title
      alt: $alt
    ) {
      photos {
        photoId
        photoUri
        title
        alt
      }
    }
  }
`;

export const serviceProviderRemove = gql`
  mutation removeImage($serviceProviderId: String!, $imageId: String!) {
    removeServiceProviderPhoto(
      serviceProviderId: $serviceProviderId
      imageId: $imageId
    ) {
      photos {
        photoId
        photoUri
        title
        alt
      }
    }
  }
`;

export const serviceProviderEdit = gql`
  mutation editImage(
    $serviceProviderId: String!
    $imageId: String!
    $title: String
    $alt: String
  ) {
    editServiceProviderPhoto(
      serviceProviderId: $serviceProviderId
      imageId: $imageId
      title: $title
      alt: $alt
    ) {
      photoId
      photoUri
      title
      alt
    }
  }
`;

import { createAction } from 'typesafe-actions';
import { OptionsObject } from 'notistack';

export const enqueueNotification = createAction(
  'notifier/ENQUEUE',
  resolve => (message: React.ReactText, options: OptionsObject) => {
    const key = options && options.key;

    return resolve({
      message,
      options,
      key: key || new Date().getTime() + Math.random()
    });
  }
);

export const closeNotification = createAction(
  'notifier/CLOSE',
  resolve => (key?: React.ReactText) => {
    return resolve({ key, dismissAll: !key });
  }
);

export const removeNotification = createAction(
  'notifier/REMOVE',
  resolve => (key: React.ReactText) => resolve({ key })
);

import React from 'react';

import useAuthState from '../hooks/useAuthState';
import LoginRequired from '../pages/LoginRequired';
import EmailVerificationRequired from '../pages/EmailVerificationRequired';

function securePage<P extends object>(
  WrappedComponent: React.ComponentType<P>
) {
  const SecurePage: React.FC<P> = props => {
    const { user } = useAuthState();

    if (!user) {
      return <LoginRequired />;
    }

    if (!user.email_verified) {
      return <EmailVerificationRequired />;
    }

    return <WrappedComponent {...props} />;
  };

  return SecurePage;
}

export default securePage;

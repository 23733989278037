import React from 'react';
import {
  makeStyles,
  Paper,
  Grid,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
//import { useMount } from 'react-use';

import { IUser } from '../interfaces/User/User';
import { getIcelandicName } from '../utils/locale';
import { TABLE_ROWS_OPTIONS } from '../constants';
import TablePaginationActions from './TablePaginationActions';

interface IProps {
  users: IUser[];
  totalUsers: number;
  loading: boolean;
  currentPage: number;
  rowsPerPage: number;
  setCurrentPage: (page: number) => void;
  setRowsPerPage: (rows: number) => void;
}

interface ITableCell {
  id: string;
  label: string;
  align?: 'left' | 'right' | 'center';
  render: (user: IUser) => React.ReactNode;
}

const useStyles = makeStyles(theme => ({
  active: {
    color: 'white',
    backgroundColor: theme.palette.success.main,
    borderRadius: 5,
    padding: 2,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'center'
  },
  inactive: {
    backgroundColor: theme.palette.error.main
  },
  searchArea: {
    margin: theme.spacing(0),
    width: '100%'
  },
  button: {
    padding: theme.spacing(2, 3)
  },
  tableMargin: {
    margin: theme.spacing(2, 0)
  },
  displayTable: {
    display: 'table'
  }
}));

const UserTable: React.FC<IProps> = ({
  users,
  totalUsers,
  loading,
  currentPage,
  rowsPerPage,
  setCurrentPage,
  setRowsPerPage
}) => {
  const classes = useStyles();
  const history = useHistory();
  const tableCells = getTableCells();

  if (loading) {
    return (
      <Grid container justify="center" alignItems="center">
        <CircularProgress size={100} />
      </Grid>
    );
  }

  return (
    <React.Fragment>
      <Paper className={classes.tableMargin}>
        <TableContainer
          className={classes.displayTable}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {tableCells.map(cell => (
                <TableCell
                  key={cell.id}
                  align={cell.align}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {cell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{users.map(renderTableRow)}</TableBody>
        </TableContainer>
        <TablePagination
          labelRowsPerPage="Fjöldi raða fyrir síðu"
          rowsPerPageOptions={TABLE_ROWS_OPTIONS}
          component="div"
          count={totalUsers}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onChangePage={(_, p) => setCurrentPage(p)}
          onChangeRowsPerPage={e => {
            setCurrentPage(0);
            setRowsPerPage(parseInt(e.target.value, 10));
          }}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    </React.Fragment>
  );

  function renderTableRow(user: IUser) {
    return (
      <TableRow
        key={user.user_id}
        hover
        style={{ cursor: 'pointer' }}
        onClick={() => history.push(`/user/${user.user_id}`)}
        onMouseDown={(event) => {
          if (event.button === 1) {
            window.open(`/user/${user.user_id}`);
            window.focus();
          }
        }}
      >
        {tableCells.map(cell => (
          <TableCell key={cell.id} align={cell.align}>
            {cell.render(user)}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  function getTableCells(): ITableCell[] {
    return [
      {
        id: 'name',
        label: 'Fullt nafn',
        render: ({ name }) => name
      },
      {
        id: 'email',
        label: 'Netfang',
        render: ({ email }) => email
      },
      {
        id: 'companySSN',
        label: 'Kennitala fyrirtækis',
        render: ({ app_metadata }) =>
          app_metadata ? app_metadata.companySSN : ''
      },
      {
        id: 'serviceProviderNames',
        label: 'Aðgangur að fyrirtæki',
        render: ({ serviceProviders }) =>
          (serviceProviders || [])
            .map(sp => getIcelandicName(sp.translations))
            .join(', ')
      },
      {
        id: 'roles',
        label: 'Hlutverk',
        render: ({ roles }) => (roles.length ? roles[0].name : '')
      },
      {
        id: 'regions',
        label: 'Landsvæði',
        render: ({ regions }) =>
          (regions || []).map(r => getIcelandicName(r.translations)).join(', ')
      },
      {
        id: 'active',
        label: 'Virkur',
        render: ({ blocked }) => renderActiveCell(!blocked)
      }
    ];
  }

  function renderActiveCell(active: boolean) {
    return (
      <Typography
        className={cx(classes.active, { [classes.inactive]: !active })}
      >
        {active ? 'Já' : 'Nei'}
      </Typography>
    );
  }
};

export default UserTable;

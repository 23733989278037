import { RouteComponentProps } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import React, { useEffect, useState } from 'react';

import securePage from '../hocs/securePage';
import {
  IChargingStation,
  IChargingStationCategory,
  IChargingStationProvider,
  IChargingStationProviders,
  IChargingStationCategories
} from '../interfaces/ChargingStation/ChargingStation';
import { STATION_DETAILS, GET_PROVIDERS, GET_CATEGORIES } from '../graphql/queries/ChargingStations';
import Layout from '../components/Layout';
import ChargingStationForm from '../components/ChargingStationForm';
import Section from '../components/Section';

const ChargingStationDetails: React.FC<RouteComponentProps<{ id: string }>> = ({
  match
}) => {
  const { data: sData } = useQuery<IChargingStation>(STATION_DETAILS, {
    variables: { chargingStationId: match.params.id }
  });

  const { data: pData } = useQuery<IChargingStationProviders>(GET_PROVIDERS);
  const { data: cData } = useQuery<IChargingStationCategories>(GET_CATEGORIES);
  //const place = data ? data.Place : null;
  const [station, setStation] = useState<IChargingStation>();
  const [categories, setCategories] = useState<IChargingStationCategory[]>();
  const [providers, setProviders] = useState<IChargingStationProvider[]>();

  useEffect(() => {
    if (sData) {
      setStation(sData['ChargingStation']);

    }
  }, [sData]);

  useEffect(() => {
    if (pData) {
      setProviders(pData["ChargingStationProviders"].Providers);
    }
  }, [pData]);

  useEffect(() => {
    if (cData) {
      setCategories(cData["ChargingStationCategories"].Categories);
    }
  }, [cData]);

  return (
    <Layout useDefaultSpacing>
      <Section title="Hleðslustöð">
        <ChargingStationForm
          chargingStation={station}
          newChargingStation={false}
          categories={categories}
          providers={providers}
        />
      </Section>
    </Layout>
  );
};

export default securePage(ChargingStationDetails);

import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useQuery } from 'react-apollo';

import securePage from '../hocs/securePage';
import { IPlaceDetails } from '../interfaces/Place/Place';
import { PLACE_DETAILS } from '../graphql/queries/PlaceQueries';
import Layout from '../components/Layout';
import LoadingPage from '../components/LoadingPage';
import Section from '../components/Section';
import PlaceForm from '../components/PlaceForm';
import { getIcelandicName } from '../utils/locale';

const PlaceDetails: React.FC<RouteComponentProps<{ id: string }>> = ({
  match
}) => {
  const { loading, error, data } = useQuery<IPlaceDetails>(PLACE_DETAILS, {
    variables: { placeId: match.params.id }
  });
  const place = data ? data.Place : null;

  if (loading || error) {
    return <LoadingPage error={error ? error.message : ''} />;
  }
  return (
    <Layout useDefaultSpacing>
      <Section title={getIcelandicName(place.translations)}>
        <PlaceForm place={place} newPlace={false} />
      </Section>
    </Layout>
  );
};

export default securePage(PlaceDetails);

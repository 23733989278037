import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import axios from 'axios';

import Layout from '../components/Layout';
import Section from '../components/Section';
import SSNForm from '../components/SSNForm';
import LoadingPage from '../components/LoadingPage';

interface ICompanyInfo {
  legalName: string;
  SSN: string;
}

const SSNInput: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const [companyName, setCompanyName] = useState('');
  const [initSSN, setInitSSN] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const res: {
          data: { data: ICompanyInfo[] };
        } = await axios.get(
          `${process.env.REACT_APP_SSN_API_URL}/ssn/${match.params.id}`
        );
        const companyInfo =
          res.data.data && res.data.data.length ? res.data.data[0] : null;
        setCompanyName(
          companyInfo && companyInfo.legalName ? companyInfo.legalName : ''
        );
        setInitSSN(companyInfo && companyInfo.SSN ? companyInfo.SSN : '');
      } catch {
        setError(true);
      }
      setLoading(false);
    };
    fetchInfo();
  }, [match.params.id]);

  if (loading || error) {
    return (
      <LoadingPage
        simpleNav
        error={error ? 'Unable to fetch company info' : ''}
      />
    );
  }

  return (
    <Layout useDefaultSpacing simpleNav>
      <Section title={companyName}>
        <SSNForm
          initialSSN={initSSN}
          companyName={companyName}
          submitSSN={submitSSN}
        />
      </Section>
    </Layout>
  );

  async function submitSSN(newSSN: string) {
    await axios.post(
      `${process.env.REACT_APP_SSN_API_URL}/ssn/${match.params.id}`,
      {},
      { params: { SSN: newSSN } }
    );
  }
};

export default SSNInput;

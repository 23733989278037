import React, { useState } from 'react';
import { useQuery } from 'react-apollo';

import Layout from '../components/Layout';
import GeoFenceTable from '../components/GeoFenceTable';

import securePage from '../hocs/securePage';
import Section from '../components/Section';
import { IGeoFenceList } from '../interfaces/GeoFence/GeoFence';
import { GEOFENCES } from '../graphql/queries/GeoFenceQueries';
import LoadingPage from '../components/LoadingPage';
import useAdminChecker from '../hooks/useAdminChecker';
interface IProps {}

const GeoFences: React.FC<IProps> = () => {
  const isAdmin = useAdminChecker();
  const [regionIds, setRegionIds] = useState<Array<string>>([]);
  const { loading, data } = useQuery<{ GeoFences: IGeoFenceList[] }>(
    GEOFENCES,
    {
      variables: { regionIds }
    }
  );
  if (loading) {
    return <LoadingPage />;
  }

  const geofences = data ? data.GeoFences : [];
  return (
    <Layout useDefaultSpacing>
      <Section
        title="Landmörk"
        link={isAdmin ? '/geofences/new' : undefined}
        linkText={isAdmin ? 'Stofna ný landmörk' : undefined}
      >
        <GeoFenceTable
          geofences={geofences}
          initialFilter={regionIds}
          searchGeofences={setRegionIds}
        ></GeoFenceTable>
      </Section>
    </Layout>
  );
};

export default securePage(GeoFences);

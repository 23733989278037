import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { isLoggedIn } from '../utils/auth';
import useAuthActions from '../hooks/useAuthActions';
import useTimeout from '../hooks/useTimeout';

const Login: React.FC = () => {
  const { login } = useAuthActions();
  const [isTextVisible, setTextVisible] = useState(false);

  useEffect(() => {
    if (!isLoggedIn()) {
      login();
    }
  }, [login]);

  useTimeout(() => {
    setTextVisible(true);
  }, 2000);

  if (isLoggedIn()) {
    return <Redirect to="/" />;
  }

  return isTextVisible ? (
    <p>Redirecting you to the login page, please wait...</p>
  ) : null;
};

export default Login;

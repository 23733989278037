import { gql } from 'apollo-boost';
export const CREATE_GEOFENCE = gql`
  mutation createFence($values: GeoFenceInput!) {
    createGeoFence(values: $values) {
      id
      type
      properties {
        translations {
          locale
          name
        }
      }
      regions {
        id
        translations {
          locale
          name
        }
      }
    }
  }
`;

export const UPDATE_GEOFENCE = gql`
  mutation updateFence($fenceId: String!, $values: GeoFenceUpdate!) {
    updateGeoFence(fenceId: $fenceId, values: $values) {
      id
      type
      properties {
        translations {
          locale
          name
        }
      }
      regions {
        id
        translations {
          locale
          name
        }
      }
    }
  }
`;

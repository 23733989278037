import { gql } from 'apollo-boost';
import { userFragment } from '../fragments/UserFragments';

export const UPDATE_USER = gql`
  mutation updateUser($userId: String!, $userUpdate: UserUpdate!) {
    updateUser(userId: $userId, userInfo: $userUpdate) {
      ...user
    }
  }
  ${userFragment}
`;

export const UPDATE_USER_ROLE = gql`
  mutation updateUserRole($userId: String!, $roleId: String!) {
    setUserRole(userId: $userId, roleId: $roleId) {
      ...user
    }
  }
  ${userFragment}
`;

export const BLOCK_USER = gql`
  mutation blockUser($userId: String!) {
    blockUser(userId: $userId) {
      ...user
    }
  }
  ${userFragment}
`;

export const UNBLOCK_USER = gql`
  mutation unblockUser($userId: String!) {
    unblockUser(userId: $userId) {
      ...user
    }
  }
  ${userFragment}
`;

export const REQUEST_NEW_PASSWORD = gql`
  mutation requestNewPassword($email: String!) {
    requestNewPassword(email: $email) {
      success
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser($userInfo: UserInput!) {
    createUser(userInfo: $userInfo) {
      ...user
    }
  }
  ${userFragment}
`;
export const DELETE_USER = gql`
  mutation deleteUser($userId: String!) {
    removeUser(userId: $userId) {
      success
    }
  }
`;

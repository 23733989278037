import { call, put, select, takeLatest, delay } from 'redux-saga/effects';
import {
  getAuthConfig,
  getRouterState,
  getTokenExpiryTimestamp
} from '../selectors';
import * as actions from './actions';
import { LOGIN_ROUTE } from '../../constants';
import {
  login,
  logout,
  renewSession,
  setSession,
  unsetSession,
  setReturnTo
} from '../../utils/auth';

function* handleRenewSession() {
  const authConfig = yield select(getAuthConfig);

  try {
    const result = yield call(renewSession, authConfig);
    yield put(actions.loggedIn(result));
  } catch (err) {
    yield put(actions.loggedOut());
  }
}

function* handleLoginRequest() {
  const authConfig: ReturnType<typeof getAuthConfig> = yield select(
    getAuthConfig
  );
  const routerState: ReturnType<typeof getRouterState> = yield select(
    getRouterState
  );

  // Don't set the returnTo flag if we are on the login route
  if (routerState.location.pathname !== LOGIN_ROUTE) {
    yield call(setReturnTo);
  }

  yield call(login, authConfig);
}

function* handleLogoutRequest() {
  const authConfig = yield select(getAuthConfig);
  yield call(unsetSession);
  yield call(logout, authConfig);
}

function* handleLoggedIn() {
  yield call(setSession);
  yield put(actions.scheduleRenewal());
  yield put(actions.authReady());
}

function* handleLoggedOut() {
  yield call(unsetSession);
  yield put(actions.authReady());
}

function* handleScheduleRenewal() {
  const expiresAt = yield select(getTokenExpiryTimestamp);

  if (expiresAt > 0) {
    const delayMs = expiresAt - Date.now();
    yield delay(delayMs);
    yield put(actions.renewSession());
  }
}

const authSagas = [
  takeLatest(actions.RENEW_SESSION, handleRenewSession),
  takeLatest(actions.SCHEDULE_RENEWAL, handleScheduleRenewal),
  takeLatest(actions.LOGGED_IN, handleLoggedIn),
  takeLatest(actions.LOGGED_OUT, handleLoggedOut),
  takeLatest(actions.LOGIN, handleLoginRequest),
  takeLatest(actions.LOGOUT, handleLogoutRequest)
];

export default authSagas;

import MomentUtils from '@date-io/moment';
import Joi from '@hapi/joi';
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import { Autocomplete } from '@material-ui/lab';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { Form, Formik } from 'formik';
import includes from 'lodash/includes';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import EditSigns from '../components/EditSigns';
import Section from '../components/Section';
import { GET_DATA } from '../graphql/queries/Misc';
import { ALL_SERVICE_PROVIDERS } from '../graphql/queries/ServiceProviderQueries';
import useNotifier from '../hooks/useNotifier';
import { ICodes, IDataNew } from '../interfaces/Misc/zipCodes';
import {
  IServiceProvidersExtraLite,
  ISign
} from '../interfaces/ServiceProvider/ServiceProvider';
import { assistanceTexts } from '../utils/serviceProviderFieldTooltips';
import LocationInput from './LocationInput';
import SavePrompt from './SavePrompt';
import SelectPlace from './SelectPlace';
import SelectProvider from './SelectProvider';

interface IProps {
  handleFormSubmit: (values: any, signs: ISign[]) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      padding: theme.spacing(2)
    },
    headline: {
      padding: theme.spacing(2)
    },
    imgRoot: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden'
    },
    gridList: {
      flexWrap: 'nowrap',
      transform: 'translateZ(0)',
      width: '100%'
    },
    title: {
      color: theme.palette.primary.light
    },
    titleBar: {
      //background: 'none'
      background:
        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
    },
    formControl: {
      minWidth: 200
    },
    textField: {
      //minWidth: 200,
      width: 200
    },
    typographyTitle: {
      padding: 20,
      paddingBottom: 5,
      paddingTop: 10
    },
    paperSections: {
      marginTop: 20
    },
    typography: {
      display: 'inline'
    },
    typographyStrong: {
      fontWeight: 700,
      display: 'inline'
    },
    popoverTypography: {
      padding: theme.spacing(2)
    }
  })
);

const CreateServiceProviderForm: React.FC<IProps> = ({ handleFormSubmit }) => {
  const [value, setValue] = React.useState(0);
  const [signs, setSigns] = useState<ISign[] | []>([]);
  const [zipCodeOptions, setZipCodeOptions] = useState<ICodes[] | null>(null);
  const [townCodeOptions, setTownCodeOptions] = useState<ICodes[] | null>(null);
  const [signsOptions, setSignsOptions] = useState<ICodes[] | null>(null);
  const [regionsOptions, setRegionsOptions] = useState<ICodes[] | null>(null);
  const [allServicesOptions, setAllServicesOptions] = useState<ICodes[] | null>(
    null
  );
  const [categoriesOptions, setCategoriesOptions] = useState<any>(null);
  const [invalidFields, setInvalidFields] = useState([]);
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [popoverText, setPopoverText] = useState('');

  const notifier = useNotifier();
  const history = useHistory();

  const popoverOpen = Boolean(popoverAnchor != null);
  const popoverId = popoverOpen ? 'simple-popover' : undefined;

  const basicInfo = [
    'legalName',
    'name',
    'nameEn',
    'SSN',
    'nameEs',
    'nameFr',
    'nameDe'
  ];
  const namesBasicInfo = [
    'Lögheiti',
    'Markaðsheiti',
    'Markaðsheiti en',
    'Kennitala',
    'Markaðsheiti es',
    'Markaðsheiti fr',
    'Markaðsheiti de'
  ];

  const addressInfo = [
    'address',
    'poBox',
    'zipCode',
    'townCode',
    'latitude',
    'longitude'
  ];

  const namesAddressInfo = [
    'Heimilisfang',
    'Pósthólf',
    'Póstnúmer',
    'Sveitafélagsnúmer',
    'Breiddargráða',
    'Lengdargráða'
  ];

  const networkInfo = [
    'email',
    'email2',
    'phoneNr',
    'phoneNr2',
    'website',
    'website2',
    'facebook',
    'instagram',
    'tripadvisor',
    'youtube'
  ];
  const namesNetworkInfo = [
    'Netfang',
    'Netfang 2',
    'Símanúmer',
    'Símanúmer 2',
    'Vefsíða',
    'Vefsíða 2',
    'Facebook',
    'Instagram',
    'Tripadvisor',
    'Youtube'
  ];

  const typeOfServiceInfo = ['eService'];
  const namesTypeOfServiceInfo = ['Eingöngu rafræn þjónusta'];

  const serviceInfo = ['nrOfHouses', 'nrOfApartments', 'nrOfRooms', 'nrOfBeds'];
  const namesServiceInfo = [
    'Fjöldi húsa',
    'Fjöldi íbúða',
    'Fjöldi herbergja',
    'Fjöldi rúma'
  ];

  const vakinn = [
    'isApprovedRestaurant',
    'isApprovedTourismService',
    'ecoLabel',
    'stars',
    'superior',
    'iso',
    'swan',
    'blueFlag',
    'earthcheck',
    'greenKey'
  ];

  const namesVakinn = [
    'Er viðurkenndur veitingastaður',
    'Er viðurkennd ferðaþjónusta',
    'Umhverfismerki',
    'Stjörnur',
    'Superior',
    'Gæðavottað hostel',
    'Gæðavottað orlofshús- íbúð',
    'Gæðavottað tjaldsvæði',
    'Gæðavottað gistiheimili',
    'Gæðavottuð heimagisting'
  ];

  const openingHours = ['seasonal', 'from', 'to'];
  const namesOpeningHours = ['Árstíðarbundið', 'Frá', 'Til'];

  const contactInfo = [
    'contactName',
    'contactEmail',
    'contactPhone',
    'contactComment'
  ];
  const namesContactInfo = ['Nafn', 'Netfang', 'Sími', 'Athugasemd'];

  const relatedServiceProvidersInfo = ['serviceProviderIds'];
  const namesRelatedServiceProvidersInfo = ['Tengdar þjónustur'];

  const placesInfo = ['places'];
  const namesPlacesInfo = ['Tengdir staðir'];

  const giftcertInfo = ['giftcert', 'isat'];
  const namesGiftcertInfo = ['Gjafabréf', 'ÍSAT'];
  const cleanAndSafeInfo = ['cleanAndSafe'];
  const namesCleanAndSafeInfo = ['Clean&Safe'];
  const accessibleInfo = ['accessible'];
  const namesAccessibleInfo = ['Gott aðgengi'];

  const multiline = ['contactComment'];

  const required = [
    'SSN',
    'name',
    'nameEn',
    'legalName',
    'address',
    'regions',
    'zipCode',
    'phoneNr',
    'email',
    /*     'website',
     */ 'latitude',
    'longitude'
    /* 'purhcasedRegistration' */
  ];

  const selectAllowedList = [
    'zipCode',
    'townCode',
    'regions',
    'eService',
    'isApprovedRestaurant',
    'isApprovedTourismService',
    'superior',
    'ecoLabel',
    'stars',
    'allYear',
    'seasonal',
    'from',
    'to',
    'active',
    'purhcasedRegistration',
    'serviceProviderIds',
    'places',
    'giftcert',
    'cleanAndSafe',
    'iso',
    'swan',
    'blueFlag',
    'earthcheck',
    'greenKey',
    'accessible'
  ];
  const optionsList = {
    zipCode: zipCodeOptions,
    townCode: townCodeOptions,
    regions: regionsOptions,
    purhcasedRegistration: regionsOptions,
    serviceProviderIds: allServicesOptions
  };

  const labelWidth = {
    eService: 200,
    isApprovedRestaurant: 235,
    isApprovedTourismService: 215,
    ecoLabel: 120,
    stars: 70,
    superior: 70,
    allYear: 60,
    seasonal: 110,
    active: 30,
    purhcasedRegistration: 120,
    giftcert: 70,
    cleanAndSafe: 70
  };

  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" color="primary" />;

  const {
    loading: allServicesLoading,
    error: allServicesError,
    data: allServices
  } = useQuery<IServiceProvidersExtraLite>(ALL_SERVICE_PROVIDERS);
  const { loading, error, data } = useQuery<IDataNew>(GET_DATA);

  useEffect(() => {
    if (allServices) {
      const allServicesOptions =
        allServices.ServiceProviders.ServiceProviders.map(provider => {
          return { label: provider.name, value: provider.serviceProviderId };
        });
      setAllServicesOptions(allServicesOptions);
    }
    if (data) {
      setZipCodeOptions(data.ZipCodes);
      setTownCodeOptions(data.TownCodes);
      const newRegions = data.Regions.map(region => {
        return { label: region.translations[0].name, value: region.id };
      });
      setRegionsOptions(newRegions);
      var newCategoriesOptions = {};
      data.Categories.forEach(category => {
        category.subCategories.forEach(sub => {
          newCategoriesOptions[sub.id] = false;
        });
      });
      setCategoriesOptions(newCategoriesOptions);

      const newSigns = data.Signs.map(sign => {
        return { label: sign.translations[0].name, value: sign.id };
      });
      setSignsOptions(newSigns);
    }
  }, [allServices, data]);

  const classes = useStyles({});
  const schema = Joi.object({
    SSN: Joi.string().min(10).max(10),
    name: Joi.string().required(),
    nameEn: Joi.string().required(),
    legalName: Joi.string().required(),
    address: Joi.string().allow(''),
    regions: Joi.array().min(1).required(),
    zipCode: Joi.any(),
    phoneNr: Joi.string().required(),
    email: Joi.string().required(),
    latitude: Joi.string().allow(''),
    longitude: Joi.string().allow('')
  });

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleInfoClick = (event, infoText) => {
    setPopoverAnchor({ x: event.clientX, y: event.clientY });
    setPopoverText(infoText);
  };

  const handlePopoverClose = () => {
    setPopoverAnchor(null);
  };

  const checkFormValidation = (values: any) => {
    var validateValues = {};
    required.forEach(value => {
      validateValues[value] = values[value];
    });
    const joiValidate = schema.validate(validateValues, { abortEarly: false });
    if (joiValidate.error) {
      const newInvalidFields = joiValidate.error.details.map(
        field => field.path[0]
      );
      setInvalidFields(newInvalidFields);
      notifier.notify('Laga þarf villurnar í forminu til að halda áfram', {
        variant: 'error'
      });
    } else {
      setInvalidFields([]);
      handleFormSubmit(values, signs);
    }
  };

  function renderAssistanceAdornment(key: string) {
    return (
      <div>
        {assistanceTexts[key] !== undefined && (
          <div>
            <InputAdornment position="end">
              <IconButton
                onClick={event => handleInfoClick(event, assistanceTexts[key])}
              >
                <InfoIcon />
              </IconButton>
            </InputAdornment>
          </div>
        )}
      </div>
    );
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={0}>{children}</Box>}
      </Typography>
    );
  }

  if (loading || allServicesLoading) {
    return (
      <Grid container direction="column" justify="center" alignContent="center">
        <CircularProgress size={100} />
      </Grid>
    );
  }
  if (error) {
    return <Typography variant="h2">{error.message}</Typography>;
  }
  if (allServicesError) {
    return <Typography variant="h2">{allServicesError.message}</Typography>;
  }

  if (
    data &&
    zipCodeOptions &&
    townCodeOptions &&
    regionsOptions &&
    allServices &&
    categoriesOptions
  ) {
    return (
      <Section>
        <div className={classes.root}>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleTabChange}
              variant="fullWidth"
              scrollButtons="auto"
              aria-label="full width tabs example"
            >
              <Tab label="Grunnupplýsingar" />
              <Tab label="Þjónustuupplýsingar" />
              <Tab label="Gildi og Tákn" />
            </Tabs>
          </AppBar>
          <Formik
            initialValues={{
              SSN: '',
              name: '',
              nameEn: '',
              address: '',
              regions: [],
              zipCode: '',
              townCode: '',
              poBox: '',
              phoneNr: '',
              phoneNr2: '',
              email: '',
              email2: '',
              website: '',
              website2: '',
              eService: '',
              facebook: '',
              instagram: '',
              tripadvisor: '',
              youtube: '',
              giftcert: '',
              cleanAndSafe: '',
              accessible: '',
              isat: '',
              isApprovedRestaurant: '',
              isApprovedTourismService: '',
              stars: '',
              ecoLabel: '',
              superior: '',
              iso: '',
              swan: '',
              blueFlag: '',
              earthcheck: '',
              greenKey: '',
              nrOfHouses: '',
              nrOfApartments: '',
              nrOfRooms: '',
              nrOfBeds: '',
              nrOfSleepingBagSpace: '',
              allYear: '',
              seasonal: '',
              from: null,
              to: null,
              latitude: '',
              longitude: '',
              contactName: '',
              contactEmail: '',
              contactPhone: '',
              contactComment: '',
              purhcasedRegistration: [],
              serviceProviderIds: [],
              places: [],
              categories: categoriesOptions
            }}
            onSubmit={checkFormValidation}
          >
            {formik => {
              const {
                values,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit
              } = formik;
              const validateValues = {};
              required.forEach(value => {
                validateValues[value] = values[value];
              });
              const hasErrors = !!schema.validate(validateValues).error;

              return (
                <React.Fragment>
                  <Form onSubmit={handleSubmit}>
                    <TabPanel value={value} index={0}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={8} key={1}>
                          <Paper className={classes.paperSections}>
                            <Typography
                              variant="h6"
                              className={classes.typographyTitle}
                            >
                              Grunnupplýsingar
                            </Typography>
                            {renderInfo(
                              basicInfo,
                              namesBasicInfo,
                              values,
                              handleChange,
                              handleBlur,
                              setFieldValue
                            )}
                          </Paper>
                          <Paper className={classes.paperSections}>
                            <Typography
                              variant="h6"
                              className={classes.typographyTitle}
                            >
                              Heimilisfang
                            </Typography>
                            {renderInfo(
                              addressInfo,
                              namesAddressInfo,
                              values,
                              handleChange,
                              handleBlur,
                              setFieldValue
                            )}
                          </Paper>
                          <Paper className={classes.paperSections}>
                            <Typography
                              variant="h6"
                              className={classes.typographyTitle}
                            >
                              Netupplýsingar
                            </Typography>
                            {renderInfo(
                              networkInfo,
                              namesNetworkInfo,
                              values,
                              handleChange,
                              handleBlur,
                              setFieldValue
                            )}
                          </Paper>
                          <Paper className={classes.paperSections}>
                            <Typography
                              variant="h6"
                              className={classes.typographyTitle}
                            >
                              Upplýsingar um tengilið
                            </Typography>
                            {renderInfo(
                              contactInfo,
                              namesContactInfo,
                              values,
                              handleChange,
                              handleBlur,
                              setFieldValue
                            )}
                          </Paper>
                        </Grid>
                        {renderRightSide(
                          true,
                          false,
                          values,
                          handleChange,
                          handleBlur,
                          setFieldValue
                        )}
                      </Grid>
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={8} key={1}>
                          <Paper className={classes.paperSections}>
                            <Typography
                              variant="h6"
                              className={classes.typographyTitle}
                            >
                              Tegund þjónustu
                            </Typography>
                            {renderInfo(
                              typeOfServiceInfo,
                              namesTypeOfServiceInfo,
                              values,
                              handleChange,
                              handleBlur,
                              setFieldValue
                            )}
                          </Paper>
                          <Paper className={classes.paperSections}>
                            <Typography
                              variant="h6"
                              className={classes.typographyTitle}
                            >
                              Opnunartími
                            </Typography>
                            {renderInfo(
                              openingHours,
                              namesOpeningHours,
                              values,
                              handleChange,
                              handleBlur,
                              setFieldValue
                            )}
                          </Paper>
                          <Paper className={classes.paperSections}>
                            <Typography
                              variant="h6"
                              className={classes.typographyTitle}
                            >
                              Bæta við tengdri þjónustu
                            </Typography>
                            {renderInfo(
                              relatedServiceProvidersInfo,
                              namesRelatedServiceProvidersInfo,
                              values,
                              handleChange,
                              handleBlur,
                              setFieldValue
                            )}
                          </Paper>
                          <Paper className={classes.paperSections}>
                            <Typography
                              variant="h6"
                              className={classes.typographyTitle}
                            >
                              Bæta við tengdum stað
                            </Typography>
                            {renderInfo(
                              placesInfo,
                              namesPlacesInfo,
                              values,
                              handleChange,
                              handleBlur,
                              setFieldValue
                            )}
                          </Paper>
                          {/*                           <Paper className={classes.paperSections}>
                            <Typography
                              variant="h6"
                              className={classes.typographyTitle}
                            >
                              Ferðagjöfin 2020
                            </Typography>
                            {renderInfo(
                              giftcertInfo,
                              namesGiftcertInfo,
                              values,
                              handleChange,
                              handleBlur,
                              setFieldValue
                            )}
                          </Paper>
                          <Paper className={classes.paperSections}>
                            <Typography
                              variant="h6"
                              className={classes.typographyTitle}
                            >
                              Clean&Safe
                            </Typography>
                            {renderInfo(
                              cleanAndSafeInfo,
                              namesCleanAndSafeInfo,
                              values,
                              handleChange,
                              handleBlur,
                              setFieldValue
                            )}
                          </Paper> */}
                          <Paper className={classes.paperSections}>
                            <Typography
                              variant="h6"
                              className={classes.typographyTitle}
                            >
                              Gott aðgengi
                            </Typography>
                            {renderInfo(
                              accessibleInfo,
                              namesAccessibleInfo,
                              values,
                              handleChange,
                              handleBlur,
                              setFieldValue
                            )}
                          </Paper>
                        </Grid>
                        {renderRightSide(
                          false,
                          true,
                          values,
                          handleChange,
                          handleBlur,
                          setFieldValue
                        )}
                      </Grid>
                    </TabPanel>

                    <TabPanel value={value} index={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={8} key={1}>
                          <EditSigns
                            signs={signs}
                            setSigns={setSigns}
                            signsOptions={signsOptions}
                            allSigns={data.Signs}
                            latest={true}
                            disableEdit={false}
                          ></EditSigns>
                          <Paper className={classes.paperSections}>
                            <Typography
                              variant="h6"
                              className={classes.typographyTitle}
                            >
                              Gisting
                            </Typography>
                            {renderInfo(
                              serviceInfo,
                              namesServiceInfo,
                              values,
                              handleChange,
                              handleBlur,
                              setFieldValue
                            )}
                          </Paper>
                          <Paper className={classes.paperSections}>
                            <Typography
                              variant="h6"
                              className={classes.typographyTitle}
                            >
                              Vakinn
                            </Typography>
                            {renderInfo(
                              vakinn,
                              namesVakinn,
                              values,
                              handleChange,
                              handleBlur,
                              setFieldValue
                            )}
                          </Paper>
                        </Grid>
                        {renderRightSide(
                          false,
                          false,
                          values,
                          handleChange,
                          handleBlur,
                          setFieldValue
                        )}
                      </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={3}></TabPanel>
                  </Form>
                  <SavePrompt formik={formik} error={hasErrors} />
                </React.Fragment>
              );
            }}
          </Formik>
        </div>
      </Section>
    );
  }
  return null;

  function renderInfo(
    allowed: string[],
    names: string[],
    values: any,
    handleChange: any,
    handleBlur: any,
    setFieldValue: any
  ) {
    return (
      <Grid container spacing={3}>
        {allowed.map((key, index) => {
          if (key === 'latitude' || key === 'longitude') {
            var isEservice =
              values['eService'] === 'true' || values['eService'] === 'True'
                ? true
                : false;
            return (
              <Grid item xs={12} sm={6} key={index}>
                <Paper className={classes.paper} elevation={0}>
                  <LocationInput
                    location={{
                      latitude: values.latitude,
                      longitude: values.longitude
                    }}
                    type={key}
                    label={names[index]}
                    error={includes(invalidFields, key)}
                    required={!isEservice}
                    onChange={location => {
                      setFieldValue('latitude', location.latitude);
                      setFieldValue('longitude', location.longitude);
                    }}
                    endAdornment={renderAssistanceAdornment(key)}
                  />
                </Paper>
              </Grid>
            );
          }
          if (key === 'website' || key === 'website2') {
            return (
              <Grid item xs={12} sm={6} key={index}>
                <Grid container justify="center" alignItems="center">
                  <Grid
                    item
                    xs={10}
                    sm={!!values[key] ? 10 : 12}
                    key={index + '1'}
                  >
                    <Paper className={classes.paper} elevation={0}>
                      <TextField
                        fullWidth
                        /* className={classes.textField} */ id={key}
                        label={names[index]}
                        variant="outlined"
                        value={values[key]}
                        required={includes(required, key)}
                        error={includes(invalidFields, key)}
                        multiline={includes(multiline, key)}
                        rows={6}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                          endAdornment: renderAssistanceAdornment(key)
                        }}
                      />
                    </Paper>
                  </Grid>
                  {!!values[key] && (
                    <Grid item xs={2} sm={2} key={index + '2'}>
                      <Button
                        color="primary"
                        variant="outlined"
                        href={
                          //remove https:// in front of string before applying https to ignore duplicates, https must be included to redirect to external link
                          'https://' + values[key].replace(/(^\w+:|^)\/\//, '')
                        }
                        target="_blank"
                        style={{ padding: 0, height: 56 }}
                      >
                        Prófa
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            );
          }
          if (key === 'address') {
            var isEservice =
              values['eService'] === 'true' || values['eService'] === 'True'
                ? true
                : false;
            return (
              <Grid item xs={12} sm={6} key={index}>
                <Paper className={classes.paper} elevation={0}>
                  {includes(selectAllowedList, key) ? (
                    renderSelectInfo(
                      key,
                      names[index],
                      values,
                      handleChange,
                      handleBlur,
                      setFieldValue
                    )
                  ) : (
                    <TextField
                      fullWidth
                      /* className={classes.textField} */ id={key}
                      label={names[index]}
                      variant="outlined"
                      value={values[key]}
                      required={!isEservice}
                      error={includes(invalidFields, key)}
                      multiline={includes(multiline, key)}
                      rows={6}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        endAdornment: renderAssistanceAdornment(key)
                      }}
                    />
                  )}
                </Paper>
              </Grid>
            );
          }

          return (
            <Grid item xs={12} sm={6} key={index}>
              <Paper className={classes.paper} elevation={0}>
                {includes(selectAllowedList, key) ? (
                  renderSelectInfo(
                    key,
                    names[index],
                    values,
                    handleChange,
                    handleBlur,
                    setFieldValue
                  )
                ) : (
                  <TextField
                    fullWidth
                    /* className={classes.textField} */ id={key}
                    label={names[index]}
                    variant="outlined"
                    value={values[key]}
                    required={includes(required, key)}
                    error={includes(invalidFields, key)}
                    multiline={includes(multiline, key)}
                    rows={6}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      endAdornment: renderAssistanceAdornment(key)
                    }}
                  />
                )}
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    );
  }
  function renderSelectInfo(
    key: string,
    name: string,
    values: any,
    handleChange: any,
    handleBlur: any,
    setFieldValue: any
  ) {
    if (key === 'stars') {
      return (
        <FormControl
          /* className={classes.formControl} */ fullWidth
          variant={'outlined'}
          required={includes(required, key)}
        >
          <InputLabel id={key + '-label'}>{name}</InputLabel>
          <Select
            id={key}
            labelId={key + '-label'}
            labelWidth={labelWidth[key]}
            value={values[key]}
            onChange={handleChange(key)}
            onBlur={handleBlur(key)}
          >
            <MenuItem value={'0'}>0</MenuItem>
            <MenuItem value={'1'}>1</MenuItem>
            <MenuItem value={'2'}>2</MenuItem>
            <MenuItem value={'3'}>3</MenuItem>
            <MenuItem value={'4'}>4</MenuItem>
            <MenuItem value={'5'}>5</MenuItem>
          </Select>
        </FormControl>
      );
    } else if (key === 'ecoLabel') {
      return (
        <FormControl
          /* className={classes.formControl} */ fullWidth
          variant={'outlined'}
          required={includes(required, key)}
        >
          <InputLabel id={key + '-label'}>{name}</InputLabel>
          <Select
            id={key}
            labelId={key + '-label'}
            labelWidth={labelWidth[key]}
            value={values[key]}
            onChange={handleChange(key)}
            onBlur={handleBlur(key)}
          >
            <MenuItem value={'Gold'}>Gull</MenuItem>
            <MenuItem value={'Silver'}>Silfur</MenuItem>
            <MenuItem value={'Bronze'}>Brons</MenuItem>
          </Select>
        </FormControl>
      );
    } else if (key === 'accessible') {
      return (
        <FormControl
          /* className={classes.formControl} */ fullWidth
          variant={'outlined'}
          required={includes(required, key)}
        >
          <InputLabel id={key + '-label'}>{name}</InputLabel>
          <Select
            id={key}
            labelId={key + '-label'}
            labelWidth={labelWidth[key]}
            value={values[key]}
            onChange={handleChange(key)}
            onBlur={handleBlur(key)}
          >
            <MenuItem value={''}>Ekkert</MenuItem>
            <MenuItem value={'Mobility'.toUpperCase()}>Hjólastóll</MenuItem>
            <MenuItem value={'MobilityVisibility'.toUpperCase()}>
              Hjólastóll-Sjónskerðing
            </MenuItem>
            <MenuItem value={'MobilityHearing'.toUpperCase()}>
              Hjólastóll-Heyrnarskerðing
            </MenuItem>
            <MenuItem value={'MobilityVisibilityHearing'.toUpperCase()}>
              Hjólastóll-Heyrnarskerðing-Sjónskerðing
            </MenuItem>
          </Select>
        </FormControl>
      );
    } else if (key === 'from' || key === 'to') {
      return values.seasonal === 'true' ? (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            fullWidth
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            format="DD/MM/YYYY"
            //margin="normal"
            color="primary"
            label={name}
            id={key}
            value={values[key]}
            onChange={e => setFieldValue(key, moment(e))}
            onBlur={handleBlur(key)}
          />
        </MuiPickersUtilsProvider>
      ) : null;
    } else if (key === 'places') {
      return (
        <SelectPlace
          id={`checkboxes-${key}`}
          values={values[key]}
          error={false}
          label="Tengdir staðir"
          onChange={val => setFieldValue(key, val)}
          fullWidth
        />
      );
    } else if (key === 'serviceProviderIds') {
      return (
        <SelectProvider
          id={`checkboxes-${key}`}
          values={values[key]}
          error={false}
          label="Tengdar þjónustur"
          onChange={val => setFieldValue(key, val)}
          fullWidth
        />
      );
    } else if (key === 'regions' || key === 'purhcasedRegistration') {
      return (
        <Autocomplete
          multiple
          disableCloseOnSelect
          id={`checkboxes-${key}`}
          options={optionsList[key]}
          onChange={(_, value) => setFieldValue(key, value)}
          onBlur={handleBlur(key)}
          value={values[key]}
          getOptionLabel={option => option.label}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </React.Fragment>
          )}
          style={{ width: '100%' }}
          renderInput={params => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password'
              }}
              variant="outlined"
              label={name + (includes(required, key) ? ' *' : '')}
              error={includes(invalidFields, key)}
            />
          )}
        />
      );
    } else if (key === 'zipCode' || key === 'townCode') {
      var isEservice =
        values['eService'] === 'true' || values['eService'] === 'True'
          ? true
          : false;
      return (
        <Autocomplete
          id={`checkboxes-${key}`}
          options={optionsList[key]}
          onChange={(_, value) => setFieldValue(key, value)}
          onBlur={handleBlur(key)}
          value={values[key]}
          getOptionLabel={option => option.label}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </React.Fragment>
          )}
          style={{ width: '100%' }}
          renderInput={params => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password'
              }}
              variant="outlined"
              label={name}
              required={!isEservice && key != 'townCode'}
              error={includes(invalidFields, key)}
            />
          )}
        />
      );
    } else {
      return (
        <FormControl
          /* className={classes.formControl} */ fullWidth
          variant="outlined"
          required={includes(required, key)}
        >
          <InputLabel id={key + '-label'}>{name}</InputLabel>
          <Select
            id={key}
            labelId={key + '-label'}
            labelWidth={labelWidth[key]}
            value={values[key]}
            onChange={handleChange(key)}
            onBlur={handleBlur(key)}
            error={includes(invalidFields, key)}
            endAdornment={renderAssistanceAdornment(key)}
          >
            <MenuItem value={'true'}>Já</MenuItem>
            <MenuItem value={'false'}>Nei</MenuItem>
          </Select>
        </FormControl>
      );
    }
  }
  function renderRightSide(
    landshluti,
    flokkar,
    values,
    handleChange,
    handleBlur,
    setFieldValue
  ) {
    return (
      <Grid item xs={12} sm={4} key={2}>
        {landshluti ? (
          <Paper className={classes.paperSections}>
            <Grid container direction="row">
              <Typography variant="h6" className={classes.typographyTitle}>
                Landshluti
              </Typography>
              <IconButton
                onClick={event =>
                  handleInfoClick(event, assistanceTexts['regions'])
                }
              >
                <InfoIcon />
              </IconButton>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} key={'landshluti'}>
                <Paper className={classes.paper} elevation={0}>
                  {renderSelectInfo(
                    'regions',
                    'Landshluti',
                    values,
                    handleChange,
                    handleBlur,
                    setFieldValue
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        ) : flokkar ? (
          <Paper className={classes.paperSections}>
            <Grid container direction="row">
              <Typography variant="h6" className={classes.typographyTitle}>
                Flokkar
              </Typography>
              <IconButton
                onClick={event =>
                  handleInfoClick(event, assistanceTexts['groups'])
                }
              >
                <InfoIcon />
              </IconButton>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} key={'Flokkar'}>
                <Paper className={classes.paper} elevation={0}>
                  {data.Categories.map(category => {
                    return (
                      <ExpansionPanel key={category.translations[0].name}>
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon color="primary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="h6">
                            {category.translations[0].name}
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <FormGroup>
                            {category.subCategories.map(sub => {
                              return (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.categories[sub.id]}
                                      onChange={() =>
                                        setFieldValue(
                                          `categories.${sub.id}`,
                                          !values.categories[sub.id]
                                        )
                                      }
                                      onBlur={handleBlur(
                                        `categories.${sub.id}`
                                      )}
                                      color="primary"
                                    />
                                  }
                                  label={sub.translations[0].name}
                                />
                              );
                            })}
                          </FormGroup>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    );
                  })}
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        ) : null}
        <Paper className={classes.paperSections}>
          <Typography variant="h6" className={classes.typographyTitle}>
            Staða
          </Typography>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} key={'Útgáfa'}>
              <Paper className={classes.paper} elevation={0}>
                {renderSelectInfo(
                  'purhcasedRegistration',
                  'Keypt skráning',
                  values,
                  handleChange,
                  handleBlur,
                  setFieldValue
                )}
              </Paper>
            </Grid>
          </Grid>
        </Paper>
        <Grid container spacing={2} className={classes.paperSections}>
          <Grid item xs={12}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              fullWidth
              style={{ padding: 10 }}
            >
              Senda inn þjónustu
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              color="default"
              variant="contained"
              fullWidth
              style={{ padding: 10 }}
              onClick={() => history.push('/service-providers')}
            >
              Hætta við
            </Button>
          </Grid>
        </Grid>

        <Popover
          id={popoverId}
          open={popoverOpen}
          anchorReference="anchorPosition"
          anchorPosition={
            popoverAnchor != null
              ? { top: popoverAnchor.y + 25, left: popoverAnchor.x }
              : { top: 0, left: 0 }
          }
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <Typography className={classes.popoverTypography}>
            {popoverText}
          </Typography>
        </Popover>
      </Grid>
    );
  }
};

export default CreateServiceProviderForm;

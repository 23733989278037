import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  section: {
    minHeight: 200,
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3)
  },
  submitButton: {
    padding: 10
  },
  sectionTitle: {
    marginBottom: theme.spacing(3)
  }
}));

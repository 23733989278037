import { gql } from 'apollo-boost';
export const GEOFENCES = gql`
  query getFences($regionIds: [String!]) {
    GeoFences(regionIds: $regionIds) {
      id
      type
      properties {
        translations {
          locale
          name
        }
        regionIds
      }
      regions {
        id
        translations {
          locale
          name
        }
      }
    }
  }
`;

export const GEOFENCE = gql`
  query getFences($fenceId: String!) {
    GeoFence(fenceId: $fenceId) {
      id
      type
      geometry {
        type
        coordinates
      }
      properties {
        translations {
          locale
          name
        }
        regionIds
      }
      regions {
        id
        translations {
          locale
          name
        }
      }
    }
  }
`;

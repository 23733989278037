import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Paper,
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Button,
  IconButton,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';
import includes from 'lodash/includes';
import Joi from '@hapi/joi';
import { Autocomplete } from '@material-ui/lab';
import {
  ICollection,
  ICollectionContent
} from '../interfaces/ServiceProvider/ServiceProvider';
import { ICodes, ICodes2 } from '../interfaces/Misc/zipCodes';
import { ILocale } from '../interfaces/Misc/zipCodes';
import { LOCALES } from '../graphql/queries/Misc';
import { useQuery } from 'react-apollo';
import { isEmptyHTML } from '../utils/markup';
import useNotifier from '../hooks/useNotifier';
import cx from 'classnames';
import RichText from './RichText';
import ConfirmDialog from './ConfirmDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2)
    },
    paperCollection: {
      backgroundColor: '#f4f4f7',
      padding: theme.spacing(2)
    },
    typographyTitle: {
      padding: 20,
      paddingBottom: 5,
      paddingTop: 10
    },
    paperSections: {
      marginTop: 20
    }
  })
);

interface IProps {
  categories?: any;
  collections: ICollection[];
  latest: boolean;
  className?: string;
  addCollection: (
    categoryId: string,
    collection: ICollectionContent
  ) => Promise<void>;
  editCollection: (
    categoryId: string,
    collection: ICollectionContent,
    collectionId: string,
    index: number
  ) => Promise<void>;
  deleteCollection: (collectionId: string, index: number) => Promise<void>;
  infoOnClick?: (event: React.MouseEvent) => void;
  disableEdit: boolean;
}

const EditCollection: React.FC<IProps> = ({
  categories,
  collections,
  latest,
  className,
  addCollection,
  editCollection,
  deleteCollection,
  infoOnClick,
  disableEdit
}) => {
  const [categoriesOptions, setCategoriesOptions] = useState<any | null>(null);
  const classes = useStyles({});
  var cat: ICodes2 = {};
  const initalCollectionForm = {
    title: '',
    text: '',
    locale: '',
    category: cat,
    metaDescription: '',
    metaKeywords: ''
  };
  const notifier = useNotifier();
  const [collectionForm, setCollectionForm] = useState(initalCollectionForm);
  const [invalidFields, setInvalidFields] = useState([]);
  const [collectionId, setCollectionId] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(0);
  const [deleteCollectionInfo, setDeleteCollectionInfo] = useState<{
    id: string;
    index: number;
  } | null>(null);

  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" color="primary" />;
  const hasCategories = !!categories;

  const { data: localesData } = useQuery<{ Locales: ILocale[] }>(LOCALES);
  const locales: ILocale[] = localesData ? localesData.Locales : [];

  const schema = Joi.object({
    title: Joi.string().required(),
    text: Joi.string().required(),
    locale: Joi.string().required(),
    category: Joi.object()
      .min(hasCategories ? 1 : 0)
      .required()
  });
  useEffect(() => {
    var newCategoriesOptions: ICodes[] = [];
    (categories || []).forEach(category => {
      category.subCategories.forEach(sub => {
        newCategoriesOptions.push({
          value: sub.id,
          label: sub.translations[0].name
        });
      });
    });
    newCategoriesOptions.sort((option1: ICodes, option2: ICodes) =>
      ('' + option1.label).localeCompare(option2.label)
    );
    setCategoriesOptions(newCategoriesOptions);
  }, [categories]);

  const handleSubmit = edit => {
    var validateValues = {
      title: collectionForm.title,
      text: !isEmptyHTML(collectionForm.text) ? collectionForm.text : '',
      locale: collectionForm.locale,
      category: collectionForm.category
    };

    const joiValidate = schema.validate(validateValues, { abortEarly: false });
    if (joiValidate.error) {
      const newInvalidFields = joiValidate.error.details.map(
        field => field.path[0]
      );
      setInvalidFields(newInvalidFields);
    } else {
      const collection: ICollectionContent = {
        locale: collectionForm.locale,
        text: collectionForm.text,
        title: collectionForm.title,
        seoKeywords: collectionForm.metaKeywords,
        seoDescription: collectionForm.metaDescription
      };
      if (!edit) {
        addCollection(collectionForm.category.value, collection);
      } else {
        editCollection(
          collectionForm.category.value,
          collection,
          collectionId,
          editIndex
        );
      }
    }
  };

  const onDeleteCollection = (collectionId, index) => {
    deleteCollection(collectionId, index)
      .then(() => {
        notifier.notify('Efni eytt', { variant: 'success' });
      })
      .catch(e => {
        notifier.notify('Villa kom upp', { variant: 'error' });
      });
  };

  const handleEdit = (collection: ICollection, index: number) => {
    window.scrollTo(0, 0);
    setEditMode(true);
    setEditIndex(index);
    setInvalidFields([]);
    setCollectionId(collection.id);
    const editCollection = {
      title: collection.content.title,
      text: collection.content.text,
      locale: collection.content.locale,
      category: categoriesOptions.length
        ? categoriesOptions.filter(cat => cat.label === collection.category)[0]
        : {},
      metaDescription: collection.content.seoDescription,
      metaKeywords: collection.content.seoKeywords
    };
    setCollectionForm(editCollection);
  };

  if (categoriesOptions) {
    return (
      <div>
        {latest && (
          <Paper className={cx(classes.paperSections, className)}>
            <Grid container direction="row">
              <Typography variant="h6" className={classes.typographyTitle}>
                Efni
              </Typography>
              {
                infoOnClick &&
                <IconButton onClick={(event) => {
                  infoOnClick(event);
                }}>
                  <InfoIcon />
                </IconButton>
              }
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper} elevation={0}>
                  <TextField
                    fullWidth
                    id={'title'}
                    label={'Titill'}
                    variant="outlined"
                    value={collectionForm.title}
                    required
                    error={includes(invalidFields, 'title')}
                    onChange={e =>
                      setCollectionForm({
                        ...collectionForm,
                        title: e.target.value
                      })
                    }
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper} elevation={0}>
                  <RichText
                    id="text"
                    label="Texti"
                    value={collectionForm.text || ''}
                    required
                    error={includes(invalidFields, 'text')}
                    onChange={val =>
                      setCollectionForm({
                        ...collectionForm,
                        text: val
                      })
                    }
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper className={classes.paper} elevation={0}>
                  <FormControl fullWidth variant={'outlined'} required>
                    <InputLabel id={'locale-label'}>Tungumál</InputLabel>
                    <Select
                      id={'locale'}
                      labelId={'locale-label'}
                      labelWidth={80}
                      value={collectionForm.locale}
                      onChange={e =>
                        setCollectionForm({
                          ...collectionForm,
                          locale: e.target.value as string
                        })
                      }
                      error={includes(invalidFields, 'locale')}
                    >
                      {locales.map(locale => (
                        <MenuItem key={locale.value} value={locale.value}>
                          {locale.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Paper>
              </Grid>
              {hasCategories && (
                <Grid item xs={12} sm={6}>
                  <Paper className={classes.paper} elevation={0}>
                    <Autocomplete
                      id={'category'}
                      options={categoriesOptions}
                      onChange={(_, value) =>
                        setCollectionForm({
                          ...collectionForm,
                          category: value
                        })
                      }
                      value={collectionForm.category}
                      getOptionLabel={option => option.label}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.label}
                        </React.Fragment>
                      )}
                      style={{ width: '100%' }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password'
                          }}
                          variant="outlined"
                          label={'Flokkur'}
                          required
                          error={includes(invalidFields, 'category')}
                        />
                      )}
                    />
                  </Paper>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <Paper className={classes.paper} elevation={0}>
                  <TextField
                    fullWidth
                    id={'info'}
                    label={'SEO - Lýsing'}
                    variant="outlined"
                    value={collectionForm.metaDescription}
                    onChange={e =>
                      setCollectionForm({
                        ...collectionForm,
                        metaDescription: e.target.value
                      })
                    }
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper className={classes.paper} elevation={0}>
                  <TextField
                    fullWidth
                    id={'keywords'}
                    label={'SEO - Lykilorð'}
                    variant="outlined"
                    value={collectionForm.metaKeywords}
                    onChange={e =>
                      setCollectionForm({
                        ...collectionForm,
                        metaKeywords: e.target.value
                      })
                    }
                  />
                </Paper>
              </Grid>
              {
                !disableEdit &&
                <Grid item xs={12} sm={12}>
                  <Paper
                    className={classes.paper}
                    elevation={0}
                    style={{ textAlign: 'right' }}
                  >
                    {editMode ? (
                      <React.Fragment>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => handleSubmit(true)}
                          style={{ marginTop: 30 }}
                        >
                          Breyta efni
                        </Button>
                        <Button
                          color="default"
                          variant="contained"
                          onClick={resetForm}
                          style={{ marginTop: 30, marginLeft: 16 }}
                        >
                          Hætta við
                        </Button>
                      </React.Fragment>
                    ) : (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => handleSubmit(false)}
                        style={{ marginTop: 30 }}
                      >
                        Bæta við
                      </Button>
                    )}
                  </Paper>
                </Grid>
              }

            </Grid>
          </Paper>
        )}

        <Paper className={classes.paperSections}>
          <Typography variant="h6" className={classes.typographyTitle}>
            Skráð efni
          </Typography>
          <Grid container spacing={1}>
            {collections &&
              collections.map((collection, index) => (
                <React.Fragment key={index}>
                  <Grid
                    item
                    xs={12}
                    sm={hasCategories ? 4 : 7}
                    key={`${collection.id}-1`}
                  >
                    <Paper
                      className={classes.paperCollection}
                      style={{ marginLeft: 8 }}
                      elevation={0}
                    >
                      <Typography>{collection.content.title}</Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={2} key={`${collection.id}-2`}>
                    <Paper className={classes.paperCollection} elevation={0}>
                      <Typography>
                        {getLocaleLabel(collection.content.locale)}
                      </Typography>
                    </Paper>
                  </Grid>
                  {hasCategories && (
                    <Grid item xs={12} sm={3} key={`${collection.id}-3`}>
                      <Paper className={classes.paperCollection} elevation={0}>
                        <Typography>{collection.category}</Typography>
                      </Paper>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={3} key={`${collection.id}-4`}>
                    <Paper
                      className={classes.paperCollection}
                      style={{ marginRight: 8, textAlign: 'center' }}
                      elevation={0}
                    >
                      <Button
                        color="primary"
                        style={{ padding: 0 }}
                        onClick={() => handleEdit(collection, index)}
                        disabled={!latest}
                      >
                        Breyta
                      </Button>
                      <Button
                        color="secondary"
                        style={{ padding: 0 }}
                        onClick={() =>
                          setDeleteCollectionInfo({ id: collection.id, index })
                        }
                        disabled={!latest}
                      >
                        Eyða
                      </Button>
                    </Paper>
                  </Grid>
                </React.Fragment>
              ))}
          </Grid>
        </Paper>
        {deleteCollectionInfo && (
          <ConfirmDialog
            open
            message="Ertu viss um að þú viljir eyða þessu efni?"
            confirmText="Eyða efni"
            onConfirm={() => {
              onDeleteCollection(
                deleteCollectionInfo.id,
                deleteCollectionInfo.index
              );
              setDeleteCollectionInfo(null);
            }}
            onCancel={() => setDeleteCollectionInfo(null)}
          />
        )}
      </div>
    );
  } else {
    return null;
  }

  function resetForm() {
    setInvalidFields([]);
    setEditMode(false);
    setEditIndex(0);
    setCollectionForm(initalCollectionForm);
  }

  function getLocaleLabel(localeValue: string) {
    for (const locale of locales) {
      if (locale.value === localeValue) {
        return locale.label;
      }
    }
    return localeValue;
  }
};

export default EditCollection;

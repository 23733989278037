import { gql } from 'apollo-boost';
import { regionsFragment } from '../fragments/UserFragments';

export const SET_REGION_EMAIL = gql`
    mutation setRegionEmail(
        $id: String!
        $email: String!
    ) {
        setRegionEmail(id: $id, email: $email) {
            success
        }
    }
`;
import { gql } from 'apollo-boost';
import { placeFragment } from './PlaceFragments';

export const vakinnFragment = gql`
  fragment vakinn on IServiceProvider {
    vakinn {
      isApprovedRestaurant
      isApprovedTourismService
      stars
      ecoLabel
      superior
      iso
      swan 
      blueFlag
      earthcheck
      greenKey
    }
  }
`;
export const signsFragment = gql`
  fragment signs on IServiceProvider {
    signs {
      id
      value
      translations {
        locale
        name
      }
      postFix
      icon
    }
  }
`;
export const openinghoursFragment = gql`
  fragment openingHours on IServiceProvider {
    openingHours {
      seasonal
      from
      to
    }
  }
`;
export const contactinfoFragment = gql`
  fragment contactInfo on IServiceProvider {
    contactInfo {
      name
      email
      phone
      comment
    }
  }
`;
export const photosFragment = gql`
  fragment photos on IServiceProvider {
    photos {
      photoId
      photoUri
      alt
      title
    }
  }
`;

export const contentFragment = gql`
  fragment content on IServiceProvider {
    content {
      locale
      text
      title
      seoKeywords
      seoDescription
    }
  }
`;

export const locationFragment = gql`
  fragment location on IServiceProvider {
    location {
      type
      coordinates
    }
  }
`;

export const subCategoriesFragment = gql`
  fragment subCategories on ICategory {
    subCategories {
      id
      translations {
        locale
        name
      }
    }
  }
`;
export const categoriesFragment = gql`
  fragment categories on IServiceProvider {
    categories {
      id
      translations {
        locale
        name
      }
      ...subCategories
    }
  }
  ${subCategoriesFragment}
`;

export const relatedServiceProvidersFragment = gql`
  fragment relatedServiceProviders on IServiceProvider {
    relatedServiceProviders {
      serviceProviderId
      legalName
      email
    }
  }
`;

export const serviceProviderFragment = gql`
  fragment serviceProvider on IServiceProvider {
    serviceProviderId
    version
    legalName
    translations {
      name
      locale
      description
      seoTitle
      seoDescription
      seoKeywords
    }
    SSN
    address
    poBox
    zipCode
    townCode
    eService
    phoneNr
    phoneNr2
    email
    email2
    website
    website2
    facebook
    instagram
    tripadvisor
    youtube
    nrOfRooms
    nrOfBeds
    nrOfHouses
    nrOfApartments
    nrOfSleepingBagSpace
    regions{
        id
        translations{
            locale
            name
        }
    }
    categoryIds
    purchasedRegionIds
    placeIds
    updatedAt
    updatedBy
    isPrimary
    active
    ownerEmail
    purchasedRegistration{
      id
        translations{
            locale
            name
        }
    }
    version
    giftcert
    cleanAndSafe
    accessible
    isat
    permitId
    permitIssued
    permitImgUri
    aliases
    chargingStationIds
    relatedPlaces {
      ...place
    }
    ...vakinn
    ...signs
    ...openingHours
    ...contactInfo
    ...photos
    ...location
    ...categories
    ...relatedServiceProviders
  },
    ${vakinnFragment},
    ${signsFragment},
    ${openinghoursFragment},
    ${contactinfoFragment},
    ${photosFragment},
    ${locationFragment},
    ${categoriesFragment}
    ${relatedServiceProvidersFragment}
    ${placeFragment}
`;

export const serviceProviderDetailsFragment = gql`
  fragment serviceProviderDetails on IServiceProvider {
    serviceProviderId
    version
    legalName
    translations {
      name
      locale
      description
      seoTitle
      seoDescription
      seoKeywords
    }
    SSN
    address
    poBox
    zipCode
    townCode
    eService
    phoneNr
    phoneNr2
    email
    email2
    website
    website2
    facebook
    instagram
    tripadvisor
    youtube
    nrOfRooms
    nrOfBeds
    nrOfHouses
    nrOfApartments
    nrOfSleepingBagSpace
    regions {
        id
        translations {
            locale
            name
        }
    }
    categoryIds
    purchasedRegionIds
    placeIds
    updatedAt
    updatedBy
    isPrimary
    recentlyValidated
    validatedAt
    active
    ownerEmail
    purchasedRegistration {
      id
        translations{
            locale
            name
        }
    }
    giftcert,
    chargingStationIds,
    cleanAndSafe,
    accessible,
    ...vakinn
    ...signs
    ...openingHours
    ...contactInfo
    ...location
    ...categories
  },
    ${vakinnFragment},
    ${signsFragment},
    ${openinghoursFragment},
    ${contactinfoFragment},
    ${locationFragment},
    ${categoriesFragment}
`;

export const serviceProviderLiteFragment = gql`
  fragment serviceProviderLite on IServiceProvider {
    serviceProviderId
    legalName
    translations {
      locale
      name
    }
    email
    address
    poBox
    zipCode
    townCode
    eService
    phoneNr
    updatedBy
    updatedAt
    createdBy
    createdAt
    active
    SSN
    validatedAt
    recentlyValidated
    purchasedRegistration {
      id
      translations {
        locale
        name
      }
    }
  }
`;

import { gql } from 'apollo-boost';

export const ZIP_CODES = gql`
  {
    ZipCodes {
      label
      value
    }
  }
`;

export const GET_DATA = gql`
  {
    Categories {
      translations {
        locale
        name
      }
      subCategories {
        id
        translations {
          locale
          name
        }
      }
    }
    ZipCodes {
      label
      value
    }
    TownCodes {
      label
      value
    }
    Regions {
      id
      translations {
        locale
        name
      }
    }
    EcoLabels {
      label
      value
    }

    Signs {
      id
      translations {
        locale
        name
      }
      icon
      postFix
    }
  }
`;

export const GET_DATA_WITH_GEO = gql`
  {
    Categories {
      translations {
        locale
        name
      }
      subCategories {
        id
        translations {
          locale
          name
        }
      }
    }
    ZipCodes {
      label
      value
    }
    TownCodes {
      label
      value
    }
    Regions {
      id
      translations {
        locale
        name
      }
    }
    EcoLabels {
      label
      value
    }

    Signs {
      id
      translations {
        locale
        name
      }
      icon
      postFix
    }
    GeoFences {
      id
      type
      properties {
        translations {
          locale
          name
        }
      }
    }
  }
`;

export const GET_CATEGORIES = gql`
  query getCategories {
    Categories {
      id
      translations {
        locale
        name
      }
      subCategories {
        id
        translations {
          locale
          name
        }
      }
    }
  }
`;

export const REGIONS = gql`
  {
    Regions {
      id
      translations {
        name
        locale
      }
    }
  }
`;

export const LOCALES = gql`
  {
    Locales {
      label
      value
    }
  }
`;

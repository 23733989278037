import React, { useState } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Paper,
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box
} from '@material-ui/core';
import includes from 'lodash/includes';
import Joi from '@hapi/joi';
import cx from 'classnames';
import { useQuery } from 'react-apollo';

import { IMunicipalityTranslation as ITranslation } from '../interfaces/Municipality/Municipality';
import { ILocale } from '../interfaces/Misc/zipCodes';
import { LOCALES } from '../graphql/queries/Misc';
import { isEmptyHTML } from '../utils/markup';
import RichText from './RichText';
import ConfirmDialog from './ConfirmDialog';

interface IProps {
  translations: ITranslation[];
  className?: string;
  hasWebsiteField?: boolean;
  nameLabel?: string;
  addTranslation: (translation: ITranslation) => void;
  editTranslation: (translation: ITranslation, index: number) => void;
  deleteTranslation: (index: number) => void;
  disableEdit: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2)
    },
    paperTranslation: {
      backgroundColor: '#f4f4f7',
      padding: theme.spacing(2)
    },
    typographyTitle: {
      padding: 20,
      paddingBottom: 5,
      paddingTop: 10
    },
    paperSections: {
      marginTop: 20
    }
  })
);

const initialValues = {
  name: '',
  description: '',
  locale: '',
  seoTitle: '',
  seoKeywords: '',
  seoDescription: '',
  website: ''
};

const schema = Joi.object({
  name: Joi.string().required(),
  description: Joi.string().required(),
  locale: Joi.string().required()
});

const EditTranslations: React.FC<IProps> = ({
  translations,
  className,
  hasWebsiteField,
  nameLabel = 'Nafn',
  addTranslation,
  editTranslation,
  deleteTranslation,
  disableEdit
}) => {
  const classes = useStyles();

  const [translationForm, setTranslationForm] = useState(initialValues);
  const [invalidFields, setInvalidFields] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [addMode, setAddMode] = useState(false);
  const [editIndex, setEditIndex] = useState(0);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

  const { data: localesData } = useQuery<{ Locales: ILocale[] }>(LOCALES);
  const allLocales: ILocale[] = localesData ? localesData.Locales : [];
  const currentLocales = translations.map(t => t.locale);
  const locales = allLocales.filter(
    l => l.value === translationForm.locale || !currentLocales.includes(l.value)
  );

  const disableForm = !editMode && !addMode && !locales.length;

  const handleSubmit = edit => {
    var validateValues = {
      name: translationForm.name,
      description: !isEmptyHTML(translationForm.description)
        ? translationForm.description
        : '',
      locale: translationForm.locale
    };
    const joiValidate = schema.validate(validateValues, { abortEarly: false });

    if (joiValidate.error) {
      const newInvalidFields = joiValidate.error.details.map(
        field => field.path[0]
      );
      setInvalidFields(newInvalidFields);
    } else {
      const translation: ITranslation = {
        ...translationForm,
        website: hasWebsiteField ? translationForm.website : undefined
      };

      if (!edit) {
        addTranslation(translation);
        setAddMode(false);
        resetForm();
      } else {
        editTranslation(translation, editIndex);
        setEditMode(false);
        resetForm();
      }
    }
  };

  const onDeleteTranslation = index => {
    deleteTranslation(index);
    resetForm();
  };

  const handleEdit = (translation: ITranslation, index: number) => {
    window.scrollTo(0, 0);
    setEditMode(true);
    setEditIndex(index);
    setInvalidFields([]);
    setTranslationForm({
      name: translation.name || '',
      description: translation.description || '',
      locale: translation.locale || '',
      seoTitle: translation.seoTitle || '',
      seoKeywords: translation.seoKeywords || '',
      seoDescription: translation.seoDescription || '',
      website: translation.website || ''
    });
  };

  if (translations) {
    translations.map(trans => {
      if (!trans.name && !includes(invalidFields, 'name')) {
        let invalids = invalidFields;
        invalids.push('name');
        setInvalidFields(invalids);
      }
      if (!trans.locale && !includes(invalidFields, 'locale')) {
        let invalids = invalidFields;
        invalids.push('locale');
        setInvalidFields(invalids);
      }
      if (!trans.description && !includes(invalidFields, 'description')) {
        let invalids = invalidFields;
        invalids.push('description');
        setInvalidFields(invalids);
      }
      return null;
    });
  }

  return (
    <div>
      <Paper className={cx(classes.paperSections, className)}>
        <Box
          border={
            invalidFields.length > 0 || translations.length < allLocales.length
              ? 1
              : 0
          }
          borderColor={
            invalidFields.length > 0 || translations.length < allLocales.length
              ? 'red'
              : undefined
          }
        >
          <Typography variant="h6" className={classes.typographyTitle}>
            Skráð efni
          </Typography>
          <Grid container spacing={1} style={{ paddingBottom: 20 }}>
            {(translations || []).map((translation, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={7}>
                  <Paper
                    className={classes.paperTranslation}
                    style={{ marginLeft: 8 }}
                    elevation={0}
                  >
                    <Typography>{translation.name}</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Paper className={classes.paperTranslation} elevation={0}>
                    <Typography>
                      {getLocaleLabel(translation.locale)}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Paper
                    className={classes.paperTranslation}
                    style={{ marginRight: 8, textAlign: 'center' }}
                    elevation={0}
                  >
                    <Button
                      color="primary"
                      style={{ padding: 0 }}
                      onClick={() => handleEdit(translation, index)}
                    >
                      Breyta
                    </Button>
                    <Button
                      color="secondary"
                      style={{ padding: 0 }}
                      onClick={() => setDeleteIndex(index)}
                    >
                      Eyða
                    </Button>
                  </Paper>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Box>
      </Paper>
      {!editMode && !addMode && translations.length !== allLocales.length && (
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              style={{ marginTop: 15 }}
              onClick={() => setAddMode(true)}
            >
              Bæta við efni
            </Button>
          </Grid>
        </Grid>
      )}
      {(editMode || addMode) && (
        <Paper className={classes.paperSections}>
          <Typography variant="h6" className={classes.typographyTitle}>
            Textalýsing
          </Typography>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12}>
              <Paper className={classes.paper} elevation={0}>
                <TextField
                  fullWidth
                  id="name"
                  label={nameLabel}
                  variant="outlined"
                  value={translationForm.name}
                  required
                  error={includes(invalidFields, 'name')}
                  disabled={disableForm}
                  onChange={e =>
                    setTranslationForm({
                      ...translationForm,
                      name: e.target.value
                    })
                  }
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Paper className={classes.paper} elevation={0}>
                <RichText
                  id="description"
                  label="Texti"
                  value={translationForm.description || ''}
                  required
                  error={includes(invalidFields, 'description')}
                  disabled={disableForm}
                  onChange={val =>
                    setTranslationForm({
                      ...translationForm,
                      description: val
                    })
                  }
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper} elevation={0}>
                <FormControl fullWidth variant={'outlined'} required>
                  <InputLabel id={'locale-label'}>Tungumál</InputLabel>
                  <Select
                    id="locale"
                    labelId="locale-label"
                    labelWidth={80}
                    value={translationForm.locale}
                    onChange={e =>
                      setTranslationForm({
                        ...translationForm,
                        locale: e.target.value as string
                      })
                    }
                    error={includes(invalidFields, 'locale')}
                    disabled={disableForm}
                  >
                    {locales.map(locale => (
                      <MenuItem key={locale.value} value={locale.value}>
                        {locale.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper} elevation={0}>
                <TextField
                  fullWidth
                  id="seoTitle"
                  label={'SEO - Titill'}
                  variant="outlined"
                  value={translationForm.seoTitle}
                  disabled={disableForm}
                  onChange={e =>
                    setTranslationForm({
                      ...translationForm,
                      seoTitle: e.target.value
                    })
                  }
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper} elevation={0}>
                <TextField
                  fullWidth
                  id="seoDescription"
                  label={'SEO - Lýsing'}
                  variant="outlined"
                  value={translationForm.seoDescription}
                  disabled={disableForm}
                  onChange={e =>
                    setTranslationForm({
                      ...translationForm,
                      seoDescription: e.target.value
                    })
                  }
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper} elevation={0}>
                <TextField
                  fullWidth
                  id="seoKeywords"
                  label={'SEO - Lykilorð'}
                  variant="outlined"
                  value={translationForm.seoKeywords}
                  disabled={disableForm}
                  onChange={e =>
                    setTranslationForm({
                      ...translationForm,
                      seoKeywords: e.target.value
                    })
                  }
                />
              </Paper>
            </Grid>
            {hasWebsiteField && (
              <Grid item xs={12} sm={6}>
                <Paper className={classes.paper} elevation={0}>
                  <TextField
                    fullWidth
                    id="website"
                    label={'Vefsíða'}
                    variant="outlined"
                    value={translationForm.website}
                    disabled={disableForm}
                    onChange={e =>
                      setTranslationForm({
                        ...translationForm,
                        website: e.target.value
                      })
                    }
                  />
                </Paper>
              </Grid>
            )}
            {
              !disableEdit &&
              <Grid item xs={12} sm={12}>
                <Paper
                  className={classes.paper}
                  elevation={0}
                  style={{ textAlign: 'right' }}
                >
                  {editMode && !addMode ? (
                    <React.Fragment>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => handleSubmit(true)}
                        style={{ marginTop: 30 }}
                      >
                        Breyta efni
                      </Button>
                      <Button
                        color="default"
                        variant="contained"
                        onClick={resetForm}
                        style={{ marginTop: 30, marginLeft: 16 }}
                        disabled={disableForm}
                      >
                        Hætta við
                      </Button>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => handleSubmit(false)}
                        style={{ marginTop: 30 }}
                        disabled={disableForm}
                      >
                        Bæta við
                      </Button>
                      <Button
                        color="default"
                        variant="contained"
                        onClick={() => setAddMode(false)}
                        style={{ marginTop: 30, marginLeft: 16 }}
                        disabled={disableForm}
                      >
                        Hætta við
                      </Button>
                    </React.Fragment>
                  )}
                </Paper>
              </Grid>
            }

          </Grid>
        </Paper>
      )}

      <ConfirmDialog
        open={deleteIndex !== null}
        message="Ertu viss um að þú viljir eyða þessari þýðingu?"
        confirmText="Eyða"
        onConfirm={() => {
          onDeleteTranslation(deleteIndex);
          setDeleteIndex(null);
        }}
        onCancel={() => setDeleteIndex(null)}
      />
    </div>
  );

  function resetForm() {
    setInvalidFields([]);
    setEditMode(false);
    setEditIndex(0);
    setTranslationForm(initialValues);
  }

  function getLocaleLabel(localeValue: string) {
    for (const locale of allLocales) {
      if (locale.value === localeValue) {
        return locale.label;
      }
    }
    return localeValue;
  }
};

export default EditTranslations;
